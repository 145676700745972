<script>
import { vueTopprogress } from 'vue-top-progress';
import { api } from '@/services/ServiceConfig/ServiceConfig.js';

import Vue from 'vue';

import HelperPlugin from '@/plugins/HelperPlugin';
import { email } from 'vuelidate/lib/validators';
Vue.use(HelperPlugin);

import { cpf } from '@/validate/customValidators.js';

import settingsService from '@/services/Settings/settingsService';

export default {
  components: {
    vueTopprogress,
  },
  data() {
    return {
      modalidade: process.env.VUE_APP_TYPE,
      submodalidade: process.env.VUE_APP_SUBTYPE,
      user: {},
      envPath: process.env.VUE_APP_ROOT_SITE,
      cpfAtivo: false,
      emailAtivo: true,
      emailToShow: '',
      titulo: '',
    };
  },
  validations: {
    user: {
      email: {
        valid: email,
      },
      cpf: {
        valid: cpf,
      },
    },
  },
  mounted() {
    this.checkUserLogged();
    this.getSettings();
  },
  computed: {
    appTitle() {
      return process.env.VUE_APP_TITLE;
    },
    loggedInUser() {
      return this.$store.getters.isLoggedIn;
    },
    investorLogged() {
      return this.$store.getters.isLoggedInvestor;
    },
    ref() {
      return this.$route.params.ref;
    },
  },
  methods: {
    redirectTo() {
      this.$router.push('/dashboard');
    },
    async getSettings() {
      let privateSettings = (await settingsService.getPrivateSettings()) || {};

      this.titulo = privateSettings.platformName || '';
    },
    checkUserLogged() {
      if (Object.keys(this.loggedInUser).length > 0) {
        this.redirectTo();
      }
    },
    navigateToSite() {
      this.$router.push('/login');
    },
    changeToCpf() {
      this.cpfAtivo = true;
      this.emailAtivo = false;
    },
    changeToEmail() {
      this.cpfAtivo = false;
      this.emailAtivo = true;
    },
    async recoveryPassword() {
      var cpfError = this.cpfAtivo ? !this.$v.user.cpf.valid : false;
      var emailError = this.emailAtivo ? !this.$v.user.email.valid : false;

      if (cpfError || emailError) {
        return this.$notify({
          title: 'Informe os dados corretamente!',
          message:
            'Um ou mais dados informados não foram preenchidos corretamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 7000,
        });
      }

      const { email, cpf } = this.user;
      var emailToSend = email;
      this.emailToShow = '';

      this.$refs.topProgress.start();

      try {
        await api.post('/forgot-password', {
          email: email,
          cpf: cpf,
          type: this.cpfAtivo ? 'cpf' : 'email',
        });

        this.$notify({
          title: 'Enviado com Sucesso!',
          message:
            'Caso os dados sejam válidos, será enviado o email de recuperação para o email cadastrado.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
        this.$refs.topProgress.done();
      } catch (error) {
        this.$notify({
          title: 'Ooppss!',
          message: 'Ocorreu um erro ao enviar o e-mail de recuperação!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },
  },
};
</script>

<template>
  <div class="sign-in">
    <div class="signin2"></div>
    <div class="card">
      <div class="back" style="margin-top: -30px !important">
        <i
          class="fa fa-arrow-left"
          style="color: #fff"
          @click="navigateToSite()"
        ></i>
      </div>

      <img
        src="@/assets/images/logo_ajx.png"
        alt="Customer Logo"
        class="brand"
        style="width: 200px !important"
      />

      <br />

      <!-- <h1
        style="
          font-size: 28px;
          color: #000;
          font-family: Poppins, Arial, Helvetica, sans-serif !important;
          font-weight: bold;
        "
      >
        {{ titulo }}
      </h1> -->
      <p style="text-align: center">
        Digite seu e-mail ou seu CPF para receber um link de recuperação
      </p>

      <form @submit.prevent="recoveryPassword()" class="login-form">
        <div class="form-group" v-if="emailAtivo">
          <label for="" class="control-label">Email</label>
          <input
            v-model="user.email"
            data-testId="email"
            type="email"
            class="form-control"
            placeholder="email@dominio.com"
            required
          />
          <div class="error" v-if="user.email != '' && !$v.user.email.valid">
            Informe um e-mail válido.
          </div>
        </div>

        <div class="form-group" v-if="cpfAtivo">
          <label for="" class="control-label">CPF</label>
          <input
            v-model="user.cpf"
            data-testId="cpf"
            type="cpf"
            class="form-control"
            placeholder="999.999.999-99"
            v-mask="'###.###.###-##'"
            required
          />
          <div class="error" v-if="user.cpf != '' && !$v.user.cpf.valid">
            Informe um CPF válido.
          </div>
        </div>

        <div class="btn-group" role="group" aria-label="Basic example">
          <button
            type="button"
            v-if="emailAtivo"
            class="btn"
            style="
              background-color: #79c5f2 !important;
              color: #12100b !important;
              background-image: none !important;
            "
            @click="changeToEmail"
          >
            Informar e-mail
          </button>
          <button
            type="button"
            v-if="!emailAtivo"
            class="btn btn-warning"
            @click="changeToEmail"
          >
            Informar e-mail
          </button>

          <button
            type="button"
            v-if="cpfAtivo"
            class="btn"
            style="
              background-color: #79c5f2 !important;
              color: #12100b !important;
              background-image: none !important;
            "
            @click="changeToCpf"
          >
            Informar CPF
          </button>
          <button
            type="button"
            v-if="!cpfAtivo"
            class="btn btn-warning"
            @click="changeToCpf"
          >
            Informar CPF
          </button>
        </div>
        <button
          type="submit"
          class="btn btn-primary"
          style="
            background-color: #79c5f2 !important;
            color: #12100b !important;
          "
        >
          Enviar e-mail
        </button>
        <br />
        <br />
        <p
          style="text-align: center; font-size: 12px; font-style: italic"
          v-if="emailToShow.length != ''"
        >
          Um e-mail foi enviado para: <b>{{ emailToShow }}</b>
        </p>
      </form>
    </div>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>

<style scoped>
/*   AJX CSS  */

.sign-in {
  background-color: #12100b !important;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/ecwl-ajx-stage.appspot.com/o/auth-background.png?alt=media&token=3cc7d49f-9fee-4fba-8053-62412d544729);
  background-repeat: no-repeat;
}

.signin2 {
  background-color: #000 !important;
  opacity: 0.8;
  width: 100%;
  position: absolute;
  height: 100vh;
}

p {
  color: #fff;
}

.sign-in form > button.btn {
  background: #0006b4 !important;
  color: #e4e6ed !important;
}

.sign-in form > button.btn:hover {
  background: #00035a !important;
}

.sign-in > .card {
  background-color: #333;
  border-radius: 0px;
  border-color: #000 !important;
  border-width: 1px !important;
  border: outset;
  box-shadow: rgb(0 0 0 / 90%) 0px 0px 30px;
}

.Password__field {
  padding: 13px !important;
  border: 1px solid #f1f1f1 !important;
  border-radius: 2px !important;
  height: 48px !important;
}

.form-control {
  border-color: #fff !important;
  color: #fff;
}

.control-label {
  color: #fff;
}

div .error {
  color: #f00;
}
</style>
