<script>
import CertificationTable from '@/components/BrokerArea/Certification/CertificationTable';
import certificationsService from '@/services/Certifications/certificationsService';

import { vueTopprogress } from 'vue-top-progress';
import { DoubleBounce } from 'vue-loading-spinner';
import logsService from '@/services/Logs/logsService';

const tableColumns = ['Título', 'Data', 'Nome arquivo', 'Ações'];

export default {
  components: {
    CertificationTable,
    vueTopprogress,
    DoubleBounce,
  },
  data() {
    return {
      table: {
        title: 'Minhas certificações',
        columns: [...tableColumns],
      },
      certifications: [],
      ajx_certifications: [],
      isLoading: true,
      closeModal: false,
    };
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
  },
  mounted() {
    this.getCertifications();
  },
  methods: {
    async getCertifications() {
      this.$refs.topProgress.start();
      this.certifications = await certificationsService.getAllCertifications() || [];
      if (this.certifications.lenght === 0) {
        this.$notify({
          title: 'Sem notas fiscais para mostrar',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.isLoading = false;
      this.$refs.topProgress.done();
    },
    async deleteCertification(preDeletedItem) {
      try {
        const logUser = {
          user: {
            email: this.currentPessoa.email.email,
          },
        };
        const logContent = preDeletedItem.item;
        logsService.createNewLog(logUser, '', 'Excluir Certification', '', logContent);

        await certificationsService.deleteCertification(preDeletedItem.item.id);
        await certificationsService.deleteCertificationInStorage(preDeletedItem.item.filename);

        this.getCertifications();

        this.$notify({
          title: 'Nota fiscal Deletada com sucesso',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        preDeletedItem = {};
      } catch (error) {
        console.log(error);
        this.$notify({
          title: 'Houve um erro ao deletar a nota fiscal',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<template>
  <div class="users-list">
    <div class="row">
      <div class="col-12">
        <card
          :title="`Certificações AJX`"
          :addItems="false"
          :itemType="'Certificação'"
          :routerType="'certification'"
        >
          <h3 v-if="ajx_certifications.length === 0">Nenhuma certificação AJX emitida até o momento.</h3>
          <div class="table-responsive" v-if="ajx_certifications.length > 0">
            <certification-table
              :data="ajx_certifications"
              :columns="table.columns"
              thead-classes="text-primary"
              @deleteItem="deleteCertification"
            ></certification-table>

            <double-bounce v-if="isLoading"></double-bounce>
          </div>
        </card>

        <card
          :title="`${table.title} (${this.certifications.length})`"
          :addItems="true"
          :itemType="'Certificação'"
          :routerType="'certification'"
        >
          <div class="table-responsive">
            <certification-table
              :data="certifications"
              :columns="table.columns"
              thead-classes="text-primary"
              @deleteItem="deleteCertification"
            ></certification-table>

            <double-bounce v-if="isLoading"></double-bounce>
          </div>
        </card>
      </div>
    </div>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
