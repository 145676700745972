import store from '@/store/index';
import Vue from 'vue';
import InstancePlugin from '@/plugins/InstancePlugin';
Vue.use(InstancePlugin);

const modalidade = process.env.VUE_APP_TYPE;

// GeneralViews
import DashboardLayout from '@/layout/dashboard/DashboardLayout.vue';
import NotFound from '@/pages/NotFoundPage.vue';

// Admin pages
const Dashboard = Vue.prototype.$CWLGetPageInstance('Dashboard.vue');
const Profile = Vue.prototype.$CWLGetPageInstance('Profile.vue');
const SignIn = Vue.prototype.$CWLGetPageInstance('Authentication/SignIn.vue');
const SignUp = Vue.prototype.$CWLGetPageInstance('Authentication/SignUp.vue');

const SocialAgent = Vue.prototype.$CWLGetPageInstance(
  'SocialTransformation/SocialAgent.vue',
);
const EditSocialAgent = Vue.prototype.$CWLGetPageInstance(
  'SocialTransformation/EditSocialAgent.vue',
);

const Users = Vue.prototype.$CWLGetPageInstance('Operationals/Users.vue');
const AddUser = Vue.prototype.$CWLGetPageInstance('Operationals/AddUser.vue');
const EditUser = Vue.prototype.$CWLGetPageInstance('Operationals/EditUser.vue');

const Benefits = Vue.prototype.$CWLGetPageInstance('Benefits/Benefits.vue');
const AddBenefit = Vue.prototype.$CWLGetPageInstance('Benefits/AddBenefit.vue');
const EditBenefit = Vue.prototype.$CWLGetPageInstance(
  'Benefits/EditBenefit.vue',
);

const Brokers = Vue.prototype.$CWLGetPageInstance('Brokers/Brokers.vue');
const AddBroker = Vue.prototype.$CWLGetPageInstance('Brokers/AddBroker.vue');
const EditBroker = Vue.prototype.$CWLGetPageInstance('Brokers/EditBroker.vue');

const Banks = Vue.prototype.$CWLGetPageInstance('Banks/Banks.vue');
const AddBank = Vue.prototype.$CWLGetPageInstance('Banks/AddBank.vue');
const EditBank = Vue.prototype.$CWLGetPageInstance('Banks/EditBank.vue');

const Categorias = Vue.prototype.$CWLGetPageInstance(
  'Categorias/Categorias.vue',
);
const AddCategoria = Vue.prototype.$CWLGetPageInstance(
  'Categorias/AddCategoria.vue',
);
const EditCategoria = Vue.prototype.$CWLGetPageInstance(
  'Categorias/EditCategoria.vue',
);

const SegmentInterest = Vue.prototype.$CWLGetPageInstance(
  'SegmentInterest/SegmentInterest',
);
const EditSegment = Vue.prototype.$CWLGetPageInstance(
  'SegmentInterest/EditSegment',
);
const AddSegment = Vue.prototype.$CWLGetPageInstance(
  'SegmentInterest/AddSegment',
);

const States = Vue.prototype.$CWLGetPageInstance('States/States');
const EditState = Vue.prototype.$CWLGetPageInstance('States/EditState');
const AddState = Vue.prototype.$CWLGetPageInstance('States/AddState');

const Financing = Vue.prototype.$CWLGetPageInstance('Financing/Financing');
const AddFinancing = Vue.prototype.$CWLGetPageInstance(
  'Financing/AddFinancing',
);
const EditFinancing = Vue.prototype.$CWLGetPageInstance(
  'Financing/EditFinancing',
);
const ResumeFinancing = Vue.prototype.$CWLGetPageInstance(
  'Financing/ResumeFinancing',
);
const Contributions = Vue.prototype.$CWLGetPageInstance(
  'Financing/Contributions',
);

const Values = Vue.prototype.$CWLGetPageInstance('Values/Values');
const AddValue = Vue.prototype.$CWLGetPageInstance('Values/AddValue');
const EditValue = Vue.prototype.$CWLGetPageInstance('Values/EditValue');

const InvestimentsProfile = Vue.prototype.$CWLGetPageInstance(
  'InvestmentsProfile/InvestmentsProfile',
);
const AddInvestment = Vue.prototype.$CWLGetPageInstance(
  'InvestmentsProfile/AddInvestment',
);
const EditInvestment = Vue.prototype.$CWLGetPageInstance(
  'InvestmentsProfile/EditInvestment',
);

const Attachments = Vue.prototype.$CWLGetPageInstance(
  'Attachments/AttachmentsList',
);
const AddAtachment = Vue.prototype.$CWLGetPageInstance(
  'Attachments/AddAttachment',
);

const TermsOfUseList = Vue.prototype.$CWLGetPageInstance(
  'TermsOfUse/TermsOfUseList',
);
const AddTermOfUse = Vue.prototype.$CWLGetPageInstance(
  'TermsOfUse/AddTermOfUse',
);
const TermOfUse = Vue.prototype.$CWLGetPageInstance('TermsOfUse/TermOfUse');

const InvestorsList = Vue.prototype.$CWLGetPageInstance(
  'Investors/InvestorsList',
);
const InvestorDetail = Vue.prototype.$CWLGetPageInstance(
  'Investors/InvestorDetail',
);

const CampaignDetails = Vue.prototype.$CWLGetPageInstance(
  'Campaigns/CampaignDetails',
);

const MyOrdersList = Vue.prototype.$CWLGetPageInstance('MyOrders/MyOrdersList');
const OrdersList = Vue.prototype.$CWLGetPageInstance('Orders/OrdersList');
const AllOrdersList = Vue.prototype.$CWLGetPageInstance(
  'Orders/AllOrdersList.vue',
);

const Logs = Vue.prototype.$CWLGetPageInstance('Logs/Logs');

const DocumentsList = Vue.prototype.$CWLGetPageInstance(
  'Documents/DocumentsList',
);
const AttachIdentificationDocument = Vue.prototype.$CWLGetPageInstance(
  'Documents/AttachIdentificationDocument',
);
const AttachIncomeDocument = Vue.prototype.$CWLGetPageInstance(
  'Documents/AttachIncomeDocument',
);
const AttachAddressDocument = Vue.prototype.$CWLGetPageInstance(
  'Documents/AttachAddressDocument',
);
const AttachOtherDocument = Vue.prototype.$CWLGetPageInstance(
  'Documents/AttachOtherDocument',
);

const ReportsList = Vue.prototype.$CWLGetPageInstance('Reports/ReportsList');
const AnnualReport = Vue.prototype.$CWLGetPageInstance('Reports/AnnualReport');
const PaymentReport = Vue.prototype.$CWLGetPageInstance(
  'Reports/PaymentReport',
);
const OffersReport = Vue.prototype.$CWLGetPageInstance('Reports/OffersReport');
const CampaignGeneral = Vue.prototype.$CWLGetPageInstance(
  'Reports/CampaignsGeneral',
);
const OrdersGeneral = Vue.prototype.$CWLGetPageInstance(
  'Reports/OrdersGeneral',
);
const CampaignStatement = Vue.prototype.$CWLGetPageInstance(
  'Reports/CampaignStatement',
);
const VencimentoCCBsGeral = Vue.prototype.$CWLGetPageInstance(
  'Reports/VencimentoCCBsGeral',
);

const DynamicFields = Vue.prototype.$CWLGetPageInstance(
  'DynamicFields/DynamicFields',
);
const AddDynamicField = Vue.prototype.$CWLGetPageInstance(
  'DynamicFields/AddDynamicField',
);
const EditDynamicField = Vue.prototype.$CWLGetPageInstance(
  'DynamicFields/EditDynamicField',
);
const Settings = Vue.prototype.$CWLGetPageInstance('Settings/Settings');
const Transactions = Vue.prototype.$CWLGetPageInstance(
  'Transactions/Transactions',
);

const ForgotPassword = Vue.prototype.$CWLGetPageInstance(
  'Authentication/ForgotPassword',
);

//Social Transformation
const RegisterSocialTransformationAgent = Vue.prototype.$CWLGetPageInstance(
  'SocialTransformation/RegisterSocialTransformationAgent',
);

//new tuff
const VideoContentList = Vue.prototype.$CWLGetPageInstance(
  'VideoContent/VideoContentList',
);
const PlaylistContentList = Vue.prototype.$CWLGetPageInstance(
  'PlaylistContent/PlaylistContentList',
);
const AddPlaylist = Vue.prototype.$CWLGetPageInstance(
  'PlaylistContent/AddPlaylist',
);

const AddVideoContent = Vue.prototype.$CWLGetPageInstance(
  'VideoContent/AddVideo',
);
const NoticiasMercado = Vue.prototype.$CWLGetPageInstance(
  'NoticiasMercado/NoticiasMercadoDashboard',
);
const NoticiasMercadoVideosList = Vue.prototype.$CWLGetPageInstance(
  'NoticiasMercado/NoticiasMercadoVideosList',
);
const NoticiasMercadoVideoDetails = Vue.prototype.$CWLGetPageInstance(
  'NoticiasMercado/NoticiasMercadoVideoDetails',
);
const EbookListDetails = Vue.prototype.$CWLGetPageInstance(
  'NoticiasMercado/EbookListDetails',
);
const EbookList = Vue.prototype.$CWLGetPageInstance(
  'NoticiasMercado/EbookList',
);

// Ebooks CRUD
const AddNews = Vue.prototype.$CWLGetPageInstance('NewsContent/AddNews.vue');
const NewsList = Vue.prototype.$CWLGetPageInstance('NewsContent/NewsList.vue');

//Meetings
const Meetings = Vue.prototype.$CWLGetPageInstance('Meet/Meetings.vue');

//Exam
const Exam = Vue.prototype.$CWLGetPageInstance('Exam/Exam');
const EvalExam = Vue.prototype.$CWLGetPageInstance('Exam/EvaluateExam');

// Survey
const Survey = Vue.prototype.$CWLGetPageInstance('Survey/Survey');

//Universidade do saber
const Academy = Vue.prototype.$CWLGetPageInstance(
  'UniversityKnowledge/Academy',
);
const CRM = Vue.prototype.$CWLGetPageInstance('UniversityKnowledge/CRM');
const AcademyBooks = Vue.prototype.$CWLGetPageInstance(
  'UniversityKnowledge/AcademyBooks',
);

//Area assessor
const Invoices = Vue.prototype.$CWLGetPageInstance('FiscalRecord/invoices.vue');
const AddInvoice = Vue.prototype.$CWLGetPageInstance(
  'FiscalRecord/AddInvoice.vue',
);
const EditInvoice = Vue.prototype.$CWLGetPageInstance(
  'FiscalRecord/EditInvoice.vue',
);
const Certifications = Vue.prototype.$CWLGetPageInstance(
  'Certifications/certifications',
);
const AddCertification = Vue.prototype.$CWLGetPageInstance(
  'Certifications/AddCertification.vue',
);
const EditCertification = Vue.prototype.$CWLGetPageInstance(
  'Certifications/EditCertification.vue',
);
const GoogleSheet = Vue.prototype.$CWLGetPageInstance(
  'Brokers/BrokerArea/Sheets.vue',
);

const Claim = store.getters.userClaim.role;

const beforeEnter = (to, from, next) => {
  if (
    Claim !== 'master' &&
    Claim !== 'gestor' &&
    Claim !== 'assessor' &&
    to.name !== 'Criar Campanha' &&
    to.name !== 'Criar Oferta' &&
    to.name !== 'Ver Campanha' &&
    to.name !== 'Ver Oferta' &&
    to.name !== 'Campanhas' &&
    to.name !== 'Ofertas' &&
    to.name !== 'Ver Campanha ' &&
    to.name !== 'Investidores' &&
    to.name !== 'Educação'
  ) {
    console.log('entrei');
    next('dashboard');
  }

  next();
};

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: Claim === 'assessor' ? '/market-news' : '/dashboard',
    children: [
      {
        path: '/login/:ref',
        component: SignIn,
        name: 'login-ref',
        meta: {
          layout: 'guest',
        },
      },
      {
        path: '/login',
        component: SignIn,
        name: 'login',
        meta: {
          layout: 'guest',
        },
      },
      {
        path: '/register/:ref',
        component: SignUp,
        name: 'register-ref',
        meta: {
          layout: 'guest',
        },
      },
      {
        path: '/register',
        component: SignUp,
        name: 'register',
        meta: {
          layout: 'guest',
        },
      },
      {
        path: '/forgot-password',
        component: ForgotPassword,
        name: 'forgot-password',
        meta: {
          layout: 'guest',
        },
      },
      {
        path: '/term-of-use',
        name: 'term-of-use',
        component: TermOfUse,
        meta: {
          layout: 'full',
        },
      },
      // {
      //   path: '/dynamic-fields',
      //   component: DynamicFields,
      //   name: 'Campos Dinâmicos',
      // },
      // {
      //   path: '/add-dynamic-field',
      //   component: AddDynamicField,
      //   name: 'Criar Campo Dinâmico',
      // },
      // {
      //   path: '/edit-dynamic-field/:id',
      //   component: EditDynamicField,
      //   name: 'Editar Campo Dinâmico',
      // },
      // {
      //   path: '/settings',
      //   component: Settings,
      //   name: 'Configurações da plataforma',
      // },
      // {
      //   path: '/exam',
      //   component: Exam,
      //   name: 'Prova AJX',
      // },
      // {
      //   path: '/exam/eval/:id',
      //   component: EvalExam,
      //   name: 'Avaliar prova ajx',
      // },
      // {
      //   path: '/survey',
      //   component: Survey,
      //   name: 'Avaliação AJX'
      // },
      // {
      //   path: '/survey/:id',
      //   component: Survey,
      //   name: 'Avaliação AJX'
      // },
      // {
      //   path: '/transactions/:type/:id',
      //   component: Transactions,
      //   name: 'Transações',
      // },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: Dashboard,
        // beforeEnter,
      },
      {
        path: 'profile',
        name: 'Meus Dados',
        component: Profile,
      },
      // {
      //   path: 'profile/:id',
      //   name: 'Meus Dados',
      //   component: Profile,
      // },
      // {
      //   path: 'users',
      //   name: 'Usuários',
      //   component: Users,
      //   // beforeEnter,
      // },
      // {
      //   path: 'social-transformation/agents',
      //   name: 'Agentes (API3)',
      //   component: SocialAgent,
      // },
      // {
      //   path: 'social-transformation/agents/:id',
      //   name: 'Editar Agente (API3)',
      //   component: EditSocialAgent,
      // },
      // {
      //   path: 'add-user',
      //   name: 'Adicionar Usuário',
      //   component: AddUser,
      //   // beforeEnter,
      // },
      // {
      //   path: 'edit-user/:id',
      //   name: 'Editar Usuário',
      //   component: EditUser,
      //   // beforeEnter,
      // },
      // {
      //   path: 'banks',
      //   name: 'Bancos',
      //   component: Banks,
      //   // beforeEnter,
      // },
      // {
      //   path: 'categorias',
      //   name: 'Categorias',
      //   component: Categorias,
      //   beforeEnter,
      // },
      // {
      //   path: 'add-categoria',
      //   name: 'Adicionar Categoria',
      //   component: AddCategoria,
      //   beforeEnter,
      // },
      // {
      //   path: 'edit-categoria/:id',
      //   name: 'Editar Categoria',
      //   component: EditCategoria,
      //   beforeEnter,
      // },
      // {
      //   path: 'add-bank',
      //   name: 'Adicionar Banco',
      //   component: AddBank,
      //   // beforeEnter,
      // },
      // {
      //   path: 'edit-bank/:id',
      //   name: 'Editar Banco',
      //   component: EditBank,
      //   // beforeEnter,
      // },
      // {
      //   path: 'segment-interest',
      //   name: 'Segmentos',
      //   component: SegmentInterest,
      //   beforeEnter,
      // },
      // {
      //   path: 'add-segment',
      //   name: 'Adicionar Segmento',
      //   component: AddSegment,
      //   beforeEnter,
      // },
      // {
      //   path: 'edit-segment/:id',
      //   name: 'Editar Segmento',
      //   component: EditSegment,
      //   beforeEnter,
      // },
      // {
      //   path: 'states',
      //   name: 'Estados',
      //   component: States,
      //   beforeEnter,
      // },
      // {
      //   path: 'add-state',
      //   name: 'Adicionar Estado',
      //   component: AddState,
      //   beforeEnter,
      // },
      // {
      //   path: 'edit-state/:id',
      //   name: 'Editar Estado',
      //   component: EditState,
      //   beforeEnter,
      // },
      // {
      //   path: 'values',
      //   name: 'Valores',
      //   component: Values,
      //   beforeEnter,
      // },
      // {
      //   path: 'add-values',
      //   name: 'Adicionar Valores',
      //   component: AddValue,
      //   beforeEnter,
      // },
      // {
      //   path: 'edit-values/:id',
      //   name: 'Editar Valores',
      //   component: EditValue,
      //   beforeEnter,
      // },
      // {
      //   path: 'investments-profile',
      //   name: 'Perfil de Investimentos',
      //   component: InvestimentsProfile,
      //   beforeEnter,
      // },
      // {
      //   path: 'add-investment',
      //   name: 'Adicionar Investimento',
      //   component: AddInvestment,
      //   beforeEnter,
      // },
      // {
      //   path: 'edit-investment/:id',
      //   name: 'Editar Investimento',
      //   component: EditInvestment,
      //   beforeEnter,
      // },
      // {
      //   path: 'benefits',
      //   name: 'Benefícios',
      //   component: Benefits,
      //   beforeEnter,
      // },
      // {
      //   path: 'add-benefit',
      //   name: 'Adicionar Benefício',
      //   component: AddBenefit,
      //   beforeEnter,
      // },
      // {
      //   path: 'edit-benefit/:id',
      //   name: 'Editar Benefício',
      //   component: EditBenefit,
      //   beforeEnter,
      // },
      // {
      //   path: 'brokers',
      //   name: 'Assessores',
      //   component: Brokers,
      // },
      // {
      //   path: 'add-broker',
      //   name: 'Adicionar Assessor',
      //   component: AddBroker,
      // },
      // {
      //   path: 'edit-broker/:id',
      //   name: 'Editar Assessor',
      //   component: EditBroker,
      // },
      // {
      //   path: 'invoices',
      //   name: 'Invoices',
      //   component: Invoices,
      // },
      // {
      //   path: 'add-invoice',
      //   name: 'Adicionar NF',
      //   component: AddInvoice,
      // },
      // {
      //   path: 'edit-invoice/:id',
      //   name: 'Editar NF',
      //   component: EditInvoice,
      // },
      // {
      //   path: 'certifications',
      //   name: 'Certificações',
      //   component: Certifications,
      // },
      // {
      //   path: 'add-certification',
      //   name: 'Adicionar Certificação',
      //   component: AddCertification,
      // },
      // {
      //   path: 'edit-certification/:id',
      //   name: 'Editar Certificação',
      //   component: EditCertification,
      // },
      // {
      //   path: 'sheets',
      //   name: 'Google Sheets',
      //   component: GoogleSheet,
      // },
      // {
      //   path: 'meetings',
      //   name: 'Reuniões',
      //   component: Meetings,
      // },
      // {
      //   path: 'financing',
      //   name: modalidade == 'doacao' ? 'Campanhas' : 'Ofertas',
      //   component: Financing,
      //   beforeEnter,
      // },
      // {
      //   path: 'add-financing',
      //   name: modalidade == 'doacao' ? 'Criar Campanha' : 'Criar Oferta',
      //   component: AddFinancing,
      //   beforeEnter,
      // },
      // {
      //   path: 'resume-financing/:id',
      //   name: modalidade == 'doacao' ? 'Ver Campanha' : 'Ver Oferta',
      //   component: ResumeFinancing,
      //   beforeEnter,
      // },
      // {
      //   path: 'edit-financing/:id',
      //   name: modalidade == 'doacao' ? 'Ver Campanha' : 'Ver Oferta',
      //   component: EditFinancing,
      //   beforeEnter,
      // },
      // {
      //   path: 'contributions/:id',
      //   name: 'Ver Aportes',
      //   component: Contributions,
      // },
      // {
      //   path: 'attachments',
      //   name: 'Anexos',
      //   component: Attachments,
      //   beforeEnter,
      // },
      // {
      //   path: 'add-attachment',
      //   name: 'Adicionar Anexo',
      //   component: AddAtachment,
      //   beforeEnter,
      // },
      // {
      //   path: 'investors',
      //   name: 'Investidores',
      //   component: InvestorsList,
      //   beforeEnter,
      // },
      // {
      //   path: 'investor-detail/:id',
      //   name: 'Detalhes Investidor',
      //   component: InvestorDetail,
      //   beforeEnter,
      // },
      // {
      //   path: 'terms-of-use',
      //   name: 'Contratos',
      //   component: TermsOfUseList,
      //   beforeEnter,
      // },
      // {
      //   path: 'add-term-of-use',
      //   name: 'Adicionar Contrato',
      //   component: AddTermOfUse,
      //   beforeEnter,
      // },
      // {
      //   path: 'campaign-details/:id',
      //   name:
      //     modalidade == 'doacao'
      //       ? 'Detalhes da Campanha'
      //       : 'Detalhes da Oferta',
      //   component: CampaignDetails,
      //   // beforeEnter,
      // },
      {
        path: 'my-orders',
        name: 'Meus Investimentos',
        component: MyOrdersList,
        // beforeEnter,
      },
      // {
      //   path: 'orders/:id',
      //   name: 'Investimentos',
      //   component: OrdersList,
      //   // beforeEnter,
      // },
      // {
      //   path: 'orders',
      //   name: 'Ordens',
      //   component: AllOrdersList,
      // },
      // {
      //   path: 'logs',
      //   name: 'Logs',
      //   component: Logs,
      //   beforeEnter,
      // },
      // {
      //   path: '/social-transformation/register',
      //   name: 'Agente de Pertencimento ICoOwner ODS/ESG',
      //   component: RegisterSocialTransformationAgent,
      // },
      // {
      //   path: 'video-content',
      //   name: 'Videos',
      //   component: VideoContentList,
      // },
      // {
      //   path: 'add-video-content',
      //   name: 'Videos',
      //   component: AddVideoContent,
      // },
      // {
      //   path: 'ebooks',
      //   name: 'E-books',
      //   component: NewsList,
      // },
      // {
      //   path: 'add-ebooks-content',
      //   name: 'Adicionar E-book',
      //   component: AddNews,
      // },
      // {
      //   path: 'edit-ebooks/:id',
      //   name: 'Editar E-book',
      //   component: AddNews,
      // },
      // {
      //   path: 'edit-admin-playlist/:id',
      //   name: 'Playliss',
      //   component: AddPlaylist,
      // },
      // {
      //   path: 'admin-playlists',
      //   name: 'Playlists',
      //   component: PlaylistContentList,
      // },
      // {
      //   path: 'add-admin-playlist',
      //   name: 'Playlists',
      //   component: AddPlaylist,
      // },
      // {
      //   path: 'edit-video/:id',
      //   name: 'Editar Video',
      //   component: AddVideoContent,
      // },
      {
        path: 'market-news',
        name: 'AJX News',
        component: NoticiasMercado,
        // beforeEnter,
      },
      {
        path: 'market-news-videos',
        name: 'Educação - Videos',
        component: NoticiasMercadoVideosList,
      },
      {
        path: 'market-news-video-details/:id',
        name: 'Video Details',
        component: NoticiasMercadoVideoDetails,
      },
      {
        path: 'market-news-ebooks',
        name: 'Ebooks',
        component: EbookList,
      },
      {
        path: 'market-news-ebook-details/:id',
        name: 'Ebook Details',
        component: EbookListDetails,
      },
      // {
      //   path: 'academy',
      //   name: 'Trilha de conhecimento',
      //   component: Academy,
      // },
      // {
      //   path: 'crm',
      //   name: 'CRM',
      //   component: CRM,
      // },
      // {
      //   path: 'academy-books',
      //   name: 'Trilha de conhecimento',
      //   component: AcademyBooks,
      // },
      // {
      //   path: 'documents-list',
      //   component: DocumentsList,
      //   children: [
      //     {
      //       path: '',
      //       name: 'Identificação',
      //       component: AttachIdentificationDocument,
      //     },
      //     {
      //       path: 'income-document',
      //       name: 'Renda',
      //       component: AttachIncomeDocument,
      //     },
      //     {
      //       path: 'address-document',
      //       name: 'Endereço',
      //       component: AttachAddressDocument,
      //     },
      //     {
      //       path: 'other-document',
      //       name: 'Outro',
      //       component: AttachOtherDocument,
      //     },
      //   ],
      // },
      // {
      //   path: 'documents-list/:id',
      //   component: DocumentsList,
      //   children: [
      //     {
      //       path: '',
      //       name: 'Identificação',
      //       component: AttachIdentificationDocument,
      //     },
      //     {
      //       path: 'income-document',
      //       name: 'Renda',
      //       component: AttachIncomeDocument,
      //     },
      //     {
      //       path: 'address-document',
      //       name: 'Endereço',
      //       component: AttachAddressDocument,
      //     },
      //     {
      //       path: 'other-document',
      //       name: 'Outro',
      //       component: AttachOtherDocument,
      //     },
      //   ],
      // },
      // {
      //   path: 'reports',
      //   name: '',
      //   component: ReportsList,
      //   beforeEnter,
      //   children: [
      //     {
      //       path: 'annual-report',
      //       name: 'Relatório Anual',
      //       component: AnnualReport,
      //     },
      //     {
      //       path: 'payment',
      //       name: 'Relatório Pagamento',
      //       component: PaymentReport,
      //     },
      //     {
      //       path: 'offers',
      //       name: 'Relatório Ofertas',
      //       component: OffersReport,
      //     },
      //     {
      //       path: 'campaigns-general',
      //       name: 'Relatório Geral',
      //       component: CampaignGeneral,
      //     },
      //     {
      //       path: 'orders-general',
      //       name: 'Relatório Acompanhamento de CCBs',
      //       component: OrdersGeneral,
      //     },
      //     {
      //       path: 'vencimento-ccbs',
      //       name: 'Relatório Vencimento CCBs',
      //       component: VencimentoCCBsGeral,
      //     },
      //     {
      //       path: 'vencimento-ccbs/:id/:report',
      //       name: 'Relatório Analítico Vencimento CCB',
      //       component: CampaignDetails,
      //     },
      //     {
      //       path: 'campaign-statement/:id',
      //       name:
      //         modalidade == 'doacao'
      //           ? 'Relatório Campanha'
      //           : 'Relatório Oferta',
      //       component: CampaignStatement,
      //     },
      //   ],
      // },
    ],
  },
  { path: '*', component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
