<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import videoService from '@/services/VideoContent/videoContentService';
import azureIntegrations from '@/services/VideoContent/azureIntegrations';
import SuccessNotification from '@/components/Notifications/General/SuccessNotification';
import { vueTopprogress } from 'vue-top-progress';
import { quillEditor } from 'vue-quill-editor';
import Datepicker from 'vuejs-datepicker';
import datetime from 'vuejs-datetimepicker';
import VueTagsInput from '@johmun/vue-tags-input';
import vSelect from 'vue-select';
import HLSCore from '@core-player/playcore-hls';
import Vue from 'vue';
import VueCoreVideoPlayer from 'vue-core-video-player';

const kr = {
  dashboard: {
    btn: {
      play: 'Play',
      pause: 'Pause',
      fullscreen: 'Full Screen',
      exitFullscreen: 'Exit Full Screen',
      mute: 'Mute',
      unmute: 'Unmute',
      back: 'Back',
      pip: 'Picture-in-Picture',
    },
    settings: {
      autoplay: 'Autoplay',
      loop: 'Loop',
      speed: 'Speed',
      resolution: 'Resolution',
    },
  },
  layers: {
    error: {
      title: '(O_O)?  Error!',
      2404: 'Video Source Not Found',
      2502: 'Media Network Error',
      2503: 'Video Cannot DECODE',
      2504: 'Video Cannot Play!',
    },
    loading: {
      msg: 'Loading ...',
    },
  },
};

Vue.component('v-select', vSelect);
Vue.use(VueCoreVideoPlayer, {
  lang: kr,
});

export default {
  data() {
    return {
      options: {},
      playerReady: false,
      isLoading: false,
      videoSource: '',
      HLSCore,
      isUploadingVideo: false,
      changedThumnail: false,
      changedBannerThumnail: false,
      player: null,
      videoFile: '',
      thumbnailFile: '',
      thumbnailFileUrl: '',
      bannerThumbnailFileUrl: '',
      video: {},
      tiposDeVideo: ['Video', 'Live'],
      tiposDeAtivo: ['Sim', 'Nao'],
      target_audienceType: ['Clientes', 'Assessores', 'Privado'],
      broker_type: [],
      translationMap: {
        Clientes: 'clients',
        Assessores: 'brokers',
        Privado: 'private',
      },
      target_audience: [''],
      Deselect: {
        render: (createElement) => createElement('span', '❌'),
      },
      vimeo_player: false,
      vimeoId: '',
    };
  },
  computed: {
    videoId() {
      return this.$route.params.id;
    },
  },
  components: {
    vueTopprogress,
    quillEditor,
    Datepicker,
    datetime,
    VueTagsInput,
    HLSCore,
  },
  methods: {
    onReady() {
      this.playerReady = true;
    },
    play() {
      this.$refs.player.play();
    },
    stop() {
      this.$refs.player.stop();
    },
    getTranslatedValue(option, isOriginalToTranslated = true) {
      if (isOriginalToTranslated) {
        return this.translationMap[option] || option;
      } else {
        const originalValue = Object.keys(this.translationMap).find(
          (key) => this.translationMap[key] === option,
        );
        return originalValue || option;
      }
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      const dividedName = file.name.split('.');
      const extension = dividedName.pop();
      const newName = Date.now();
      const newFile = new File([file], `${newName}.${extension}`, {
        type: file.type,
      });
      this.videoFile = newFile;
    },
    handleFileThomnailUpload(event) {
      const file = event.target.files[0];
      const dividedName = file.name.split('.');
      const extension = dividedName.pop();
      const newName = Date.now();
      const newFile = new File([file], `${newName}.${extension}`, {
        type: file.type,
      });
      this.changedThumnail = true;
      this.thumbnailFile = newFile;
    },
    handleFileBannerThomnailUpload(event) {
      const file = event.target.files[0];
      const dividedName = file.name.split('.');
      const extension = dividedName.pop();
      const newName = Date.now();
      const newFile = new File([file], `${newName}.${extension}`, {
        type: file.type,
      });
      this.bannerThumbnailFile = newFile;
      this.changedBannerThumnail = true;
    },
    customDateFormatter(date) {
      return this.$moment(date).format('DD/MM/YYYY');
    },
    customDateTimeFormatter(date) {
      return this.$moment(date).format('DD/MM/YYYY');
    },
    async addNew() {
      this.$refs.topProgress.start();
      if (this.thumbnailFile === '' && !this.video.thumbnail) {
        const placeholderImage =
          await videoService.getFileObjectForPlaceholder();
        this.thumbnailFile = placeholderImage;
        this.changedThumnail = true;
      }

      if (
        this.target_audience.includes('Assessores') &&
        (this.broker_type.length === 0 || !this.broker_type)
      ) {
        this.$notify({
          title: 'Ooopss',
          message: 'Escolha ao menos um tipo de assessor',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
        this.$refs.topProgress.done();
        return;
      }

      this.video.broker_type = this.broker_type || '';

      const target_audience = [];
      this.target_audience.forEach((audience) => {
        target_audience.push(this.getTranslatedValue(audience, true));
      });

      this.video.target_audience = target_audience;

      try {
        this.video.active = true;
        if (!this.video.total_views) {
          this.video.total_views = 0;
        }
        if (!this.video.total_likes) {
          this.video.total_likes = 0;
        }
        if (!this.video.total_dislikes) {
          this.video.total_dislikes = 0;
        }
        if (!this.video.private_url) {
          this.video.private_url = '';
        }
        if (!this.video.public_url) {
          this.video.public_url = '';
        }
        if (!this.video.thumbnail) {
          this.video.thumbnail = '';
        }

        if (this.video.publish_date && this.video.type == 'Video') {
          delete this.video.live_date;
        }

        if (this.video.live_date && this.video.type == 'Live') {
          delete this.video.publish_date;
        }

        if (this.videoId) {
          this.video.changedTime = new Date();
          this.video.upload_file_name =
            this.videoFile.name || this.video.upload_name || '';
          this.video.upload_status = 'Pendente';
          this.video.upload_progresso = '0';
          this.video.streamingUri = '';
          let added = await videoService.update(this.videoId, this.video);

          if (this.videoFile && this.videoFile != '') {
            //upload file
            this.isUploadingVideo = true;

            const responseVideoUpload = await azureIntegrations.uploadFile(
              this.videoFile,
              this.video.provider,
            );
            this.video.azure = responseVideoUpload.data;

            this.videoFile = null;

            // let added = await videoService.update(this.videoId, this.video);
            this.isUploadingVideo = false;
          }

          if (this.thumbnailFile !== '' && this.changedThumnail) {
            let resp = await videoService.uploadFile(
              this.thumbnailFile,
              this.thumbnailFile.name,
            );
            if (resp.state && resp.state == 'success') {
              this.video.thumbnail = JSON.stringify(resp.metadata);
              let added = await videoService.update(this.videoId, this.video);
            }
          }

          if (this.bannerThumbnailFile != '' && this.changedBannerThumnail) {
            let resp = await videoService.uploadFile(
              this.bannerThumbnailFile,
              this.bannerThumbnailFile.name,
            );
            if (resp.state && resp.state == 'success') {
              this.video.banner_thumbnail = JSON.stringify(resp.metadata);
              let added = await videoService.update(this.videoId, this.video);
            }
          }
          this.$router.push('/video-content');
        } else {
          this.video.createdTime = new Date();
          this.video.changedTime = new Date();
          this.video.upload_file_name =
            this.videoFile.name || this.video.upload_name || '';
          this.video.upload_status = 'Pendente';
          this.video.upload_progresso = '0';
          this.video.streamingUri = '';

          var added = await videoService.add({
            ...this.video,
            filename: this.videoFile.name,
          });

          if (this.videoFile && this.videoFile != '') {
            //upload file
            this.isUploadingVideo = true;

            const responseVideoUpload = await azureIntegrations.uploadFile(
              this.videoFile,
              this.video.provider,
            );
            this.video.azure = responseVideoUpload.data;

            this.videoFile = null;

            // await videoService.update(added.id, this.video);
            this.isUploadingVideo = false;
          }

          if (this.thumbnailFile != '' && this.changedThumnail) {
            let resp = await videoService.uploadFile(
              this.thumbnailFile,
              this.thumbnailFile.name,
            );
            if (resp.state && resp.state == 'success') {
              this.video.thumbnail = JSON.stringify(resp.metadata);
              await videoService.update(added.id, this.video);
            }
          }

          if (this.bannerThumbnailFile != '' && this.changedBannerThumnail) {
            let resp = await videoService.uploadFile(
              this.bannerThumbnailFile,
              this.bannerThumbnailFile.name,
            );
            if (resp.state && resp.state == 'success') {
              this.video.banner_thumbnail = JSON.stringify(resp.metadata);
              await videoService.update(added.id, this.video);
            }
          }

          this.$router.push('video-content');
          this.video = {};
        }

        this.changedBannerThumnail = false;
        this.changedThumnail = false;

        this.$refs.topProgress.done();

        this.$notify({
          component: SuccessNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        console.log(error);
        this.$refs.topProgress.done();
        this.isUploadingVideo = false;

        this.$notify({
          title: 'Ooopss',
          message: error.message,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
    async getVideoDetails() {
      this.isLoading = true;
      this.$refs.topProgress.start();
      try {
        if (this.videoId) {
          const videoDetails = await videoService.get(this.videoId);
          if (videoDetails) {
            const target_audience = [];
            this.video = videoDetails;
            videoDetails.target_audience.forEach((audience) => {
              target_audience.push(this.getTranslatedValue(audience, false));
            });
            this.target_audience = target_audience;
            this.broker_type = this.video.broker_type || [];
            if (videoDetails.provider === 'Azure') {
              this.video.upload_status = 'Completo';
              this.video.upload_progresso = '100';
            }

            if (this.video.thumbnail) {
              let thumnailDetails = JSON.parse(this.video.thumbnail);
              let url = await videoService.urlThumbnail(thumnailDetails.name);
              this.thumbnailFile;

              this.thumbnailFileUrl = url;
            }

            if (this.video.banner_thumbnail) {
              let thumnailDetails = JSON.parse(this.video.banner_thumbnail);
              let url = await videoService.urlThumbnail(thumnailDetails.name);

              this.bannerThumbnailFileUrl = url;
            }
          }
        }

        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
      this.$refs.topProgress.done();
    },
  },
  async mounted() {
    if (this.videoId) {
      await this.getVideoDetails();

      if (this.video.azure && this.video.azure.streamingUri) {
        this.vimeo_player = this.video.azure.streamingUri.includes('vimeo');

        if (this.vimeo_player) {
          const vimeoId = this.video.azure.streamingUri.split('/');
          this.vimeoId = vimeoId[4];
          this.videoSource = this.video.azure.streamingUri;
        } else {
          this.videoSource =
            this.video.azure.streamingUri + '(format=m3u8-cmaf)';
        }
      } else {
        if (this.video.streaming_urls) {
          this.vimeo_player = this.video.streaming_urls.includes('vimeo');

          if (this.vimeo_player) {
            const vimeoId = this.video.streaming_urls.split('/');
            this.vimeoId = vimeoId[4];
            this.videoSource = this.video.streaming_urls;
          } else {
            this.videoSource = this.video.streaming_urls + '(format=m3u8-cmaf)';
          }
        }
      }

      this.isLoading = false;
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>

<template>
  <div class="add-user custom-row">
    <card>
      <form @submit.prevent="addNew()">
        <h5 slot="header" class="title">
          <router-link
            to="/video-content"
            class="fa fa-arrow-left"
          ></router-link>
          Salvar Video
        </h5>

        <div class="row">
          <div class="col-md-12">
            <base-input
              label="Titulo*"
              placeholder="Titulo do video"
              v-model="video.title"
              required
            >
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <label>Descrição do Vídeo</label> <br />

            <quill-editor ref="quillEditor" v-model="video.description" />
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <base-input
              label="Apresentador*"
              placeholder="Apresentador"
              v-model="video.recorder"
              required
            >
            </base-input>
          </div>

          <div class="col-md-6">
            <base-input
              label="Participantes"
              placeholder="Participantes"
              v-model="video.participants"
            >
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <base-input required label="Tipo de Conteúdo *">
              <v-select
                label="uf"
                class="style-chooser"
                :options="tiposDeVideo"
                v-model="video.type"
                :components="{ Deselect }"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    <em style="opacity: 0.9; color: #555"
                      ><b>{{ search }}</b> não encontrada</em
                    >
                  </template>
                </template>
              </v-select>
            </base-input>
          </div>
          <div class="col-md-4" v-if="video.type == 'Video'">
            <label for="" class="control-label">Data de Publicação *</label
            ><br />
            <date-picker
              v-model="video.publish_date"
              valueType="format"
              format="DD/MM/YYYY"
            >
            </date-picker>
          </div>
          <div class="col-md-4" v-if="video.type == 'Live'">
            <label for="" class="control-label">Data/Hora da Live*</label><br />
            <input type="date" class="form-control" v-model="video.live_date" />
          </div>
          <div class="col-md-4">
            <base-input required label="Ativo*">
              <v-select
                label="uf"
                class="style-chooser"
                :options="tiposDeAtivo"
                v-model="video.isActive"
                :components="{ Deselect }"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    <em style="opacity: 0.9; color: #555"
                      ><b>{{ search }}</b> não encontrada</em
                    >
                  </template>
                </template>
              </v-select>
            </base-input>
          </div>
          <div
            class="col-md-4 row ml-2 mt-2 mr-2"
            style="display: flex; align-items: center; justify-content: center"
          >
            <label class="mr-2">Público alvo:</label>

            <div v-for="(type, index) in target_audienceType" :key="index">
              <input
                :id="type"
                class="mr-2"
                type="checkbox"
                :value="type"
                v-model="target_audience"
              />
              <label :for="type" class="mr-2">{{ type }}</label>
            </div>
          </div>
          <div
            class="col-md-4 row mt-2"
            style="display: flex; align-items: center; justify-content: center"
            v-if="this.target_audience.indexOf('Assessores') > 0"
          >
            <label class="mr-2">Para qual tipo:</label>

            <div
              v-for="(type, index) in ['Monetize', 'Life Office', 'Híbrido']"
              :key="index"
            >
              <input
                :id="type"
                class="mr-2"
                type="checkbox"
                :value="type"
                v-model="broker_type"
              />
              <label :for="type" class="mr-2">{{ type }}</label>
            </div>
          </div>
          <div class="col-md-4">
            <base-input
              required
              :label="`Provedor* ${
                videoId
                  ? 'Ao trocar de provedor, você terá que enviar o vídeo novamento para fazer a troca'
                  : ''
              }`"
            >
              <v-select
                label="uf"
                class="style-chooser"
                :options="['Azure', 'Vimeo']"
                v-model="video.provider"
                :components="{ Deselect }"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    <em style="opacity: 0.9; color: #555"
                      ><b>{{ search }}</b> não encontrada</em
                    >
                  </template>
                </template>
              </v-select>
            </base-input>
            <div class="row" v-if="video.provider">
              <div class="col-md-12 m-0">
                <label style="font-size: 12px; color: #f00"
                  >{{
                    this.video.provider === 'Azure'
                      ? 'Limitação de tamanho do vídeo para 200mb'
                      : 'Indisponível no app por enquanto'
                  }}
                </label>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="row">
          <div class="col-md-4">
            <input class="mr-2" type="checkbox" v-model="video.show_banner"/>
            <label>Mostrar no Banner</label>
          </div>
        </div> -->

        <div class="row">
          <div class="col-md-12">
            <label
              >Video
              <input
                type="file"
                class="form-control"
                accept=".mp4, .mov, .webm, .mkv, .avi, .wmv"
                @change="handleFileUpload($event)"
              />
            </label>
          </div>
        </div>

        <div class="row" v-if="video.upload_status == 'Pendente'">
          <div class="col-md-12">
            <label style="font-size: 15pt; color: #f00"
              >Upload do Video Pendente, aguarde...
            </label>
          </div>
        </div>

        <div class="row" v-if="video.upload_status == 'Em Andamento'">
          <div class="col-md-12">
            <label style="font-size: 15pt; color: #f00"
              >Upload do Video em andamento {{ video.upload_progresso }}%
            </label>
          </div>
        </div>

        <div class="row" v-if="video.upload_status == 'Otimizando'">
          <div class="col-md-12">
            <label style="font-size: 15pt; color: #f00"
              >Otimizando o vídeo...
            </label>
          </div>
        </div>

        <div class="row" v-if="video.upload_status == 'Erro'">
          <div class="col-md-12">
            <label style="font-size: 15pt; color: #f00"
              >Erro ao fazer o upload do video, tente novamente.
            </label>
          </div>
        </div>

        <div class="row" v-if="videoSource != ''">
          <div class="col-md-12">
            <h4>Video Preview</h4>
          </div>
        </div>
        <div class="row" v-if="videoSource != ''">
          <div class="col-md-12" v-if="!isLoading">
            <vue-core-video-player
              v-if="!this.vimeo_player"
              :core="HLSCore"
              :autoplay="false"
              :src="videoSource"
            ></vue-core-video-player>
            <vimeo-player
              v-if="this.vimeo_player"
              ref="player"
              :video-url="videoSource"
              @ready="onReady"
              :options="{ responsive: true }"
            >
            </vimeo-player>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <label
              >Thumbnail
              <input
                type="file"
                accept=".png, .jpeg, .jpg"
                class="form-control"
                @change="handleFileThomnailUpload($event)"
              />
            </label>
          </div>
        </div>

        <div class="row" v-if="video.thumbnailFileUrl != ''">
          <div class="col-md-12">
            <h4>Thumbnail (Preview)</h4>
          </div>
        </div>
        <div class="row" v-if="thumbnailFileUrl != '' && !changedThumnail">
          <div class="col-md-12">
            <img
              :src="thumbnailFileUrl"
              style="width: 650px; height: 350px; display: block"
            />
          </div>
        </div>

        <div class="row" v-if="video.show_banner == true">
          <div class="col-md-12">
            <label
              >Banner Thumbnail
              <input
                type="file"
                class="form-control"
                @change="handleFileBannerThomnailUpload($event)"
              />
            </label>
          </div>
        </div>

        <div class="row" v-if="bannerThumbnailFileUrl != ''">
          <div class="col-md-12">
            <h4>Preview do Banner Thumbnail</h4>
          </div>
        </div>

        <div class="row" v-if="bannerThumbnailFileUrl != ''">
          <div class="col-md-12">
            <img
              :src="bannerThumbnailFileUrl"
              style="width: 650px; height: 350px; display: block"
            />
          </div>
        </div>

        <div class="row" v-if="isUploadingVideo">
          <div class="col-md-12">
            <label style="font-size: 15pt; color: #f00"
              >O upload to video pode levar varios minutos, por favor nao
              atualize a pagina!
            </label>
          </div>
        </div>

        <button type="submit" class="btn btn-sm" v-if="!isUploadingVideo">
          Salvar
        </button>
      </form>
    </card>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
<style>
.custom-row .row {
  padding: 10px 0px 0px 0px;
}

.vue-tags-input {
  width: 100%;
  max-width: 100% !important;
  background-color: transparent !important;
  border-radius: 6px;
}

.ti-input {
  border-radius: 8px;
  background-color: transparent;
}

.ti-new-tag-input {
  background-color: transparent;
}
</style>
