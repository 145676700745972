/* eslint-disable require-jsdoc */
import firebase from 'firebase';

import { api } from '@/services/ServiceConfig/ServiceConfig';

export default {
  collection() {
    return firebase.firestore().collection('settings');
  },

  async getMarketplaceToken() {
    const privateSettings = await this.getPrivateSettings();
    const settings =
      privateSettings.integracao.safe2pay.safe2pay_api_keys || {};
    const sandbox = settings.marketplace_token_sandbox_active === true;
    return sandbox
      ? settings.marketplace_token_sandbox
      : settings.marketplace_token_prd;
  },

  async getPublicSettings() {
    try {
      const { data } = await api.get('settings/public');
      return data.setting;
    } catch (e) {
      return {};
    }
  },

  async getPrivateSettings() {
    try {
      const { data } = await api.get('settings/private');
      return data.setting;
    } catch (e) {
      return {};
    }
  },

  async getFeaturesToggle() {
    try {
      const { data } = await api.get('settings/private');
      const docData = data.setting;
      if (docData && docData._features) {
        return docData._features;
      } else {
        return {};
      }
    } catch (e) {
      return {};
    }
  },

  async getSettings() {
    const publicSettings = await this.getPublicSettings();
    const privateSettings = await this.getPrivateSettings();

    return {
      ...publicSettings,
      ...privateSettings,
    };
  },

  async updatePublicSettings(settings) {
    await this.collection().doc('public').update(settings);
  },

  async updatePrivateSettings(settings) {
    await this.collection().doc('private').update(settings);
  },

  async getDashboardLimits() {
    const settings = await this.getPublicSettings();
    return settings.dashboardLimits || {};
  },

  async socialTransformationEnabled() {
    const settings = await this.getPublicSettings();
    return (
      settings.socialTransformation &&
      settings.socialTransformation.enabled === true
    );
  },

  async getLocaleIdentifier() {
    const settings = await this.getPrivateSettings();
    if (settings._locale_identifier) {
      return settings._locale_identifier;
    }
    return 'default';
  },
};
