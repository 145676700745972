<script>
import BenefitsTable from '@/components/Benefits/BenefitsTable';
import benefitsService from '@/services/Benefits/benefitsService';

import EmptyBenefitsNotification from '@/components/Notifications/Benefits/EmptyBenefitsNotification';
import DeleteBenefitErrorNotification from '@/components/Notifications/Benefits/DeleteBenefitErrorNotification';
import DeleteBenefitSuccessNotification from '@/components/Notifications/Benefits/DeleteBenefitSuccessNotification';

import { vueTopprogress } from 'vue-top-progress';
import { DoubleBounce } from 'vue-loading-spinner';
import logsService from '@/services/Logs/logsService';

const tableColumns = ['Nome', 'Desconto', 'Validade', 'URL', 'Nível', 'Status', 'Criado por', 'Aprovado por', 'Ações'];

export default {
  components: {
    BenefitsTable,
    vueTopprogress,
    DoubleBounce,
  },
  data() {
    return {
      table: {
        title: 'Lista de Benefícios',
        columns: [...tableColumns],
      },
      benefits: [],
      isLoading: true,
      closeModal: false,
    };
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
  },
  mounted() {
    this.getBenefits();
  },
  methods: {
    async getBenefits() {
      this.$refs.topProgress.start();
      this.benefits = await benefitsService.getAllBenefits() || [];
      if (this.benefits.lenght === 0) {
        this.$notify({
          component: EmptyBenefitsNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.isLoading = false;
      this.$refs.topProgress.done();
    },
    refreshData() {
      this.getBenefits();
    },
    deleteBenefit(preDeletedItem) {
      try {
        const logUser = {
          user: {
            email: this.currentPessoa.email.email,
          },
        };
        const logContent = preDeletedItem.item;
        logsService.createNewLog(logUser, '', 'Excluir Benefício', '', logContent);

        benefitsService.deleteBenefit(preDeletedItem.item.id);

        this.getBenefits();

        this.$notify({
          component: DeleteBenefitSuccessNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        preDeletedItem = {};
      } catch (error) {
        this.$notify({
          component: DeleteBenefitErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<template>
  <div class="users-list">
    <div class="row">
      <div class="col-12">
        <card
          :title="`${table.title} (${this.benefits.length})`"
          :addItems="true"
          :itemType="'Benefício'"
          :routerType="'benefit'"
        >
          <div class="table-responsive">
            <benefits-table
              :data="benefits"
              :columns="table.columns"
              :hasActions="true"
              thead-classes="text-primary"
              @deleteItem="deleteBenefit"
              @refreshData="refreshData"
            ></benefits-table>

            <double-bounce v-if="isLoading"></double-bounce>
          </div>
        </card>
      </div>
    </div>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
