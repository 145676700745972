<script>
import { vueTopprogress } from 'vue-top-progress';
import { DoubleBounce } from 'vue-loading-spinner';
import OrdersTable from '@/components/Orders/OrdersTable';
import getRemuneracaoAcumulada from '../../../functions/getRemuneracaoAcumulada';
import pessoasService from '@/services/Pessoas/pessoasService';
import ordersService from '@/services/Orders/ordersService';
import financingService from '@/services/Financing/financingService';

export default {
  components: {
    OrdersTable,
    vueTopprogress,
    DoubleBounce,
  },
  data() {
    return {
      tableConfirmados: {
        title: 'Aportes Efetivados',
        columns: ['Oferta', 'Valor do Investimento', 'Vencimento', 'Andamento', 'Remuneração Acumulada', 'Valor Atualizado', 'Situação', 'Ações'],
        type: 'confirmed',
        data: [],
      },
      tablePendentes: {
        title: 'Aportes Pendentes',
        columns: ['Oferta', 'Nº CCB', 'Situação', 'Ações'],
        type: 'pending',
        data: [],
      },
      confirmedOrderOptions: [
        { value: 'status', label: 'Status' },
        { value: 'liquidada', label: 'Liquidada' },
        { value: 'broker', label: 'Assessor' },
        { value: 'tipo_pagamento', label: 'Tipo de pagamento' },
        { value: 'data_contratacao', label: 'Data de contratação' },
        { value: 'data_pagamento', label: 'Data do pagamento' },
        { value: 'data_vencimento', label: 'Data do vencimento' },
        { value: 'data_liquidacao', label: 'Data da liquidação' },
      ],
      person: {},
      isLoading: true,
      closeModal: false,
    };
  },
  computed: {
    investorId() {
      return this.$route.params.id;
    },
    loggedPessoa() {
      return this.$store.getters.getPessoa;
    }
  },
  mounted() {
    this.getOrders();
  },
  methods: {
    async getOrders() {
      try {

        const user = await pessoasService.getPessoaBy({id: this.investorId});

        this.person = user;

        const orders = await ordersService.getOrdersByUser(this.investorId);

        this.tableConfirmados.data = []
        this.tablePendentes.data = []
        await orders.forEach(async (order) => {
          const _order = order;

          if (_order.status == 'Pago' || _order.status == 'Liquidado') {
            _order.remuneracao_acumulada = getRemuneracaoAcumulada(_order);
            this.tableConfirmados.data.push(_order);
          } else {
            if (_order.aceite_termos)
              this.tablePendentes.data.push(_order);
          }
        });

        this.isLoading = false;
      } catch (error) { }
    },
  },
};
</script>
    <!-- invested_capital: number;
    accumulated_income: number;
    average_monthly_income: number;
    confirmed_investments: number; -->
<template>
  <div class="orders-list">
    <div class="row">
      <div class="col-lg-12">
        <div class="card"><!---->
          <div class="card-header">
            <h5 class="card-title">
              <!-- <router-link
                  to="/investors"
                  class="fa fa-arrow-left"
                  title="Voltar"
                ></router-link> -->
              Relação de Investimentos
            </h5>
          </div>
          <div class="card-body">
            <h4 class="text-primary ml-4" v-if="person && person.documento && person.documento.numero">
              Cliente: <b>{{ person.nome }}</b> | CPF: {{ person.documento.numero }} | E-mail: {{ person.email.email }} | Telefone: {{ person.telefone.telefone }}
            </h4>
            <h5 v-if="person && person.dados_complementares && person.dados_complementares.dados_bancarios" class="ml-4"><b>Dados Bancários:</b>
                                <br/>Banco {{ person.dados_complementares.dados_bancarios.banco || "" }} |
                                     Agência: {{ person.dados_complementares.dados_bancarios.conta || ""}} |
                                     Conta: {{ person.dados_complementares.dados_bancarios.conta || "" }} <br/>
                                     Chave Pix: {{ person.dados_complementares.dados_bancarios.chave_pix || "" }} </h5>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <orders-table
          :title="tableConfirmados.title"
          :type="tableConfirmados.type"
          :columns="tableConfirmados.columns"
          :data="tableConfirmados.data"
          :confirmedOrderOptions="confirmedOrderOptions"
          @reloadData="getOrders"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <double-bounce v-if="isLoading"></double-bounce>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <orders-table
          :title="tablePendentes.title"
          :type="tablePendentes.type"
          :columns="tablePendentes.columns"
          :data="tablePendentes.data"
          @reloadData="getOrders"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <double-bounce v-if="isLoading"></double-bounce>
      </div>
    </div>
  <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
