<script>
import { vueTopprogress } from 'vue-top-progress';
import { DoubleBounce } from 'vue-loading-spinner';
import OrdersTable from '@/components/Orders/OrdersTable';
import getRemuneracaoAcumulada from '../../../functions/getRemuneracaoAcumulada';
import pessoasService from '@/services/Pessoas/pessoasService';
import ordersService from '@/services/Orders/ordersService';
import financingService from '@/services/Financing/financingService';

export default {
  components: {
    OrdersTable,
    vueTopprogress,
    DoubleBounce,
  },
  data() {
    return {
      tableConfirmados: {
        title: 'Aportes Efetivados',
        columns: ['Oferta', 'Valor do Investimento', 'Vencimento', 'Andamento', 'Remuneração Acumulada', 'Valor Atualizado', 'Situação', 'Ações'],
        type: 'confirmed',
        data: [],
      },
      tablePendentes: {
        title: 'Aportes Pendentes',
        columns: ['Oferta', 'Nº CCB', 'Situação', 'Ações'],
        type: 'pending',
        data: [],
      },
      person: {},
      isLoading: true,
      closeModal: false,
    };
  },
  computed: {
    investorId() {
      return this.$route.params.id;
    },
    loggedPessoa() {
      return this.$store.getters.getPessoa;
    }
  },
  mounted() {
    this.getOrders();
  },
  methods: {
    async getOrders() {
      try {

        const user = await pessoasService.getPessoaBy({id: this.investorId});

        this.person = user;

        const orders = await ordersService.getOrdersByUser(this.investorId);

        this.tableConfirmados.data = []
        this.tablePendentes.data = []
        await orders.forEach(async (order) => {
          const _order = order;

          if (_order.status == 'Pago' || _order.status == 'Liquidado') {
            _order.remuneracao_acumulada = getRemuneracaoAcumulada(_order);
            this.tableConfirmados.data.push(_order);
          } else {
            order.remuneracao_acumulada = 0
            if (_order.aceite_termos)
            this.tablePendentes.data.push(_order);
          }
        });

        this.isLoading = false;
      } catch (error) { }
    },
  },
};
</script>
    <!-- invested_capital: number;
    accumulated_income: number;
    average_monthly_income: number;
    confirmed_investments: number; -->
<template>
  <div class="orders-list">
    <div class="row">
      <div class="col-lg-12">
        <div class="card"><!---->
          <div class="card-header">
            <h5 class="card-title">
              <!-- <router-link
                  to="/investors"
                  class="fa fa-arrow-left"
                  title="Voltar"
                ></router-link> -->
              Relação de Aportes
            </h5>
          </div>
          <div class="card-body">
            <h5 class="text-primary ml-4" v-if="person && person.documento && person.documento.numero">
              Cliente: <b>{{ person.nome }}</b> | CPF: {{ person.documento.numero }}
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-12">
        <div class="form-group">
          <label
            class="control-label"
            v-tooltip="
              'Você pode pesquisar digitando no campo de busca abaixo. A pesquisa é realizada por nome, data de criação e status.'
            "
            style="cursor: default"
            >Pesquisar</label
          >
          <input
            type="text"
            class="form-control"
            placeholder="Para pesquisar, digite sua busca aqui..."
            v-model="searchBar"
          />
        </div>
      </div>

      <div class="col-lg-3 col-12 mb-3">
        <label for="" v-if="modalidade == 'doacao'">Período da Campanha</label>
        <label for="" v-if="modalidade == 'equity'">Período da Oferta</label>
        <date-picker
          v-model="campaignPeriod"
          valueType="format"
          range
          format="DD/MM/YYYY"
        ></date-picker>
      </div>

      <div class="col-lg-3 col-12">
        <base-input label="Status da Campanha" v-if="modalidade == 'doacao'">
          <select class="form-control" required v-model="selectedStatus">
            <option value="" selected>Selecionar</option>
            <option
              :value="status"
              v-for="(status, index) in campaignStatus"
              :key="index"
            >
              {{ statusDescription(status) }}
            </option>
          </select>
        </base-input>

        <base-input label="Status da Oferta" v-if="modalidade == 'equity'">
          <select class="form-control" required v-model="selectedStatus">
            <option value="" selected>Selecionar</option>
            <option
              :value="status"
              v-for="(status, index) in campaignStatus"
              :key="index"
            >
              {{ statusDescription(status) }}
            </option>
          </select>
        </base-input>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <orders-table
          :title="tableConfirmados.title"
          :type="tableConfirmados.type"
          :columns="tableConfirmados.columns"
          :data="tableConfirmados.data"
          @reloadData="getOrders"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <double-bounce v-if="isLoading"></double-bounce>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <orders-table
          :title="tablePendentes.title"
          :type="tablePendentes.type"
          :columns="tablePendentes.columns"
          :data="tablePendentes.data"
          @reloadData="getOrders"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <double-bounce v-if="isLoading"></double-bounce>
      </div>
    </div>
  <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
