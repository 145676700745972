<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import 'vue-file-agent/dist/vue-file-agent.css';

import { SlideYUpTransition } from 'vue2-transitions';

import financingService from '@/services/Financing/financingService';
import fiduciaService from '@/services/Fiducia/fiduciaService';
import ordersService from '@/services/Orders/ordersService';
import socialTransformationService from '@/services/SocialTransformation/socialTransformationService';

import Vue from 'vue';
import VueFileAgent from 'vue-file-agent';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import attachmentsService from '@/services/Attachments/attachmentsService';
import pessoasService from '@/services/Pessoas/pessoasService';
import settingsService from '@/services/Settings/settingsService';
import { collectionRef } from '@/firebase';

import { vueTopprogress } from 'vue-top-progress';
import { quillEditor } from 'vue-quill-editor';

import Modal from '@/components/Modal';
import ReportLayout from '@/components/Financing/ReportLayout';
import { DoubleBounce } from 'vue-loading-spinner';

import store from '@/store/index';

import moment from 'moment';
import { Printd } from 'printd';
import logsService from '@/services/Logs/logsService';
import { withLog } from '@/logger/with_log';
import { uuid } from 'vue-uuid';

import {
  FinanciamentoPadrao,
  CWLStatusCampanha,
  PessoaCaracteristica,
  EnderecoPadrao,
} from '@/store/cwl_enums';
import axios from 'axios';
import { VTooltip } from 'v-tooltip';

import HelperPlugin from '@/plugins/HelperPlugin';
Vue.use(HelperPlugin);

Vue.use(VueFileAgent);
Vue.component('v-select', vSelect);
Vue.directive('tooltip', VTooltip);

import { cnpj } from '@/validate/customValidators.js';

import * as municipios from '@/assets/data/municipios.json';
import { or } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      showConfirmarInvestimentoModal: false,
      showConfirmarInvestimentoModalHeader: true,
      orders: [],

      updating: false,
      isAgenteSocial: false,
      modalidade: process.env.VUE_APP_TYPE,
      submodalidade: process.env.VUE_APP_SUBTYPE,
      content: '',
      contrato_personalizado_content: '',
      editorOption: {
        placeholder:
          'Descreva um pouco mais. Lembre-se: aqui é onde você conta sua história para as pessoas apoiarem a sua causa.',
      },

      editorContratoPersonalizadoOptions: {
        placeholder:
          'Digite aqui o contrato personalizado da sua oferta.',
      },

      editorCuradoriaOption: {
        placeholder: 'Escreva aqui suas observações sobre a curadoria.',
      },

      CWLStatusCampanha: CWLStatusCampanha,
      PessoaCaracteristica: PessoaCaracteristica,
      financing: {
        hasMinimalCapture: false,
        curadoria: {},
        dados_complementares: {},
      },
      financingAccounts: {
        beneficiary: {},
        platform: {},
      },
      cachedFinancing: {
        hasMinimalCapture: false,
      },

      dynamicFields: [],
      relevantInformations: '',
      allSettings: {},
      plataformTotalInvested: 0,
      categorias: [],
      estados: [],
      cidades: [],
      bancos: [],
      modalidades: [],
      showDebtFields: false,
      /**
       * Terms with modal
       */
      showModalTermos: false,
      acceptTerm: false,
      /**
       * Taxas e Prazos with modal
       */
      showModalTaxasPrazos: false,

      /**
       * Publicar automaticamente
       */
      publicarAutomaticamente: false,

      /**
       * Campanha para PJ
       */
      isForPJ: false,
      pessoaPJ: null,
      cnpjForSearch: '',
      isLoadingPessoaPJ: false,
      showPJFields: false,
      blockByPJError: false,
      skipCNPJSearch: false,

      cepForSearch: '',
      isLoadingEnderecoPessoaPJ: false,
      showPJAddressFields: false,
      skipCepSearch: false,

      isLoadingPJCidades: false,

      fileRecords: [],
      Deselect: {
        render: (createElement) => createElement('span', '❌'),
      },
      isLoadingCidades: false,

      /**
       * Curadoria
       */
      criador_da_campanha_nome: '',
      criador_da_campanha_email: '',
      criador_da_campanha_cpf: '',
      criador_da_campanha_telefone: '',
      criador_da_campanha_nascimento: '',

      dados_bancarios: {
        codigo_banco: '',
        agencia_numero: '',
        agencia_digito: '',
        conta_numero: '',
        conta_digito: '',
      },

      textoDadosBancarios:
        'Para podermos realizar a remessa dos valores, você deve ser o titular da conta abaixo',
      myOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: 'Pular tutorial',
          buttonPrevious: 'Voltar',
          buttonNext: 'Próximo',
          buttonStop: 'Finalizar',
        },
      },
      steps: [
        {
          target: '.ql-bold', // We're using document.querySelector() under the hood
          header: {
            title: 'Tornar o texto negrito',
          },
          content: `Este botão torna um texto comum em <strong>negrito</strong>!`,
        },
        {
          target: '.ql-link',
          header: {
            title: 'Adicionar link',
          },
          content: 'Este botão permite que você adicione links.',
        },
        {
          target: '.ql-image',
          header: {
            title: 'Adicionar Imagens',
          },
          content: 'Este botão permite que você adicione imagens.',
        },
      ],
      errorText: {
        type: 'Por favor, selecione uma imagem',
        size: 'As imagens não podem ter mais de 5MB',
      },

      operacaoErrorShown: false,
      operacaoErrorMessage: "",
      operacaoEmAndamento: false,
      operacaoConcluida: false,
      isBancarizadorManual: false,

      showExcluirOfertaModal: false
    };
  },
  validations: {
    cnpjForSearch: {
      valid: cnpj,
    },
  },
  components: {
    vueTopprogress,
    quillEditor,
    Modal,
    DoubleBounce,
    ReportLayout,
    SlideYUpTransition,
  },
  computed: {
    objId() {
      return this.$route.params.id;
    },
    editor() {
      return this.$refs.quillEditor.quill;
    },
    editorContratoPersonalizado() {
      return this.$refs.quillEditorContratoPersonalizado.quill;
    },
    currentPessoa() {
      const { channel, ...person } = this.$store.getters.getPessoa

      return person;
    },
    role() {
      const value = this.$store.getters.userClaim
        ? this.$store.getters.userClaim.role
        : '';
      return value.toLowerCase();
    },
    isAdminOrGestorOrCurador() {
      return (
        this.role == PessoaCaracteristica.Master ||
        this.role == PessoaCaracteristica.Gestor ||
        this.role == PessoaCaracteristica.Curador ||
        this.role == 'master'
      );
    },
    isAdminOrGestor() {
      return this.role == 'master' || this.role == PessoaCaracteristica.Gestor;
    },
    isGestor() {
      return this.role == PessoaCaracteristica.Gestor;
    },
    isAdmin() {
      return this.role == 'master';
    },
    isCurador() {
      return this.role == PessoaCaracteristica.Curador;
    },
    hasCnpj() {
      const status = this.financing.status;
      return (
        this.role == PessoaCaracteristica.Curador ||
        (status != CWLStatusCampanha.EmCriacao &&
          status != CWLStatusCampanha.Reprovada)
      );
    },
  },
  async mounted() {
    this.isForPJ = this.modalidade == 'equity';
    this.financing = this.$CWLNewFinanciamentoPadrao();
    this.cachedFinancing = this.$CWLNewFinanciamentoPadrao();
    
    if ( this.modalidade == 'equity' ) {
      if ( this.submodalidade == '588' ) {
        this.modalidades = [
          {
            name: this.$t('financing.modalityEquity'),
            code: 'equity'
          },
          {
            name: this.$t('financing.modalityDebt'),
            code: 'debt'
          }
        ];
      }
      else if ( this.submodalidade == 'bacen' ) {
        this.modalidades = [
          {
            name: this.$t('financing.modalityBacen'),
            code: 'bacen'
          }
        ];
      }
    }

    await this.get();
    this.handleBankFormTextDescription(this.financing.is_empresa);
    this.getSettings();
    this.getDynamicFields();
    this.getCategorias();
    this.getBancos();
    this.initTour();

    socialTransformationService
      .isSocialAgentByUserId(this.currentPessoa.pessoa_id)
      .then((value) => (this.isAgenteSocial = value));
  },
  methods: {
    statusDescription(status) {
      return this.$CWLFinancingStatus(status);
    },
    modalidadeDescription(modalidade) {
      return modalidade ? modalidade.name : "Não informado";
    },
    onEditorBlur(quill) {},
    onEditorFocus(quill) {},
    onEditorReady(quill) {},
    onEditorChange({ quill, html, text }) {
      this.content = html;
      this.financing.content = html;
    },
    onContratoPersonalizadoChange({ html }) {
      this.contrato_personalizado_content = html;
      this.financing.contrato_personalizado = html;
    },
    onCuradoriaEditorChange({ quill, html, text }) {
      this.financing.curadoria.observacao = html;
    },
    onRelevantEditorChange({ html }) {
      this.relevantInformations = html;
      this.financing.relevantInformations = html;
    },
    calcDaysBetweenDates(initialDate, finalDate) {
      const diffTime = Math.abs(finalDate - initialDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      return diffDays;
    },
    async get() {
      this.$refs.topProgress.start();

      try {
        const financing = await financingService.findById(this.objId);
        this.cachedFinancing = financing;
        this.financing = {
          ...financing,
          curadoria: financing.curadoria || {},
        };

        const financingAccounts = await financingService.findAccountsById(
          this.objId,
        );

        this.financingAccounts = {
          beneficiary: {},
          platform: {},
          ...financingAccounts,
        };

        this.getEstados();

        this.showDebtFields = this.financing.modalidade && this.financing.modalidade.code == 'debt';

        const objImg = {
          name: this.financing.id,
          url: this.financing.imageURL,
          type: 'image',
          size: 333,
        };

        this.fileRecords.push(objImg);

        this.acceptTerm = this.financing.acceptTerm;
        this.isForPJ = this.financing.is_empresa;
        if (this.isForPJ) {
          this.pessoaPJ = this.financing.dados_complementares.empresa;

          this.skipCNPJSearch = true;
          this.cnpjForSearch =
            this.financing.dados_complementares.empresa.documento.numero;
          this.showPJFields = true;

          this.skipCepSearch = true;
          this.cepForSearch =
            this.financing.dados_complementares.empresa.endereco.cep;
          this.showPJAddressFields = true;
        }

        this.$refs.topProgress.done();
        this.content = this.financing.content;
        this.relevantInformations = this.financing.relevantInformations;

        if (
          this.financing.status == this.CWLStatusCampanha.Reprovada &&
          this.financing.curadoria
        ) {
          setTimeout(
            function () {
              document.getElementById('parsedContent').innerHTML =
                this.financing.curadoria.observacao;
            }.bind(this),
            1000,
          );
        }

        const created_by = this.financing.users.created_by;
        if (created_by) {
          this.criador_da_campanha_nome = created_by.nome;
          this.criador_da_campanha_email = created_by.email
            ? created_by.email.email
            : '';
          this.criador_da_campanha_cpf = created_by.documento
            ? created_by.documento.numero
            : '';
          this.criador_da_campanha_telefone = created_by.telefone
            ? created_by.telefone.telefone
            : '';
          this.criador_da_campanha_nascimento = created_by.dados_complementares
            ? created_by.dados_complementares.birthday
            : '';
        }

        this.publicarAutomaticamente = this.financing.publicar_automaticamente;

        this.getDadosBancarios();
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },

    async getDadosBancarios() {
      if (this.financing.dados_bancarios) {
        this.dados_bancarios.banco = this.financing.dados_bancarios.banco;
        this.dados_bancarios.agencia_numero =
          this.financing.dados_bancarios.agencia_numero;
        this.dados_bancarios.agencia_digito =
          this.financing.dados_bancarios.agencia_digito;
        this.dados_bancarios.conta_numero =
          this.financing.dados_bancarios.conta_numero;
        this.dados_bancarios.conta_digito =
          this.financing.dados_bancarios.conta_digito;
      } else if (
        this.financing.is_empresa &&
        this.financing.dados_complementares.empresa &&
        this.financing.dados_complementares.empresa.dados_bancarios
      ) {
        this.dados_bancarios.banco =
          this.financing.dados_complementares.empresa.dados_bancarios.banco;
        this.dados_bancarios.agencia_numero =
          this.financing.dados_complementares.empresa.dados_bancarios.agencia_numero;
        this.dados_bancarios.agencia_digito =
          this.financing.dados_complementares.empresa.dados_bancarios.agencia_digito;
        this.dados_bancarios.conta_numero =
          this.financing.dados_complementares.empresa.dados_bancarios.conta_numero;
        this.dados_bancarios.conta_digito =
          this.financing.dados_complementares.empresa.dados_bancarios.conta_digito;
      } else if (!this.financing.is_empresa && this.currentPessoa) {
        const pessoaLogadaFirebase = await pessoasService.getPessoa(
          this.currentPessoa.pessoa_id,
        );
        const pessoaLogada = pessoaLogadaFirebase.data();
        if (pessoaLogada.dados_bancarios) {
          this.dados_bancarios.banco = this.pessoaLogada.dados_bancarios.banco;
          this.dados_bancarios.agencia_numero =
            this.pessoaLogada.dados_bancarios.agencia_numero;
          this.dados_bancarios.agencia_digito =
            this.pessoaLogada.dados_bancarios.agencia_digito;
          this.dados_bancarios.conta_numero =
            this.pessoaLogada.dados_bancarios.conta_numero;
          this.dados_bancarios.conta_digito =
            this.pessoaLogada.dados_bancarios.conta_digito;
        }
      }
    },

    async getDynamicFields() {
      this.dynamicFields = [];
      this.$refs.topProgress.start();

      var templateCampos = this.modalidade == 'equity' ? this.modalidade : 'Doação';
      if ( this.financing.modalidade ) {
        templateCampos = this.financing.modalidade.code;
      }
      const dynamicFields = await financingService.getDynamicFields(
        templateCampos,
      );

      dynamicFields.forEach((field) => {
        const newField = field.data();
        console.log({newField});
        if (
          newField.isEditable &&
          !this.allSettings.overwriteMinGlobalInvestment &&
          newField.reference == 'Investimento Mínimo Permitido'
        ) {
          this.financing.fields[newField.reference].value =
            this.allSettings.minGlobalInvestment;
          newField.readonly = true;
        } else {
          if (!this.financing.fields[newField.reference]) {
            this.financing.fields[newField.reference] = newField;
          }
        }

        newField.id = field.id;

        this.dynamicFields.push(newField);
      });

      this.$refs.topProgress.done();
    },

    async getSettings() {
      this.allSettings = await settingsService.getPublicSettings();
      let privateSettings = await settingsService.getPrivateSettings();
      this.isBancarizadorManual = privateSettings.is_bancarizador_manual != undefined ? privateSettings.is_bancarizador_manual : false;

      document.getElementById('parsedContentTermosUso').innerHTML =
        this.allSettings.terms.condicoes_termos_uso;

      document.getElementById('parsedContentTaxasPrazo').innerHTML =
        this.allSettings.terms.taxas_prazos;
    },

    async verificarCampanhaProntaParaAnalise() {
      const fieldDinamicoEncerramentoOferta =
        this.financing.fields['Encerramento da Oferta'];
      const fieldDinamicoEncerramentoOfertaValue =
        fieldDinamicoEncerramentoOferta.value;
      const fieldDinamicoValor = this.financing.fields['Valor'];
      const fieldDinamicoValorValue = fieldDinamicoValor.value;

      if (
        this.financing.name != '' &&
        this.financing.categoria != null &&
        fieldDinamicoEncerramentoOfertaValue != null &&
        fieldDinamicoEncerramentoOfertaValue != '' &&
        fieldDinamicoValorValue != null &&
        fieldDinamicoValorValue != '' &&
        this.financing.estado != null &&
        this.financing.cidade != null &&
        this.financing.acceptTerm
      ) {
        this.financing.campanha_pronta_para_analise = true;
      }
    },

    async salvarEnviarParaAnalise() {
      this.save(true);
    },

    async updateCampaignStatus(status) {
      this.$refs.topProgress.start();

      try {
        this.financing.status = status;
        let formattedTax;
        if (this.financing.fields['taxa_juros'] && this.financing.fields['taxa_juros'].value) {
          formattedTax = this.financing.fields['taxa_juros'].value.replace(/,/g, '.');
          this.financing.fields['taxa_juros'].value = formattedTax;
        }

        const logUser = {
          user: {
            email: this.currentPessoa.email.email,
          },
        };
        const logContent = this.financing;
        logsService.createNewLog(
          logUser,
          '',
          'Status atualizado pelo gestor: ' + status,
          '',
          logContent,
        );

        await financingService.update(this.financing.id, this.financing);

        this.$refs.topProgress.done();

        this.$notify({
          title: 'Status atualizado com sucesso!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },

    async publicarCampanha() {
      const that = this;
      this.$refs.topProgress.start();

      try {
        this.financing.status = CWLStatusCampanha.EmAndamento;
        this.financing.dates.started_at = moment().format(
          'YYYY/MM/DD HH:mm:ss',
        );

        let formattedTax;
        if (this.financing.fields['taxa_juros'] && this.financing.fields['taxa_juros'].value) {
          formattedTax = this.financing.fields['taxa_juros'].value.replace(/,/g, '.');
          this.financing.fields['taxa_juros'].value = formattedTax;
        }

        this.financing.users.edited_by = this.currentPessoa;
        this.financing.dates.edited_at = moment().format('YYYY/MM/DD HH:mm:ss');
        const fieldDate = this.financing.fields['Encerramento da Oferta'];

        this.financing.dates.end_at = moment(
          fieldDate.value,
          'YYYY-MM-DD',
        ).format('YYYY/MM/DD');

        const logUser = {
          user: {
            email: this.currentPessoa.email.email,
          },
        };
        const logContent = this.financing;
        logsService.createNewLog(
          logUser,
          '',
          'Publicar Campanha',
          '',
          logContent,
        );

        await financingService.update(this.objId, this.financing);

        this.$refs.topProgress.done();
        this.$router.push('/financing');
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },

    update() {
      this.save(false);
    },

    async save(enviarParaAnalise = true) {
      if (this.updating === true) return;

      this.updating = true;
      const that = this;
      this.$refs.topProgress.start();

      try {
        let formattedTax;
        if (this.financing.fields['taxa_juros'] && this.financing.fields['taxa_juros'].value) {
          formattedTax = this.financing.fields['taxa_juros'].value.replace(/,/g, '.');
          this.financing.fields['taxa_juros'].value = formattedTax;
        }

        if ( this.modalidade == 'equity' ) {
          if ( this.financing.modalidade && this.financing.modalidade.code == 'debt' ) {
            if ( !this.financing.rentabilidade || this.financing.rentabilidade == '' ) {
              this.$notify({
                title: 'Ooopss',
                message:
                  'Por favor, informe a rentabilidade.',
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'warning',
                timeout: 3000,
              });
              this.$refs.topProgress.done();
              return;
            }
            if ( !this.financing.prazo || this.financing.prazo == '' ) {
              this.$notify({
                title: 'Ooopss',
                message:
                  'Por favor, informe o prazo.',
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'warning',
                timeout: 3000,
              });
              this.$refs.topProgress.done();
              return;
            }
          }
        }

        this.financing.users.edited_by = this.currentPessoa;
        this.financing.dates.edited_at = moment().format('YYYY/MM/DD HH:mm:ss');
        this.financing.edited_hash = "HASH_EDIT_FINANCING_" + uuid.v4();
        const fieldDate = this.financing.fields['Encerramento da Oferta'];
        const initialDate = new Date();
        const finalDate = new Date(fieldDate.value);

        this.financing.dates.end_at = moment(
          fieldDate.value,
          'YYYY-MM-DD',
        ).format('YYYY/MM/DD');

        if (this.modalidade == 'equity') {
          this.financing.hasMinimalCapture = true;
          const valorCaptacao = this.financing.fields['Valor'];
          this.financing.minimalCapture = (valorCaptacao.value / 3) * 2;
        }

        const diffDays = this.calcDaysBetweenDates(initialDate, finalDate);

        if (diffDays < this.allSettings.maxCampaignDays) {
          this.financing.status = CWLStatusCampanha.EmCriacao;
          this.financing.acceptTerm = true;

          this.verificarCampanhaProntaParaAnalise();

          if (
            enviarParaAnalise &&
            this.financing.campanha_pronta_para_analise
          ) {
            this.financing.status = CWLStatusCampanha.AguardandoAnalise;
          }

          if (this.fileRecords.length == 0) {
            this.financing.imageURL = FinanciamentoPadrao.imageURL;
          }

          /**
           * Dados bancários
           */
          if (this.dados_bancarios.banco) {
            if (!this.financing.dados_bancarios) {
              this.financing.dados_bancarios = {};
            }
            this.financing.dados_bancarios.banco = this.dados_bancarios.banco;
          }
          if (
            this.dados_bancarios.agencia_numero &&
            this.dados_bancarios.agencia_numero.length > 0
          ) {
            if (!this.financing.dados_bancarios) {
              this.financing.dados_bancarios = {};
            }
            this.financing.dados_bancarios.agencia_numero =
              this.dados_bancarios.agencia_numero;
          }
          if (
            this.dados_bancarios.agencia_digito &&
            this.dados_bancarios.agencia_digito.length > 0
          ) {
            if (!this.financing.dados_bancarios) {
              this.financing.dados_bancarios = {};
            }
            this.financing.dados_bancarios.agencia_digito =
              this.dados_bancarios.agencia_digito;
          }
          if (
            this.dados_bancarios.conta_numero &&
            this.dados_bancarios.conta_numero.length > 0
          ) {
            if (!this.financing.dados_bancarios) {
              this.financing.dados_bancarios = {};
            }
            this.financing.dados_bancarios.conta_numero =
              this.dados_bancarios.conta_numero;
          }
          if (
            this.dados_bancarios.conta_digito &&
            this.dados_bancarios.conta_digito.length > 0
          ) {
            if (!this.financing.dados_bancarios) {
              this.financing.dados_bancarios = {};
            }
            this.financing.dados_bancarios.conta_digito =
              this.dados_bancarios.conta_digito;
          }

          if (this.financing.dados_bancarios) {
            if (this.isForPJ && !this.pessoaPJ.dados_bancarios) {
              this.pessoaPJ.dados_bancarios = this.financing.dados_bancarios;
            } else if (!this.isForPJ && this.currentPessoa) {
              const pessoaLogadaFirebase = await pessoasService.getPessoa(
                this.currentPessoa.pessoa_id,
              );
              const pessoaLogada = pessoaLogadaFirebase.data();
              if (!pessoaLogada.dados_bancarios) {
                pessoaLogada.dados_bancarios = this.financing.dados_bancarios;
                await pessoasService.updatePessoa(
                  pessoaLogadaFirebase.id,
                  pessoaLogada,
                );
              }
            }
          }

          /**
           * Campanha para PJ
           */
          this.financing.is_empresa = this.isForPJ ? this.isForPJ : false;
          if (this.financing.is_empresa) {
            const { channel, ...pessoa } = store.getters.getPessoa;
            const pessoaAtualizada = await pessoasService.updateCompany(
              this.pessoaPJ,
              {
                responsavel: pessoa,
              },
            );
            this.financing.dados_complementares.empresa = pessoaAtualizada;
          }

          /**
           * Publicar automaticamente
           */
          this.financing.publicar_automaticamente =
            this.publicarAutomaticamente;

          const logUser = {
            user: {
              email: this.currentPessoa.email.email,
            },
          };
          const logContent = this.financing;
          logsService.createNewLog(
            logUser,
            '',
            'Atualizar Campanha',
            '',
            logContent,
          );

          await financingService.update(this.objId, this.financing);

          /**
           * Subconta
           */
          let objWS = {};
          if (this.financing.is_empresa) {
            objWS = {
              nome: this.pessoaPJ.nome,
              nomeComercial: this.pessoaPJ.dados_complementares.nome_fantasia,
              documento: this.pessoaPJ.documento.numero
                .replace('.', '')
                .replace('.', '')
                .replace('.', '')
                .replace('-', ''),
              email: this.currentPessoa.email.email,
              nomeResponsavel: this.currentPessoa.nome,
              documentoResponsavel: this.currentPessoa.documento.numero
                .replace('.', '')
                .replace('.', '')
                .replace('-', ''),
              financingId: this.objId,
            };
          } else {
            objWS = {
              nome: this.currentPessoa.nome,
              nomeComercial: this.currentPessoa.nome,
              documento: this.currentPessoa.documento.numero
                .replace('.', '')
                .replace('.', '')
                .replace('-', ''),
              email: this.currentPessoa.email.email,
              financingId: this.objId,
            };
          }
          if (
            !this.financing.hasMinimalCapture &&
            this.dados_bancarios.banco &&
            this.dados_bancarios.banco.code &&
            this.dados_bancarios.agencia_numero &&
            this.dados_bancarios.conta_numero &&
            this.dados_bancarios.conta_digito
          ) {
            objWS.codigo_banco = this.dados_bancarios.banco.code;
            objWS.agencia_numero = this.dados_bancarios.agencia_numero;
            objWS.agencia_digito = this.dados_bancarios.agencia_digito;
            objWS.conta_numero = this.dados_bancarios.conta_numero;
            objWS.conta_digito = this.dados_bancarios.conta_digito;
          }
          objWS.financingSubaccountId = this.financingAccounts.beneficiary.id;
          await this.$CWLupdateSubconta(
            objWS,
            !this.financing.hasMinimalCapture,
          );

          if (
            enviarParaAnalise &&
            !this.financing.campanha_pronta_para_analise
          ) {
            that.$refs.topProgress.done();
            that.$notify({
              title: 'Atualizada com sucesso!',
              message:
                'Porém, não foi possível enviar para análise, pois alguns campos obrigatórios não foram preenchidos.',
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'warning',
              timeout: 3000,
            });
            that.$router.push('/financing');

            return;
          }

          if (
            this.fileRecords.length > 0 &&
            this.fileRecords[0].url != this.financing.imageURL
          ) {
            const file = this.fileRecords[0];
            attachmentsService
              .addNewFinancingImageAttachment(
                file.file,
                file.file.name,
                'Financings',
                this.financing.id,
              )
              .then(async function (financingDocumentCreated) {
                const bucket = 'Financings/' + that.financing.id;
                that.financing.imageURL =
                  await attachmentsService.urlAttachment(
                    financingDocumentCreated.ref.name,
                    bucket,
                  );

                await financingService.update(
                  that.financing.id,
                  that.financing,
                );

                if (enviarParaAnalise) {
                  that.$refs.topProgress.done();
                  that.$router.push('/resume-financing/' + that.financing.id);
                } else {
                  that.$notify({
                    title: 'A campanha está pronta para ser enviada!',
                    icon: 'tim-icons icon-bell-55',
                    horizontalAlign: 'center',
                    verticalAlign: 'top',
                    type: 'success',
                    timeout: 3000,
                  });

                  that.$refs.topProgress.done();
                  that.financing = that.$CWLNewFinanciamentoPadrao();
                  that.$router.push('/financing');
                }
              });
          } else {
            if (enviarParaAnalise) {
              that.$refs.topProgress.done();
              that.$router.push('/resume-financing/' + that.financing.id);
            } else {
              that.$notify({
                title: 'A campanha está pronta para ser enviada!',
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'success',
                timeout: 3000,
              });

              that.$refs.topProgress.done();
              that.financing = that.$CWLNewFinanciamentoPadrao();
              that.$router.push('/financing');
            }
          }
        } else {
          this.$notify({
            title: 'Ooopss',
            message:
              'Você não pode criar com mais de ' +
              this.allSettings.maxCampaignDays +
              ' dias',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 3000,
          });

          this.$refs.topProgress.done();
        }
      } catch (error) {
        this.updating = false;
        console.error(error);
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
        that.$refs.topProgress.done();
      }
      if (this.uploading !== false) {
        setTimeout(() => {
          this.uploading = false;  
        }, 5000);
      }
    },
    async getEstados() {
      this.estados = municipios.estados.map((e) => ({
        name: e.nome,
        uf: e.sigla,
      }));

      if (this.financing.estado) {
        const ufSelected = this.estados.find(
          (e) => e.uf === this.financing.estado.uf,
        );
        this.setEstadoSelected(ufSelected, false);
      }

      if (
        this.financing.is_empresa &&
        this.financing.dados_complementares.empresa &&
        this.financing.dados_complementares.empresa.endereco.estado
      ) {
        const empresaUf =
          this.financing.dados_complementares.empresa.endereco.estado;

        const ufSelected = this.estados.find(
          (e) => e.uf === empresaUf || e.uf == empresaUf.uf,
        );
        this.setPJEstadoSelected(ufSelected, false);
      }
    },

    async getCategorias() {
      const that = this;

      await withLog(() =>
        collectionRef('categorias').orderBy('name', 'asc').get(),
      ).then((querySnapshot) => {
        querySnapshot.forEach((categoria) => {
          const obj = categoria.data();
          obj.id = categoria.id;
          that.categorias.push(obj);
        });
      });
    },

    async getBancos() {
      const that = this;

      await collectionRef('banks')
        .orderBy('name', 'asc')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((banco) => {
            const obj = banco.data();
            obj.id = banco.id;
            that.bancos.push(obj);
          });
        })
        .catch((error) => console.error('error', error));
    },

    checkAcceptTerms() {
      this.$refs.accept_term.click();
    },

    openUseTerms() {
      this.showModalTermos = true;
    },

    openTaxasPrazos() {
      this.showModalTaxasPrazos = true;
    },

    setEstadoSelected(value, skipResetFinancingCidade = true) {
      this.cidades = [];
      if (skipResetFinancingCidade) {
        this.financing.cidade = null;
      }
      this.isLoadingCidades = true;
      if (!value) {
        this.isLoadingCidades = false;
        return;
      }

      const estado = municipios.estados.find((e) => e.sigla === value.uf);
      this.cidades = estado ? estado.cidades : [];
      this.isLoadingCidades = false;
    },

    setPJEstadoSelected(value, skipResetFinancingCidade = true) {
      this.cidades = [];
      if (skipResetFinancingCidade) {
        this.pessoaPJ.endereco.cidade = null;
      }
      this.isLoadingPJCidades = true;
      if (!value) {
        this.isLoadingPJCidades = false;
        return;
      }

      const estado = municipios.estados.find((e) => e.sigla === value.uf);
      this.cidades = estado ? estado.cidades : [];
      this.isLoadingPJCidades = false;
    },

    onBeforeDelete(fileRecord) {
      const i = this.fileRecords.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecords.splice(i, 1);
      }
    },

    checkIsForPJ() {
      this.blockByPJError = this.isForPJ;

      if (this.isForPJ) {
        this.textoDadosBancarios =
          'Para podermos realizar a remessa dos valores, a empresa deve ser a titular da conta abaixo';
      } else {
        this.textoDadosBancarios =
          'Para podermos realizar a remessa dos valores, você deve ser o titular da conta abaixo';
      }
    },

    handleBankFormTextDescription(isCompany) {
      if (isCompany) {
        this.textoDadosBancarios =
          'Para podermos realizar a remessa dos valores, a empresa deve ser a titular da conta abaixo';
      } else {
        this.textoDadosBancarios =
          'Para podermos realizar a remessa dos valores, você deve ser o titular da conta abaixo';
      }
    },

    async changeCNPJ(cnpj) {
      const that = this;

      const cnpjWithoutMask = cnpj
        .replace('.', '')
        .replace('.', '')
        .replace('/', '')
        .replace('-', '');
      if (cnpjWithoutMask.length == 14 && !this.skipCNPJSearch) {
        this.showPJFields = false;

        if (!this.isLoadingPessoaPJ) {
          this.isLoadingPessoaPJ = true;
          this.initPessoaPJ();

          await pessoasService
            .searchPJByCNPJ(cnpj)
            .then(async function (document) {
              if (document.size == 0) {
                const externalPessoaFound =
                  await pessoasService.searchExternalPJByCNPJ(cnpj);

                if (externalPessoaFound) {
                  that.pessoaPJ = externalPessoaFound;
                  that.pessoaPJ.dados_complementares.responsavel =
                    store.getters.getPessoa;
                } else {
                  that.cepForSearch = '';
                }

                if (that.pessoaPJ.endereco.cep != '') {
                  that.skipCepSearch = true;
                  that.cepForSearch = that.pessoaPJ.endereco.cep;
                  that.showPJAddressFields = true;
                } else {
                  that.showPJAddressFields = false;
                }

                that.blockByPJError = false;
                that.showPJFields = true;
                that.isLoadingPessoaPJ = false;
              } else {
                document.forEach(function (pessoaFoundDocument) {
                  const pessoaFound = pessoaFoundDocument.data();
                  pessoaFound.id = pessoaFoundDocument.id;

                  if (
                    pessoaFound.dados_complementares.responsavel &&
                    store.getters.getPessoa
                  ) {
                    if (
                      pessoaFound.dados_complementares.responsavel.email
                        .email// == store.getters.getPessoa.email.email -- COMENTADO, POIS ESTA REGRA VAI DAR PROBLEMA NO FLUXO DO GESTOR REVISAR A CAMPANHA == store.getters.getPessoa.email.email
                    ) {
                      that.pessoaPJ = pessoaFound;

                      if (that.pessoaPJ.endereco.cep != '') {
                        that.skipCepSearch = true;
                        that.cepForSearch = that.pessoaPJ.endereco.cep;
                        that.showPJAddressFields = true;
                      } else {
                        that.showPJAddressFields = false;
                      }

                      that.blockByPJError = false;
                      that.showPJFields = true;
                      that.isLoadingPessoaPJ = false;
                    } else {
                      that.$notify({
                        title: 'Ooopss',
                        message:
                          'Não foi possível realizar a criação para esta empresa privada ou entidade sem fins lucrativos. Por favor, entre em contato com a plataforma ou desmarque esta opção.',
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: 'center',
                        verticalAlign: 'top',
                        type: 'warning',
                        timeout: 3000,
                      });
                      that.blockByPJError = true;
                      that.isLoadingPessoaPJ = false;
                    }
                  } else {
                    that.$notify({
                      title: 'Ooopss',
                      message:
                        'Não foi possível realizar a criação para esta empresa privada ou entidade sem fins lucrativos. Por favor, entre em contato com a plataforma ou desmarque esta opção.',
                      icon: 'tim-icons icon-bell-55',
                      horizontalAlign: 'center',
                      verticalAlign: 'top',
                      type: 'warning',
                      timeout: 3000,
                    });
                    that.blockByPJError = true;
                    that.isLoadingPessoaPJ = false;
                  }
                });
              }
            });
        }
      } else if (!this.isLoadingPessoaPJ && !this.skipCNPJSearch) {
        this.showPJFields = false;
        this.blockByPJError = true;

        this.pessoaPJ.nome = '';
        this.pessoaPJ.endereco = {};
        this.pessoaPJ.dados_complementares = {};
      }
      this.skipCNPJSearch = false;
    },

    async changeCEP(cep) {
      const cepWithoutMask = cep
        .replace('.', '')
        .replace('.', '')
        .replace('/', '')
        .replace('-', '');
      if (cepWithoutMask.length == 8 && !this.skipCepSearch) {
        this.showPJAddressFields = false;

        if (!this.isLoadingEnderecoPessoaPJ) {
          this.isLoadingEnderecoPessoaPJ = true;
          this.pessoaPJ.endereco = Object.assign({}, EnderecoPadrao);

          const enderecoFound = await pessoasService.searchAddressByZipcode(
            cep,
          );
          if (enderecoFound) {
            this.pessoaPJ.endereco = enderecoFound;
          }

          this.showPJAddressFields = true;
          this.isLoadingEnderecoPessoaPJ = false;
        }
      } else if (!this.isLoadingEnderecoPessoaPJ && !this.skipCepSearch) {
        this.showPJAddressFields = false;
        this.pessoaPJ.endereco = {};
      }
      this.skipCepSearch = false;
    },

    initPessoaPJ() {
      this.pessoaPJ = this.$CWLNewPessoaPadraoPJ();
    },

    checkPublicarAutomaticamente() {
      this.$refs.publicar_automaticamente.click();
    },

    async aprovarCampanha() {
      const that = this;
      this.$refs.topProgress.start();

      let formattedTax;
      if (this.financing.fields['taxa_juros'] && this.financing.fields['taxa_juros'].value) {
        formattedTax = this.financing.fields['taxa_juros'].value.replace(/,/g, '.');
        this.financing.fields['taxa_juros'].value = formattedTax;
      }

      try {
        this.financing.status = CWLStatusCampanha.Aprovada;
        this.financing.users.approved_by = this.currentPessoa;
        this.financing.dates.approved_at = moment().format(
          'YYYY/MM/DD HH:mm:ss',
        );

        const logUser = {
          user: {
            email: this.currentPessoa.email.email,
          },
        };
        const logContent = this.financing;
        logsService.createNewLog(
          logUser,
          '',
          'Aprovar Campanha',
          '',
          logContent,
        );

        await financingService.update(this.objId, this.financing);
        const objMailDeprecated = {
          email: this.financing.users.created_by.email.email,
          name: this.financing.users.created_by.nome,
          campanha_nome: this.financing.name,
          campanha_link: process.env.VUE_APP_ROOT_SITE + '/projetos',
        };
        const objMail = {
          financingId: this.financing.id
        };
        this.$CWLsendMailAprovarCampanha(objMail).catch((error) =>
          console.error('erro no envio de email de aprovacao', error),
        );

        this.$notify({
          title: 'Aprovada com sucesso!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
        this.$router.push('/financing');
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },
    async reprovarCampanha() {
      const that = this;
      this.$refs.topProgress.start();

      try {
        this.financing.status = CWLStatusCampanha.Reprovada;
        this.financing.users.reproved_by = this.currentPessoa;
        this.financing.dates.reproved_at = moment().format(
          'YYYY/MM/DD HH:mm:ss',
        );

        const logUser = {
          user: {
            email: this.currentPessoa.email.email,
          },
        };
        const logContent = this.financing;
        logsService.createNewLog(
          logUser,
          '',
          'Reprovar Campanha',
          '',
          logContent,
        );

        await financingService.update(this.objId, this.financing);
        const objMailDeprecated = {
          email: this.financing.users.created_by.email.email,
          name: this.financing.users.created_by.nome,
          campanha_nome: this.financing.name,
          campanha_link: process.env.VUE_APP_ROOT_SITE + '/projetos',
        };
        const objMail = {
          financingId: this.financing.id
        };
        await this.$CWLsendMailReprovarCampanha(objMail);

        this.$notify({
          title: 'Reprovada!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
        this.$router.push('/financing');
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },
    async salvarDadosBancarios() {
      this.$refs.topProgress.start();
      /**
       * Dados bancários
       */
      if (this.dados_bancarios.banco) {
        if (!this.financing.dados_bancarios) {
          this.financing.dados_bancarios = {};
        }
        this.financing.dados_bancarios.banco = this.dados_bancarios.banco;
      }
      if (
        this.dados_bancarios.agencia_numero &&
        this.dados_bancarios.agencia_numero.length > 0
      ) {
        if (!this.financing.dados_bancarios) {
          this.financing.dados_bancarios = {};
        }
        this.financing.dados_bancarios.agencia_numero =
          this.dados_bancarios.agencia_numero;
      }
      if (
        this.dados_bancarios.agencia_digito &&
        this.dados_bancarios.agencia_digito.length > 0
      ) {
        if (!this.financing.dados_bancarios) {
          this.financing.dados_bancarios = {};
        }
        this.financing.dados_bancarios.agencia_digito =
          this.dados_bancarios.agencia_digito;
      }
      if (
        this.dados_bancarios.conta_numero &&
        this.dados_bancarios.conta_numero.length > 0
      ) {
        if (!this.financing.dados_bancarios) {
          this.financing.dados_bancarios = {};
        }
        this.financing.dados_bancarios.conta_numero =
          this.dados_bancarios.conta_numero;
      }
      if (
        this.dados_bancarios.conta_digito &&
        this.dados_bancarios.conta_digito.length > 0
      ) {
        if (!this.financing.dados_bancarios) {
          this.financing.dados_bancarios = {};
        }
        this.financing.dados_bancarios.conta_digito =
          this.dados_bancarios.conta_digito;
      }

      if (this.financing.dados_bancarios) {
        if (this.isForPJ && !this.pessoaPJ.dados_bancarios) {
          this.pessoaPJ.dados_bancarios = this.financing.dados_bancarios;
        } else if (!this.isForPJ && this.currentPessoa) {
          const pessoaLogadaFirebase = await pessoasService.getPessoa(
            this.currentPessoa.pessoa_id,
          );
          const pessoaLogada = pessoaLogadaFirebase.data();
          if (!pessoaLogada.dados_bancarios) {
            pessoaLogada.dados_bancarios = this.financing.dados_bancarios;
            await pessoasService.updatePessoa(
              pessoaLogadaFirebase.id,
              pessoaLogada,
            );
          }
        }
      }

      /**
       * Campanha para PJ
       */
      this.financing.is_empresa = this.isForPJ ? this.isForPJ : false;
      if (this.financing.is_empresa) {
        const { channel, ...pessoa } = store.getters.getPessoa;
        const pessoaAtualizada = await pessoasService.updateCompany(
          this.pessoaPJ,
          {
            responsavel: pessoa,
          },
        );
        this.financing.dados_complementares.empresa = pessoaAtualizada;
      }

      await financingService.update(this.objId, this.financing);

      /**
       * Subconta
       */
      let objWS = {};
      if (this.financing.is_empresa) {
        objWS = {
          nome: this.pessoaPJ.nome,
          nomeComercial: this.pessoaPJ.dados_complementares.nome_fantasia,
          documento: this.pessoaPJ.documento.numero
            .replace('.', '')
            .replace('.', '')
            .replace('.', '')
            .replace('-', ''),
          email: this.currentPessoa.email.email,
          nomeResponsavel: this.currentPessoa.nome,
          documentoResponsavel: this.currentPessoa.documento.numero
            .replace('.', '')
            .replace('.', '')
            .replace('-', ''),
          financingId: this.objId,
        };
      } else {
        objWS = {
          nome: this.currentPessoa.nome,
          nomeComercial: this.currentPessoa.nome,
          documento: this.currentPessoa.documento.numero
            .replace('.', '')
            .replace('.', '')
            .replace('-', ''),
          email: this.currentPessoa.email.email,
          financingId: this.objId,
        };
      }
      if (
        !this.financing.hasMinimalCapture &&
        this.dados_bancarios.banco &&
        this.dados_bancarios.banco.code &&
        this.dados_bancarios.agencia_numero &&
        this.dados_bancarios.conta_numero &&
        this.dados_bancarios.conta_digito
      ) {
        objWS.codigo_banco = this.dados_bancarios.banco.code;
        objWS.agencia_numero = this.dados_bancarios.agencia_numero;
        objWS.agencia_digito = this.dados_bancarios.agencia_digito;
        objWS.conta_numero = this.dados_bancarios.conta_numero;
        objWS.conta_digito = this.dados_bancarios.conta_digito;
      }
      objWS.financingSubaccountId = this.financingAccounts.beneficiary.id;
      await this.$CWLupdateSubconta(objWS, !this.financing.hasMinimalCapture);

      this.$notify({
        title: 'Dados bancários atualizados!',
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: 'success',
        timeout: 3000,
      });
      this.$refs.topProgress.done();
    },

    verTransacoesAgenteSocial() {
      this.$router.push('/transactions/social-agent/' + this.objId);
    },

    verTransacoesCWL() {
      this.$router.push('/transactions/cwl/' + this.objId);
    },

    verTransacoesCampanha() {
      this.$router.push('/transactions/campanha/' + this.objId);
    },

    verTransacoesMarketplace() {
      this.$router.push('/transactions/marketplace/' + this.objId);
    },

    async initTour() {
      if (!this.currentPessoa.disableTour) {
        this.$tours['myTour'].start();

        const pessoaLogadaFirebase = await pessoasService.getPessoa(
          this.currentPessoa.pessoa_id,
        );
        const pessoaLogada = pessoaLogadaFirebase.data();
        pessoaLogada.id = pessoaLogadaFirebase.id;
        pessoaLogada.disableTour = true;
        await pessoasService.updatePessoa(
          pessoaLogadaFirebase.id,
          pessoaLogada,
        );

        const objCooke = this.$CWLCreatePessoaForCookie(pessoaLogada);
        this.$store.commit('SET_PESSOA', objCooke);
      }
    },

    async salvarBalancete() {
      this.$refs.topProgress.start();
      let formattedTax;
      if (this.financing.fields['taxa_juros'] && this.financing.fields['taxa_juros'].value) {
        formattedTax = this.financing.fields['taxa_juros'].value.replace(/,/g, '.');
        this.financing.fields['taxa_juros'].value = formattedTax;
      }

      try {
        const logUser = {
          user: {
            email: this.currentPessoa.email.email,
          },
        };
        const logContent = this.financing;
        logsService.createNewLog(
          logUser,
          '',
          'Salvar Balancete',
          '',
          logContent,
        );

        await financingService.update(this.financing.id, this.financing);

        this.$refs.topProgress.done();

        this.$notify({
          title:
            'Plano de Aplicação/Prestação de Contas atualizado com sucesso!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },

    setModalidadeSelected(obj) {
      if ( obj ) {
        this.showDebtFields = obj.code == 'debt';
      }
      this.getDynamicFields();
    },

    async salvarInformacoesRelevantes() {
      this.$refs.topProgress.start();
      let formattedTax;
      if (this.financing.fields['taxa_juros'] && this.financing.fields['taxa_juros'].value) {
        formattedTax = this.financing.fields['taxa_juros'].value.replace(/,/g, '.');
        this.financing.fields['taxa_juros'].value = formattedTax;
      }

      try {
        const logUser = {
          user: {
            email: this.currentPessoa.email.email,
          },
        };
        const logContent = this.financing;
        logsService.createNewLog(
          logUser,
          '',
          'Salvar Informações Relevantes',
          '',
          logContent,
        );

        await financingService.update(this.financing.id, this.financing);

        this.$refs.topProgress.done();

        this.$notify({
          title:
            'Informações Relevantes atualizado com sucesso!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },

    generatePdf() {
      const options = {
        headElements: [],
      };

      const d = new Printd(options);
      d.print(document.getElementById('myelement'));
    },

    closeModal() {
      this.showConfirmarInvestimentoModal = false;
      this.showExcluirOfertaModal = false;
      this.operacaoErrorMessage = "";
      this.operacaoErrorShown = false;
    },

    async preConfirmarOperacaoInvestimento() {
      this.showConfirmarInvestimentoModal = true;
      this.showConfirmarInvestimentoModalHeader = true;

      /**
       * Get orders
       */
      this.orders = [];

      const ordersFromService = await ordersService.getOrdersByFinancingId(
        this.financing.id,
      );
      ordersFromService.forEach( function(order) {
        if ( !order.ccb || !order.ccb.importacao ) {
          if ( !order.ccb ) {
            order.ccb = {
              importacao: {
                status: "pendente"
              }
            };
          }
          else if ( !order.ccb.importacao ) {
            order.ccb.importacao = {
              status: "pendente"
            };
          }
        }
      });
      this.orders = ordersFromService;
    },

    async downloadContratoCCB(order) {

      try {
        let filename = order.ccb.numero_ccb + "_ContratoCCB.pdf";
        await attachmentsService.downloadAttachment(filename, 'ContratosCCB');
      }
      catch (e) {
        console.log(e);
      }
    },

    async importarDocumentos(order) {
      if ( !order.ccb.importacao_documentos ) {
        order.ccb.importacao_documentos = {};
      }

      order.ccb.importacao_documentos.status = "executando";

      const objeto = {
        financing: this.financing,
        order: order
      };

      await fiduciaService
        .importarDocumentos(objeto)
        .then(function (result) {
          const dados = result.resposta;
          order.ccb.importacao_documentos.operacao = dados;
          order.ccb.importacao_documentos.status = "importado";

          ordersService.update(order.id, order);
        })
        .catch(function (error) {
          order.ccb.importacao_documentos.status = "erro";
          order.ccb.importacao_documentos.error_message = error.message;
          ordersService.update(order.id, order);
        });
    },

    async confirmarOperacaoInvestimento() {
      const that = this;
      let hasOrderPaid = this.orders.filter(order => {
        return order.status == 'Pago';
      });
      let ordersParaProcessar = this.orders.filter(order => {
        return order.status == 'Pago' && order.ccb && order.ccb.importacao && ( order.ccb.importacao.status == "erro" || order.ccb.importacao.status == "pendente" );
      }).length;
      if ( !hasOrderPaid.length ) {
        this.operacaoErrorMessage = "Não existem aportes com o status 'Pago' para efetuar a operação.";
        this.operacaoErrorShown = true;
        return;
      }
      if ( !ordersParaProcessar ) {
        this.operacaoErrorMessage = "Não existem aportes para processar.";
        this.operacaoErrorShown = true;
        return;
      }
      
      this.showConfirmarInvestimentoModalHeader = false;
      this.operacaoEmAndamento = true;
      this.operacaoConcluida = false;
      var ordersProcessadas = 0;
      this.orders.forEach(function(order) {
        var importarOperacao = false;
        if ( !order.ccb ) {
          order.ccb = {
            importacao: {
              status: "erro",
              error_message: "Este aporte não possui número CCB."
            }
          };
          ordersService.update(order.id, order);
        }
        else if ( !order.ccb.numero_ccb ) {
          order.ccb.importacao = {
            status: "erro",
            error_message: "Este aporte não possui número CCB."
          };
          ordersService.update(order.id, order);
        }
        else if ( !order.ccb.simulacao ) {
          order.ccb.importacao = {
            status: "erro",
            error_message: "Este aporte não possui simulação."
          };
          ordersService.update(order.id, order);
        }
        else if ( !order.ccb.importacao || order.ccb.importacao.status == "pendente" ) {
          importarOperacao = true;
          order.ccb.importacao = {
            status: "pendente"
          };
          ordersService.update(order.id, order);
        }
        else if ( order.ccb.importacao && order.ccb.importacao.status == "erro" ) {
          importarOperacao = true;
        }

        const objeto = {
          financing: that.financing,
          order: order
        };
        if ( order.status == 'Pago' && order.ccb && order.ccb.importacao && importarOperacao ) {
          order.ccb.importacao.status = "executando";
          fiduciaService
            .criarOperacaoCredito(objeto)
            .then(async function (result) {
              const dados = result.resposta;
              order.ccb.importacao.operacao = dados;
              order.ccb.importacao.status = "importado";

              ordersService.update(order.id, order);

              await that.importarDocumentos(order);
              ordersProcessadas++;
              that.operacaoConcluida = ordersProcessadas == ordersParaProcessar;
              that.operacaoEmAndamento = ordersProcessadas != ordersParaProcessar;
            })
            .catch(function (error) {
              order.ccb.importacao.status = "erro";
              order.ccb.importacao.error_message = error.message;
              ordersService.update(order.id, order);
              ordersProcessadas++;
              that.operacaoConcluida = ordersProcessadas == ordersParaProcessar;
              that.operacaoEmAndamento = ordersProcessadas != ordersParaProcessar;
            });
        }
      });
    },

    formatDate(date) {
      // return moment(date).tz('America/Sao_Paulo').format('DD/MM/YYYY')
      return this.$CWLFormatDate(date);
    },

    importacaoStatus(order) {
      return ( order.ccb && order.ccb.importacao && order.ccb.importacao.status ) ? order.ccb.importacao.status : "pendente";
    },

    importacaoDocumentosStatus(order) {
      const ccb = order.ccb;
      if ( !ccb.importacao || !ccb.importacao.operacao || !ccb.importacao.operacao.operacao || !ccb.importacao.operacao.operacao.operacao ) {
        return "pendente_operacao";
      }
      else {
        return ( order.ccb && order.ccb.importacao_documentos && order.ccb.importacao_documentos.status ) ? order.ccb.importacao_documentos.status : "pendente";
      }
    },

    importacaoErrorMessage(order) {
      return ( order.ccb && order.ccb.importacao && order.ccb.importacao.error_message ) ? order.ccb.importacao.error_message : "Erro Genérico";
    },

    importacaoDocumentosErrorMessage(order) {
      return ( order.ccb && order.ccb.importacao_documentos && order.ccb.importacao_documentos.error_message ) ? order.ccb.importacao_documentos.error_message : "Erro Genérico";
    },

    contratoPersonalizadoChecked(value) {
      this.financing.possui_contrato_personalizado = value;
      this.editorContratoPersonalizado.enable(value);

      this.contrato_personalizado_content = '';
      this.financing.contrato_personalizado = '';
      this.editorContratoPersonalizado.setText('');
    },

    async salvarContratoPersonalizado() {
      this.$refs.topProgress.start();
      let formattedTax;
      if (this.financing.fields['taxa_juros'] && this.financing.fields['taxa_juros'].value) {
        formattedTax = this.financing.fields['taxa_juros'].value.replace(/,/g, '.');
        this.financing.fields['taxa_juros'].value = formattedTax;
      }

      try {
        const logUser = {
          user: {
            email: this.currentPessoa.email.email,
          },
        };
        const logContent = this.financing;
        logsService.createNewLog(
          logUser,
          '',
          'Salvar Contrato Personalizado',
          '',
          logContent,
        );

        await financingService.update(this.financing.id, this.financing);

        this.$refs.topProgress.done();

        this.$notify({
          title:
            'Contrato Personalizado atualizado com sucesso!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },

    async recriarSubconta() {
      this.$refs.topProgress.start();

      await this.$CWLcreateSubconta(this.financing.id);

      this.$refs.topProgress.done();

      this.$router.go();
    },

    async preConfirmarExclusaoOferta() {
      const ordersFromService = await ordersService.getOrdersByFinancingId(
        this.financing.id,
      );

      if ( ordersFromService.length > 0 ) {
        this.$notify({
          title: 'Ooopss',
          message: 'Esta oferta já possui investimentos. Não é possível excluí-la.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
      else {
        this.showExcluirOfertaModal = true;
      }
    },

    async confirmarExclusaoOferta() {
      await this.updateCampaignStatus(CWLStatusCampanha.Excluida);
      this.showExcluirOfertaModal = false;
    }
  },
};
</script>

<template>
  <div class="add-user" id="user-content">
    <!-- <card
      v-if="
        (!financingAccounts.beneficiary.id || !financingAccounts.platform.id) &&
        isAdminOrGestorOrCurador
      "
      style="text-align: center; background-color: #288cf7; color: white"
    >
      <b
        >Esta campanha está sem token de integração. Por favor, entre em contato
        com CWL.</b
      >
      <div v-if="isAdmin">
        <br>
        <button class="btn btn-warning" @click="recriarSubconta">
          Obter token de integração
        </button>
      </div>
    </card> -->
    <card>
      <form @submit.prevent="update()">
        <h5 slot="header" class="title" v-if="modalidade == 'doacao'">
          <router-link
            to="/financing"
            class="fa fa-arrow-left"
            title="Voltar"
          ></router-link>
          Ver campanha
        </h5>

        <h5 slot="header" class="title" v-if="modalidade == 'equity'">
          <router-link
            to="/financing"
            class="fa fa-arrow-left"
            title="Voltar"
          ></router-link>
          Ver oferta
        </h5>

        <div class="row" v-if="modalidade == 'equity'">
          <div class="col-md-6">
            <base-input required label="Modalidade*">
              <v-select
                label="name"
                class="style-chooser"
                :options="modalidades"
                v-model="financing.modalidade"
                :components="{ Deselect }"
                @input="setModalidadeSelected"
                disabled
              ></v-select>
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <base-input
              id="v-step-0"
              label="Nome*"
              placeholder="Ex: Ajuda para novos leitos"
              v-model="financing.name"
              required
              :disabled="
                isCurador ||
                (financing.status != CWLStatusCampanha.EmCriacao &&
                  financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
              "
            ></base-input>
          </div>

          <div class="col-md-4">
            <base-input required label="Categoria*" class="v-step-1">
              <v-select
                label="name"
                class="style-chooser"
                :options="categorias"
                v-model="financing.categoria"
                :components="{ Deselect }"
                :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
              ></v-select>
            </base-input>
          </div>

          <div class="col-md-4">
            <base-input
              label="Status"
              :value="statusDescription(financing.status)"
              disabled
            ></base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <label>Imagem (tamanho sugerido: 512 x 342)</label>
            <VueFileAgent
              v-model="fileRecords"
              :multiple="false"
              :deletable="true"
              :meta="false"
              :accept="'image/*'"
              :maxSize="'5MB'"
              :helpText="'Arraste uma imagem aqui'"
              :errorText="errorText"
              :disabled="
                isCurador ||
                (financing.status != CWLStatusCampanha.EmCriacao &&
                  financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
              "
              @beforedelete="onBeforeDelete($event)"
            ></VueFileAgent>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-3" v-for="field in dynamicFields" :key="field.id">
            <label v-if="field.type == 'currency'" :for="field.id">{{
              field.label
            }}</label>
            <currency-input
              v-if="field.type == 'currency'"
              :id="field.id"
              class="form-control"
              v-model="financing.fields[field.reference].value"
              :placeholder="field.placeholder"
              :required="field.required"
              v-bind:readonly="field.readonly"
              currency="BRL"
              locale="pt-br"
              :auto-decimal-mode="true"
              :disabled="
                isCurador ||
                (financing.status != CWLStatusCampanha.EmCriacao &&
                  financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
              "
            />
            <base-input
              v-if="field.type != 'currency' && field.type != 'percent' && field.type == 'number'"
              :type="field.type"
              :min="field.min"
              v-bind:readonly="field.readonly"
              :label="field.label"
              v-model="financing.fields[field.reference].value"
              :placeholder="field.placeholder"
              :required="field.required"
              :disabled="
                isCurador ||
                (financing.status != CWLStatusCampanha.EmCriacao &&
                  financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
              "
            ></base-input>
            <base-input
              v-if="field.type != 'currency' && field.type != 'percent' && field.type != 'number'"
              :type="field.type"
              :min="field.min"
              v-bind:readonly="field.readonly"
              :label="field.label"
              v-model="financing.fields[field.reference].value"
              :placeholder="field.placeholder"
              :required="field.required"
              :disabled="
                isCurador ||
                (financing.status != CWLStatusCampanha.EmCriacao &&
                  financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
              "
            ></base-input>
            <base-input
              v-if="field.type != 'currency' && field.type == 'percent'"
              :type="field.type"
              :min="field.min"
              v-bind:readonly="field.readonly"
              :label="field.label"
              v-model="financing.fields[field.reference].value"
              :placeholder="field.placeholder"
              :required="field.required"
              v-mask="['#%', '##%', '###%']"
              :disabled="
                isCurador ||
                (financing.status != CWLStatusCampanha.EmCriacao &&
                  financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
              "
            ></base-input>
          </div>

          <div class="col-md-3">
            <base-input required label="Estado*">
              <v-select
                label="uf"
                class="style-chooser"
                :options="estados"
                v-model="financing.estado"
                :components="{ Deselect }"
                @input="setEstadoSelected"
                :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    <em style="opacity: 0.9; color: #555"
                      ><b>{{ search }}</b> não encontrada</em
                    >
                  </template>
                </template>
              </v-select>
            </base-input>
          </div>

          <div class="col-md-3">
            <base-input required label="Cidade*">
              <v-select
                label="nome"
                class="style-chooser"
                :options="cidades"
                v-model="financing.cidade"
                :components="{ Deselect }"
                :loading="isLoadingCidades"
                :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
              >
                <template #spinner="{ loading }">
                  <div
                    v-if="loading"
                    style="border-left-color: rgba(88, 151, 251, 0.71)"
                    class="vs__spinner"
                  ></div>
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    <em style="opacity: 0.9; color: #555"
                      ><b>{{ search }}</b> não encontrada</em
                    >
                  </template>
                  <em style="opacity: 0.9; color: #555" v-else
                    >Selecione um <b>estado</b></em
                  >
                </template>
              </v-select>
            </base-input>
          </div>
        </div>

        <div class="row" v-if="modalidade == 'equity' && showDebtFields">
          
          <div class="col-md-3">
            <base-input
              label="Rentabilidade*"
              placeholder="10,00% a.a."
              v-model="financing.rentabilidade"
              required
              :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
            ></base-input>
          </div>

          <div class="col-md-3">
            <base-input
              label="Prazo*"
              placeholder="6 meses"
              v-model="financing.prazo"
              required
              :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
            ></base-input>
          </div>

        </div>

        <div class="row" v-if="modalidade == 'doacao'">
          <div class="col-md-3">
            <div class="form-check">
              <label class="form-check-label">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="financing.hasMinimalCapture"
                />
                Possui captação mínima
                <span class="form-check-sign">
                  <span class="check"></span>
                </span>
              </label>
            </div>
          </div>

          <div class="col-md-3" v-if="financing.hasMinimalCapture">
            <label>Valor da captação mínima</label>
            <currency-input
              class="form-control"
              v-model="financing.minimalCapture"
              placeholder="Ex: 12.000,00"
              currency="BRL"
              locale="pt-br"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 mt-3">
            <label for="quillEditor">Detalhes</label>
            <quill-editor
              ref="quillEditor"
              v-model="financing.content"
              :options="editorOption"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
              @change="onEditorChange($event)"
              :disabled="
                isCurador ||
                (financing.status != CWLStatusCampanha.EmCriacao &&
                  financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
              "
            />
          </div>
        </div>

        <div class="row" v-if="modalidade == 'doacao'">
          <div class="col-md-12 mt-3">
            <base-checkbox
              v-model="isForPJ"
              v-on:input="checkIsForPJ"
              :disabled="
                isCurador ||
                (financing.status != CWLStatusCampanha.EmCriacao &&
                  financing.status != CWLStatusCampanha.Reprovada)
              "
            >
              Confirmo que possuo autorização da seguinte entidade beneficiária
              para atuar nesta plataforma.
            </base-checkbox>
          </div>
        </div>

        <template v-if="isForPJ">
          <div class="row">
            <div class="col-md-4 mt-3">
              <base-input
                label="CNPJ"
                v-model="cnpjForSearch"
                placeholder="Informe o CNPJ da entidade"
                v-mask="['##.###.###/####-##']"
                v-on:input="changeCNPJ"
                required
                :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
              ></base-input>
              <div
                class="error"
                v-if="
                  hasCnpj && cnpjForSearch != '' && $v.cnpjForSearch.$invalid
                "
              >
                Informe um CNPJ válido.
              </div>
              <label v-if="isLoadingPessoaPJ" style="cursor: default"
                ><i>Pesquisando...</i></label
              >
            </div>
            <div class="col-md-4 mt-3">
              <base-input
                label="Razão Social"
                v-model="pessoaPJ.nome"
                placeholder="Informe a razão social da entidade"
                required
                v-if="showPJFields"
                :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
              ></base-input>
            </div>
            <div class="col-md-4 mt-3">
              <base-input
                label="Nome Fantasia"
                v-model="pessoaPJ.dados_complementares.nome_fantasia"
                placeholder="Informe o nome fantasia da entidade"
                required
                v-if="showPJFields"
                :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
              ></base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <base-input
                label="CEP"
                v-model="cepForSearch"
                placeholder="Informe o CEP do endereço"
                v-mask="['##.###-###']"
                v-on:input="changeCEP"
                required
                v-if="showPJFields"
                :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
              ></base-input>
              <label v-if="isLoadingEnderecoPessoaPJ" style="cursor: default"
                ><i>Pesquisando...</i></label
              >
            </div>
            <div class="col-md-3">
              <base-input
                label="Rua"
                v-model="pessoaPJ.endereco.rua"
                placeholder="Informe a rua do endereço"
                required
                v-if="showPJFields && showPJAddressFields"
                :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
              ></base-input>
            </div>
            <div class="col-md-3">
              <base-input
                label="Número"
                v-model="pessoaPJ.endereco.numero"
                placeholder="Informe o número do endereço"
                required
                v-if="showPJFields && showPJAddressFields"
                :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
              ></base-input>
            </div>
            <div class="col-md-3">
              <base-input
                label="Complemento"
                v-model="pessoaPJ.endereco.complemento"
                placeholder="Informe o complemento do endereço"
                v-if="showPJFields && showPJAddressFields"
                :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
              ></base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <base-input
                label="Bairro"
                v-model="pessoaPJ.endereco.bairro"
                placeholder="Informe o bairro do endereço"
                required
                v-if="showPJFields && showPJAddressFields"
                :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
              ></base-input>
            </div>
            <div class="col-md-3">
              <base-input
                required
                label="Estado*"
                v-if="showPJFields && showPJAddressFields"
              >
                <v-select
                  label="uf"
                  class="style-chooser"
                  :options="estados"
                  v-model="pessoaPJ.endereco.estado"
                  :components="{ Deselect }"
                  @input="setPJEstadoSelected"
                  :disabled="
                    isCurador ||
                    (financing.status != CWLStatusCampanha.EmCriacao &&
                      financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                    || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                  "
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      <em style="opacity: 0.9; color: #555"
                        ><b>{{ search }}</b> não encontrada</em
                      >
                    </template>
                  </template>
                </v-select>
              </base-input>
            </div>
            <div class="col-md-3">
              <base-input
                required
                label="Cidade*"
                v-if="showPJFields && showPJAddressFields"
              >
                <v-select
                  label="nome"
                  class="style-chooser"
                  :options="cidades"
                  v-model="pessoaPJ.endereco.cidade"
                  :components="{ Deselect }"
                  :loading="isLoadingPJCidades"
                  :disabled="
                    isCurador ||
                    (financing.status != CWLStatusCampanha.EmCriacao &&
                      financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                    || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                  "
                >
                  <template #spinner="{ loading }">
                    <div
                      v-if="loading"
                      style="border-left-color: rgba(88, 151, 251, 0.71)"
                      class="vs__spinner"
                    ></div>
                  </template>
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      <em style="opacity: 0.9; color: #555"
                        ><b>{{ search }}</b> não encontrada</em
                      >
                    </template>
                    <em style="opacity: 0.9; color: #555" v-else
                      >Selecione um <b>estado</b></em
                    >
                  </template>
                </v-select>
              </base-input>
            </div>
          </div>
        </template>

        <div class="row" v-if="modalidade == 'doacao'">
          <div class="col-md-12 mt-3">
            <base-checkbox
              :disabled="
                isCurador ||
                (financing.status != CWLStatusCampanha.EmCriacao &&
                  financing.status != CWLStatusCampanha.Reprovada)
              "
              v-model="publicarAutomaticamente"
              helperContent="Se marcar esta opção, sua campanha será publicada de forma automática depois que a plataforma aprová-la.<br /><br />Se deixar desmarcado, você terá que publicar manualmente após a aprovação."
            >
              Quero que a campanha seja publicada automaticamente após a
              aprovação
            </base-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 mt-3">
            <base-checkbox
              v-model="acceptTerm"
              :disabled="
                isCurador ||
                (financing.status != CWLStatusCampanha.EmCriacao &&
                  financing.status != CWLStatusCampanha.Reprovada)
              "
            >
              Li e concordo com as
              <span style="color: blue" @click="openUseTerms">
                Condições, Termos de Uso</span
              >
              e
              <span style="color: blue" @click="openTaxasPrazos">
                Taxas e Prazos</span
              >
            </base-checkbox>
          </div>
        </div>

        <button
          type="submit"
          class="btn btn-sm mt-3"
          :disabled="isCurador || !acceptTerm || blockByPJError || updating"
          v-if="
            (isAdminOrGestor || !isCurador) &&
            (financing.status == this.CWLStatusCampanha.EmCriacao ||
              financing.status == this.CWLStatusCampanha.Reprovada)
          "
        >
          Salvar
        </button>

        <button
          @click="salvarEnviarParaAnalise"
          class="btn btn-sm mt-3"
          :disabled="isCurador || !acceptTerm || blockByPJError || updating"
          v-if="
            (isAdminOrGestor || !isCurador) &&
            (financing.status == this.CWLStatusCampanha.EmCriacao ||
              financing.status == this.CWLStatusCampanha.Reprovada)
          "
        >
          Salvar e enviar para análise<i
            class="tim-icons icon-send"
            style="margin-left: 8px"
          ></i>
        </button>

        <button
          @click="publicarCampanha"
          class="btn btn-sm mt-3"
          :disabled="
            isCurador ||
            (financing.status != this.CWLStatusCampanha.Aprovada &&
              financing.status != this.CWLStatusCampanha.RemovidaDoAr) ||
            financing.status == this.CWLStatusCampanha.Suspensa || financing.status == this.CWLStatusCampanha.Cancelada ||
            !financingAccounts.beneficiary.id ||
            !financingAccounts.platform.id ||
            (!financingAccounts.beneficiary.id || !financingAccounts.platform.id)
          "
          v-if="
            (isGestor ||
              (modalidade == 'doacao' && !isAdminOrGestorOrCurador)) &&
            (financing.status == this.CWLStatusCampanha.Aprovada ||
              financing.status == this.CWLStatusCampanha.RemovidaDoAr)
          "
        >
          {{ modalidade == 'doacao' ? 'Publicar campanha' : 'Publicar oferta' }}
          <i class="tim-icons icon-spaceship" style="margin-left: 8px"></i>
        </button>
      </form>

      <modal :show="showModalTermos" @close="showModalTermos = false">
        <div class="term-of-use">
          Estas são nossas <b>Condições</b> e <b>Termos de Uso</b> da
          plataforma. Leia com atenção e qualquer dúvida, entre em contato.
          <button
            style="
              background-color: rgb(255, 255, 255);
              border: 1px solid #aaa;
              border-radius: 20px;
              height: 40px;
              width: 40px;
              position: absolute;
              right: 0;
              top: 10px;
              cursor: pointer;
              color: #888;
            "
            @click="showModalTermos = false"
          >
            <i class="tim-icons icon-simple-remove"></i>
          </button>
          <br />
          <br />
          <div
            class="card"
            style="
              background-color: #fff !important;
              max-width: 1024px !important;" 
          >
            <div class="login-form" style="max-width: 1024px">
              <a target="_blank" href="https://storage.googleapis.com/public-documentos-institucionais/termos-uso.pdf">Termos de Uso</a>
              <!-- <div id="parsedContentTermosUso"></div> -->
            </div>
          </div>
        </div>
      </modal>

      <modal :show="showModalTaxasPrazos" @close="showModalTaxasPrazos = false">
        <div class="term-of-use">
          Estas são nossas <b>Taxas</b> e <b>Prazos</b> da plataforma. Leia com
          atenção e qualquer dúvida, entre em contato.
          <button
            style="
              background-color: rgb(255, 255, 255);
              border: 1px solid #aaa;
              border-radius: 20px;
              height: 40px;
              width: 40px;
              position: absolute;
              right: 0;
              top: 10px;
              cursor: pointer;
              color: #888;" 
            @click="showModalTaxasPrazos = false"
          >
            <i class="tim-icons icon-simple-remove"></i>
          </button>
          <br />
          <br />
          <div
            class="card"
            style="background-color: #fff !important; max-width: 1024px !important; "
          >
            <div class="login-form" style="max-width: 1024px">
              <div id="parsedContentTaxasPrazo"></div>
            </div>
          </div>
        </div>
      </modal>
    </card>

    <card
      v-if="
        isCurador ||
        isAdminOrGestor ||
        financing.status == this.CWLStatusCampanha.Reprovada
      "
    >
      <h5 slot="header" class="title">Curadoria</h5>

      <div class="row" v-if="isCurador || isAdminOrGestor">
        <div class="col-md-6 pr-md-1">
          <base-input
            label="Criador da campanha"
            placeholder=""
            v-model="criador_da_campanha_nome"
            disabled
            v-if="modalidade == 'doacao'"
          ></base-input>
          <base-input
            label="Criador da oferta"
            placeholder=""
            v-model="criador_da_campanha_nome"
            disabled
            v-if="modalidade == 'equity'"
          ></base-input>
        </div>
        <div class="col-md-6 pr-md-1">
          <base-input
            label="E-mail do criador"
            placeholder=""
            v-model="criador_da_campanha_email"
            disabled
          ></base-input>
        </div>
      </div>

      <div class="row" v-if="isCurador || isAdminOrGestor">
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Telefone do criador"
            placeholder=""
            v-model="criador_da_campanha_telefone"
            disabled
          ></base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input
            label="CPF do criador"
            placeholder=""
            v-model="criador_da_campanha_cpf"
            disabled
          ></base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Data de nascimento do criador"
            placeholder=""
            v-model="criador_da_campanha_nascimento"
            disabled
          ></base-input>
        </div>
      </div>

      <div class="row" v-if="isCurador || isAdminOrGestor">
        <div class="col-md-12">
          <label for="quillEditor">Observações</label>
          <quill-editor
            ref="quillEditor"
            v-model="financing.curadoria.observacao"
            :options="editorCuradoriaOption"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
            @change="onCuradoriaEditorChange($event)"
            :disabled="
              !isCurador && financing.status != CWLStatusCampanha.Reprovada
            "
          />
        </div>
      </div>

      <div class="row" v-if="!isCurador && !isAdminOrGestor">
        <div class="col-md-12">
          <label
            v-if="financing.users.reproved_by"
            class="control-label"
            style="cursor: default"
          >
            Sua {{ modalidade == 'doacao' ? 'campanha' : 'oferta' }} foi
            analisada pela curadoria e não foi aprovada pelos motivos abaixo:
          </label>
          <label
            v-if="!financing.users.reproved_by"
            class="control-label"
            style="cursor: default"
            >A sua {{ modalidade == 'doacao' ? 'campanha' : 'oferta' }} foi
            reprovada pelos motivos abaixo:</label
          >

          <div class="form-control" id="parsedContent"></div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 pr-md-1">
          <button
            type="button"
            class="btn btn-sm mt-3"
            @click="aprovarCampanha"
            v-if="isCurador || isAdminOrGestor"
            :disabled="financing.status != CWLStatusCampanha.AguardandoAnalise"
          >
            Aprovar
          </button>
          <button
            type="button"
            class="btn btn-sm mt-3 btn-warning"
            @click="reprovarCampanha"
            v-if="isCurador || isAdminOrGestor"
            :disabled="financing.status != CWLStatusCampanha.AguardandoAnalise"
          >
            Reprovar
          </button>
        </div>
      </div>
    </card>

    <card v-if="isGestor">
      <h5 slot="header" class="title">Gestor</h5>

      <div class="row">
        <div class="col-md-10">
          <base-input
            label="Plano de Aplicação/Prestação de Contas"
            v-model="financing.balancete"
            placeholder="Link para Plano de Aplicação/Prestação de Contas"
            :readonly="!isGestor"
          ></base-input>
        </div>

        <div class="col-md-2 pr-md-1">
          <div class="form-group">
            <label class="control-label"></label>
            <button
              type="button"
              class="btn btn-sm form-control"
              style="color: #fff;"
              @click="salvarBalancete()"
              :disabled="financing.status == this.CWLStatusCampanha.Suspensa || financing.status == this.CWLStatusCampanha.Cancelada"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>

      <div class="row" v-if="modalidade == 'equity'">
        <div class="col-md-10">
          <base-input
            label="Informações Relevantes"
            v-model="financing.informacoes_relevantes"
            placeholder="Link para Informações Relevantes"
            :readonly="!isGestor"
          ></base-input>
        </div>

        <div class="col-md-2 pr-md-1">
          <div class="form-group">
            <label class="control-label"></label>
            <button
              type="button"
              class="btn btn-sm form-control"
              style="color: #fff;"
              @click="salvarInformacoesRelevantes()"
              :disabled="financing.status == this.CWLStatusCampanha.Suspensa || financing.status == this.CWLStatusCampanha.Cancelada"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 pr-md-1">
          <button
            type="button"
            class="btn btn-sm mt-3 btn-warning"
            :disabled="financing.status == this.CWLStatusCampanha.RemovidaDoAr"
            @click="updateCampaignStatus(CWLStatusCampanha.RemovidaDoAr)"
          >
            Retirar oferta do ar
          </button>

          <button
            v-if="modalidade == 'equity'"
            type="button"
            class="btn btn-sm mt-3 btn-warning"
            :disabled="financing.status != this.CWLStatusCampanha.RemovidaDoAr || financing.status == this.CWLStatusCampanha.Suspensa"
            @click="updateCampaignStatus(CWLStatusCampanha.Suspensa)"
          >
            Suspender Oferta
          </button>

          <button
            v-if="modalidade == 'equity'"
            type="button"
            class="btn btn-sm mt-3 btn-warning"
            :disabled="financing.status != this.CWLStatusCampanha.RemovidaDoAr || financing.status == this.CWLStatusCampanha.Cancelada"
            @click="updateCampaignStatus(CWLStatusCampanha.Cancelada)"
          >
            Cancelar Oferta
          </button>

          <button
            v-if="modalidade == 'equity'"
            type="button"
            class="btn btn-sm mt-3 btn-default"
            :disabled="financing.status != this.CWLStatusCampanha.RemovidaDoAr || financing.status == this.CWLStatusCampanha.Cancelada"
            @click="updateCampaignStatus(CWLStatusCampanha.ConcluidaComSucesso)"
          >
            Concluir Oferta
          </button>
          <button
            type="button"
            class="btn btn-sm mt-3 btn-warning"
            :disabled="financing.status != this.CWLStatusCampanha.RemovidaDoAr"
            @click="preConfirmarExclusaoOferta()"
          >
            Excluir Oferta
          </button>
        </div>
      </div>

      <div class="row" v-if="modalidade == 'equity'">
        <div class="col-md-4 mt-3">
          <label for="">Relatório de Oferta</label><br />
          <button class="btn btn-primary" @click="generatePdf">
            Gerar Relatório de Oferta
          </button>
        </div>
      </div>

      <div class="row" v-if="submodalidade == 'bacen' && (financing.status == CWLStatusCampanha.ConcluidaComSucesso || financing.status == CWLStatusCampanha.ConcluidaSemSucesso)">
        <div class="col-md-4 mt-3">
          <label for="">Operação de Investimento</label><br />
          <button class="btn btn-primary" @click="preConfirmarOperacaoInvestimento">
            Confirmar Operação de Investimento
          </button>
        </div>
      </div>
    </card>

    <!-- <card v-if="isAdmin">
      <h5 slot="header" class="title">Administrador</h5>

      <div class="row">
        <div class="col-md-4">
          <base-input
            label="Subconta ID"
            v-model="financingAccounts.beneficiary.id"
            placeholder="Subconta ID"
            disabled
          ></base-input>
        </div>
        <div class="col-md-4">
          <base-input
            label="Subconta Token Sandbox"
            v-model="financingAccounts.beneficiary.token_sandbox"
            placeholder="Subconta Token Sandbox"
            disabled
          ></base-input>
        </div>
        <div class="col-md-4">
          <base-input
            label="Subconta Token Produtivo"
            v-model="financingAccounts.beneficiary.token"
            placeholder="Subconta Token Produivo"
            disabled
          ></base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <base-input
            label="Subconta CWL ID"
            v-model="financingAccounts.platform.id"
            placeholder="Subconta CWL ID"
            disabled
          ></base-input>
        </div>
        <div class="col-md-4">
          <base-input
            label="Subconta CWL Token Sandbox"
            v-model="financingAccounts.platform.token_sandbox"
            placeholder="Subconta CWL Token Sandbox"
            disabled
          ></base-input>
        </div>
        <div class="col-md-4">
          <base-input
            label="Subconta CWL Token Produtivo"
            v-model="financingAccounts.platform.token"
            placeholder="Subconta CWL Token Produivo"
            disabled
          ></base-input>
        </div>
      </div>

      <div class="row" v-if="financingAccounts.agent">
        <div class="col-md-4">
          <base-input
            label="Subconta Agente (API3) ID"
            v-model="financingAccounts.agent.id"
            placeholder="Subconta CWL ID"
            disabled
          ></base-input>
        </div>
        <div class="col-md-4">
          <base-input
            label="Subconta Agente (API3) Token Sandbox"
            v-model="financingAccounts.agent.token_sandbox"
            placeholder="Subconta CWL Token Sandbox"
            disabled
          ></base-input>
        </div>
        <div class="col-md-4">
          <base-input
            label="Subconta Agente (API3) Token Produtivo"
            v-model="financingAccounts.agent.token"
            placeholder="Subconta CWL Token Produivo"
            disabled
          ></base-input>
        </div>
      </div>
    </card> -->

    <card>
      <h5 slot="header" class="title">
        Dados Bancários
        <small id="dadosBancariosHelp" class="form-text text-muted">{{
          textoDadosBancarios
        }}</small>
      </h5>

      <div class="row">
        <div class="col-md-4 pr-md-1">
          <base-input required label="Banco">
            <v-select
              label="name"
              class="style-chooser"
              :options="bancos"
              v-model="dados_bancarios.banco"
              :components="{ Deselect }"
              :disabled="(financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )"
              required
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  <em style="opacity: 0.9; color: #555"
                    ><b>{{ search }}</b> não encontrado</em
                  >
                </template>
              </template>
            </v-select>
          </base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Agência"
            placeholder="Informe sua agência"
            v-mask="['####']"
            v-model="dados_bancarios.agencia_numero"
            :disabled="(financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )"
            required
          ></base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Dígito da agência"
            placeholder="Dígito"
            v-mask="['#']"
            v-model="dados_bancarios.agencia_digito"
            :disabled="(financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )"
            required
          ></base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Conta"
            placeholder="Informe o número da sua conta"
            v-mask="['####################']"
            v-model="dados_bancarios.conta_numero"
            :disabled="(financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )"
            required
          ></base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Dígito da conta"
            placeholder="Dígito"
            v-mask="['#']"
            v-model="dados_bancarios.conta_digito"
            :disabled="(financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )"
            required
          ></base-input>
        </div>
      </div>

      <button
        type="button"
        class="btn btn-sm mt-3"
        @click="salvarDadosBancarios"
        :disabled="isCurador || !acceptTerm || blockByPJError || financing.status == this.CWLStatusCampanha.Suspensa || financing.status == this.CWLStatusCampanha.Cancelada"
      >
        Salvar dados bancários
      </button>
    </card>

    <card v-if="modalidade == 'equity'">
      <h5 slot="header" class="title">
        Contrato Personalizado da Oferta
      </h5>
      <h5 slot="header" class="subtitle">
        <b>Variáveis:</b> #data, #local, #nomeInvestidor, #cpfInvestidor,
        #razaoSocialEmissor, #montante
      </h5>

      <base-checkbox :checked="financing.possui_contrato_personalizado" v-on:input="contratoPersonalizadoChecked">
        Essa oferta possui um contrato personalizado
      </base-checkbox>

      <div class="row">
        <div class="col-md-12 mt-3">
          <quill-editor
            ref="quillEditorContratoPersonalizado"
            v-model="financing.contrato_personalizado"
            :options="editorContratoPersonalizadoOptions"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
            @change="onContratoPersonalizadoChange($event)"
            :disabled="!financing.possui_contrato_personalizado"
          />
        </div>
      </div>

      <button
        type="button"
        class="btn btn-sm mt-3"
        @click="salvarContratoPersonalizado"
        :disabled="
        financing.status == this.CWLStatusCampanha.Suspensa || financing.status == this.CWLStatusCampanha.Cancelada ||
        (isGestor && financing.status != this.CWLStatusCampanha.RemovidaDoAr)
        "
      >
        Salvar contrato personalizado
      </button>
    </card>

    <card v-if="isAgenteSocial">
      <h5 slot="header" class="title">Transações</h5>
      <div class="row">
        <div class="col-md-2 pr-md-1">
          <button
            type="button"
            class="btn btn-sm mt-3"
            @click="verTransacoesAgenteSocial"
          >
            Agente (API3)
          </button>
        </div>
      </div>
    </card>

    <card v-if="this.role == 'master' || this.role == 'gestor'">
      <h5 slot="header" class="title">Transações</h5>

      <div class="row">
        <button
          type="button"
          class="btn btn-sm mt-3"
          @click="verTransacoesMarketplace"
        >
          Marketplace
        </button>
        <button
          type="button"
          class="btn btn-sm mt-3"
          @click="verTransacoesCampanha"
          v-if="modalidade == 'doacao'"
        >
          Campanha
        </button>
        <button
          type="button"
          class="btn btn-sm mt-3"
          @click="verTransacoesCampanha"
          v-if="modalidade == 'equity'"
        >
          Oferta
        </button>
        <button type="button" class="btn btn-sm mt-3" @click="verTransacoesCWL">
          CWL
        </button>
      </div>
    </card>

    <vue-topprogress ref="topProgress"></vue-topprogress>
    <v-tour name="myTour" :steps="steps" :options="myOptions"></v-tour>
    <report-layout
      id="myelement"
      :financing="financing"
      v-if="modalidade == 'equity'"
      :settings="allSettings"
    ></report-layout>

    <template>
      <SlideYUpTransition :duration="500">
        <div
          class="modal fade"
          @click.self="closeModal"
          :class="[
            { 'show d-block': showExcluirOfertaModal },
            { 'd-none': !showExcluirOfertaModal },
            { 'modal-mini': true },
          ]"
          v-show="showExcluirOfertaModal"
          tabindex="-1"
          role="dialog"
          :aria-hidden="!showExcluirOfertaModal"
        >
          <div class="modal-dialog">
            <div class="modal-content confirmation-modal">
              <i class="fa fa-exclamation-triangle fa-5x"></i>

              <h1 class="modal-content-title">Atenção</h1>

              <p class="modal-content-description">
                <span>Você tem certeza?</span>
              </p>

              <div class="modal-buttons">
                <button @click="closeModal()" class="cancel-action">
                  Cancelar
                </button>

                <button
                  style="
                    margin-top: 20px;
                    background-color: #288cf7;
                    color: #fff;
                    width: 250px !important;
                  "
                  @click="confirmarExclusaoOferta()"
                >
                  Sim, quero excluir
                </button>
              </div>
            </div>
          </div>
        </div>
      </SlideYUpTransition>
    </template>

    <template>
      <SlideYUpTransition :duration="500">
        <div
          class="modal fade"
          @click.self="closeModal"
          :class="[
            { 'show d-block': showConfirmarInvestimentoModal },
            { 'd-none': !showConfirmarInvestimentoModal },
          ]"
          v-show="showConfirmarInvestimentoModal"
          tabindex="-1"
          role="dialog"
          :aria-hidden="!showConfirmarInvestimentoModal"
        >
          <div class="modal-dialog" style="max-width: 1000px !important;">
            <div class="modal-content confirmation-modal" style="margin-top: 50px; max-height: 500px; overflow: scroll; display: -webkit-box;">
              <div v-if="showConfirmarInvestimentoModalHeader && !isBancarizadorManual">
                <h1 class="modal-content-title">IMPORTAR OPERAÇÃO DE CRÉDITO</h1>

                <p
                  class="modal-content-description"
                >
                  Esta operação não poderá ser cancelada. <br /><br />

                  Você tem certeza?
                </p>

                <div class="modal-buttons">
                  <button @click="closeModal()" class="cancel-action">
                    Cancelar
                  </button>

                  <button
                    style="
                      margin-top: 20px;
                      background-color: #288cf7;
                      color: #fff;
                      width: 250px !important;
                    "
                    @click="confirmarOperacaoInvestimento()"
                  >
                    Sim, quero continuar
                  </button>

                  <div class="error" style="color: red;" v-if="operacaoErrorShown">
                    {{ operacaoErrorMessage }}
                  </div>
                </div>

                <br /><br />
              </div>

              <div v-if="!showConfirmarInvestimentoModalHeader">
                <h1 style="color: red !important;" v-if="operacaoEmAndamento" class="modal-content-title">NÃO FECHE ESTA JANELA. IMPORTAÇÃO EM ANDAMENTO.</h1>
                <h1 style="color: blue !important;" v-if="operacaoConcluida" class="modal-content-title">IMPORTAÇÃO CONCLUÍDA. VERIFIQUE O STATUS DE CADA OPERAÇÃO.</h1>
              </div>
              
              <table class="table table-hover" :style="isBancarizadorManual ? 'margin-top: -200px' : ''">
                <thead class="thead-light">
                  <tr>
                    <slot name="columns">
                      <th>Data</th>
                      <th>Nome</th>
                      <th>Valor</th>
                      <th>Status Pgto</th>
                      <th>CCB</th>
                      <th v-if="!isBancarizadorManual">Código Operação</th>
                      <th v-if="!isBancarizadorManual" style="text-align: center">Status</th>
                      <th v-if="!isBancarizadorManual" style="text-align: center">Documento</th>
                      <th style="text-align: center">Contrato CCB</th>
                    </slot>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(order, index) in orders"
                    :key="index"
                    style="cursor: default !important; height: 60px"
                  >
                    <td>
                      {{ formatDate(order.order_date) }}
                    </td>
                    <td>
                      {{ order.pessoa.nome }}
                    </td>
                    <td>
                      {{ order.item.value | currency }}
                    </td>
                    <td>
                      {{ order.status }}
                    </td>
                    <td>
                      {{ ( order.ccb && order.ccb.numero_ccb ) ? order.ccb.numero_ccb : "" }}
                    </td>
                    <td v-if="!isBancarizadorManual">
                      <span v-if="importacaoStatus(order) == 'importado'">
                        {{ order.ccb.importacao.operacao.operacao.operacao }}
                      </span>
                      <span v-if="importacaoStatus(order) != 'importado'">
                        --
                      </span>
                    </td>
                    <td style="text-align: center;" v-if="importacaoStatus(order) == 'executando' && !isBancarizadorManual">
                      <double-bounce></double-bounce>
                    </td>
                    <td style="text-align: center;" v-if="importacaoStatus(order) == 'pendente' && !isBancarizadorManual">
                      <i title="Pendente de Envio" class="tim-icons icon-cloud-upload-94"></i>
                    </td>
                    <td style="text-align: center;" v-if="importacaoStatus(order) == 'erro' && !isBancarizadorManual">
                      <i :title="importacaoErrorMessage(order)" class="tim-icons icon-alert-circle-exc"></i>
                    </td>
                    <td style="text-align: center;" v-if="importacaoStatus(order) == 'importado' && !isBancarizadorManual">
                      <i :title="'Código da operação: ' + order.ccb.importacao.operacao.operacao.operacao" class="tim-icons icon-check-2"></i>
                    </td>
                    
                    <td style="text-align: center;" v-if="importacaoDocumentosStatus(order) == 'executando' && !isBancarizadorManual">
                      <double-bounce></double-bounce>
                    </td>
                    <td style="text-align: center;" v-if="importacaoDocumentosStatus(order) == 'pendente' && !isBancarizadorManual">
                      <i title="Pendente de Envio" class="tim-icons icon-cloud-upload-94" style="cursor: pointer;" @click="importarDocumentos(order)"></i>
                    </td>
                    <td style="text-align: center;" v-if="importacaoDocumentosStatus(order) == 'pendente_operacao' && !isBancarizadorManual">
                      <i title="Aguardando Operação" class="tim-icons icon-cloud-upload-94"></i>
                    </td>
                    <td style="text-align: center;" v-if="importacaoDocumentosStatus(order) == 'erro' && !isBancarizadorManual">
                      <i :title="importacaoDocumentosErrorMessage(order)" class="tim-icons icon-alert-circle-exc" style="cursor: pointer;" @click="importarDocumentos(order)"></i>
                    </td>
                    <td style="text-align: center;" v-if="importacaoDocumentosStatus(order) == 'importado' && !isBancarizadorManual">
                      <i title="Importado!" class="tim-icons icon-check-2"></i>
                    </td>

                    <td style="text-align: center;">
                      <i title="Download Contrato CCB" class="tim-icons icon-cloud-download-93" style="cursor: pointer;" @click="downloadContratoCCB(order)"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </SlideYUpTransition>
    </template>
  </div>
</template>
