<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import 'vue-file-agent/dist/vue-file-agent.css';
import moment from 'moment-timezone';

import { cep } from '@/validate/customValidators.js';
import pessoasService from '@/services/Pessoas/pessoasService';
import statesService from '@/services/States/statesService';
import Card from '@/components/Cards/Card.vue';
import investorsService from '@/services/Investors/investorsService';
import attachmentsService from '@/services/Attachments/attachmentsService';
import logsService from '@/services/Logs/logsService';

import { vueTopprogress } from 'vue-top-progress';
import { DoubleBounce } from 'vue-loading-spinner';

import { quillEditor } from 'vue-quill-editor';
import { uuid } from 'vue-uuid';

import FileUpload from 'vue-upload-component';
import ImageCompressor from '@xkeshi/image-compressor';

import { PessoaCaracteristica, CWLStatusInvestidor } from '@/store/cwl_enums';
import {
  cnpj,
  cpf,
  isValidPhone,
  isValidRandomKey,
} from '../../../validate/customValidators';

export default {
  components: { vueTopprogress, Card, quillEditor, FileUpload, DoubleBounce },
  props: ['models', 'pessoaProps', 'validDocuments', 'getKeyType'],
  data() {
    return {
      isWebcamReady: false,
      hasPhoto: false,
      stream: MediaStream,
      photo: {},
      getProfileDocumentsLoading: false,
      model: {
        states: [],
      },
      pessoa: {
        endereco: {
          estado: {},
        },
        dados_complementares: {
          dados_bancarios: {},
          perfil: {},
        },
      },
      isLoadingEnderecoPessoa: false,
      limite_investimento: '',
      stateSelected: {},
      submodalidade: process.env.VUE_APP_SUBTYPE,
      editorCuradoriaOption: {
        placeholder: 'Escreva aqui suas observações sobre a curadoria.',
      },
      selectedFilename: '',
      file: {
        item: {},
        name: 'Documento de Identificação',
      },
      fileChanged: false,

      // Documentos Anexos
      new_files: [],
      new_file_fields_shown: false,
      new_file_to_add: {},
      tipos_documentos: [],
      perfil_documentos: [],
      accept: 'image/png,image/jpeg,image/jpg,application/pdf',
      extensions: 'jpg,jpeg,png',
      minSize: 1024,
      multiple: false,
      directory: false,
      drop: false,
      dropDirectory: false,
      createDirectory: false,
      addIndex: false,
      thread: 3,
      show_alerta_salvar_documentos: false,
      perfil_documentos_excluidos: [],
      observacao_historico: '',
      pessoaHistory: {},
    };
  },
  validations: {
    pessoa: {
      endereco: {
        cep: {
          valid: cep,
        },
      },
    },
  },
  async mounted() {
    this.model = this.$attrs.model || this.models;
    await this.getTipoDocumentos();
    await this.getStates();
    this.pessoa = this.pessoaProps;
    this.pessoaHistory = this.pessoaProps;
    await this.getPerfilDocumentos();
    this.setAddressStateSelected();
    this.updateLimiteInvestimento();
  },
  computed: {
    investor() {
      return this.$store.getters.isLoggedIn;
    },
    pessoaId() {
      return this.$route.params.id;
    },
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
    currentUser() {
      return this.$store.getters.userClaim;
    },
    role() {
      const value = this.$store.getters.userClaim
        ? this.$store.getters.userClaim.role
        : '';
      return value.toLowerCase();
    },
    isGestor() {
      return this.role == PessoaCaracteristica.Gestor;
    },
    isCurador() {
      return this.role == PessoaCaracteristica.Curador;
    },
    isGestorOrCurador() {
      return this.isCurador || this.isGestor;
    },
    isUsuario() {
      return this.role == PessoaCaracteristica.InvestorRole;
    },
    CWLStatusInvestidor() {
      return CWLStatusInvestidor;
    },
  },
  watch: {
    perfil_documentos(val) {
      this.validDocuments(val);
    },
    'pessoa.dados_complementares.dados_bancarios.chave_pix'(value) {
      this.pessoa.dados_complementares.dados_bancarios.chave_pix =
        this.getMask(value);
    },
  },
  methods: {
    getMask(chavePix) {
      const defaultChavePix = chavePix;
      chavePix = chavePix.replace(/[^a-zA-Z0-9+]/g, '');

      if (cpf(chavePix)) {
        this.getKeyType('cpf');
        return chavePix.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      }

      if (isValidPhone(chavePix)) {
        this.getKeyType('phone');
        if (chavePix.startsWith('+'))
          return chavePix.replace(
            /(\d{2})(\d{2})(\d{5})(\d{4})/,
            '$1 ($2) $3-$4',
          );

        if (chavePix.match(/^\d{11}$/))
          return chavePix.replace(/(\d{2})(\d{5})(\d{4})/, '+55 ($1) $2-$3');
      }

      if (cnpj(chavePix)) {
        this.getKeyType('cnpj');
        return chavePix.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          '$1.$2.$3/$4-$5',
        );
      }

      if (isValidRandomKey(chavePix)) {
        this.getKeyType('random');
        return chavePix.replace(
          /(.{8})(.{4})(.{4})(.{4})(.{12})/,
          '$1-$2-$3-$4-$5',
        );
      }

      this.getKeyType('email');
      return defaultChavePix;
    },
    async initializeWebcam() {
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: false,
        });
        this.isWebcamReady = true;
        this.$nextTick(() => {
          const videoRef = this.$refs.video;
          videoRef.srcObject = this.stream;
          videoRef.play();
        });
      } catch (err) {
        console.log('Error to access the webcam', err);
      }
    },
    cancelWebcam() {
      if (this.stream) {
        const tracks = this.stream.getTracks();
        tracks.forEach((track) => track.stop());
        this.stream = null;
        this.isWebcamReady = false;
        this.hasPhoto = false;
      }
    },
    capturePhoto() {
      const videoRef = this.$refs.video;
      const canvasRef = this.$refs.canvas;
      const ctx = canvasRef.getContext('2d');
      canvasRef.width = videoRef.clientWidth;
      canvasRef.height = videoRef.clientHeight;
      canvasRef.classList.add('ml-1');

      ctx.drawImage(videoRef, 0, 0, canvasRef.width, canvasRef.height);

      canvasRef.toBlob((blob) => {
        const photoFile = new File([blob], `${uuid.v4()}.png`, {
          type: 'image/png',
        });
        this.photo = photoFile;
        this.hasPhoto = true;
      });
    },
    savePhoto() {
      this.photo.id = uuid.v4();
      if (this.photo) {
        // Create a blob field
        this.photo.blob = '';
        let URL = window.URL || window.webkitURL;
        if (URL) {
          this.photo.blob = URL.createObjectURL(this.photo);
        }
        // Thumbnails
        this.photo.thumb = '';
        if (this.photo.blob && this.photo.type.substr(0, 6) === 'image/') {
          this.photo.thumb = this.photo.blob;
        }
      }

      const perfil_documento = {
        file: this.photo,
        name: this.photo.name,
        tipo_documento: this.new_file_to_add,
        status: 'Aguardando Envio',
        isPhoto: true,
      };

      this.perfil_documentos.push(perfil_documento);
      this.show_alerta_salvar_documentos = true;
    },
    onEditorBlur(quill) {},
    onEditorFocus(quill) {},
    onEditorReady(quill) {},
    onCuradoriaEditorChange({ quill, html, text }) {
      this.pessoa.dados_complementares.approvalObservacao = html;
    },
    async getTipoDocumentos() {
      this.tipos_documentos = await attachmentsService.getTipoDocumentos();
    },

    removerPerfilDocumento(index) {
      this.perfil_documentos_excluidos.push(this.perfil_documentos[index]);
      this.$emit('handleIsDirty', this.perfil_documentos[index].file.id, 'remove');
      this.perfil_documentos.splice(index, 1);
      this.show_alerta_salvar_documentos = true;
    },

    async fileUploadInputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // Filter system files or hide files
        this.$emit('handleIsDirty', newFile.id);
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }
        // Filter php html js file
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent();
        }
        // Automatic compression
        if (
          newFile.file &&
          newFile.error === '' &&
          newFile.type.substr(0, 6) === 'image/' &&
          this.autoCompress > 0 &&
          this.autoCompress < newFile.size
        ) {
          newFile.error = 'compressing';
          const imageCompressor = new ImageCompressor(null, {
            convertSize: 1024 * 1024,
            maxWidth: 512,
            maxHeight: 512,
          });
          imageCompressor
            .compress(newFile.file)
            .then((file) => {
              this.$refs.upload.update(newFile, {
                error: '',
                file,
                size: file.size,
                type: file.type,
              });
            })
            .catch((err) => {
              this.$refs.upload.update(newFile, {
                error: err.message || 'compress',
              });
            });
        }
      }
      if (
        newFile &&
        newFile.error === '' &&
        newFile.file &&
        (!oldFile || newFile.file !== oldFile.file)
      ) {
        // Create a blob field
        newFile.blob = '';
        let URL = window.URL || window.webkitURL;
        if (URL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
        // Thumbnails
        newFile.thumb = '';
        if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
          newFile.thumb = newFile.blob;
        }
      }
      if (
        newFile &&
        newFile.error === '' &&
        newFile.type === 'application/pdf' &&
        newFile.blob &&
        (!oldFile || newFile.blob !== oldFile.blob)
      ) {
        newFile.contentType = 'application/pdf';
        let perfil_documento = {
          file: newFile,
          name: newFile.name,
          tipo_documento: this.new_file_to_add,
          status: 'Aguardando Envio',
        };
        this.perfil_documentos.push(perfil_documento);
        this.show_alerta_salvar_documentos = true;
      }
      // image size
      if (
        newFile &&
        newFile.error === '' &&
        newFile.type.substr(0, 6) === 'image/' &&
        newFile.blob &&
        (!oldFile || newFile.blob !== oldFile.blob)
      ) {
        let perfil_documento = {
          file: newFile,
          name: newFile.name,
          tipo_documento: this.new_file_to_add,
          status: 'Aguardando Envio',
        };
        this.perfil_documentos.push(perfil_documento);
        this.show_alerta_salvar_documentos = true;

        newFile.error = 'image parsing';
        let img = new Image();
        img.onload = () => {
          this.$refs.upload.update(newFile, {
            error: '',
            height: img.height,
            width: img.width,
          });
        };
        img.οnerrοr = (e) => {
          this.$refs.upload.update(newFile, { error: 'parsing image size' });
        };
        img.src = newFile.blob;
      }
    },

    async fileUploadInputFile(newFile, oldFile) {},

    async reprovarCadastro() {
      this.$refs.topProgress.start();

      this.pessoa.dados_complementares.approvalStatus =
        CWLStatusInvestidor.Reprovado;
      this.pessoa.dados_complementares.approvalObservacao =
        this.pessoaHistory.dados_complementares.approvalObservacao +
        `<p>${moment().format('DD/MM/YYYY HH:mm:ss')} - ${
          this.currentUser.uid
        } - ${this.currentUser.email} - Aprovação de cadastro</p>`;

      try {
        await pessoasService.updatePessoaBy(this.pessoa, {
          email: this.pessoa.email.email,
        });
        await investorsService.enviarEmailAtualizacaoCadastroReprovado(
          this.pessoa,
        );

        this.$notify({
          title:
            'Investidor reprovado! Ele receberá um e-mail com esta informação.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        this.$emit('cadastroReprovado');
      } catch (e) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.$refs.topProgress.done();
    },
    async aprovarCadastro() {
      this.$refs.topProgress.start();

      let objPersonData = this.pessoa.dados_complementares;
      objPersonData.approvalStatus = CWLStatusInvestidor.Aprovado;
      objPersonData.approvalObservacao =
        this.pessoaHistory.dados_complementares.approvalObservacao +
        `<p>${moment().format('DD/MM/YYYY HH:mm:ss')} - ${
          this.currentUser.uid
        } - ${this.currentUser.email} - Aprovação de cadastro</p>`;

      console.log('Pessoa aprovada', this.pessoa, objPersonData);

      const objUpdatePessoa = { dados_complementares: objPersonData };

      try {
        await pessoasService.updatePessoaBy(objUpdatePessoa, {
          email: this.pessoa.email.email,
        });
        await investorsService.enviarEmailAtualizacaoCadastroAprovado(
          this.pessoa,
        );

        this.$notify({
          title:
            'Investidor aprovado! Ele receberá um e-mail com esta informação.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        this.$emit('cadastroAprovado');
      } catch (e) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.$refs.topProgress.done();
    },
    goToDocuments() {
      const pessoa = { ...this.pessoa };
      pessoa.endereco.estado = this.stateSelected;
      console.log('Go To Documents', pessoa);
      this.$emit('saveProfileInformations', {
        pessoa: pessoa,
        goToDocuments: true,
      });
    },
    async saveProfileInformations() {
      await this.salvarPerfilDocumentos();
    },

    async asyncForEach(array, callback) {
      if (!array) return;
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    },

    async salvarPerfilDocumentos() {
      this.$refs.topProgress.start();
      let that = this;
      var email = this.currentPessoa.email.email;
      let pessoa = { ...that.pessoa };
      email = pessoa.email.email;

      var errors = 0;

      const run = async () => {
        pessoa.endereco.estado = that.stateSelected;

        pessoa.dados_complementares.approvalObservacao =
          this.pessoaHistory.dados_complementares.approvalObservacao +
          `<p>${moment().format('DD/MM/YYYY HH:mm:ss')} - ${
            this.currentUser.uid
          } - ${this.currentUser.email} - ${this.observacao_historico}</p>`;

        if (!that.show_alerta_salvar_documentos) {
          var documentFilled = true;
          await that.asyncForEach(
            that.tipos_documentos,
            async (tipoDocumento) => {
              if (tipoDocumento.obrigatorio && documentFilled) {
                var temEsseDocumento = false;
                await that.asyncForEach(
                  pessoa.perfil_documentos,
                  async (perfilDocumento) => {
                    if (
                      perfilDocumento.tipo_documento.nome == tipoDocumento.nome
                    ) {
                      temEsseDocumento = true;
                    }
                  },
                );
                documentFilled = temEsseDocumento;
              }
            },
          );
          that.$emit('saveProfileInformations', {
            pessoa: pessoa,
            goToDocuments: false,
            documentFilled: documentFilled,
          });
          that.$refs.topProgress.done();
          return;
        } else {
          await that.asyncForEach(
            that.perfil_documentos_excluidos,
            async (perfilDocumento) => {
              try {
                await attachmentsService.deleteAttachment(
                  perfilDocumento.file.name,
                  'InvestorDocuments',
                  email,
                );
              } catch (e) {
                console.log(e);
              }
            },
          );

          let pessoa_perfil_documentos = [];
          await that.asyncForEach(
            that.perfil_documentos,
            async (perfilDocumento) => {
              let nomeDocumento =
                perfilDocumento.tipo_documento.nome +
                '_' +
                perfilDocumento.file.id;
              try {
                if (perfilDocumento.status != 'Enviado') {
                  perfilDocumento.status = 'Enviando...';
                  if (perfilDocumento.isPhoto) {
                    await attachmentsService.addNewDocumentAttachment(
                      perfilDocumento.file,
                      nomeDocumento,
                      'InvestorDocuments',
                      email,
                    );
                  } else {
                    await attachmentsService.addNewDocumentAttachment(
                      perfilDocumento.file.file,
                      nomeDocumento,
                      'InvestorDocuments',
                      email,
                    );
                  }
                  perfilDocumento.status = 'Enviado';
                }

                let pessoaPerfilDocumento = {
                  id: perfilDocumento.file.id,
                  tipo_documento: perfilDocumento.tipo_documento,
                  storage_nome_arquivo: nomeDocumento,
                };
                pessoa_perfil_documentos.push(pessoaPerfilDocumento);
              } catch (e) {
                errors++;
                console.log('ERRO', e);
                perfilDocumento.status = 'Erro ao enviar';
              }
            },
          );

          var documentFilled = true;
          await that.asyncForEach(
            that.tipos_documentos,
            async (tipoDocumento) => {
              if (tipoDocumento.obrigatorio && documentFilled) {
                var temEsseDocumento = false;
                await that.asyncForEach(
                  pessoa_perfil_documentos,
                  async (perfilDocumento) => {
                    if (
                      perfilDocumento.tipo_documento.nome == tipoDocumento.nome
                    ) {
                      temEsseDocumento = true;
                    }
                  },
                );
                documentFilled = temEsseDocumento;
              }
            },
          );

          if (errors > 0) {
            that.$notify({
              title: 'Ooopss',
              message:
                'Desculpe, ocorreu um erro ao salvar os documentos. Tente novamente.',
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'warning',
              timeout: 3000,
            });
          } else {
            that.show_alerta_salvar_documentos = false;
            that.$emit('saveProfileInformations', {
              pessoa: pessoa,
              goToDocuments: false,
              documentFilled: documentFilled,
            });
          }
        }

        that.$refs.topProgress.done();
      };
      run();
    },
    setAddressStateSelected() {
      this.stateSelected = {};

      if (
        !this.pessoa.endereco ||
        Object.keys(this.pessoa.endereco).length === 0
      )
        return;

      const estado = this.pessoa.endereco.estado;

      if (typeof estado == 'string') {
        for (let index = 0; index < this.model.states.length; index++) {
          const _state = this.model.states[index];
          if (_state.uf == estado) {
            this.stateSelected = _state;
            break;
          }
        }
      } else if (estado.uf) {
        this.stateSelected = estado;
      }
    },
    async getStates() {
      this.model.states = await statesService.getAllStates();
    },
    async changeCEP() {
      if (!this.$v.pessoa.endereco.cep.valid || this.isLoadingEnderecoPessoa)
        return;

      if (!this.isLoadingEnderecoPessoa) {
        this.isLoadingEnderecoPessoa = true;
        try {
          const enderecoFound = await pessoasService.searchAddressByZipcode(
            this.pessoa.endereco.cep,
          );
          this.pessoa.endereco = Object.assign(
            this.pessoa.endereco || {},
            enderecoFound || {},
          );
          this.setAddressStateSelected();
        } catch (error) {
          console.error(error);
        }
        this.isLoadingEnderecoPessoa = false;
      }
    },
    selectedFile() {
      this.selectedFilename = this.$refs.uploadFile.files[0].name;
      this.fileChanged = true;
    },
    clickFileInput() {
      this.$refs.uploadFile.click();
    },
    async getPerfilDocumentos() {
      try {
        this.getProfileDocumentsLoading = true;
        if (this.pessoaId) {
          this.pessoa = await pessoasService.findPessoaById(this.pessoaId);
          if (this.pessoa.dados_complementares.estado_civil) {
            this.pessoa.dados_complementares.estado_civil =
              this.normalizeString(
                this.pessoa.dados_complementares.estado_civil,
              );
          }

          if (this.pessoa.dados_complementares.genero) {
            this.pessoa.dados_complementares.genero = this.normalizeString(
              this.pessoa.dados_complementares.genero,
            );
          }
        }
        var email = this.currentPessoa.email.email;
        if (this.pessoaId) {
          email = this.pessoa.email.email;
        }
        const result = await attachmentsService.getUserDocuments();

        let that = this;

        for (const item of result) {
          let perfilDocumento = {
            file: {
              id: item.id,
              name: item.name,
              thumb: item.url,
              contentType: item.contentType,
            },
            tipo_documento: {
              nome: item.name,
            },
            status: 'Enviado',
          };
          that.perfil_documentos.push(perfilDocumento);
        }
      } catch (err) {
        console.log('Erro ao buscar documentos:', err);
        this.$notify({
          title: 'Ooopss',
          message: `Desculpe, ocorreu um erro. ${err}`,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      } finally {
        this.getProfileDocumentsLoading = false;
      }
    },
    async getUserDocument() {
      if (this.pessoaId) {
        this.pessoa = await pessoasService.findPessoaById(this.pessoaId);
      }

      var email = this.currentPessoa.email.email;
      if (this.pessoaId) {
        email = this.pessoa.email.email;
      }
      const result = await attachmentsService.getUserDocuments();

      result.map((item) => {
        if (item.name == 'Documento de Identificação') {
          this.file.item = item;
          this.selectedFilename = item.name;
        }
      });
    },
    async downloadDocument(perfilDocumento) {
      if (perfilDocumento.status != 'Enviado') {
        return;
      }
      var nome = '';
      if (
        perfilDocumento.tipo_documento &&
        perfilDocumento.tipo_documento.nome &&
        perfilDocumento.tipo_documento.id
      ) {
        nome =
          perfilDocumento.tipo_documento.nome +
          '_' +
          perfilDocumento.tipo_documento.id;
      } else {
        nome = perfilDocumento.file.name;
      }

      let name =
        perfilDocumento.file.name == 'Documento de Identificação' ||
        perfilDocumento.file.name == 'Documento de Identificação.jpeg'
          ? perfilDocumento.file.name
          : nome;
      this.$refs.topProgress.start();

      var email = this.currentPessoa.email.email;
      if (this.pessoaId) {
        email = this.pessoa.email.email;
      }

      var type = undefined;
      if (perfilDocumento.file.contentType == 'application/pdf') {
        type = '.pdf';
      } else if (perfilDocumento.file.contentType == 'image/png') {
        type = '.png';
      } else if (perfilDocumento.file.contentType == 'image/jpeg') {
        type = '.jpeg';
      } else if (perfilDocumento.file.contentType == 'image/jpg') {
        type = '.jpg';
      }

      try {
        const path = 'InvestorDocuments/' + email;
        await attachmentsService.downloadAttachment(name, path, type);
      } catch (e) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.$refs.topProgress.done();
    },
    async downloadItem() {
      this.$refs.topProgress.start();

      const item = this.file.item;
      var email = this.currentPessoa.email.email;
      if (this.pessoaId) {
        email = this.pessoa.email.email;
      }

      try {
        const path = 'InvestorDocuments/' + email;
        await attachmentsService.downloadAttachment(item.name, path);
      } catch (e) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.$refs.topProgress.done();
    },
    async addNewAttachment() {
      this.$refs.topProgress.start();
      const file = await this.$refs.uploadFile.files[0];
      if (!file) {
        const pessoa = { ...this.pessoa };
        pessoa.endereco.estado = this.stateSelected;
        var documentFilled = false;
        if (this.file.item.name) {
          documentFilled = true;
        }
        console.log('Add New Anexo', pessoa);
        this.$emit('saveProfileInformations', {
          pessoa: pessoa,
          goToDocuments: false,
          documentFilled: documentFilled,
        });
        this.$refs.topProgress.done();
        return;
      }

      var email = this.currentPessoa.email.email;
      if (this.pessoaId) {
        let pessoa = await pessoasService.findPessoaById(this.pessoaId);
        email = pessoa.email.email;
      }

      try {
        const logUser = {
          user: {
            email: email,
          },
        };
        logsService.createNewLog(
          logUser,
          '',
          'Adicionar Documento Identificação',
          '',
          'Documento de Identificação',
        );
        await attachmentsService.addNewDocumentAttachment(
          file,
          'Documento de Identificação',
          'InvestorDocuments',
          email,
        );

        const pessoa = { ...this.pessoa };
        pessoa.endereco.estado = this.stateSelected;

        console.log('Add Anexo', pessoa);
        this.$emit('saveProfileInformations', {
          pessoa: pessoa,
          goToDocuments: false,
          documentFilled: true,
        });
      } catch (e) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.$refs.topProgress.done();
    },
    /* eslint-disable max-len */
    updateLimiteInvestimento() {
      const rendaBruta = this.pessoa.dados_complementares.perfil
        .renda_bruta_anual
        ? this.pessoa.dados_complementares.perfil.renda_bruta_anual
        : 0;
      const investimentosFinanceiros = this.pessoa.dados_complementares.perfil
        .investimentos_financeiros
        ? this.pessoa.dados_complementares.perfil.investimentos_financeiros
        : 0;
      const investimentosNestaPlataforma = this.pessoa.dados_complementares
        .perfil.investimentos_nesta_plataforma
        ? this.pessoa.dados_complementares.perfil.investimentos_nesta_plataforma
        : 0;
      const investimentosOutrasPlataformas = this.pessoa.dados_complementares
        .perfil.investimentos_outras_plataformas
        ? this.pessoa.dados_complementares.perfil
            .investimentos_outras_plataformas
        : 0;

      if (investimentosFinanceiros > 1000000) {
        this.limite_investimento = 'Ilimitado';
      } else if (investimentosFinanceiros > 100000 || rendaBruta > 100000) {
        let calculo10Porcento = 0;
        if (investimentosFinanceiros > rendaBruta) {
          calculo10Porcento = investimentosFinanceiros * 0.1;
        } else {
          calculo10Porcento = rendaBruta * 0.1;
        }
        const montante =
          investimentosNestaPlataforma + investimentosOutrasPlataformas;
        const limiteInvestimentoPuro = calculo10Porcento - montante;

        const limiteInvestimentoPreFormatado = parseFloat(
          limiteInvestimentoPuro,
        )
          .toFixed(2)
          .replace('.', ',');
        const limiteInvestimento =
          'R$ ' +
          limiteInvestimentoPreFormatado
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        this.limite_investimento = limiteInvestimento;
      } else {
        const montante =
          investimentosNestaPlataforma + investimentosOutrasPlataformas;
        const limiteInvestimentoPuro = 10000 - montante;

        const limiteInvestimentoPreFormatado = parseFloat(
          limiteInvestimentoPuro,
        )
          .toFixed(2)
          .replace('.', ',');
        const limiteInvestimento =
          'R$ ' +
          limiteInvestimentoPreFormatado
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        this.limite_investimento = limiteInvestimento;
      }
    },
    normalizeString(inputString) {
      // Remove accents
      const withoutAccents = inputString
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

      // Convert to lowercase
      const normalized = withoutAccents.toLowerCase();

      return normalized;
    },
  },
};
</script>

<template>
  <form @submit.prevent="saveProfileInformations()">
    <card>
      <h5 slot="header" class="title mt-3">Dados Complementares</h5>
      <div class="row">
        <div class="col-md-4 pr-md-1">
          <base-input
            v-model="pessoa.dados_complementares.rg"
            label="RG *"
            :boldLabel="true"
            placeholder="Informe seu RG"
            required
            @change="$emit('handleChangeIsDirty', $event, 'dados_complementares.rg')"
          ></base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input label="Estado Civil *">
            <select
              class="form-control"
              v-model="pessoa.dados_complementares.estado_civil"
              required
            >
              <option value="">Selecionar</option>
              <option value="solteiro">Solteiro(a)</option>
              <option value="casado">Casado(a)</option>
              <option value="divorciado">Divorciado(a)</option>
              <option value="viuvo">Viúvo(a)</option>
              <option value="separado_judicialmente">
                Separado(a) Judicialmente
              </option>
              <option value="outro">Outro</option>
            </select>
          </base-input>
        </div>
        <div class="col-md-4 pl-md-1">
          <base-input label="Genêro *">
            <select
              class="form-control"
              v-model="pessoa.dados_complementares.genero"
              required
            >
              <option value="">Selecionar</option>
              <option value="masculino">Masculino</option>
              <option value="feminino">Feminino</option>
              <option value="outro">Outro</option>
            </select>
          </base-input>
        </div>
      </div>

      <h5 class="title mt-3">Endereço</h5>
      <div class="row">
        <div class="col-md-12 pr-md-1">
          <div class="form-group">
            <base-input
              label="CEP *"
              v-mask="'#####-###'"
              placeholder="Digite seu CEP"
              v-model="pessoa.endereco.cep"
              v-on:input="changeCEP"
              required
            ></base-input>
            <label v-if="isLoadingEnderecoPessoa" style="cursor: default"
              ><i>Pesquisando...</i></label
            >
            <div
              class="error"
              v-if="pessoa.endereco.cep && !$v.pessoa.endereco.cep.valid"
            >
              Informe um CEP válido.
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Número *"
            placeholder="Digite seu Número"
            v-mask="['########']"
            v-model="pessoa.endereco.numero"
            required
          ></base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Complemento"
            maxlength="30"
            placeholder="Digite seu Complemento"
            v-model="pessoa.endereco.complemento"
          ></base-input>
        </div>
        <div class="col-md-4 pl-md-1">
          <base-input
            label="Bairro *"
            v-model="pessoa.endereco.bairro"
            placeholder="Digite seu Bairro"
            required
          ></base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Rua *"
            v-model="pessoa.endereco.rua"
            placeholder="Digite sua Rua"
            required
          >
          </base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Cidade *"
            placeholder="Digite sua Cidade"
            v-model="pessoa.endereco.cidade"
            required
          ></base-input>
        </div>
        <div class="col-md-4 pl-md-1">
          <label>Estado *</label>
          <select class="form-control" v-model="stateSelected" required>
            <option
              :value="state"
              v-for="(state, index) in model.states"
              :key="index"
            >
              {{ state.name }}
            </option>
          </select>
        </div>
      </div>

      <h5 class="title mt-3">Profissão</h5>

      <div class="row">
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Ocupação *"
            placeholder="Digite sua Ocupação"
            v-model="pessoa.dados_complementares.ocupacao"
            required
          ></base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Nome da Empresa (Opcional)"
            placeholder="Digite o nome de sua Empresa"
            v-model="pessoa.dados_complementares.nome_empresa"
          ></base-input>
        </div>
        <div class="col-md-4 pl-md-1">
          <base-input
            label="Cargo (Opcional)"
            placeholder="Digite seu Cargo"
            v-model="pessoa.dados_complementares.cargo"
          ></base-input>
        </div>
      </div>

      <h5 class="title mt-3">Dados Bancários</h5>

      <div class="row">
        <div class="col-md-2 pr-md-1">
          <base-input
            label="Agência (sem dígito) *"
            :boldLabel="true"
            placeholder="Digite sua Agência"
            v-model="pessoa.dados_complementares.dados_bancarios.agencia"
            required
            @change="$emit('handleChangeIsDirty', $event, 'dados_complementares.dados_bancarios.agencia')"
          ></base-input>
        </div>
        <div class="col-md-3 pr-md-1">
          <base-input
            label="Conta (com dígito) *"
            :boldLabel="true"
            placeholder="Digite sua Conta"
            v-model="pessoa.dados_complementares.dados_bancarios.conta"
            required
            @change="$emit('handleChangeIsDirty', $event, 'dados_complementares.dados_bancarios.conta')"
          ></base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Chave PIX *"
            :boldLabel="true"
            placeholder="Digite sua chave pix"
            v-model="pessoa.dados_complementares.dados_bancarios.chave_pix"
            required
            @change="$emit('handleChangeIsDirty', $event, 'dados_complementares.dados_bancarios.chave_pix')"
          ></base-input>
        </div>
        <div class="col-md-3 pl-md-1">
          <base-input label="Banco *" :boldLabel="true" required>
            <select
              class="form-control"
              v-model="pessoa.dados_complementares.dados_bancarios.banco"
              required
              @change="$emit('handleChangeIsDirty', $event, 'dados_complementares.dados_bancarios.banco')"
            >
              <option value="" disabled>Selecionar</option>
              <option
                v-for="bank in model.banks"
                :key="bank.code"
                :value="bank.name"
              >
                {{ bank.name }}
              </option>
            </select>
          </base-input>
        </div>
      </div>

      <h5 class="title mt-3">Perfil de Investimento</h5>

      <div class="row">
        <div class="col-md-12">
          <base-input
            required
            label="Qual situação melhor se encaixa ao seu perfil atual em relação a investimentos? (opcional)"
          >
            <select
              class="form-control"
              v-model="pessoa.dados_complementares.perfil.perfil"
            >
              <option value="" disabled>Selecionar</option>
              <option
                v-for="profile in model.profiles"
                :key="profile.id"
                :value="profile.profile"
              >
                {{ profile.profile }}
              </option>
            </select>
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <base-input
            label="Qual o principal segmento você tem interesse em investir? (opcional)"
          >
            <select
              class="form-control"
              v-model="pessoa.dados_complementares.perfil.segmento"
            >
              <option value="" disabled>Selecionar</option>
              <option
                v-for="segment in model.segments"
                :key="segment.id"
                :value="segment.name"
              >
                {{ segment.name }}
              </option>
            </select>
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <base-input
            label="Quanto do seu patrimônio você está disposto a investir? (opcional)"
          >
            <select
              class="form-control"
              v-model="pessoa.dados_complementares.perfil.valor"
            >
              <option value="" disabled>Selecionar</option>
              <option
                v-for="value in model.values"
                :key="value.id"
                :value="value.values"
              >
                {{ value.values }}
              </option>
            </select>
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 mb-1">
          <base-checkbox
            v-model="pessoa.dados_complementares.aparecer_investidor"
          >
            Concordo em aparecer como investidor
          </base-checkbox>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 mb-3">
          <base-checkbox
            v-model="pessoa.dados_complementares.politicamente_exposto"
          >
            Sou uma pessoa politicamente exposta
          </base-checkbox>
        </div>
      </div>

      <div class="row" v-if="submodalidade == '588'">
        <div class="col-md-12">
          <label for="other-investments">{{
            $t('profile.otherInvestments')
          }}</label>
          <currency-input
            id="other-investments"
            class="form-control"
            v-model="
              pessoa.dados_complementares.perfil
                .investimentos_outras_plataformas
            "
            placeholder="Informe o valor dos Investimentos"
            currency="BRL"
            locale="pt-br"
            :auto-decimal-mode="true"
            :readonly="isGestorOrCurador"
          />
        </div>
      </div>

      <div class="row" v-if="submodalidade == '588'">
        <div class="col-md-12">
          <label for="investment-this-platform">{{
            $t('profile.investmentThisPlatform')
          }}</label>
          <currency-input
            readonly
            id="investment-this-platform"
            class="form-control"
            v-model="
              pessoa.dados_complementares.perfil.investimentos_nesta_plataforma
            "
            placeholder="R$ 0,00"
            currency="BRL"
            locale="pt-br"
            :auto-decimal-mode="true"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <label
            for="annual-gross"
            v-if="submodalidade == '588'"
            >{{ $t('profile.annualGross.588') }}</label
          >
          <label
            for="annual-gross"
            v-if="submodalidade == 'bacen'"
            >{{ $t('profile.annualGross.bacen') }}</label
          >

          <currency-input
            id="annual-gross"
            class="form-control"
            v-model="pessoa.dados_complementares.perfil.renda_bruta_anual"
            :placeholder="
              submodalidade == '588'
                ? $t('profile.annualGross.588')
                : $t('profile.annualGross.bacen')
            "
            currency="BRL"
            locale="pt-br"
            :auto-decimal-mode="true"
            v-on:focusout="updateLimiteInvestimento"
            required
          />
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <label
            for="financial-investments"
            v-if="submodalidade == '588'"
            >{{ $t('profile.financialInvestments.588') }}</label
          >
          <label
            for="financial-investments"
            v-if="submodalidade == 'bacen'"
            >{{ $t('profile.financialInvestments.bacen') }}</label
          >

          <currency-input
            id="financial-investments"
            class="form-control"
            v-model="
              pessoa.dados_complementares.perfil.investimentos_financeiros
            "
            :placeholder="
              submodalidade == '588'
                ? $t('profile.financialInvestments.588')
                : $t('profile.financialInvestments.bacen')
            "
            currency="BRL"
            locale="pt-br"
            :auto-decimal-mode="true"
            v-on:focusout="updateLimiteInvestimento"
            required
          />
        </div>
      </div>

      <div class="row" v-if="submodalidade == '588'">
        <div class="col-md-12">
          <base-input
            label="Limite Atual de Investimento"
            placeholder=""
            v-model="limite_investimento"
            readonly
          ></base-input>
        </div>
      </div>

      <!-- <div class="row">
        <div class="col-md-12">
          <label class="form-label" style="font-weight: bold;">Documento de Identificação</label> <br>
            <button type="button" class="btn btn-sm" v-if="file.item.name" @click="clickFileInput">
              Alterar
            </button>
            <button type="button" class="btn btn-sm btn-warning" v-if="!file.item.name" @click="clickFileInput">
              Selecionar documento obrigatório
            </button>
            <span style="font-style: italic;">
              RG + CPF ou CNH (carregar foto self, segurando o documento de identificação ao lado do rosto)
            </span>
            <button type="button" class="btn btn-sm btn-dark" v-if="file.item.name" @click="downloadItem">
              Download
            </button>
            <span v-if="fileChanged" style="font-style: italic; font-size: 12px; padding-top: 5px;">Selecionado! Não esqueça de clicar em Salvar.</span>
          <input style="opacity: 0; height: 0px; width: 0px; opacity:0; position:absolute; left: 0; top: 0;" class="form-control" type="file" ref="uploadFile" id="formFile" @change="selectedFile()" accept="image/*, .pdf" tabindex="-1">
        </div>
      </div> -->

      <br />
      <div class="row">
        <div class="col-md-12">
          <h5><b>DOCUMENTOS</b></h5>
        </div>
      </div>
      <div class="row" v-if="!new_file_fields_shown">
        <div class="col-md-3">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            fill
            @click="new_file_fields_shown = true"
          >
            Adicionar Documentos
          </button>
        </div>
      </div>
      <div class="row" v-if="new_file_fields_shown">
        <div class="col-md-12">
          <base-input label="Tipo de Documento" style="font-weight: bold">
            <select class="form-control col-md-3" v-model="new_file_to_add">
              <option
                :value="tipo_documento"
                v-for="(tipo_documento, index) in tipos_documentos"
                :key="index"
              >
                {{ tipo_documento.nome }}
              </option>
            </select>
          </base-input>
          <span
            ><i><b>Atenção:</b> {{ new_file_to_add.orientacao }}</i></span
          >
        </div>
      </div>
      <div class="row" v-if="new_file_fields_shown && new_file_to_add.nome">
        <div class="w-100">
          <file-upload
            class="btn btn-primary"
            :extensions="extensions"
            :accept="accept"
            :capture="new_file_to_add.photo_selfie ? 'capture' : false"
            :multiple="multiple"
            :directory="directory"
            :create-directory="createDirectory"
            :thread="thread < 1 ? 1 : thread > 5 ? 5 : thread"
            :drop="drop"
            :drop-directory="dropDirectory"
            v-model="new_files"
            @input-filter="fileUploadInputFilter"
            @input-file="fileUploadInputFile"
            ref="upload"
          >
            {{
              new_file_to_add.photo_selfie
                ? 'Buscar foto do documento'
                : 'Selecione o documento'
            }}
          </file-upload>

          <span
            class="btn btn-primary"
            v-if="new_file_to_add.photo_selfie"
            @click="initializeWebcam"
            >Tirar foto com documento</span
          >
        </div>
      </div>
      <div class="row d-flex flex-column mx-1 mt-2" v-if="isWebcamReady">
        <div class="row" v-if="isWebcamReady">
          <video ref="video" width="auto" height="380"></video>
          <canvas ref="canvas"></canvas>
        </div>
        <div class="row my-2">
          <button
            class="btn btn-primary ml-0 mr-2"
            type="button"
            @click="cancelWebcam"
          >
            Cancelar
          </button>
          <button
            class="btn btn-primary ml-0 mr-0"
            type="button"
            @click="capturePhoto"
          >
            Tirar foto
          </button>
          <button
            class="btn btn-primary ml-auto mr-0"
            type="button"
            @click="savePhoto"
            :disabled="!hasPhoto"
          >
            Salvar foto
          </button>
        </div>
      </div>
      <div class="row">
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Miniatura</th>
                <th>Tipo de Documento</th>
                <th style="text-align: center">Status</th>
                <th style="text-align: center">Ações</th>
              </tr>
            </thead>
            <tbody v-if="getProfileDocumentsLoading">
              <tr>
                <td colspan="4" class="text-center">
                  <double-bounce></double-bounce>
                </td>
              </tr>
            </tbody>
            <tbody v-if="!getProfileDocumentsLoading">
              <tr v-if="!perfil_documentos.length">
                <td colspan="4">
                  <div class="text-center p-5">
                    <h4>
                      Não existem documentos cadastrados. <br />Por favor,
                      clique em Adicionar Documentos.
                    </h4>
                  </div>
                </td>
              </tr>
              <tr
                v-for="(perfilDocumento, index) in perfil_documentos"
                :key="perfilDocumento.file.id"
              >
                <td @click="downloadDocument(perfilDocumento)">
                  <img
                    class="td-image-thumb"
                    v-if="
                      perfilDocumento.file.thumb &&
                      perfilDocumento.file.contentType != 'application/pdf'
                    "
                    :src="perfilDocumento.file.thumb"
                  />
                  <span
                    v-else-if="
                      perfilDocumento.file.contentType == 'application/pdf'
                    "
                  >
                    <img
                      src="@/assets/images/pdf.png"
                      alt="PDF"
                      style="width: 32px"
                    />
                  </span>
                  <span v-else>--</span>
                </td>
                <td>
                  {{ perfilDocumento.tipo_documento.nome }}
                </td>
                <td style="text-align: center">
                  {{ perfilDocumento.status }}
                </td>
                <td style="text-align: center">
                  <div class="btn-group">
                    <button
                      class="btn btn-warning btn-sm"
                      v-if="
                        isGestor ||
                        perfilDocumento.status === 'Aguardando Envio'
                      "
                      type="button"
                      @click="removerPerfilDocumento(index)"
                    >
                      Remover
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="show_alerta_salvar_documentos"
          class="col-md-12"
          style="
            text-align: center;
            margin-top: -40px;
            color: red;
            font-weight: bold;
          "
        >
          <span
            ><i
              >*** CLIQUE EM SALVAR PARA CONCLUIR AS ALTERAÇÕES DOS DOCUMENTOS
              ***</i
            ></span
          >
        </div>
      </div>
      <div class="row" v-if="isGestor">
        <div class="col-md-4 pr-md-1">
          <base-input
            v-model="observacao_historico"
            label="Informe o histórico de alteração *"
            :boldLabel="true"
            placeholder=""
            required
          ></base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <button
            type="submit"
            slot="footer"
            class="btn btn-sm btn-primary mt-3"
            fill
            :disabled="isGestor && observacao_historico.toString().trim() == ''"
          >
            Salvar
          </button>
        </div>
      </div>
    </card>

    <card
      v-if="
        isGestorOrCurador ||
        pessoa.dados_complementares.approvalStatus ==
          CWLStatusInvestidor.Reprovado
      "
    >
      <h5 slot="header" class="title mt-3" v-if="isGestor">Gestor</h5>
      <h5 slot="header" class="title mt-3" v-if="isCurador">Curador</h5>
      <h5 slot="header" class="title mt-3" v-if="isUsuario">Curadoria</h5>

      <div class="row" v-if="isGestorOrCurador">
        <div class="col-md-12">
          <label for="quillEditor">Observações</label>
          <quill-editor
            ref="quillEditor"
            v-model="pessoa.dados_complementares.approvalObservacao"
            :options="editorCuradoriaOption"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
            @change="onCuradoriaEditorChange($event)"
            :disabled="
              !isGestorOrCurador &&
              pessoa.dados_complementares.approvalStatus ==
                CWLStatusInvestidor.Reprovado
            "
          />
        </div>
      </div>

      <div class="row" v-if="isUsuario">
        <div class="col-md-12">
          <label class="control-label" style="cursor: default">
            Seu cadastro foi analisado pela curadoria e não foi aprovado pelos
            motivos abaixo:
          </label>
        </div>
      </div>

      <div class="row" v-if="isUsuario">
        <div class="col-md-12">
          <div
            style="font-style: italic; font-size: 12px"
            v-html="pessoa.dados_complementares.approvalObservacao"
          ></div>
        </div>
      </div>
    </card>
    <vue-topprogress ref="topProgress"></vue-topprogress>
  </form>
</template>

<style>
.td-image-thumb {
  max-width: 4em;
  max-height: 4em;
}
</style>
