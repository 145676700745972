<script>
import { vueTopprogress } from 'vue-top-progress';
import { DoubleBounce } from 'vue-loading-spinner';
import { questionsArray } from '@/functions/questions.js';
import brokersService from '@/services/Brokers/brokersService';
import {BaseRadio} from '@/components/';
import examService from '@/services/Exam/examService';

const approvalStatusLabel = ["Aprovado", "Reprovado"];

export default {
  components: {
    vueTopprogress,
    DoubleBounce,
    BaseRadio,
  },
  data() {
    return {
      question_control: 0,
      question_answer: "",
      answers: [],
      questions: questionsArray,
      evaluate: {},
      exam: {},
      approval_status: "",
      approval_status_label: approvalStatusLabel,
    };
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
    correctScore() {
      let score = 0;
      for (const questionId in this.evaluate) {
        if (this.evaluate[questionId] === 'Correto') {
          score += 1;
        } else if (this.evaluate[questionId] === 'Parcialmente correto') {
          score += 0.5;
        }
      }
      return score;
    },
    percentCorrect() {
      return ((this.correctScore / (65 * 1.0)) * 100).toFixed(0);
    },
  },
  mounted() {
    this.getBroker();
  },
  methods: {
    async getBroker() {
      const broker = await brokersService.getBroker(this.$route.params.id);
      this.answers = broker.exam.answers;
      this.exam = broker.exam;
    },
    async handleExam() {
      try {
        const exam = { ...this.exam, answers: this.answers, exam_approval: this.approval_status, percent: this.percentCorrect };

        await brokersService.updateBroker(this.$route.params.id, { exam });

        await examService.deleteExamByEmail(this.currentPessoa.email.email);        

        this.$router.go(-1);

        this.$notify({
          title: 'Exame corrigido',
          message: 'Exame atualizado com sucesso',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch(err) {
        console.log(err);
        this.$notify({
          title: 'Erro ao corrigir exame',
          message: 'Entre em contato com a administração',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'error',
          timeout: 3000,
        });
      }
    },
    handleCheckInputClick(questionId, option) {
      this.$set(this.evaluate, questionId, option);
      const findAnswerIndex = this.answers.findIndex(answer => answer.id === questionId);

      this.answers[findAnswerIndex] = { ...this.answers[findAnswerIndex], evaluate: option };
    },
    onChangeSelect(event) {
      this.approval_status = event.target.value
    },
  },
};
</script>

<template>
  <div class="add-user">
    <card>
      <form @submit.prevent="handleExam()">
        <template v-for="(question, index) in questions">
          <div style="display: flex; justify-content: space-between; align-items: center;">
            <div style="width: 100%">
              <strong>{{ question.id }}. {{ question.question }}</strong>
              <template v-if="question.options" v-for="(option, index) in question.options">
                <br>{{ option }}
              </template>
              <textarea
                type="text"
                :rows="question.id === 65 ? 10 : 3"
                class="textarea form-control mt-2 mb-4"
                placeholder="Resposta"
                style="border: 1px solid #ccc; border-radius: 10px; padding: 10px;"
                disabled
                :value="answers[index].answer"
              >
              </textarea>

              <div style="width: 100%; display: flex; align-items: center; justify-content: flex-end; margin-bottom: 20px;">
                <label class="mr-4">
                  <input
                    type="radio"
                    :value="'Correto'"
                    :name="`question-${question.id}`"
                    @click="handleCheckInputClick(question.id, 'Correto')"
                    :checked="evaluate[question.id] === 'Correto'"
                  >
                  Correto
                </label>

                <label class="mr-4">
                  <input
                    type="radio"
                    :value="'Parcialmente correto'"
                    :name="`question-${question.id}`"
                    @click="handleCheckInputClick(question.id, 'Parcialmente correto')"
                    :checked="evaluate[question.id] === 'Parcialmente correto'"
                  >
                  Parcialmente correto
                </label>
                
                <label class="mr-4">
                  <input
                    type="radio"
                    :value="'Incorreto'"
                    :name="`question-${question.id}`"
                    @click="handleCheckInputClick(question.id, 'Incorreto')"
                    :checked="evaluate[question.id] === 'Incorreto'"
                  >
                  Incorreto
                </label>
              </div>
            </div>
          </div>
        </template>
        
        <div style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
          <p>Porcentagem de acertos: {{ percentCorrect }}%</p>

          <select class="form-control col-md-3" @change="onChangeSelect($event)" v-model="approval_status">
            <option value="" selected>Resultado:</option>
            <option
              v-for="(status, index) in approval_status_label"
              :value="status"
              :key="index"
            >
              {{ status }}
            </option>
          </select>

          <button type="submit" class="btn btn-sm ml-0">Enviar correção</button>
        </div>
      </form>
    </card>
  </div>
</template>
