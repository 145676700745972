import axios from 'axios';

export default {
    async triggerWelcome(userId) {
        const response = await axios.post(
          process.env.VUE_APP_ENDPOINT_WS + 'whatsTriggerWelcome',
          {
            userId : userId
          },
          {
            headers: {
                'Content-Type': 'application/json'
            }
          }
        );

        console.log(response);

        return response;
      }
}