import moment from 'moment-timezone';

export default order => {
    //Calculo de remuneracao deve considerar D+30 a partir do pagamento do aporte

    if (order && (order.status === 'Pago' || order.status === 'Liquidado')) {
        
        const today = moment();

        const orderPaymentDate = moment.unix(order.data_pagamento)

        const dataVencimento = moment.unix(order.data_vencimento).format('DD/MM/YYYY')

        var months = 0
        if (order.data_liquidacao) {
            months = moment.unix(order.data_liquidacao).diff(orderPaymentDate, 'month');
        } else {
            months = today.diff(orderPaymentDate, 'month');
        }

        var prazoAtual =  0
        if (months > parseInt(order.financing_details.fields['prazo_oferta'].value)) 
            prazoAtual = order.financing_details.fields['prazo_oferta'].value
        else prazoAtual = months
        // console.log({[order.ccb.numero_ccb]: { 
        //     ccb: order.ccb.numero_ccb,
        //     order: order,
        //     checkpoint: prazoAtual,
        //     prazo_oferta: parseInt(order.financing_details.fields['prazo_oferta'].value),
        //     vencimento: dataVencimento, 
        //     percentual: Math.round(prazoAtual == order.financing_details.fields['prazo_oferta'].value ? 100 : (prazoAtual / order.financing_details.fields['prazo_oferta'].value) * 100)
        //    }})
        return ({ 
                 checkpoint: prazoAtual,
                 prazo_oferta: parseInt(order.financing_details.fields['prazo_oferta'].value),
                 vencimento: dataVencimento, 
                 percentual: Math.round(prazoAtual == order.financing_details.fields['prazo_oferta'].value ? 100 : (prazoAtual / order.financing_details.fields['prazo_oferta'].value) * 100)
                })

    } else {
        return {}
    }
    
};

