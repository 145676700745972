<script>
import PieChart from '@/components/Charts/PieChart';
import * as chartConfigs from '@/components/Charts/config';
import LimitsTable from '@/components/Limits/LimitsTable';
import OrdersTable from '@/components/Orders/OrdersTable';
import CampaignCard from '@/components/Campaign/CampaignCard';
import { vueTopprogress } from 'vue-top-progress';
import getRemuneracaoAcumulada from '../../functions/getRemuneracaoAcumulada';
import ordersService from '@/services/Orders/ordersService';
import financingService from '@/services/Financing/financingService';
import settingsService from '@/services/Settings/settingsService';
import NoticiasMercadoNovosCursos from '@/components/NoticiasMercado/NoticiasMercadoNovosCursos.vue';
import TopNumbersDashboard from '@/components/Dashboard/TopNumbersDashboard.vue';

import {
  PessoaCaracteristica,
  CWLStatusCCB,
  CWLInvestimentoDashboard,
} from '@/store/cwl_enums';

const tableColumns = [
  'Investimentos Financeiros',
  'Renda Bruta Anual',
  'Limite Anual ',
];

export default {
  components: {
    PieChart,
    LimitsTable,
    OrdersTable,
    vueTopprogress,
    CampaignCard,
    NoticiasMercadoNovosCursos,
    TopNumbersDashboard,
  },
  data() {
    return {
      chartOptions: {
        hoverBorderWidth: 20,
      },
      chartData: {
        labels: ['Nesta Plataforma', 'Outras Plataformas'],
        datasets: [
          {
            backgroundColor: ['#42b883', '#1d8cf8'],
            data: [],
          },
        ],
        totalInvestments: 0,
      },
      campaignStatusChartData: {
        labels: ['Em Andamento', 'Concluídas'],
        datasets: [
          {
            backgroundColor: ['#1d8cf8', '#42b883'],
            data: [],
          },
        ],
        totalInvestments: 0,
      },
      collectedChartData: {
        labels: ['Pagamento confirmado', 'Aguardando confirmação de pagamento'],
        datasets: [
          {
            backgroundColor: ['#42b883', '#1d8cf8'],
            data: [],
          },
        ],
        totalInvestments: 0,
      },
      ordersStatusChartData: {
        labels: ['Capital investido', 'Remuneração Acumulada'],
        datasets: [
          {
            backgroundColor: ['#1d8cf8', '#42b883'],
            data: [],
          },
        ],
        totalInvestments: 0,
      },
      table: {
        title: 'Limites de Investimentos',
        columns: [...tableColumns],
      },
      limits: [],
      limitsExamples: [],
      investorInformations: [],
      person: {},
      orders: [],
      totalPercentage: 0,
      calculatedPaid: 0,
      calculatedReserved: 0,
      updatedFinancings: [],
      allOrders: [],
      months: {},
      chartAnnualLimit: 40000,
      isOrdersLoading: false,
      dashboardType: process.env.VUE_APP_TYPE,
      rootSite: process.env.VUE_APP_ROOT_SITE,
      projectSitePath: process.env.VUE_APP_SITE_PROJECT_PATH,
      ofertaTemp: {},
      investimentos_nesta_plataforma: 0,
      tableMyOrders: {
        title: 'Meus Aportes',
        role: 'customer',
        columns: [
          'CCB',
          'Valor do Investimento',
          'Vencimento',
          'Evolução',
          'Remuneração Acumulada',
          'Valor Atualizado',
          'Situação',
          'Documento',
        ],
        data: [],
        dataWithoutFilter: [],
        orderBy: 'Valor do Investimento',
        orderDirection: 'asc',
        filtersList: ['Pago', 'Liquidado'],
      },
      filterBy: '',
    };
  },
  computed: {
    enableRTL() {
      return this.$route.query.enableRTL;
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    bigLineChartCategories() {
      return this.$t('dashboard.chartCategories');
    },
    loggedUser() {
      return this.$store.getters.isLoggedIn;
    },
    userClaim() {
      return (this.$store.getters.userClaim.role || '').toLowerCase();
    },
    loggedPessoa() {
      return this.$store.getters.getPessoa;
    },
    isAdminGestorCurador() {
      return (
        this.userClaim == PessoaCaracteristica.Curador ||
        this.userClaim == PessoaCaracteristica.Gestor ||
        this.userClaim == PessoaCaracteristica.Master
      );
    },
    isCurador() {
      return this.userClaim == PessoaCaracteristica.Curador;
    },
    goToProfile() {
      return this.$route.query.gotoprofile;
    },
  },
  methods: {
    async getOfertaTemp() {
      const ofertaStore = this.$store.getters.getOferta;
      if (ofertaStore) {
        const idOfertaStore = ofertaStore.oferta_id;
        if (idOfertaStore) {
          const ofertaFromFirebase = await financingService.get(idOfertaStore);

          this.ofertaTemp = ofertaFromFirebase;
          this.ofertaTemp.id = ofertaFromFirebase.id;
        }
      }
    },
    async goToOfertaSite() {
      let _projectPath = '/projetos';
      if (this.projectSitePath) {
        const _id = this.ofertaTemp ? this.ofertaTemp.id || '' : '';
        _projectPath = this.projectSitePath.replace('{id}', _id);
      }
      window.top.location.href = this.rootSite + _projectPath;
    },
    async getLimits() {
      const dashboardLimits = await settingsService.getDashboardLimits();

      this.limitsExamples = dashboardLimits.examples;
      this.limits = dashboardLimits.items;

      this.getInvestorInformations();
    },
    async getInvestorInformations() {
      const user = this.$store.getters.getPessoa;
      this.investorInformations.push(user);
      this.person = user;
    },
    initInvestmentsChart() {
      if (
        this.person &&
        this.person.dados_complementares &&
        this.person.dados_complementares.perfil
      ) {
        const { investimentos_outras_plataformas } =
          this.person.dados_complementares.perfil;

        const investimentos_nesta_plataforma_preformatted = parseFloat(
          this.investimentos_nesta_plataforma,
        )
          .toFixed(2)
          .replace('.', ',');
        const investimentos_nesta_plataforma_formatted =
          'R$ ' +
          investimentos_nesta_plataforma_preformatted
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        const investimentos_outras_plataformas_preformatted = parseFloat(
          investimentos_outras_plataformas,
        )
          .toFixed(2)
          .replace('.', ',');
        const investimentos_outras_plataformas_formatted =
          'R$ ' +
          investimentos_outras_plataformas_preformatted
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        const chartData = {
          datasets: [
            {
              backgroundColor: ['#42b883', '#1d8cf8'],
              data: [
                this.investimentos_nesta_plataforma,
                investimentos_outras_plataformas,
              ],
            },
          ],
          labels: ['Nesta Plataforma', 'Outras Plataformas'],
        };

        this.chartData = chartData;
        this.chartData.totalInvestments =
          (investimentos_outras_plataformas
            ? investimentos_outras_plataformas
            : 0) + this.investimentos_nesta_plataforma;
        this.chartData.totalInvestments = this.chartData.totalInvestments
          ? this.chartData.totalInvestments
          : 0;
      }
    },
    async getOrders() {
      this.isOrdersLoading = true;

      const orders = await ordersService.getOrdersByUser();

      this.orders = orders;

      this.allOrders = this.orders;
      this.orders.forEach(async (order) => {
        const _order = order;
        if (_order.status == 'Pago' || _order.status == 'Liquidado' || _order.status === "Processando") {
          const financing = await financingService.get(_order.financing_id);
          _order.financing = financing;

          _order.situacao = 'Vigente';
          if (!_order.data_liquidacao) {
            _order.situacao = 'Vigente';
          } else if (_order.data_liquidacao) {
            _order.situacao = 'Liquidado';
          }
          _order.remuneracao_acumulada = getRemuneracaoAcumulada(_order);
          this.tableMyOrders.data.push(_order);
          this.handleChangeOrder({
            column: 'Valor do Investimento',
            order: 'asc',
          });
        }
      });

      this.generateCampaignCards();
      if (this.dashboardType == 'equity') {
        this.initPaidChart();
        this.initOrderStatusChart();
      }

      // this.createHorizontalBarChart();
      this.createBarChart();
      this.isOrdersLoading = false;
    },
    async generateCampaignCards() {
      const orders = this.orders;
      const that = this;

      for (const order of orders) {
        const returnedFinancing = await financingService.get(
          order.financing_id,
        );

        const updatedFinancing = returnedFinancing;

        updatedFinancing.id = returnedFinancing.id;

        that.updatedFinancings.push(updatedFinancing);
      }

      this.initCampaignStatusChart();
    },
    initCampaignStatusChart() {
      const successfullyFinished = [];
      const unsuccessfullyFinished = [];
      const inProgress = [];

      // if (this.updatedFinancings.length) {
      //   this.updatedFinancings.map((campanha) => {
      //     if (campanha.status === CWLStatusCampanha.ConcluidaComSucesso) {
      //       successfullyFinished.push(campanha);
      //     } else if (
      //       campanha.status === CWLStatusCampanha.ConcluidaSemSucesso
      //     ) {
      //       unsuccessfullyFinished.push(campanha);
      //     } else {
      //       inProgress.push(campanha);
      //     }
      //   });
      // }

      if (this.orders.length) {
        this.orders.map((order) => {
          // const CWLStatusCCB = {
          // Pendente: 'Pendente',
          // Pago: 'Pago',
          // LiquidacaoAntecipada: 'LiquidaçãoAntecipada',
          // LiquidacaoPrazo: 'LiquidaçãoPrazo',

          if (order.liquidacao_efetivada) {
            successfullyFinished.push(order);
          } else if (order.status === CWLStatusCCB.Pago) {
            inProgress.push(order);
          }
        });
      }

      const chartData = {
        datasets: [
          {
            backgroundColor: ['#2a8ef7', '#68e497'],
            // backgroundColor: ['#2a8ef7', '#68e497', '#ed415e'],
            data: [
              inProgress.length,
              successfullyFinished.length,
              // unsuccessfullyFinished.length,
            ],
          },
        ],
        labels: [
          'Em Andamento',
          'Concluídas',
          // 'Finalizado com Sucesso',
          // 'Finalizado sem Sucesso',
        ],
      };

      this.campaignStatusChartData = chartData;
    },
    async initPaidChart() {
      let paidOrders = [];
      let reservedOrders = [];
      let pendenteOrders = [];

      const privateSettings = await settingsService.getPrivateSettings();
      const investimentoDashboard = privateSettings.investimento_dashboard
        ? privateSettings.investimento_dashboard
        : CWLInvestimentoDashboard.PagosPendentes;
      this.allOrders.find((order) => {
        if (investimentoDashboard == CWLInvestimentoDashboard.PagosPendentes) {
          if (order.status == 'Pago') {
            paidOrders.push(order.item.value * order.item.quantity);
          } else if (order.status == 'Pendente') {
            pendenteOrders.push(order.item.value * order.item.quantity);
          } else {
            reservedOrders.push(order.item.value * order.item.quantity);
          }
        } else if (
          investimentoDashboard == CWLInvestimentoDashboard.Pagos &&
          order.status == 'Pago'
        ) {
          paidOrders.push(order.item.value * order.item.quantity);
        }
      });

      paidOrders = paidOrders.reduce((a, b) => a + b, 0);
      reservedOrders = reservedOrders.reduce((a, b) => a + b, 0);
      pendenteOrders = pendenteOrders.reduce((a, b) => a + b, 0);

      const chartData = {
        datasets: [
          {
            backgroundColor: ['#42b883', '#1d8cf8'],
            data: [paidOrders, pendenteOrders],
          },
        ],
        labels: ['Pago', 'Aguardando Confirmação de Pagamento'],
      };

      this.collectedChartData = chartData;
      this.collectedChartData.totalInvestments =
        paidOrders + reservedOrders + pendenteOrders;

      if (investimentoDashboard == CWLInvestimentoDashboard.PagosPendentes) {
        this.investimentos_nesta_plataforma =
          paidOrders + reservedOrders + pendenteOrders;
      } else if (investimentoDashboard == CWLInvestimentoDashboard.Pagos) {
        this.investimentos_nesta_plataforma = paidOrders;
      }

      if (this.dashboardType == 'equity') {
        this.initInvestmentsChart();
      }
    },
    async initOrderStatusChart() {
      const pessoa = this.$store.getters.getPessoa;

      if (pessoa.dashboard_metrics) {
        this.ordersStatusChartData = {}; // [pessoa.dashboard_metrics.invested_capital, pessoa.dashboard_metrics.accumulated_income];
      }

      const chartData = {
        datasets: [
          {
            backgroundColor: ['#2a8ef7', '#68e497'],
            // backgroundColor: ['#2a8ef7', '#68e497', '#ed415e'],
            data: [
              pessoa.dashboard_metrics.invested_capital,
              parseFloat(pessoa.dashboard_metrics.accumulated_income),
            ],
          },
        ],
        labels: ['Aporte Original', 'Remuneração Acumulada'],
      };

      this.ordersStatusChartData = chartData;
    },
    calcValues(campanha) {
      this.calculatedReserved = campanha.totais.porcentagem_reservado;
      this.calculatedPaid = campanha.totais.porcentagem_investido;
      this.calculatedPendente = campanha.totais.porcentagem_pendente;
      this.totalPercentage = campanha.totais.porcentagem_total;

      return this.totalPercentage;
    },
    createHorizontalBarChart() {
      const horizontalLabels = [];
      const horizontalData = [];

      if (this.updatedFinancings.length) {
        this.updatedFinancings.map((campanha) => {
          horizontalLabels.push(campanha.name.slice(0, 12) + '..');

          const percentageItem = this.calcValues(campanha);
          horizontalData.push(parseFloat(percentageItem).toFixed(2));
        });
      }

      const textToShow =
        this.dashboardType == 'doacao'
          ? 'Progresso das Campanhas Investidas'
          : 'Progresso das Ofertas Investidas';

      new Chart(document.getElementById('bar-chart-horizontal'), {
        type: 'horizontalBar',
        data: {
          labels: horizontalLabels,
          datasets: [
            {
              label: 'Porcentagem',
              backgroundColor: ['#52b884'],
              data: horizontalData,
            },
          ],
        },
        options: {
          ...chartConfigs.barChartOptions,
          legend: { display: false },
          title: {
            display: true,
            text: textToShow,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  min: 0,
                  max: 100,
                },
              },
            ],
          },
        },
      });
    },
    async createBarChart() {
      const moment = require('moment');

      const ctx = document.getElementById('canvas');

      Chart.pluginService.register({
        afterDraw: function (chart) {
          if (typeof chart.config.options.lineAt != 'undefined') {
            let lineAt = chart.config.options.lineAt;
            const ctxPlugin = chart.chart.ctx;
            const xAxe = chart.scales[chart.config.options.scales.xAxes[0].id];
            const yAxe = chart.scales[chart.config.options.scales.yAxes[0].id];

            if (yAxe.min != 0) return;

            ctxPlugin.strokeStyle = 'red';
            ctxPlugin.beginPath();
            lineAt = (lineAt - yAxe.min) * (100 / yAxe.max);
            lineAt = ((100 - lineAt) / 100) * yAxe.height + yAxe.top;
            ctxPlugin.moveTo(xAxe.left, lineAt);
            ctxPlugin.lineTo(xAxe.right, lineAt);
            ctxPlugin.stroke();
          }
        },
      });

      const compare = (a, b) => {
        if (a.data_pagamento < b.data_pagamento) {
          return -1;
        }
        if (a.data_pagamento > b.data_pagamento) {
          return 1;
        }
        return 0;
      };

      const dateRange = (startDate, endDate) => {
        var start = startDate.split('-');
        var end = endDate.split('-');
        var startYear = parseInt(start[0]);
        var endYear = parseInt(end[0]);
        var dates = [];

        for (var i = startYear; i <= endYear; i++) {
          var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
          var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
          for (
            var j = startMon;
            j <= endMonth;
            j = j > 12 ? j % 12 || 11 : j + 1
          ) {
            var month = j + 1;
            var displayMonth = month < 10 ? '0' + month : month;
            dates.push([displayMonth, i].join('/'));
          }
        }
        return dates;
      };

      const pessoa = this.$store.getters.getPessoa;

      const [dia, mes, ano] = pessoa.data_criacao.split(' ')[0].split('/');

      const months = dateRange(
        [ano, mes, dia].join('-'),
        moment().format('YYYY-MM-DD'),
      );

      const orders = this.allOrders
        .filter((order) => {
          return order.status == 'Pago';
        })
        .map((order) => {
          if (!order.data_pagamento)
            order.data_pagamento = moment(order.order_date).unix();
          return order;
        })
        .sort(compare);

      orders.map((order) => {
        const orderDate = new Date(
          moment.unix(order.data_pagamento).format('YYYY/MM/DD'),
        );

        const currentYear = orderDate.getFullYear().toString();
        const currentMonth = (orderDate.getMonth() + 1)
          .toString()
          .padStart(2, '0');

        const orderMonth = [currentMonth, currentYear].join('/');

        var valorParaSomar = 0;
        if (order.status == 'Pago') {
          valorParaSomar = Number(order.item.value);
        }
        const currentValue = (this.months[orderMonth] || 0) + valorParaSomar;

        this.months[orderMonth] = currentValue;
      });

      var chartData = [];

      months.forEach((month, index) => {
        chartData[index] = this.months[month] || 0;
      });

      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: months,
          datasets: [
            {
              label: 'Valor Investido',
              backgroundColor: 'rgba(54, 162, 235, 0.2)',
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 1,
              borderDash: [],
              borderDashOffset: 0.0,
              data: chartData,
              callback: function (value, index, values) {
                return (
                  'R$' +
                  parseFloat(value)
                    .toFixed(2)
                    .replace('.', ',')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                );
              },
            },
          ],
        },
        options: {
          ...chartConfigs.barChartOptions,
          scales: {
            yAxes: [
              {
                ticks: {
                  min: 0,
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              label(tooltipItem, data) {
                const label = data.datasets[tooltipItem.datasetIndex].label;
                const value =
                  'R$' +
                  parseFloat(tooltipItem.yLabel)
                    .toFixed(2)
                    .replace('.', ',')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                return `${label}: ${value}`;
              },
            },
          },
        },
      });
    },
    handleChangeOrder(options) {
      this.tableMyOrders.orderDirection =
        options.column === this.tableMyOrders.orderBy ? options.order : 'asc';
      this.tableMyOrders.orderBy = options.column;

      if (options.column === 'Valor do Investimento') {
        this.tableMyOrders.data.sort((a, b) => {
          if (this.tableMyOrders.orderDirection === 'asc') {
            return a.data_pagamento > b.data_pagamento ? 1 : -1;
          }
          return b.data_pagamento > a.data_pagamento ? 1 : -1;
        });
      }

      if (options.column === 'Vencimento') {
        this.tableMyOrders.data.sort((a, b) => {
          if (this.tableMyOrders.orderDirection === 'asc') {
            return a.financing_details.fields['primeiro_vencimento'].value >
              b.financing_details.fields['primeiro_vencimento'].value
              ? 1
              : -1;
          }
          return b.financing_details.fields['primeiro_vencimento'].value >
            a.financing_details.fields['primeiro_vencimento'].value
            ? 1
            : -1;
        });
      }

      if (options.column === 'Valor Atualizado') {
        this.tableMyOrders.data.sort((a, b) => {
          const valueA =
            a.item.quantity * a.item.value + a.remuneracao_acumulada || 0;
          const valueB =
            b.item.quantity * b.item.value + b.remuneracao_acumulada || 0;

          if (this.tableMyOrders.orderDirection === 'asc') {
            return valueA > valueB ? 1 : -1;
          }
          return valueB > valueA ? 1 : -1;
        });
      }
    },
    handleChangeFilter() {
      if (this.tableMyOrders.dataWithoutFilter.length === 0) {
        this.tableMyOrders.dataWithoutFilter = this.tableMyOrders.data;
      }
      this.tableMyOrders.data = this.tableMyOrders.dataWithoutFilter;

      if (this.filterBy !== '') {
        this.tableMyOrders.data = this.tableMyOrders.data.filter(
          (item) => item.status === this.filterBy,
        );
      }
    },
  },
  mounted() {
    this.i18n = this.$i18n;
    if (this.enableRTL) {
      this.i18n.locale = 'ar';
      this.$rtl.enableRTL();
    }
    if (this.goToProfile == '1') {
      this.$router.push('/profile');
    }
    if (!this.isAdminGestorCurador) {
      this.getLimits();
      this.getOrders();
    }
    if (this.dashboardType == 'equity') {
      this.getOfertaTemp();
    }
  },
  beforeDestroy() {
    if (this.$rtl.isRTL) {
      this.i18n.locale = process.env.VUE_APP_I18N_LOCALE || 'en';
      this.$rtl.disableRTL();
    }
  },
  watch: {
    updatedFinancings() {
      if (!this.isAdminGestorCurador && this.dashboardType == 'equity') {
        // this.createHorizontalBarChart();
        // this.initCampaignStatusChart();
        // this.createBarChart();
      }
    },
  },
};
</script>

<template>
  <div>
    <div>
      <div class="row" v-if="dashboardType === 'equity' && ofertaTemp.id">
        <div class="col-12">
          <a @click="goToOfertaSite()" href="#" title="" class="button-action">
            <card
              style="
                background-color: #288cf7;
                border-radius: 4px;
                text-align: center;
                color: #fff;
              "
            >
              Clique para continuar seu investimento
              <img
                :src="ofertaTemp.imageURL"
                style="height: 50px; margin-left: 10px; margin-right: 20px"
              />
              <span>
                <b>{{ ofertaTemp.name }}</b>
              </span>
            </card>
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12" v-if="dashboardType === 'equity'">
          <top-numbers-dashboard></top-numbers-dashboard>
        </div>
      </div>
      <div class="row" v-if="dashboardType === 'equity'">
        <div class="col-12">
          <card type="chart">
            <template slot="header">
              <div class="row">
                <div class="col-sm-6">
                  <h2 class="card-title">Linha do Tempo</h2>
                  <h5 class="card-category">Meus aportes realizados</h5>
                </div>
              </div>
            </template>
            <div class="chart-area">
              <canvas id="canvas" height="100%"></canvas>
            </div>
          </card>
        </div>
      </div>
      <div class="jumbotron d-flex">
        <div class="col-md-12" v-if="dashboardType === 'equity'">
          <noticias-mercado-novos-cursos></noticias-mercado-novos-cursos>
        </div>
      </div>
      <div class="row" v-if="dashboardType === 'equity'">
        <!-- <div class="col-lg-4" :class="{ 'text-right': isRTL }">
          <card type="chart">
            <template slot="header">
              <h5 class="card-category">Total de Investimentos</h5>
              <h3 class="card-title">
                {{ chartData.totalInvestments | currency }}
              </h3>
            </template>
            <div class="chart-area pie-chart">
              <pie-chart
                :data="chartData"
                :options="chartOptions"
                :chart-data="chartData"
                ref="pieChart"
              />
            </div>
          </card>
        </div> -->

        <div class="col-lg-4" :class="{ 'text-right': isRTL }">
          <card type="chart">
            <template slot="header">
              <h5 class="card-category">Meus Aportes</h5>
              <h3 class="card-title" v-if="dashboardType == 'equity'">
                Valores aportados em CCBs
              </h3>
            </template>
            <div class="chart-area pie-chart">
              <pie-chart
                :data="collectedChartData"
                :options="chartOptions"
                :chart-data="collectedChartData"
                ref="pieChart"
              />
            </div>
          </card>
        </div>

        <div class="col-lg-4" :class="{ 'text-right': isRTL }">
          <card type="chart">
            <template slot="header">
              <h5 class="card-category">Meus Aportes</h5>
              <h3 class="card-title" v-if="dashboardType == 'equity'">
                Situação de CCBs
              </h3>
            </template>
            <div class="chart-area pie-chart">
              <pie-chart
                :data="campaignStatusChartData"
                :options="chartOptions"
                :chart-data="campaignStatusChartData"
                ref="pieChart"
              />
            </div>
          </card>
        </div>

        <div class="col-lg-4" :class="{ 'text-right': isRTL }">
          <card type="chart">
            <template slot="header">
              <h5 class="card-category">Meu Capital</h5>
              <h3 class="card-title" v-if="dashboardType == 'equity'">
                Posição Financeira
              </h3>
            </template>
            <div class="chart-area pie-chart">
              <pie-chart
                :data="ordersStatusChartData"
                :options="chartOptions"
                :chart-data="ordersStatusChartData"
                ref="pieChart"
              />
            </div>
          </card>
        </div>

        <!-- <div class="col-lg-4" :class="{ 'text-right': isRTL }">
          <card type="chart">
            <template slot="header">
              <h5 class="card-category" v-if="dashboardType == 'doacao'">
                Progresso das Campanhas
              </h5>
              <h5 class="card-category" v-if="dashboardType == 'equity'">
                Progresso das Ofertas
              </h5>
            </template>
            <div class="chart">
              <canvas
                id="bar-chart-horizontal"
                width="100%"
                height="100%"
              ></canvas>
            </div>
          </card>
        </div> -->
      </div>

      <div class="row">
        <div class="col-lg-12">
          <orders-table
            :title="tableMyOrders.title"
            :role="tableMyOrders.role"
            :data="tableMyOrders.data"
            :columns="tableMyOrders.columns"
            :isOrdersLoading="isOrdersLoading"
            @handleChangeOrder="handleChangeOrder"
            :orderBy="tableMyOrders.orderBy"
            :orderDirection="tableMyOrders.orderDirection"
            :filterBy="filterBy"
            :filtersList="tableMyOrders.filtersList"
            @update:filterBy="(newValue) => (filterBy = newValue)"
            @handleChangeFilter="handleChangeFilter"
          />
        </div>
      </div>

      <!-- <div class="row">
        <div class="col-md-12">
          <card class="card">
            <h4
              slot="header"
              class="card-title"
              v-if="dashboardType == 'equity'"
            >
              {{ $t('dashboard.myInvestmentsTitle') }}
            </h4>
            <h4
              slot="header"
              class="card-title"
              v-if="dashboardType == 'doacao'"
            >
              {{ $t('dashboard.myContributionsTitle') }}
            </h4>
            <h4
              slot="header"
              class="card-category"
              v-if="dashboardType == 'equity'"
            >
              {{ $t('dashboard.myInvestmentsSubtitle') }}
            </h4>
            <h4
              slot="header"
              class="card-category"
              v-if="dashboardType == 'doacao'"
            >
              {{ $t('dashboard.myContributionsSubtitle') }}
            </h4>
            <h5
              class="card-title"
              v-if="updatedFinancings.length == 0 && dashboardType == 'doacao'"
            >
              Você ainda não fez doações 🙁
            </h5>
            <h5
              class="card-title"
              v-if="updatedFinancings.length == 0 && dashboardType == 'equity'"
            >
              Você ainda não fez aportes 🙁
            </h5>
            <div class="row campaign-list">
              <router-link
                :to="`campaign-details/${campanha.id}`"
                tag="div"
                class="card-column"
                v-for="(campanha, index) in updatedFinancings"
                :key="index"
              >
                <campaign-card :financing="campanha" :dashboard="true" />
              </router-link>
            </div>
          </card>
        </div>
      </div> -->

      <vue-topprogress ref="topProgress"></vue-topprogress>
    </div>
  </div>
</template>
