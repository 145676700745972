<script>
import Vue from 'vue';

import getTransactions from '@/functions/getTransactions';

import HelperPlugin from '@/plugins/HelperPlugin';
Vue.use(HelperPlugin);

import { vueTopprogress } from 'vue-top-progress';

export default {
  data() {
    return {
      transacoes: [],
      financing: {},
    };
  },
  components: {
    vueTopprogress,
  },
  computed: {
    routeId() {
      return this.$route.params.id;
    },
    routeType() {
      return this.$route.params.type;
    },
  },
  mounted() {
    this.financing = this.$CWLNewFinanciamentoPadrao();
    this.loadTransactions();
  },
  methods: {
    async loadTransactions() {
      try {
        this.transacoes = [];
        const financingId = this.routeId;
        const accountKind = this.getAccountKind();
        console.debug(`loading transactions. financingId=${financingId}, accountKind=${accountKind}`);
        this.transacoes = await getTransactions(financingId, accountKind);
      } catch (error) {
        console.error('load transactions error', error);
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

      }
    },
    getAccountKind() {
      let accountKind;
      if (this.routeType == 'campanha') {
        accountKind = 'beneficiary';
      } else if (this.routeType == 'cwl') {
        accountKind = 'platform';
      } else if (this.routeType == 'social-agent') {
        accountKind = 'agent';
      } else if (this.routeType == 'marketplace') {
        accountKind = 'marketplace';
      } else {
        throw new Error('Tipo de transação não suportado!');
      }
      return accountKind;
    },
    splitName(transaction) {
      const splits = transaction.Splits || [];
      return splits.length > 0 ? splits[splits.length - 1].Name : '';
    },
    splitAmount(transaction) {
      const splits = transaction.Splits || [];
      return splits.length > 0 ? splits[splits.length - 1].Amount : '';
    },
  },
};
</script>

<template>
  <div class="users-list">
    <div class="row">
      <div class="col-12">
        <card>
          <div>
            <table class="table table-hover">
              <thead class="thead-light">
                <tr>
                  <th>#</th>
                  <th>Customer</th>
                  <th>Reference</th>
                  <th>Message</th>
                  <th>PaymentDate</th>
                  <th>Amount</th>
                  <th>TaxValue</th>
                  <th>Split Name</th>
                  <th>Split Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in transacoes" :key="index">
                  <td>{{ item.IdTransaction }}</td>
                  <td>{{ item.Customer.Name }}</td>
                  <td>{{ item.Reference }}</td>
                  <td>{{ item.Message }}</td>
                  <td>{{ item.PaymentDate }}</td>
                  <td>{{ item.Amount }}</td>
                  <td>{{ item.TaxValue }}</td>
                  <td>{{ splitName(item) }}</td>
                  <td>{{ splitAmount(item) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
