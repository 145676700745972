<script>
import certificationsService from '@/services/Certifications/certificationsService';
import SuccessNotification from '@/components/Notifications/General/SuccessNotification';
import ErrorRegisterNotification from '@/components/Notifications/General/ErrorRegisterNotification';
import {vueTopprogress} from 'vue-top-progress';
import logsService from '@/services/Logs/logsService';
import { nonEmpty } from '@/validate/customValidators.js';
import { DoubleBounce } from 'vue-loading-spinner';

export default {
  data() {
    return {
      isLoading: false,
      certification: {
        name: "",
        date: "",
      },
      certification_file: {},
      certification_url: '',
    };
  },
  mounted() {
    this.getCertification();
  },
  validations: {
    certification: {
      name: {
        valid: (name) => nonEmpty(name),
      },
      date: {
        valid: (date) => nonEmpty(date),
      },
    },
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
    isFormValid() {
      return (
        !this.$v.certification.date.valid ||
        !this.$v.certification.name.valid
      );
    },
    certificationId() {
      return this.$route.params.id;
    },
  },
  components: {
    vueTopprogress,
    DoubleBounce,
  },
  methods: {
    async getCertification() {
      const certification = await certificationsService.getCertification(this.certificationId);
      const certificationUrl = await certificationsService.getDownloadURL(certification.filename);
      this.certification = certification;
      this.certification_url = certificationUrl;
    },
    async updateCertification() {
      this.isLoading = true;
      this.$refs.topProgress.start();

      try {
        const logUser = {
          user: {
            email: this.currentPessoa.email.email
          }
        };
        logsService.createNewLog(logUser, '', 'Editar Certificação', '', this.certification);

        if (this.certification_file) {
          await certificationsService.uploadCertificationToStorage(this.certification_file, this.certification.filename);
          this.certification.filename = this.certification_file.name;
        }

        await certificationsService.updateCertification(this.certificationId, this.certification);

        this.$refs.topProgress.done();

        this.$router.push('/certifications');

        this.$notify({
          component: SuccessNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        console.log(error);
        this.$refs.topProgress.done();
        this.$notify({
          component: ErrorRegisterNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      } finally {
        this.isLoading = false;
      }
    },
    handleFileUpload(event){
      const file = event.target.files[0]
      const dividedName = file.name.split(".");
      const extension = dividedName.pop();
      const newName = Date.now();
      const newFile = new File([file], `${newName}.${extension}`, { type: file.type });
      this.certification_file = newFile;
    },
  },
  watch: {
    data() {
      this.isFormValid()
    }
  }
};
</script>

<template>
  <div class="add-user">
    <card>
      <form @submit.prevent="updateCertification()">
        <h5 slot="header" class="title">
          <router-link to="/certifications" class="fa fa-arrow-left"></router-link>
          Atualizar Certificação
        </h5>

        <div class="row">
          <div class="col-md-4 pr-md-1 mb-2">
            <base-input
              label="Título*"
              placeholder="Título"
              v-model="certification.name"
              required
            ></base-input>
            <div class="error" v-if="!$v.certification.name.valid">
              Informe um nome válido.
            </div>
          </div>
          <div class="col-md-4 pr-md-1 mb-2">
            <base-input label="Data">
              <date-picker
                v-model="certification.date"
                valueType="format"
                format="DD/MM/YYYY"
              >
              </date-picker>
            </base-input>
            <div class="error" v-if="!$v.certification.date.valid">
              Informe uma data válido.
            </div>
          </div>

          <div class="col-md-12">
            <label>Certificação (somente pdf)
              <input 
              type="file" 
              class="form-control" 
              accept=".pdf"
              @change="handleFileUpload( $event )"
              />
            </label>
          </div>
        </div>
        
        <button type="button" class="btn btn-sm mr-4"><a :href="this.certification_url" target="_blank" class="link">Visualizar PDF: {{ this.certification.filename || "" }}</a></button>
        <button type="submit" class="btn btn-sm" :disabled="isFormValid || isLoading">Atualizar</button>
      </form>
    </card>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>

<style>
  .link {
    color: #ffffff;
    padding: 20px 0;
    text-decoration: none;
  }

  .link:hover, a:focus {
    color: #ffffff;
  }
</style>