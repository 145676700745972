import * as cpfCnpjValidator from 'cpf-cnpj-validator';

const onlyNumbers = /\d+/g;

export function nonEmpty(value) {
  if (value === undefined) return false;

  const formattedValue = `${value}`;
  const trimmedValue = formattedValue.trim();
  return trimmedValue !== undefined && trimmedValue != '';
}

export function cpf(value) {
  if (value == undefined) return false;

  const formattedValue = `${value}`;

  return value && cpfCnpjValidator.cpf.isValid(formattedValue) ? true : false;
}

export function cnpj(value) {
  if (value == undefined) return false;

  return value && cpfCnpjValidator.cnpj.isValid(value) ? true : false;
}

export function cep(value) {
  if (value == undefined) return false;

  var number = (value.match(onlyNumbers) || []).join('');
  return number.length == 8;
}

export function phone(value) {
  if (value == undefined) return false;
  var number = (value.match(/\d+/g) || []).join('');
  if (number.length < 2) return false;
  var prefix = number.substring(0, 2);
  return prefixes.indexOf(prefix) != -1;
}

export function isValidPhone(value) {
  return value.match(
    /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/,
  );
}

export function isValidRandomKey(value) {
  return value.match(/^[0-9a-f]{32}$/);
}

const prefixes = [
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '21',
  '22',
  '24',
  '27',
  '28',
  '31',
  '32',
  '33',
  '34',
  '35',
  '37',
  '38',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '47',
  '48',
  '49',
  '51',
  '53',
  '54',
  '55',
  '61',
  '62',
  '63',
  '64',
  '65',
  '66',
  '67',
  '68',
  '69',
  '71',
  '73',
  '74',
  '75',
  '77',
  '79',
  '81',
  '82',
  '83',
  '84',
  '85',
  '86',
  '87',
  '88',
  '89',
  '91',
  '92',
  '93',
  '94',
  '95',
  '96',
  '97',
  '98',
  '99',
];
