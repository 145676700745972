<script>
import { vueTopprogress } from 'vue-top-progress';
import { DoubleBounce } from 'vue-loading-spinner';
import OrdersTable from '@/components/Orders/OrdersTable';
import getRemuneracaoAcumulada from '../../../functions/getRemuneracaoAcumulada';
import ordersService from '@/services/Orders/ordersService';
import financingService from '@/services/Financing/financingService';
import moment from 'moment';

export default {
  components: {
    OrdersTable,
    vueTopprogress,
    DoubleBounce,
  },
  data() {
    return {
      table: {
        title: 'Aportes Efetivados',
        role: 'customer',
        columns: [
          'CCB',
          'Valor do Investimento',
          'Vencimento',
          'Evolução',
          'Remuneração Acumulada',
          'Valor Atualizado',
          'Situação',
          'Documento',
        ],
        data: [],
        dataWithoutFilter: [],
        orderBy: 'Valor do Investimento',
        orderDirection: 'asc',
        filtersList: ['Pago', 'Liquidado'],
      },
      isLoading: true,
      closeModal: false,
      filterBy: '',
    };
  },
  computed: {
    investorId() {
      return this.$route.params.id;
    },
    loggedPessoa() {
      return this.$store.getters.getPessoa;
    },
  },
  mounted() {
    this.getOrders();
  },
  methods: {
    async getOrders() {
      try {
        const orders = await ordersService.getOrdersByUser();

        orders.forEach(async (order) => {
          const _order = order;
          if (_order.status == 'Pago' || _order.status == 'Liquidado' || _order.status === "Processando") {
            const financing = await financingService.get(_order.financing_id);
            _order.financing = financing;

            _order.situacao = 'Vigente';
            if (!_order.data_liquidacao) {
              _order.situacao = 'Vigente';
            } else if (_order.data_liquidacao) {
              _order.situacao = 'Liquidado';
            }
            _order.remuneracao_acumulada = getRemuneracaoAcumulada(_order);

            if (!_order.data_pagamento) {
              _order.data_pagamento = moment(_order.order_date).unix();
            }

            this.table.data.push(_order);
            this.handleChangeOrder({
              column: 'Valor do Investimento',
              order: 'asc',
            });
          }
        });
      } catch (error) {}
      this.isLoading = false;
    },
    handleChangeOrder(options) {
      this.table.orderDirection =
        options.column === this.table.orderBy ? options.order : 'asc';
      this.table.orderBy = options.column;

      if (options.column === 'Valor do Investimento') {
        this.table.data.sort((a, b) => {
          if (this.table.orderDirection === 'asc') {
            return a.data_pagamento > b.data_pagamento ? 1 : -1;
          }
          return b.data_pagamento > a.data_pagamento ? 1 : -1;
        });
      }

      if (options.column === 'Vencimento') {
        this.table.data.sort((a, b) => {
          if (this.table.orderDirection === 'asc') {
            return a.financing_details.fields['primeiro_vencimento'].value >
              b.financing_details.fields['primeiro_vencimento'].value
              ? 1
              : -1;
          }
          return b.financing_details.fields['primeiro_vencimento'].value >
            a.financing_details.fields['primeiro_vencimento'].value
            ? 1
            : -1;
        });
      }

      if (options.column === 'Valor Atualizado') {
        this.table.data.sort((a, b) => {
          const valueA =
            a.item.quantity * a.item.value + a.remuneracao_acumulada || 0;
          const valueB =
            b.item.quantity * b.item.value + b.remuneracao_acumulada || 0;

          if (this.table.orderDirection === 'asc') {
            return valueA > valueB ? 1 : -1;
          }
          return valueB > valueA ? 1 : -1;
        });
      }
    },
    handleChangeFilter() {
      if (this.table.dataWithoutFilter.length === 0) {
        this.table.dataWithoutFilter = this.table.data;
      }
      this.table.data = this.table.dataWithoutFilter;

      if (this.filterBy !== '') {
        this.table.data = this.table.data.filter(
          (item) => item.status === this.filterBy,
        );
      }
    },
  },
};
</script>
<!-- invested_capital: number;
    accumulated_income: number;
    average_monthly_income: number;
    confirmed_investments: number; -->
<template>
  <div class="orders-list">
    <div class="row">
      <div class="col-lg-12">
        <div class="card card p-4">
          <!---->
          <div class="card-header">
            <h5 class="card-title">Meus Aportes</h5>
          </div>
          <div class="card-body">
            <h4 class="text-primary">
              Cliente: {{ loggedPessoa.nome }}
              <!-- - {{ investorId ? 'Outro' : 'Eu' }} -->
            </h4>
            <h5 class="text-primary">
              CPF: {{ loggedPessoa.documento.numero }}
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <orders-table
          :title="table.title"
          :data="table.data"
          :role="table.role"
          :columns="table.columns"
          :isOrdersLoading="isLoading"
          @handleChangeOrder="handleChangeOrder"
          :orderBy="table.orderBy"
          :orderDirection="table.orderDirection"
          :filterBy="filterBy"
          :filtersList="table.filtersList"
          @update:filterBy="(newValue) => (filterBy = newValue)"
          @handleChangeFilter="handleChangeFilter"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <double-bounce v-if="isLoading"></double-bounce>
      </div>
    </div>
    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
