import firebase from 'firebase';
import { withLog } from '../../logger/with_log';
import axios from 'axios';

export default {
  newsRef() {
    return firebase.firestore().collection('news');
  },

  storageRef() {
    return firebase.storage().ref('news/');
  },

  async createNews(news) {
    const response = await axios.post(
      `${process.env.VUE_APP_ENDPOINT_WS}createNews`,
      news,
      {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        timeout: 60000
      }
    );

    return response.data;
  },

  async updateNews(news, newsId) {
    const response = await axios.put(
      `${process.env.VUE_APP_ENDPOINT_WS}updateNews`,
      news,
      {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        params: {
          newsId,
        },
        timeout: 60000
      }
    );

    return response.data;
  },

  async deleteNews(newsId) {
    const response = await axios.delete(
      `${process.env.VUE_APP_ENDPOINT_WS}deleteNews`,
      {
        params: {
          newsId,
        },
        timeout: 60000
      }
    );

    return response.data;
  },

  async getNewsAdmin() {
    return withLog(async () => {
      const snapshot = await this.newsRef().orderBy('created_at', 'desc').get();

      const news = snapshot.docs.map((doc) => doc.data());

      for (const doc of news) {
        const thumb_url = await this.getDownloadURL(doc.thumb);

        Object.assign(doc, { thumb_url });
      }

      return news;
    });
  },

  async getAllNewsEbook(type, target_audience = 'customer') {
    return withLog(async () => {
      const news = await axios.get(`${process.env.VUE_APP_ENDPOINT_WS}getAllNewsPortal`, { params: { type, target_audience } });

      return news.data;
    });
  },

  async getAllNews() {
    return withLog(async () => {
      const news = await axios.get(`${process.env.VUE_APP_ENDPOINT_WS}getAllNewsPortal`, { params: { type: 'news' } });

      return news.data;
    });
  },

  async getNews(id) {
    return withLog(async () => {
      const news = await axios.get(`${process.env.VUE_APP_ENDPOINT_WS}getOneNews`, { params: { newsId: id } });

      return news.data;
    }); 
  },

  async addView(userId, userEmail, newsId) {
    return withLog(async () => {
      const news = await axios.post(`${process.env.VUE_APP_ENDPOINT_WS}addViewInNews`, { userId, userEmail }, { params: { newsId } });

      return news.data;
    }); 
  },

  async getDownloadURL(fileName) {
    const fileRef = this.storageRef().child(fileName);
    try {
      const downloadURL = await fileRef.getDownloadURL();
      return downloadURL;
    } catch (error) {
      console.error("Error getting download URL:", error);
      return null;
    }
  },

  async getFileObject(fileName, url) {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      // Create a File object
      const file = new File([blob], fileName, {
        type: blob.type,
      });

      return file;
    } catch(err) {
      console.log(err);
    }
  }
}