var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-user"},[(
      _vm.isUsuario &&
      _vm.currentPessoa.dados_complementares.approvalStatus ===
        _vm.CWLStatusInvestidor.Incompleto
    )?_c('div',{staticClass:"alert alert-warning",attrs:{"role":"alert"}},[_c('span',[_vm._v(" Seu cadastro não está completo. Por favor preencha todos os dados abaixo e envie todos os documentos obrigatórios: ")])]):_vm._e(),(
      _vm.isUsuario &&
      _vm.currentPessoa.dados_complementares.approvalStatus ===
        _vm.CWLStatusInvestidor.EmAnalise
    )?_c('div',{staticClass:"alert alert-info",staticStyle:{"background-color":"#00035a !important"},attrs:{"role":"alert"}},[_c('span',[_vm._v(" Seu cadastro já foi preenchido, e está sob analíse. Você será informado por e-mail de qualquer atualização. ")])]):_vm._e(),(
      _vm.isUsuario &&
      _vm.currentPessoa.dados_complementares.approvalStatus ===
        _vm.CWLStatusInvestidor.Reprovado
    )?_c('div',{staticClass:"alert alert-info",staticStyle:{"background-color":"#00035a !important"},attrs:{"role":"alert"}},[_c('span',[_vm._v(" Seu cadastro foi reprovado. Por favor verifique o motivo no final da página e faça as correções. ")])]):_vm._e(),(_vm.userRole !== 'assessor')?_c('card',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.updateUser()}}},[(!_vm.pessoaId)?_c('h5',{staticClass:"title",attrs:{"slot":"header"},slot:"header"},[_vm._v("Meus Dados")]):_vm._e(),(_vm.pessoaId)?_c('h5',{staticClass:"title",attrs:{"slot":"header"},slot:"header"},[_vm._v("Dados Básicos")]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 pr-md-1"},[_c('base-input',{attrs:{"label":"Nome*","boldLabel":true,"placeholder":"Informe o nome","required":""},model:{value:(_vm.nome),callback:function ($$v) {_vm.nome=$$v},expression:"nome"}})],1),_c('div',{staticClass:"col-md-4 pr-md-1"},[_c('div',{staticClass:"form-group"},[_c('base-input',{directives:[{name:"mask",rawName:"v-mask",value:(['(##) ####-####', '(##) #####-####']),expression:"['(##) ####-####', '(##) #####-####']"}],attrs:{"label":"Celular*","boldLabel":true,"placeholder":"Informe o melhor celular","required":""},model:{value:(_vm.telefone),callback:function ($$v) {_vm.telefone=$$v},expression:"telefone"}}),(!_vm.$v.telefone.valid)?_c('div',{staticClass:"error"},[_vm._v(" Informe um telefone com DDD válido. ")]):_vm._e()],1)]),_c('div',{staticClass:"col-md-4 pl-md-1"},[_c('label',{staticClass:"control-label",staticStyle:{"font-weight":"bold"},attrs:{"for":""}},[_vm._v("Data de Nascimento")]),_c('br'),_c('birth-datepicker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"valueIsString":true,"high":true,"hideHeader":true,"attachment":'bottom',"delimiter":'/',"placeholder":'Informe a data de nascimento. Ex: 27/12/1993',"locale":[
              'Jan',
              'Fev',
              'Mar',
              'Abr',
              'Mai',
              'Jun',
              'Jul',
              'Ago',
              'Set',
              'Out',
              'Nov',
            ]},model:{value:(_vm.birthday),callback:function ($$v) {_vm.birthday=$$v},expression:"birthday"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 pr-md-3"},[_c('div',{staticClass:"form-group"},[_c('base-input',{attrs:{"label":"E-mail*","boldLabel":true,"placeholder":"Informe o melhor e-mail","type":"email","disabled":""},model:{value:(_vm.pessoa.email.email),callback:function ($$v) {_vm.$set(_vm.pessoa.email, "email", $$v)},expression:"pessoa.email.email"}}),(!_vm.isValidEmail)?_c('div',{staticClass:"error"},[_vm._v(" Informe um e-mail válido. ")]):_vm._e()],1)]),_c('div',{staticClass:"col-md-4 pr-md-3"},[_c('div',{staticClass:"form-group"},[(_vm.userRole != 'master')?_c('base-input',{directives:[{name:"mask",rawName:"v-mask",value:(['###.###.###-##']),expression:"['###.###.###-##']"}],attrs:{"label":"CPF*","boldLabel":true,"placeholder":"Informe o seu cpf","type":"cpf","disabled":""},model:{value:(_vm.pessoa.documento.numero),callback:function ($$v) {_vm.$set(_vm.pessoa.documento, "numero", $$v)},expression:"pessoa.documento.numero"}}):_vm._e(),(_vm.userRole == 'master')?_c('base-input',{directives:[{name:"mask",rawName:"v-mask",value:(['###.###.###-##']),expression:"['###.###.###-##']"}],attrs:{"label":"CPF*","boldLabel":true,"placeholder":"Informe o seu cpf","type":"cpf"},model:{value:(_vm.pessoa.documento.numero),callback:function ($$v) {_vm.$set(_vm.pessoa.documento, "numero", $$v)},expression:"pessoa.documento.numero"}}):_vm._e(),(!_vm.isValidCpf)?_c('div',{staticClass:"error"},[_vm._v("Informe um CPF válido.")]):_vm._e()],1)]),(
            (!_vm.isMaster && _vm.isGestorOrCurador && _vm.pessoaId) ||
            (!_vm.isGestor && !_vm.pessoaId)
          )?_c('div',{staticClass:"col-md-4 pr-md-3"},[_c('div',{staticClass:"form-group"},[(!_vm.isGestorOrCurador)?_c('base-input',{attrs:{"label":"Status do Cadastro","boldLabel":true,"placeholder":"Status do cadastro","disabled":""},model:{value:(_vm.pessoa.dados_complementares.approvalStatus),callback:function ($$v) {_vm.$set(_vm.pessoa.dados_complementares, "approvalStatus", $$v)},expression:"pessoa.dados_complementares.approvalStatus"}}):_vm._e(),(_vm.isGestorOrCurador)?_c('label',[_vm._v("Status do Cadastro")]):_vm._e(),(_vm.isGestorOrCurador)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.statusSelected),expression:"statusSelected"}],staticClass:"form-control",attrs:{"required":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.statusSelected=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.statusInvestidores),function(status,index){return _c('option',{key:index,domProps:{"value":status}},[_vm._v(" "+_vm._s(status)+" ")])}),0):_vm._e()],1)]):_vm._e()]),(_vm.isBroker)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 pr-md-3"},[_c('div',{staticClass:"form-group"},[_c('base-input',{attrs:{"label":"Progresso da prova","boldLabel":true,"value":this.pessoa.exam
                  ? this.pessoa.exam.exam_progress
                  : 'Prova não inicializada',"placeholder":"Progresso da prova","disabled":""}})],1)]),_c('div',{staticClass:"col-md-4 pr-md-3"},[_c('div',{staticClass:"form-group"},[_c('base-input',{attrs:{"label":"Resultado da prova","boldLabel":true,"value":this.pessoa.exam
                  ? this.pessoa.exam.exam_approval
                  : 'Prova não inicializada',"placeholder":"Resultado da prova","disabled":""}})],1)])]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('button',{staticClass:"btn btn-sm",attrs:{"type":"submit"}},[_vm._v("Salvar")]),_c('button',{staticClass:"btn btn-sm btn-warning",attrs:{"type":"button"},on:{"click":_vm.redefinirSenha}},[_vm._v(" Redefinir senha ")]),(_vm.isMaster)?_c('div',{staticClass:"form-group"},[(_vm.isMaster)?_c('button',{staticClass:"btn btn-sm btn-info",attrs:{"type":"button"},on:{"click":_vm.simularUsuario}},[_vm._v(" Simular Usuário ")]):_vm._e(),(_vm.simularUsuarioErro != '')?_c('div',{staticClass:"error",staticStyle:{"color":"red !important"}},[_vm._v(" "+_vm._s(_vm.simularUsuarioErro)+" ")]):_vm._e()]):_vm._e(),((_vm.isMaster || _vm.isGestor) && _vm.showNovoEmailField)?_c('div',{staticClass:"form-group"},[_c('base-input',{attrs:{"label":"Novo e-mail","boldLabel":true,"placeholder":"Informe o novo e-mail","type":"email"},model:{value:(_vm.novoEmail),callback:function ($$v) {_vm.novoEmail=$$v},expression:"novoEmail"}}),(!_vm.isValidNovoEmail)?_c('div',{staticClass:"error"},[_vm._v(" Informe um e-mail válido. ")]):_vm._e()],1):_vm._e(),((_vm.isMaster || _vm.isGestor) && _vm.showNovoEmailField)?_c('div',{staticClass:"form-group"},[((_vm.isMaster || _vm.isGestor) && _vm.showNovoEmailField)?_c('button',{staticClass:"btn btn-sm btn-info",attrs:{"type":"button","disabled":_vm.updateEmailLoading},on:{"click":_vm.alterarEmail}},[_vm._v(" Salvar ")]):_vm._e(),(_vm.novoEmailError)?_c('div',{staticClass:"error",staticStyle:{"color":"red !important"}},[_vm._v(" Houve um erro ao alterar o e-mail. ")]):_vm._e()]):_vm._e()])])])]):_vm._e(),(_vm.userRole === 'assessor')?_c('broker-profile'):_vm._e(),(
      _vm.modalidade == 'equity' &&
      _vm.userRole !== 'assessor' &&
      _vm.userRole !== 'master' &&
      _vm.pessoa.email.email &&
      ((_vm.isGestorOrCurador && _vm.pessoaId) || !_vm.isGestorOrCurador)
    )?_c('div',{staticClass:"row profile"},[_c('div',{staticClass:"col-md-12"},[_c('edit-profile-form',{attrs:{"pessoa":_vm.pessoa,"model":_vm.model},on:{"saveProfileInformations":_vm.saveProfileInformations,"cadastroReprovado":_vm.cadastroReprovado,"cadastroAprovado":_vm.cadastroAprovado}})],1)]):_vm._e(),_c('vue-topprogress',{ref:"topProgress"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }