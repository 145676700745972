<script>
import {vueTopprogress} from 'vue-top-progress';
import newsService from '@/services/News/newsService';

export default {
  components: {
    vueTopprogress,
},
  data() {
    return {
      isLoading: true,
      news: {},
    };
  },
  computed: {
    ebookId() {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.getNews();
  },
  methods: {
    async getNews() {
      try {
        const news = await newsService.getNews(this.ebookId);

        this.news = news;
      } catch(err) {
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<template>
  <div>
    <div class="row" v-if="isLoading">
      <div class="col-md-12 loading-gif">
        <img src="@/assets/images/loading.gif">
      </div>
    </div>
    <div class="row" v-if="!isLoading">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12 ebook-header">
                <i class="fa fa-arrow-left mt-2 mr-3" @click="goBack" style="color: #000; cursor: pointer;"></i>
                <h3 class="news-details-title">{{ this.news.title }}</h3>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label class="news-details-publish-date">Publicado em <span class="badge badge-primary">{{ this.news.created_at }}</span></label>&nbsp;&nbsp;&nbsp;
              </div>
            </div>
            <div class="news-content">
              <img :src="news.thumb_url" alt="" class="img">
            </div>
          </div>
          
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <h3 class="news-details-title-description">Descrição:</h3>
              </div>
              <div class="col-md-12">
                <div class="news-details-description"><p>{{ this.news.description }}</p></div>
              </div>
            </div>
          </div>

          <div class="col-md-12 text-center">
            <button type="button" class="btn btn-sm p-2 mt-4"><a :href="this.news.url_download" target="_blank" class="link">Clique aqui para baixar o ebook</a></button>
          </div>
        </div>        
      </div>
    </div>
  </div>
</template>
<style>
  .ebook-header {
    display: flex;
    align-items: center;
  }

  .news-content .img {
    border-radius: 8px;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .no-margin {
    margin: 0px;
  }
  
  .loading-gif {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .news-content {
    width: 100%;
    display: block;
    margin: 20px 0;
  }

  .news-details-title {
    padding: 10px 0px 0px 0px;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0px;
    color: #333;
  }

  .news-details-title-description {
    padding: 10px 0px 0px 0px;
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 0px;
    color: #333;
  }

  .news-details-publish-date {
    padding: 5px 0px 0px 0px;
    font-size: 0.8rem;
    font-weight: 500;
    color: #333;
  }

  .news-details-description {
    padding: 10px 0px 0px 0px;
    border-radius: 5px;
    color: #333;
    font-size: 1.1rem;
  }

  .news-details-description p {
    color: #333;
  }

  .link {
    color: #ffffff;
    padding: 20px 0;
    text-decoration: none;
  }

  .link:hover, a:focus {
    color: #ffffff;
  }
</style>