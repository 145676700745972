<template>
    <div class="top-banner">
      <div class="row" v-if="isLoading">
        <div class="col-md-12 loading-gif">
          <img src="@/assets/images/loading.gif">
        </div>
      </div>
      <div class="row" v-if="banners.length > 0">
          <div v-if="!isLoading">
            <ssr-carousel :show-dots="true" :show-arrows="true">
              <div class="slide" v-for="item in banners" :key="item.id" >
                <img class="full-size" :src="item.url" v-on:click="openDetails(item.id)">
                <div class="title">
                  <label>{{item.title}}</label>
                </div>
              </div>
            </ssr-carousel>            
          </div>
      </div>
    </div>
</template>

<script>
  import videoService from '@/services/VideoContent/videoContentService';
  export default {
    name: 'noticias-mercado-top-banner',
    props: {
    },
    components: {
    },
    data() {
      return {
        
        isLoading: false,
        banners : []
      }
    },
    mounted() {
      this.loadBanners();
    },
    methods: {
      async loadBanners() {
        this.isLoading = true;
        let fullVideosObject = await videoService.getBannerVideos();

        for(let i = 0; i < fullVideosObject.length; i++) {
          let v = fullVideosObject[i];
          let url = '@assets/images/no-thumbnail.jpg';
          if(v.banner_thumbnail) {
            let thumb = JSON.parse(v.banner_thumbnail);
            url = await videoService.urlThumbnail(thumb.name);
          }

          this.banners.push({
            id: v.id,
            url: url,
            title: v.title
          })
        }

        this.isLoading = false;

      },
      openDetails(id) {
        this.$router.push(`market-news-video-details/${id}?o-latest`) 
      }
    }
    
  };
  </script>

<style>

.top-banner .slide {
  overflow: hidden;
  position: relative;
}

.top-banner .slide .title {
  position: absolute;
  width: 80%;
  margin: 0 auto;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .7);
  bottom: 50px;
  text-align: center;
  padding: 20px;
}

.top-banner .slide .title label{
  margin: 0px;
  font-size: 1.2rem;
  color: #fff;
}

.top-banner .VueCarousel-inner {
  visibility: visible!important;;
}

.top-banner .loading-gif {
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-banner .full-size {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
  
</style>
  