<script>
import attachmentsService from '@/services/Attachments/attachmentsService';
import moment from 'moment-timezone';
import getRemuneracaoAcumulada from '@/functions/getRemuneracaoAcumulada';
import getOrderCheckPointStatus from '@/functions/getOrderCheckpointStatus';
import ordersService from '@/services/Orders/ordersService';
import brokersService from '@/services/Brokers/brokersService';
import historyService from '@/services/History/historyService';
import hubspotService from '@/services/HubSpot/hubspotService';
import pessoasService from '@/services/Pessoas/pessoasService';
import financingService from '@/services/Financing/financingService';
import ConfirmationModal from '@/components/ConfirmationModal';
import { SlideYUpTransition } from 'vue2-transitions';
import birthDatepicker from 'vue-birth-datepicker';
import VueHtml2pdf from 'vue-html2pdf';

import * as XLSX from 'xlsx';

import { DoubleBounce } from 'vue-loading-spinner';

export default {
  name: 'orders-dinamic-table',
  props: {
    title: {
      type: String,
      default: () => "",
      description: 'Table title',
    },
    columns: {
      type: Array,
      default: () => [],
      description: 'Table columns',
    },
    type: {
      type: String,
      default: () => "",
      description: 'Table type',
      default: () => "confirmed",
    },
    isAllOrdersPage: {
      type: Boolean,
    },
    confirmedOrderOptions: {
      type: Array,
      default: () => [],
      description: 'Array with order options',
    },
    confirmedStatusOptions: {
      type: Array,
      default: () => [],
      description: 'Array with order status options',
    },
    role: {
      type: String,
      description: 'Level user',
      default: () => "admin",
    }
  },
  components: {
    DoubleBounce,
    ConfirmationModal,
    birthDatepicker,
    SlideYUpTransition,
  },
  data() {
    return {
      page: 1,
      perPage: 10,
      pages: [],
      data: [], 
      original_data: [],
      sumInvestmentValue: 0,
      sumAccumulatedRemuneration: 0,
      sumAmmountConsolidated: 0,
      sumSettledPayment: 0,
      isLoading: true,
      items_exportar: [],
      item_modal: {},
      item_history: [],
      processing: false,
      showSolicitarDevolucao: false,
      showInformarPagamento: false,
      showDesfazerPagamento: false,
      showInformarLiquidacao: false,
      showDesfazerLiquidacao: false,
      showHistory: false,
      isLoading: false,
      downloadPDF: false,
      selectedStatusOrder: "Pago",
      selectedFilterSituacao: "",
      situationOptions: ["Vencido", "Pendente", "Pago", "Liquidado"],
      selectedFilterDateOrder: "data_contratacao",
      selectedOrdenationOrder: "data_contratacao",
      orderOptions: {
        confirmed: this.confirmedOrderOptions,
        pending: [
          {
            value: "data_contratacao",
            label: "Data de Contratação",
          }
        ]
      },
      searchBroker: '',
      filterDate: {},
      brokers: [],
    };
  },
  computed: {
    loggedUser() {
      return this.$store.getters.isLoggedIn;
    },
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
    displayedData() {
      this.setPages();
      const filteredData = this.data
      return this.paginate(filteredData);
    },
    originalData() {
      return this.original_data;
    }
  },
  mounted() {
    
    this.calculateTotalValues(this.data);
    // for ( const item of this.data ) {
    //   this.items_exportar.push({
    //     investidor: item.pessoa.nome,
    //     codigo_operacao: (item.ccb && item.ccb.importacao && item.ccb.importacao.operacao && item.ccb.importacao.operacao.operacao && item.ccb.importacao.operacao.operacao.operacao) ? item.ccb.importacao.operacao.operacao.operacao : '--',
    //     numero_ccb: item.ccb.numero_ccb || '--',
    //     valor_investimento: (item.item.value * item.item.quantity),
    //     remuneracao_acumulada: (this.remuneracaoAcumulada(item)),
    //     valor_remuneracao: (this.remuneracaoAcumulada(item) - (item.item.value * item.item.quantity)),
    //     carencia_total_lote: this.prazo
    //   });
    // }
    // for ( const item of this.data ) {
    //   this.items_exportar.push({
    //     investidor: item.pessoa.nome,
    //     codigo_operacao: (item.ccb && item.ccb.importacao && item.ccb.importacao.operacao && item.ccb.importacao.operacao.operacao && item.ccb.importacao.operacao.operacao.operacao) ? item.ccb.importacao.operacao.operacao.operacao : '--',
    //     numero_ccb: item.ccb.numero_ccb || '--',
    //     valor_investimento: (item.item.value * item.item.quantity),
    //     remuneracao_acumulada: (this.remuneracaoAcumulada(item)),
    //     valor_remuneracao: (this.remuneracaoAcumulada(item) - (item.item.value * item.item.quantity)),
    //     carencia_total_lote: this.prazo
    //   });
    // }
    this.getBrokers();
  },
  methods: {
    async getBrokers() {
      const brokers = await brokersService.getAllBrokers() || [];

      const formattedBrokers = brokers.map(broker => broker.name);

      this.brokers = formattedBrokers;
    },
    async getOrders(filters) {
      try {
        console.log('Trying to get server orders data...')
        this.isLoading = true;
        this.data = []
        const orders = await ordersService.getAllDocs(filters);
        await orders.forEach(async (order) => {
                    let _order = order;

                    if (_order.status == 'Pago')
                        _order.remuneracao_acumulada = getRemuneracaoAcumulada(_order);
                    else
                        _order.remuneracao_acumulada = 0

                    this.data.push(_order);
                });


        this.isLoading = false;
        
      } catch (error) { 
        console.log(error);
      }
    },
    onChangeSituation(event) {
      this.selectedFilterSituacao = event.target.value
    },
    onChangeOrdenation(event) {
      this.selectedOrdenationOrder = event.target.value
    },
    onChangeDateFilter(event) {
      this.selectedFilterDateOrder = event.target.value
    },
    setPages() {
      if (this.pages) {
        this.pages = [];
      }

      const numberOfPages = Math.ceil(this.data.length / this.perPage);

      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      const page = this.page;
      const perPage = this.perPage;

      const from = (page * perPage) - perPage;
      const to = (page * perPage);

      return data.slice(from, to);

    },
    formatDate(date) {
      return moment(date).tz('America/Sao_Paulo').format('LLL');
    },
    async downloadContratoCCB(item) {
      try {
        let temp = getRemuneracaoAcumulada(null)
        let filename = item.ccb.numero_ccb + "_ContratoCCB.pdf";
        await attachmentsService.downloadAttachment(filename, 'ContratosCCB');
      }
      catch (e) {
        console.log(e);
      }
    },
    async getHistory() {
      try {
        this.item_history = []
        let history = await historyService.getByContextAndId('Orders', this.item_modal.id);
        console.log(history);
        await history.forEach(async (log) => {
          const _log = log.data();
          this.item_history.push(_log);
        });
        
        console.log(this.item_history);
        this.isLoading = false;
      } catch (error) { }
    },
    getRemuneracaoAcumulada(order){
      return getRemuneracaoAcumulada(order)
    },
    getOrderCheckpointStatus(order){
      return getOrderCheckPointStatus(order)
    },
    getOrderDataVencimento(order){
      return getOrderCheckPointStatus(order).vencimento
    },
    getLoadBar(checkpoint){
      return `<div title="${checkpoint.percentual}% (${checkpoint.checkpoint} de ${checkpoint.prazo_oferta} meses)" class="progress-container progress-default progress-sm"><!---->
                        <div class="progress"><span class="progress-value d-none">${checkpoint.percentual}</span>
                          <div role="progressbar" aria-valuenow="${checkpoint.percentual}" aria-valuemin="0" aria-valuemax="100"
                            class="progress-bar" style="width: ${checkpoint.percentual}%;"><!----></div>
                        </div>
                      </div>`;

    },
    openModalInformarPagamento(item) {
      console.log({item})
      this.item_modal = item;
      this.item_modal.data_informacao_pagamento = ""
      this.showInformarPagamento = true;
    },
    openModalDesfazerPagamento(item) {
      this.item_modal = item;
      this.showDesfazerPagamento = true;
    },
    openModalLiquidacaoCCB(item) {
      
      item.valor_liquidacao = (item.item.quantity * item.item.value) + item.remuneracao_acumulada;
      this.item_modal = item;
      this.item_modal.data_informacao_liquidacao = ""

      console.log({item});

      this.showInformarLiquidacao = true;
    },
    openModalDesfazerLiquidacaoCCB(item){
      this.item_modal = item;
      this.showDesfazerLiquidacao = true;
    }, 
    openModalHistorico(item) {
      this.item_modal = item;
      this.showHistory = true;
      this.getHistory();
    },
    closeModal(reload = false) {
      this.item_modal = {};
      this.showSolicitarDevolucao = false;
      this.showInformarPagamento = false;
      this.showInformarLiquidacao = false;
      this.showDesfazerPagamento = false;
      this.showDesfazerLiquidacao = false;
      this.showHistory = false;
      if (reload) 
        this.$emit('reloadData');
    },
    orderData() {

      let field = this.selectedOrdenationOrder
      return this.data.sort((a, b) => a[field] - b[field])
      
    },
    filterData() {

        var unixDataInicio = null;
        var unixDataFinal = null;

        if (!this.selectedFilterSituacao){
            alert('Para realizar a consulta é obrigatório selecionar uma situação!');
            return false;
        }
        if (this.filterDate.length && this.filterDate[0] && this.filterDate[1]) {
            const [dataInicio, dataFinal] = this.filterDate;
        
            const formatISOUnixDate = (date,time) => {
            const [ dia, mes, ano ] = date.split('/');
            return moment([ano, mes, dia].join('-')+time).unix();
        } 
            
            unixDataInicio = formatISOUnixDate(dataInicio, 'T00:00:00');
            unixDataFinal = formatISOUnixDate(dataFinal, 'T23:59:59');
        } else {
            alert('Para realizar a consulta é obrigatório selecionar uma data de início e uma data de término!');
            return false;
        }

        let searchFilters;
        
        if (this.searchBroker && this.searchBroker.length > 0) {
          searchFilters = {
            brokerName: this.searchBroker,
            order_status: this.selectedFilterSituacao,
            field_to_filter: this.selectedFilterDateOrder,
            start_at: unixDataInicio,
            end_at: unixDataFinal
          }
        } else {
          searchFilters = {
            order_status: this.selectedFilterSituacao,
            field_to_filter: this.selectedFilterDateOrder,
            start_at: unixDataInicio,
            end_at: unixDataFinal
          }
        }

        this.getOrders( searchFilters )
        return 
      //Filtros de Broker
      const toNormalForm = function(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
      }

      const multiSearchAnd = (text, searchWords) => (
        searchWords.every((el) => {
          console.log(text, searchWords,el)
          return text.match(new RegExp(el,"i"))
        })
      )

      //Filtros de data
      var unixDataInicio = null;
      var unixDataFinal = null;
      if (this.filterDate.length && this.filterDate[0] && this.filterDate[1]) {
        const [dataInicio, dataFinal] = this.filterDate;
      
        const formatISOUnixDate = (date) => {
          const [ dia, mes, ano ] = date.split('/');
          return moment([ano, mes, dia].join('-')).unix();
        } 
        
        unixDataInicio = formatISOUnixDate(dataInicio);
        unixDataFinal = formatISOUnixDate(dataFinal);
      }
      
      console.log('Datas', {unixDataInicio, unixDataFinal})
      console.log('Situacao', this.selectedFilterSituacao)
      console.log('Broker', this.searchBroker)
      this.data = this.originalData.filter(obj => {
      
        let match = true;

        if (this.selectedFilterSituacao){
          console.log('Try to filter situation')
          match = ( obj.situacao == this.selectedFilterSituacao)
          if (!match) return false;
        }

        if (unixDataInicio && unixDataFinal) {
          console.log('Try to filter dates', obj[this.selectedFilterDateOrder], unixDataInicio, unixDataFinal)
          match = ( obj[this.selectedFilterDateOrder] && 
                    (obj[this.selectedFilterDateOrder] >= unixDataInicio && obj[this.selectedFilterDateOrder] <= unixDataFinal))
          if (!match) return false;
        }
        
        if (this.searchBroker){
          console.log('Try to filter broker')
          try {
            
            if (!obj.broker || !obj.broker.name)
              return false;

            var arrSearchText = toNormalForm(this.searchBroker).split(' ');

            console.log({arrSearchText})

            match = multiSearchAnd(toNormalForm(obj.broker.name), arrSearchText)
            console.log({match})
            
            if (!obj.broker || !match) return false;
          } catch (error) {
            console.log({error});
          }
          // return false
        }
        console.log('Final match', match)
        return match
      });

      console.log('Advanced filter', this.data);

      this.data = this.orderData();

    },
    async salvarInformarLiquidacao() {

      if (!this.item_modal.data_informacao_liquidacao) {
        alert('Informar a data de liquidação é obrigatório');
        this.processing = false;
        return
      }
      
      const user_ip = await fetch('https://api.ipify.org?format=json')
      .then(x => x.json())
      .then(({ ip }) => {
          return ip;
      });

      if (!user_ip) {
        this.selectedIndex = '';
        this.processing = false;
        this.closeModal();
        alert('Não foi possível obter o IP do usuário. Operação cancelada.');
        return;
      }

      this.processing = true;
      const orderFirebase = await ordersService.get(this.item_modal.id);
      var order = orderFirebase.data();
      order.id = orderFirebase.id;
      order.liquidada = true
      
      order.valor_liquidacao = this.item_modal.valor_liquidacao;

      console.log('Dt pagamento', this.item_modal.data_informacao_liquidacao)
      
      const [ dia, mes, ano ] = this.item_modal.data_informacao_liquidacao.split(' ')[0].split('/');

      order.data_liquidacao = moment([ano, mes, dia].join('-')).unix();

      await ordersService.update(order.id, order);

      let pessoa = await pessoasService.findPessoaById(this.currentPessoa.pessoa_id);

      const objectHistory = {
        register_id: order.id,
        context: 'Orders',
        subject: 'Liquidação de CCB (ao final da vigência)',
        user_id: this.currentPessoa.pessoa_id,
        user_name: pessoa.nome,
        user_ip: user_ip,
        transaction_date: moment().unix()
      }

      await historyService.create(objectHistory);

      await hubspotService.atualizarValorUltimoInvestimentoRealizadoContatoAPI(order.pessoa.id, order.item.value);

      this.selectedIndex = '';
      this.processing = false;
      this.closeModal(true);
    },
    async salvarDesfazerPagamento() {
      
      const user_ip = await fetch('https://api.ipify.org?format=json')
      .then(x => x.json())
      .then(({ ip }) => {
          return ip;
      });
      
      if (!user_ip) {
        this.selectedIndex = '';
        this.processing = false;
        this.closeModal();
        alert('Não foi possível obter o IP do usuário. Operação cancelada.');
        return;
      }

      this.processing = true;
      const orderFirebase = await ordersService.get(this.item_modal.id);
      var order = orderFirebase.data();
      order.id = orderFirebase.id;
      order.status = 'Pendente';
      order.pagamento_manual = false;
      order.data_pagamento = 0

      await ordersService.update(order.id, order);

      let pessoa = await pessoasService.findPessoaById(this.currentPessoa.pessoa_id);

      const objectHistory = {
        register_id: order.id,
        context: 'Orders',
        subject: 'Cancelamento de Baixa Manual de Pagamento',
        user_id: this.currentPessoa.pessoa_id,
        user_name: pessoa.nome,
        user_ip: user_ip,
        transaction_date: moment().unix()
      }

      await historyService.create(objectHistory);

      await hubspotService.atualizarContatoAPI(order.pessoa.id);

      this.selectedIndex = '';
      this.processing = false;
      this.closeModal(true);
    },
    async salvarDesfazerLiquidacaoCCB() {
      this.processing = true;
      const user_ip = await fetch('https://api.ipify.org?format=json')
      .then(x => x.json())
      .then(({ ip }) => {
          return ip;
      });

      if (!user_ip) {
        this.selectedIndex = '';
        this.processing = false;
        this.closeModal();
        alert('Não foi possível obter o IP do usuário. Operação cancelada.');
        return;
      }

      const orderFirebase = await ordersService.get(this.item_modal.id);
      var order = orderFirebase.data();
      order.id = orderFirebase.id;
      order.liquidada = false
      order.data_liquidacao = 0;
      order.valor_liquidacao = 0;

      await ordersService.update(order.id, order);

      let pessoa = await pessoasService.findPessoaById(this.currentPessoa.pessoa_id);

      const objectHistory = {
        register_id: order.id,
        context: 'Orders',
        subject: 'Cancelamento de Liquidação de CCB',
        user_id: this.currentPessoa.pessoa_id,
        user_name: pessoa.nome,
        user_ip: user_ip,
        transaction_date: moment().unix()
      }

      await historyService.create(objectHistory);

      await hubspotService.atualizarContatoAPI(order.pessoa.id);

      this.selectedIndex = '';
      this.processing = false;
      this.closeModal(true);
    },
    calculateTotalValues(data) {
      if (!data || data.length === 0) {
        return;
      }
      for (const order of data) {
        const investmentValue =  order.item.quantity * order.item.value || 0;
        const accumulatedRemuneration = order.remuneracao_acumulada || 0;
        const settledPayment = order.valor_liquidacao || 0;
        
        this.sumInvestmentValue += investmentValue;
        this.sumAccumulatedRemuneration += accumulatedRemuneration;
        this.sumAmmountConsolidated += (investmentValue + accumulatedRemuneration);
        this.sumSettledPayment += settledPayment;
      }
    },
    redefineTotalValues() {
        this.sumInvestmentValue = 0;
        this.sumAccumulatedRemuneration = 0;
        this.sumAmmountConsolidated = 0;
        this.sumSettledPayment = 0;
    },
    downloadFile(format) {
      const formattedData = this.data.map(item => {
        const formattedOrder = {
          ccb: item.ccb ? item.ccb.numero_ccb : 'Código não gerado',
          oferta: item.item.description, 
          data_contratacao: item.order_date,
          data_pagamento: moment.unix(item.data_pagamento).format('DD/MM/YYYY'),
          cliente: (item && item.pessoa && item.pessoa.nome && item.pessoa.documento && item.pessoa.documento.numero) ? item.pessoa.nome + ' - ' + item.pessoa.documento.numero : "", 
          email: (item && item.pessoa && item.pessoa.email && item.pessoa.email.email ? item.pessoa.email.email : ''), 
          telefone: (item && item.pessoa && item.pessoa.telefone && item.pessoa.telefone.telefone ? item.pessoa.telefone.telefone : ''), 
          // dados_bancarios: (item.pessoa && item.pessoa.dados_complementares && item.pessoa.dados_complementares.dados_bancarios && item.pessoa.dados_complementares.dados_bancarios.banco ? item.pessoa.dados_complementares.dados_bancarios.banco + " - " : '') + (item.pessoa.dados_complementares.dados_bancarios.agencia || '') + " - " + (item.pessoa.dados_complementares.dados_bancarios.conta || '') + " - " +  (item.pessoa.dados_complementares.dados_bancarios.chave_pix || ''),
          banco: item.pessoa && item.pessoa.dados_complementares && item.pessoa.dados_complementares.dados_bancarios && item.pessoa.dados_complementares.dados_bancarios.banco ? item.pessoa.dados_complementares.dados_bancarios.banco : '',
          agencia: item.pessoa && item.pessoa.dados_complementares && item.pessoa.dados_complementares.dados_bancarios && item.pessoa.dados_complementares.dados_bancarios.agencia ? item.pessoa.dados_complementares.dados_bancarios.agencia : '', 
          conta_bancaria: item.pessoa && item.pessoa.dados_complementares && item.pessoa.dados_complementares.dados_bancarios && item.pessoa.dados_complementares.dados_bancarios.conta ? item.pessoa.dados_complementares.dados_bancarios.conta : '', 
          chave_pix: item.pessoa && item.pessoa.dados_complementares && item.pessoa.dados_complementares.dados_bancarios && item.pessoa.dados_complementares.dados_bancarios.chave_pix? item.pessoa.dados_complementares.dados_bancarios.chave_pix : '',
          taxa_juros: item.financing_details.fields["taxa_juros"] ? item.financing_details.fields["taxa_juros"].value + "%" : "",
          prazo_meses_card: item.financing_details.fields["prazo_meses_card"] ? item.financing_details.fields["prazo_meses_card"].value : "",
          valor_investimento: (item.item.quantity * item.item.value || 0.00).toLocaleString('pt-br', {minimumFractionDigits: 2}),
          status: item.status || '',
          tipo_pagamento: item.tipo_pagamento || '',
          data_vencimento: item.financing_details.fields['primeiro_vencimento'].value.split('-').reverse().join('/'),
          remuneracao_acumulada: (item.remuneracao_acumulada || 0.00).toLocaleString('pt-br', {minimumFractionDigits: 2}),
          total_patrimonio_acumulado: ((item.item.quantity * item.item.value) + item.remuneracao_acumulada || 0.00).toLocaleString('pt-br', {minimumFractionDigits: 2}),
          valor_liquidado: (item.valor_liquidacao || 0.00).toLocaleString('pt-br', {minimumFractionDigits: 2}),
          ccb_status_liquidacao: item.liquidada ? 1 : 0,
          assessor: item.broker ? item.broker.name : 'Não identificado',
          gateway: item.origem_pagamento || '', 
        };
        
        return formattedOrder;
      });

      const data = XLSX.utils.json_to_sheet(formattedData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, 'data');

      let fileName = 'Relatorio';
      let fileExtension = '';


      switch (format) {
        case 'xlsx':
          fileExtension = '.xlsx';
          break;
        case 'csv':
          fileExtension = '.csv';
          break;
        default:
          return;
      }

      fileName += fileExtension;
      XLSX.writeFile(wb, fileName);
    }
  },
  watch: {
    data() {
      this.redefineTotalValues();
      this.calculateTotalValues(this.data);
      this.setPages();
    }
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card p-2"><!---->
          <div class="card-header">
            <h5 class="card-title">{{title}} <small>({{ data.length + ' aportes' }})</small></h5>
          </div>
          <div class="card-body">
            <div v-if="role == 'admin' && isAllOrdersPage" class="row">
              <div class="col-lg-3 col-12">
              <div class="form-group">
                <label
                  class="control-label"
                  style="cursor: default"
                  >Assessor</label
                >
                <v-select
                  label="brokers"
                  class="style-chooser"
                  v-model="searchBroker"
                  :options="brokers"
                >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    <em style="opacity: 0.9; color: #555">não encontrado</em>
                  </template>
                </template>
              </v-select>
              </div>
            </div>
            <div class="col-lg-2 col-12 mb-3">
              <base-input label="Filtrar por">
                <select class="form-control" @change="onChangeDateFilter($event)" v-model="selectedFilterDateOrder">
                  <option value="" selected>Selecione:</option>
                  <option
                    :value="status.value"
                    v-for="(status, index) in (data ? orderOptions[type]: [])"
                    :key="index"
                  >
                    {{ status.label }}
                  </option>
                </select>
              </base-input>
            </div>  
            <div class="col-lg-3 col-12 mb-3">
              <base-input label="Período">
                <date-picker
                  v-model="filterDate"
                  valueType="format"
                  range
                  format="DD/MM/YYYY"
                >
                </date-picker>
              </base-input>
            </div>
            <div class="col-lg-2 col-3">
              <base-input label="Situação">
                <select class="form-control" @change="onChangeSituation($event)" v-model="selectedFilterSituacao">
                  <option value="" selected>Selecione:</option>
                  <option
                    :value="situation"
                    v-for="(situation) in situationOptions"
                    :key="situation"
                  >
                    {{ situation }}
                  </option>
                </select>
              </base-input>
            </div>
            <!-- <div class="col-lg-2 col-3">
              <base-input label="Ordenar por">
                <select class="form-control" @change="onChangeOrdenation($event)" v-model="selectedOrdenationOrder">
                  <option value="" selected>Selecione:</option>
                  <option
                    :value="status.value"
                    v-for="(status, index) in (displayedData ? orderOptions[type]: [])"
                    :key="index"
                  >
                    {{ status.label }}
                  </option>
                </select>
              </base-input>
            </div> -->
            <div class="col-lg-2 col-3 pb-3 pt-3">
              <button
                class="btn btn-primary"
                @click="filterData()"
                v-bind:disabled="isLoading"
              >
                Buscar
              </button>
            </div>
            </div>
            <div class="row">
            <div class="col-md-12 text-center">
                <double-bounce v-if="isLoading"></double-bounce>
            </div>
            </div>
            <div class="table-responsive" v-if="data.length">
              <table class="table tablesorter">
                <thead class="thead-light">
                  <tr>
                    <th style="{ maxWidth: '5px' }" class="text-center">Seq.</th>
                    <th class="text-center" :style="{ minWidth: index == 0 ? '400px' : '100px' }" v-for="(column, index) in columns" v-bind:key="column">{{ column }}</th>
                  </tr>
                </thead>
                <tbody class="">
                  <!-- columns: ['Oferta', 'Nº CCB', 'Valor do Investimento', 'Vencimento CCB', 'Andamento', 'Remuneração Acumulada', 'Situação', 'Contrato'], -->
                  <!-- columns: ['Oferta', 'Nº CCB', 'Valor do Investimento', 'Situação', 'Boleto', 'Contrato'], -->

                  <tr v-for="(item, index) in data" :key="index">
                    <td style="{ maxWidth: '5px' }" class="text-center">{{ index + 1}}</td>
                    <td :style="{ minWidth: '400px' }" class="text-left" width="30%">
                      <b>CCB nº {{ item.ccb && item.ccb.simulacao ? item.ccb.simulacao.numero_ccb : item.ccb && item.ccb.numero_ccb ? item.ccb.numero_ccb : "" }}</b> <br/> {{ item.item.description }} - em {{ item.data_contratacao | unix-to-br-date }}
                      <br/>Cliente: <b>
                        <router-link :to="`/orders/${item.pessoa.id}`" target= '_blank'>
                          {{ item.pessoa.nome }}
                        </router-link>
                        
                      </b> | CPF: {{ (item.pessoa && item.pessoa.documento && item.pessoa.documento.numero ? item.pessoa.documento.numero : '') }}
                           | E-mail: {{ (item.pessoa && item.pessoa.email && item.pessoa.email.email ? item.pessoa.email.email : '') }}
                           | Telefone: {{ (item.pessoa && item.pessoa.telefone && item.pessoa.telefone.telefone ? item.pessoa.telefone.telefone : '') }}
                      <br/> Assessor: {{ item.broker ? `${item.broker.name.split(' ')[0]} ${item.broker.name.split(' ').pop()}` : 'Não identificado'  }}
                      <span class="badge badge-success ml-1"> {{ item.financing_details ? item.financing_details.fields["taxa_juros"].value + "%" : "" }}</span>  
                      <span class="badge badge-info ml-1">{{ item.financing_details ? item.financing_details.fields["prazo_meses_card"].value : "" }}</span> 
                    </td>
                    <td class="text-center" v-if="item.data_pagamento">
                      <b>{{ item.item.quantity * item.item.value || 0 | currency }}</b> <br/>
                      Início Vigência {{ item.data_pagamento | unix-to-br-date }} <br/>
                      ({{ item.data_pagamento ? 'Pago' : '' }} via {{ item.tipo_pagamento }} em {{ item.data_pagamento | unix-to-br-date }} - {{  item.origem_pagamento }})
                    </td>
                    <td class="text-center" v-if="!item.data_pagamento && item.tipo_pagamento == 'Boleto'">
                      {{ item.item.quantity * item.item.value || 0 | currency  }} <br/>
                      Vencimento {{item.boleto_vencimento}} <br/>
                      ({{ item.status }} via {{ item.tipo_pagamento }})
                    </td>
                    <td class="text-center" v-if="!item.data_pagamento && item.tipo_pagamento !== 'Boleto'">
                      {{ item.item.quantity * item.item.value || 0 | currency  }} <br/>
                      ({{ item.status }} via {{ item.tipo_pagamento }})
                    </td>

                    <td class="text-center" v-if="type == 'confirmed' && item.data_pagamento">{{ item.financing_details.fields['primeiro_vencimento'].value.split('-').reverse().join('/') || "" }}</td>
                    <td class="text-center" v-if="type == 'confirmed' && !item.data_pagamento">--</td>

                    <td class="text-center" v-if="type == 'confirmed'" width="10%" v-html="getLoadBar(getOrderCheckpointStatus(item))">
                      
                    </td>
                    <td class="text-center" v-if="type == 'confirmed'">
                      {{ item.remuneracao_acumulada || 0 | currency  }} <br/>
                    </td>
                    <td class="text-center" v-if="type == 'confirmed'">
                      {{(item.item.quantity * item.item.value) + item.remuneracao_acumulada || 0 | currency   }}
                    </td>
                    
                    <td class="text-center" v-if="type == 'confirmed' && !item.data_liquidacao" > {{ item.status }} </td>
                    <td class="text-center" v-if="type == 'confirmed' && item.data_liquidacao" > {{ item.status }} <br/>({{item.valor_liquidacao | currency}} em {{item.data_liquidacao | unix-to-br-date}}) 
                        <br/>
                        <span v-if="item.pagamento_processado">(Pagamento processado em {{ item.data_processamento_pagamento | unix-to-br-date }})</span>
                    </td>
                    <td class="text-center" v-if="type == 'pending'"> {{ item.status }} </td>
                    <td class="text-center" v-if="isAllOrdersPage" > {{ item.broker ? item.broker.name + (isAllOrdersPage ? ' - (Comissão: R$ 0 (0%))' : '') : 'Não identificado'  }} </td>
                    
                    <td class="text-center" v-if="isAllOrdersPage" > {{ item.origem_pagamento || "Não identificado" }} </td>
                    
                    <!-- <td class="text-center" v-if="type == 'pending'"> 
                      <a 
                        :href="item.boleto_link" 
                        target="_blank" 
                        rel="noopener"
                        style="text-decoration: underline; color: #0068A5;">
                          <i title="Visualizar boleto" class="tim-icons icon-cloud-download-93 pl-2"/>
                      </a>
                    </td> -->

                    <!-- Contrato -->
                    <td class="text-center" width="10%">
                      <div style="display: inline-block; white-space: nowrap;">
                        <a class="pr-2" v-if="item.status == 'Pendente'" 
                          :href="item.boleto_link" 
                          target="_blank" 
                          rel="noopener"
                          style="text-decoration: underline; color: #0068A5;">
                            <i title="Visualizar boleto" class="tim-icons icon-cloud-download-93 pl-2"/>
                        </a>
                        <i v-if="item.ccb && !item.contrato_url && item.ccb.numero_ccb && item.ccb.possui_contrato_download" title="Download Contrato CCB" class="tim-icons icon-cloud-download-93" @click="downloadContratoCCB(item)"></i>
                        <a class="pr-2" v-if="item.status == 'Pago' && item.contrato_url" 
                          :href="item.contrato_url" 
                          target="_blank" 
                          rel="noopener"
                          style="text-decoration: underline; color: #0068A5;">
                            <i title="Download Contrato CCB" class="tim-icons icon-cloud-download-93 pl-2"/>
                        </a>
                        <a 
                          href="#"
                          v-if="item.status == 'Pago' && 
                            !isAllOrdersPage && role == 'admin' && 
                            item.pagamento_manual && 
                            !item.liquidada && 
                            item.status != 'Pendente'                         
                          "
                          title="Desfazer Pagamento"
                          @click="openModalDesfazerPagamento(item)"
                        >
                          <i class="tim-icons icon-coins pl-2"></i>
                        </a>
                        <a
                          href="#"
                          v-if="item.status == 'Pago' && 
                            !isAllOrdersPage && 
                            role == 'admin' && 
                            item.status == 'Pago' &&                        
                            !item.liquidada
                          "
                          title="Informar Liquidação"
                          @click="openModalLiquidacaoCCB(item)"
                        >
                          <i class="tim-icons icon-money-coins pl-2"></i>
                        </a>
                        <a
                          href="#"
                          v-if="item.status == 'Liquidada' && 
                            !isAllOrdersPage && role == 'admin' && 
                            item.liquidada                          
                          "
                          title="Desfazer Liquidação"
                          @click="openModalDesfazerLiquidacaoCCB(item)"
                        >
                          <i class="tim-icons icon-coins pl-2"></i>
                        </a>
                        <a
                          href="#"
                          v-if="isAllOrdersPage || role == 'admin'"
                          title="Histórico de transações"
                          @click="openModalHistorico(item)"
                        >
                          <i class="tim-icons icon-book-bookmark pl-2"></i>
                        </a>
                      </div>
                      <!-- <i  title="Download Contrato CCB" class="tim-icons icon-cloud-download-93" @click="downloadContratoCCB(item)"></i> -->
                    </td>
                  </tr>
                </tbody>
                </table>
                <table class="table tablesorter">
                <tfoot class="thead-light">
                  <tr>
                    <th class="text-primary"><strong></strong></th>
                    <th class="text-center"><strong>Investido</strong></th>
                    <th class="text-center"></th>
                    <th class="text-center"></th>
                    <th class="text-center"><strong>Remuneração</strong></th>
                    <th class="text-center"><strong>Acumulado</strong></th>
                    <th class="text-center"><strong>Liquidado</strong></th>
                  </tr>
                  <tr>
                    <th class="text-primary"><strong>Total:</strong></th>
                    <th class="text-center"><strong>{{ this.sumInvestmentValue | currency }}</strong></th>
                    <th class="text-center"></th>
                    <th class="text-center"></th>
                    <th class="text-center"><strong>{{ this.sumAccumulatedRemuneration | currency }}</strong></th>
                    <th class="text-center"><strong>{{ this.sumAmmountConsolidated | currency }}</strong></th>
                    <th class="text-center"><strong>{{ this.sumSettledPayment | currency }}</strong></th>
                  </tr>
                </tfoot>
              </table>
            </div>
            <!-- <nav class="table-pagination" v-if="data.length">
              <ul class="pagination">
                <li class="page-item">
                  <button
                    type="button"
                    class="page-link"
                    v-if="page != 1"
                    @click="page--"
                  >
                    <i class="fas fa-angle-left"></i>
                  </button>
                </li>

                <li class="page-item">
                  <button
                    type="button"
                    class="page-link"
                    v-for="(pageNumber, index) in pages.slice(page - 1, page + 4)"
                    :key="index"
                    @click="page = pageNumber"
                    :class="{ 'is-active': page === pageNumber }"
                  >
                    {{ pageNumber }}
                  </button>
                </li>

                <li class="page-item">
                  <button
                    type="button"
                    class="page-link"
                    v-if="page < pages.length"
                    @click="page++"
                  >
                    <i class="fas fa-angle-right"></i>
                  </button>
                </li>
              </ul>
            </nav> -->
            <div class="export-button" v-if="isAllOrdersPage && data.length">
              <button
                class="btn btn-primary"
                @click="downloadFile('xlsx')"
                :disabled="!data.length"
              >
                <i class="tim-icons icon-cloud-download-93"></i>
                Exportar para Excel
              </button>
              <button
                class="btn btn-primary"
                @click="downloadFile('csv')"
                :disabled="!data.length"
              >
                <i class="tim-icons icon-cloud-download-93"></i>
                Exportar para CSV
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- <div class="row">
      <div class="export-button">
        <button
          class="btn btn-primary"
          @click="download"
          :disabled="!displayedData.length"
          :hidden="!prazo"
        >
          <i class="tim-icons icon-cloud-download-93"></i>
          Exportar para Excel
        </button>
      </div>
    </div> -->
    <template>
      <SlideYUpTransition :duration="500">
        <div
          class="modal fade"
          @click.self="closeModal"
          :class="[
            { 'show d-block': showSolicitarDevolucao },
            { 'd-none': !showSolicitarDevolucao },
            { 'modal-mini': type === 'mini' },
          ]"
          v-show="showSolicitarDevolucao"
          tabindex="-1"
          role="dialog"
          :aria-hidden="!showSolicitarDevolucao"
        >
          <div class="modal-dialog">
            <div class="modal-content confirmation-modal">
              <i class="fa fa-exclamation-triangle fa-3x"></i>

              <h1 class="modal-content-title">Atenção</h1>

              <p class="modal-content-description">
                Por favor, informe o motivo da devolução.
              </p>

              <textarea
                type="text"
                cols="100"
                rows="5"
                class="form-control"
                v-model="item_modal.devolucao_observacao"
              >
              </textarea>

              <div class="modal-buttons">
                <button @click="closeModal()" class="cancel-action">
                  Fechar
                </button>

                <button
                  :disabled=processing
                  :class="{ disabled_button: processing }"
                  style="
                    margin-top: 20px; 
                    background-color: #288cf7;
                    color: #fff;
                    width: 250px !important;
                  "
                  @click="salvarSolicitacaoDevolucao()"
                >
                  Salvar
                </button>

                <button
                  :disabled=processing
                  :class="{ disabled_button: processing }"
                  style="
                    margin-top: 20px;
                    background-color: #288cf7;
                    color: #fff;
                    width: 250px !important;
                  "
                  @click="salvarDevolucaoRealizada()"
                >
                  {{ processing ? 'Aguarde...' : 'Confirmar devolução'}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </SlideYUpTransition>
    </template>

    <template>
      <SlideYUpTransition :duration="500">
        <div
          class="modal fade"
          @click.self="closeModal"
          :class="[
            { 'show d-block': showDesfazerPagamento },
            { 'd-none': !showDesfazerPagamento },
            { 'modal-mini': type === 'mini' },
          ]"
          v-show="showDesfazerPagamento"
          tabindex="-1"
          role="dialog"
          :aria-hidden="!showDesfazerPagamento"
        >
          <div class="modal-dialog">
            <div class="modal-content confirmation-modal">
              <i class="fa fa-exclamation-triangle fa-3x"></i>

              <h1 class="modal-content-title">Atenção</h1>

              <p class="modal-content-description">
                Tem certeza que deseja desfazer o pagamento?
              </p>

              <div class="modal-buttons">
                <button @click="closeModal()" class="cancel-action">
                  Cancelar
                </button>

                <button
                  :disabled=processing
                  :class="{ disabled_button: processing }" 
                  style="margin-top: 20px;
                    background-color: #288cf7;
                    color: #fff;
                    width: 250px !important;
                  "
                  @click="salvarDesfazerPagamento()"
                >
                {{ processing ? 'Aguarde...' : 'Sim, tenho certeza'}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </SlideYUpTransition>
    </template>

    <template>
      <SlideYUpTransition :duration="500">
        <div
          class="modal fade"
          @click.self="closeModal"
          :class="[
            { 'show d-block': showInformarLiquidacao },
            { 'd-none': !showInformarLiquidacao },
            { 'modal-mini': type === 'mini' },
          ]"
          v-show="showInformarLiquidacao"
          tabindex="-1"
          role="dialog"
          :aria-hidden="!showInformarLiquidacao"
        >
          <div class="modal-dialog">
            <div class="modal-content confirmation-modal">
              <i class="fa fa-exclamation-triangle fa-3x"></i>

              <h1 class="modal-content-title">Atenção</h1>

              <div class="form-group">
                <label for="" class="control-label">Data da liquidação *</label><br />
                <birth-datepicker
                  v-model="item_modal.data_informacao_liquidacao"
                  v-mask="'##/##/####'"
                  :valueIsString="true"
                  :high="true"
                  :hideHeader="true"
                  :attachment="'bottom'"
                  :class="'Password__field'"
                  :delimiter="'/'"
                  :placeholder="'Ex: 27/12/2022'"
                  :locale="[
                    'Jan',
                    'Fev',
                    'Mar',
                    'Abr',
                    'Mai',
                    'Jun',
                    'Jul',
                    'Ago',
                    'Set',
                    'Out',
                    'Nov',
                  ]"
                />
              </div>
              <p class="modal-content-description">
                Tem certeza que deseja informar a liquidação da CCB, no valor de <br/><b>{{ this.item_modal.valor_liquidacao | currency }}?</b>
              </p>

              <div class="modal-buttons">
                <button @click="closeModal()" class="cancel-action">
                  Cancelar
                </button>

                <button
                  :disabled=processing
                  :class="{ disabled_button: processing }"
                  style="
                    margin-top: 20px;
                    background-color: #288cf7;
                    color: #fff;
                    width: 250px !important;"
                  @click="salvarInformarLiquidacao()"
                >
                  {{ processing ? 'Aguarde...' : 'Sim, tenho certeza'}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </SlideYUpTransition>
    </template>

    <template>
      <SlideYUpTransition :duration="500">
        <div
          class="modal fade"
          @click.self="closeModal"
          :class="[
            { 'show d-block': showDesfazerLiquidacao },
            { 'd-none': !showDesfazerLiquidacao },
            { 'modal-mini': type === 'mini' },
          ]"
          v-show="showDesfazerLiquidacao"
          tabindex="-1"
          role="dialog"
          :aria-hidden="!showDesfazerLiquidacao"
        >
          <div class="modal-dialog">
            <div class="modal-content confirmation-modal">
              <i class="fa fa-exclamation-triangle fa-3x"></i>

              <h1 class="modal-content-title">Atenção</h1>

              <p class="modal-content-description">
                Tem certeza que deseja cancelar a liquidação anterior da CCB?
              </p>

              <div class="modal-buttons">
                <button @click="closeModal()" class="cancel-action">
                  Cancelar
                </button>

                <button
                  :disabled=processing
                  :class="{ disabled_button: processing }"
                  style="
                    margin-top: 20px;
                    background-color: #288cf7;
                    color: #fff;
                    width: 250px !important;
                  "
                  @click="salvarDesfazerLiquidacaoCCB()"
                >
                {{ processing ? 'Aguarde...' : 'Sim, tenho certeza'}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </SlideYUpTransition>
    </template>

    <template>
      <SlideYUpTransition :duration="500">
        <div
          class="modal fade"
          @click.self="closeModal"
          :class="[
            { 'show d-block': showHistory },
            { 'd-none': !showHistory },
          ]"
          v-show="showHistory"
          tabindex="-1"
          role="dialog"
          :aria-hidden="!showHistory"
        >
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="card card p-4" style="min-width: 655px !important;"><!---->
                        <div class="card-header">
                          <div class="modal-header">
                            <button 
                              type="button" 
                              style="
                                color: #288cf7;
                              "
                              @click="closeModal()" 
                              class="close" 
                              data-dismiss="modal" 
                              aria-label="Close"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <h5 class="card-title">Histórico de Transações</h5>
                        </div>
                        <div class="card-body">
                          <h4 v-if="item_history.length === 0">
                            Nenhum histórico encontrado para o registro atual.
                          </h4>
                          <div v-if="item_history.length > 0" class="table-responsive">
                            <table class="table tablesorter">
                              <thead class="text-primary">
                                <tr>
                                  <th class="text-center">Data/Hora</th>
                                  <th class="text-center">Operação</th>
                                  <th class="text-center">Usuário</th>
                                  <th class="text-center">Endereço IP</th>
                                </tr>
                              </thead>
                              <tbody class="">
                                <tr v-for="(item, index) in item_history" :key="index">
                                  <td class="text-center">
                                    {{item.transaction_date | unix-to-br-timestamp}}
                                  </td>
                                  <td class="text-center">
                                    {{item.subject}}
                                  </td>
                                  <td class="text-center">
                                    {{item.user_name}}
                                  </td>
                                  <td class="text-center">
                                    {{item.user_ip}}
                                  </td>
                                </tr>
                                <!-- <tr v-if="item_history.length === 0">
                                  <td colspan="4" class="text-center">
                                    Nenhum histórico encontrado para o registro atual.
                                  </td>
                                </tr> -->
                              </tbody>
                            </table>
                          </div>
                        </div><!----><!---->
                      </div>
                    </div>
                  </div>  
              </div>
            </div>
          </div>
        </div>
      </SlideYUpTransition>
    </template>
  </div>
</template>

<style>
  .vs__selected {
    max-width: 135px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
