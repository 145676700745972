<script>
import attachmentsService from '@/services/Attachments/attachmentsService';
import { CWLTipoPagamento, CWLInvestimentoDashboard } from '@/store/cwl_enums';
import moment from 'moment-timezone';

import * as XLSX from 'xlsx';

import { DoubleBounce } from 'vue-loading-spinner';

export default {
  name: 'financial-table',
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: 'Table columns',
    },
    data: {
      type: Array,
      default: () => [],
      description: 'Table data',
    },
    campaign: {
      type: Object
    },
    type: {
      type: String,
      default: '',
      description: 'Whether table is striped or hover type',
    },
    theadClasses: {
      type: String,
      default: '',
      description: '<thead> css classes',
    },
    tbodyClasses: {
      type: String,
      default: '',
      description: '<tbody> css classes',
    },
    prazo: {
      type: String,
      default: ''
    }
  },
  components: {DoubleBounce},
  data() {
    return {
      page: 1,
      perPage: 1024,
      pages: [],
      CWLTipoPagamento: CWLTipoPagamento,
      CWLInvestimentoDashboard: CWLInvestimentoDashboard,

      items_exportar: [],
    };
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
    displayedData() {
      return this.paginate(this.data);
    },
  },
  mounted() {
    for ( const item of this.data ) {
      this.items_exportar.push({
        investidor: item.pessoa.nome,
        codigo_operacao: (item.ccb && item.ccb.importacao && item.ccb.importacao.operacao && item.ccb.importacao.operacao.operacao && item.ccb.importacao.operacao.operacao.operacao) ? item.ccb.importacao.operacao.operacao.operacao : '--',
        numero_ccb: item.ccb.numero_ccb || '--',
        valor_investimento: (item.item.value * item.item.quantity),
        remuneracao_acumulada: (this.remuneracaoAcumulada(item)),
        valor_remuneracao: (this.remuneracaoAcumulada(item) + (item.item.value * item.item.quantity)),
        carencia_total_lote: this.prazo
      });
    }
  },
  methods: {
    hasValue(item, column) {
      return item[column.toLowerCase()] !== 'undefined';
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    setPages() {
      if (this.pages) {
        this.pages = [];
      }

      const numberOfPages = Math.ceil(this.data.length / this.perPage);

      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      const page = this.page;
      const perPage = this.perPage;

      const from = (page * perPage) - perPage;
      const to = (page * perPage);

      return data.slice(from, to);
    },
    formatDate(date) {
      return moment(date).tz('America/Sao_Paulo').format('LLL');
    },
    async downloadContratoCCB(item) {
      try {
        let filename = item.ccb.numero_ccb + "_ContratoCCB.pdf";
        await attachmentsService.downloadAttachment(filename, 'ContratosCCB');
      }
      catch (e) {
        console.log(e);
      }
    },
    remuneracaoAcumulada(order) {
      //Calculo de remuneracao deve considerar D+30 a partir do pagamento do aporte

      if (order.status === 'Pago') {
        const today = moment();
        const orderPaymentDate = moment(order.order_date.split(" ")[0], "YYYY/MM/DD");

        const months = today.diff(orderPaymentDate, 'month');

        var prazoAtual =  0
        if (months > parseInt(this.campaign.fields['prazo_oferta'].value)) 
          prazoAtual = this.campaign.fields['prazo_oferta'].value
        else prazoAtual = months

        const juros = this.calcularJurosCompostos(order.item.value, this.campaign.fields['taxa_juros'].value, prazoAtual)

        return juros;
  
      } else {
        return 0
      }
      
    },
    calcularJurosCompostos(valorAporte, taxaJuros, prazo) {
        let investimento_inicial = parseFloat(valorAporte);
        var meses = parseInt(prazo);
        let taxa_de_juros = parseFloat(taxaJuros);

        let investimento_acumulado = investimento_inicial;

        var total_investimento_acumulado = investimento_inicial;

        let juros_compostos = 0;
        let juros_compostos_total = 0;

        for (let i = 0; i < meses; i++) {
            juros_compostos = (investimento_acumulado * taxa_de_juros) / 100;
            juros_compostos_total = juros_compostos_total + juros_compostos;
            investimento_acumulado = investimento_acumulado + juros_compostos;
            // console.log(i, juros_compostos, juros_compostos_total, investimento_acumulado);
        }
        
        return juros_compostos_total;
    },
    download() {
      const data = XLSX.utils.json_to_sheet(this.items_exportar);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, 'data');
      XLSX.writeFile(wb, 'Report.xlsx');
    },
  },
  watch: {
    data() {
      this.setPages();
    },
  },
};
</script>

<template>
  <div>
    <table class="table tablesorter" :class="tableClass">
      <thead :class="theadClasses">
      <tr>
        <slot name="columns">
          <th v-for="column in columns" :key="column">{{column}}</th>
        </slot>
      </tr>
      </thead>
      <tbody :class="tbodyClasses">
        <tr v-for="(item, index) in displayedData" :key="index">
          <td v-if="prazo.length > 0">{{ item.pessoa.nome }}</td>
          <td v-if="prazo.length > 0"> {{ (item.ccb.importacao && item.ccb.importacao.operacao && item.ccb.importacao.operacao.operacao && item.ccb.importacao.operacao.operacao.operacao) ? item.ccb.importacao.operacao.operacao.operacao : '--' }} </td>
          <td v-if="prazo.length > 0"> {{ item.ccb.numero_ccb || '--' }} </td>
          <td>{{ (item.item.value * item.item.quantity) | currency }}</td>
          <td v-if="item.tipo_pagamento === CWLTipoPagamento.CartaoCredito && prazo.length == 0">Cartão de Crédito</td>
          <td v-if="item.tipo_pagamento === CWLTipoPagamento.Boleto && prazo.length == 0">Boleto</td>
          <td v-if="item.tipo_pagamento === CWLTipoPagamento.Reservado && prazo.length == 0">Reservado</td>
          <td v-if="item.tipo_pagamento === CWLTipoPagamento.TED && prazo.length == 0">TED</td>
          <td v-if="item.tipo_pagamento === CWLTipoPagamento.PIX && prazo.length == 0">PIX</td>
          <td v-if="item.tipo_pagamento === 'PIX' && prazo.length == 0">PIX</td>
          <td v-if="prazo.length == 0">{{ formatDate(item.order_date)  }}</td>
          <td v-if="prazo.length == 0">{{ item.status }}</td>
          
          <td v-if="item.status != 'Pago'"> -- </td>
          <td v-if="item.status == 'Pago'">{{ remuneracaoAcumulada(item) | currency }}</td>

          <td v-if="prazo.length > 0 && item.status == 'Pago'"> {{ ( remuneracaoAcumulada(item) + (item.item.value * item.item.quantity) ) | currency }} </td>
          <td v-if="prazo.length > 0 && item.status != 'Pago'"> -- </td>

          <td v-if="prazo.length > 0"> {{ prazo }} </td>

          <td v-if="item.status != 'Pago' && prazo.length == 0" > -- </td>
          <td v-if="item.status == 'Pago' && prazo.length > 0">
            <i v-if="item.ccb && item.ccb.numero_ccb && item.ccb.possui_contrato_download" title="Download Contrato CCB" class="tim-icons icon-cloud-download-93" @click="downloadContratoCCB(item)"></i>
          </td>
        </tr>
      </tbody>
    </table>

    <nav class="table-pagination">
      <ul class="pagination">
        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-if="page != 1"
            @click="page--"
          >
            <i class="fas fa-angle-left"></i>
          </button>
        </li>

        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
            :key="index"
            @click="page = pageNumber"
            :class="{ 'is-active': page === pageNumber }"
          >
            {{ pageNumber }}
          </button>
        </li>

        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-if="page < pages.length"
            @click="page++"
          >
            <i class="fas fa-angle-right"></i>
          </button>
        </li>
      </ul>
    </nav>

    <div class="row">
      <div class="export-button">
        <button
          class="btn btn-primary"
          @click="download"
          :disabled="!displayedData.length"
          :hidden="!prazo"
        >
          <i class="tim-icons icon-cloud-download-93"></i>
          Exportar para Excel
        </button>
      </div>
    </div>
  </div>
</template>
