<script>
import {vueTopprogress} from 'vue-top-progress';
import newsService from '@/services/News/newsService';
import moment from 'moment';

export default {
  components: {
    vueTopprogress,
},
  data() {
    return {
      isLoading: true,
      ebooks: []
    };
  },
  computed: {
    getRole() {
      return this.$store.getters.userClaim.role;
    },
  },
  mounted() {
    this.loadEbooksList();
  },
  methods: {
    openDetails(id) {
      this.$router.push(`market-news-ebook-details/${id}`) 
    },
    async loadEbooksList() {
      try {
        if (this.getRole === 'master' || this.getRole === 'gestor') {
          const ebooks = await newsService.getNewsAdmin();
          this.ebooks = ebooks;
        } else {
          const checkRole = this.getRole === 'assessor' ? 'broker' : 'customer';
          const ebooks = await newsService.getAllNewsEbook('ebook', checkRole);
          const news = await newsService.getAllNews();
          ebooks.forEach((item) => {
            item.created_at = moment(item.created_at, 'DD-MM-YYYY HH:mm:ss');
          });
          this.ebooks = ebooks.sort((a,b) => b.created_at - a.created_at);
          if (checkRole === 'broker') {
            const customerBooks = await newsService.getAllNewsEbook('ebook', 'customer');
            customerBooks.forEach(ebook => ebooks.push(ebook))
          }
          this.ebooks = [...this.ebooks, ...news];
        }
      } catch(err) {
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-md-12 ebook-header">
        <i class="fa fa-arrow-left mr-3" @click="goBack" style="color: darkblue; cursor: pointer;"></i>
        <h3 class="ebook-big-title">Material didático</h3>
      </div>
    </div>
    <div class="row" v-if="isLoading">
      <div class="col-md-12 loading-gif">
        <img src="@/assets/images/loading.gif">
      </div>
    </div>
    <div class="ebooks">
      <div class="content-ebook" v-for="item in ebooks" v-bind:key="item.id">
        <div class="list-ebook-item" v-on:click="openDetails(item.id)">
          <div class="list-academy-item-thumbnail">
            <img :src="item.thumb_url" alt="" class="img">
          </div>
          <div class="list-ebook-item-title">
            <label>{{item.title}}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
  .ebook-header {
    display: flex;
    align-items: center;
  }

  .ebooks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
  }

  .content-ebook {
    display: flex;
    flex-basis: 30%;
    height: 100%;
    width: 100%;
  }


  .loading-gif {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .list-ebook-item {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
  }

  .list-academy-item-thumbnail {
    border-radius: 10px;
    width: 100%;
    height: auto;
    box-shadow: 0 5px 10px rgb(0 0 0 / 0.2);
    transition: all .5s;
  }  

  .list-ebook-item-thumbnail:hover {
    opacity: 0.8;
  }

  .list-ebook-item-thumbnail .img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .list-ebook-item-title {
    padding: 8px 0px 0px 0px;
    text-align: left;
    font-size: 0.9rem;
    color: #333;
  }

  .list-ebook-item-details {
    font-size: 0.9rem;
    color: #555;
  }

  .ebook-big-title {
    font-weight: bold;
    color: darkblue;
    font-size: 1rem;
    margin: 0px;
  }
</style>