import Vue from 'vue';
import InstancePlugin from '@/plugins/InstancePlugin';
Vue.use(InstancePlugin);

const SideBar = Vue.prototype.$CWLGetComponentInstance('SidebarPlugin');
import Notify from "@/components/NotificationPlugin";
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
import RTLPlugin from "./RTLPlugin";

//css assets
import "@/assets/sass/black-dashboard.scss";
import "@/assets/css/nucleo-icons.css";
import "@/assets/css/tabs-component.css";
import "@/assets/demo/demo.css";
import "@/assets/css/new.css";

export default {
  install(Vue) {
    Vue.use(GlobalComponents);
    Vue.use(GlobalDirectives);
    Vue.use(SideBar);
    Vue.use(Notify);
    Vue.use(RTLPlugin);
  }
}
