<script>
import moment from 'moment';
import benefitsService from '@/services/Benefits/benefitsService';

import UpdatedBenefitNotification from '@/components/Notifications/Benefits/UpdatedBenefitNotification';
import UpdatedBenefitErrorNotification from '@/components/Notifications/Benefits/UpdatedBenefitErrorNotification';
import GetBenefitErrorNotification from '@/components/Notifications/Benefits/GetBenefitErrorNotification';
import {vueTopprogress} from 'vue-top-progress';
import logsService from '@/services/Logs/logsService';
import { nonEmpty } from '@/validate/customValidators.js';

export default {
  data() {
    return {
      benefit: {
        name: "",
        characteristics: "",
        discount_value: "",
        expiration_date: "",
        url_partner: "",
        achievement_level: "",
      },
      formattedDate: "",
    };
  },
  validations: {
    benefit: {
      name: {
        valid: (name) => nonEmpty(name),
      },
      characteristics: {
        valid: (characteristics) => nonEmpty(characteristics),
      },
      discount_value: {
        valid: (_discount_value) => nonEmpty(_discount_value),
      },
      expiration_date: {
        valid: (expiration_date) => nonEmpty(expiration_date),
      },
      url_partner: {
        valid: (url_partner) => nonEmpty(url_partner),
      },
      achievement_level: {
        valid: (achievement_level) => nonEmpty(achievement_level),
      }
    },
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
    benefitId() {
      return this.$route.params.id;
    },
    isFormValid() {
      return (
        !this.$v.benefit.name.valid ||
        !this.$v.benefit.characteristics.valid ||
        !this.$v.benefit.discount_value.valid ||
        !this.$v.benefit.expiration_date.valid ||
        !this.$v.benefit.url_partner.valid ||
        !this.$v.benefit.achievement_level.valid ||
        this.benefit.status === 'Aguardando aprovação' ||
        this.benefit.status === 'Aprovado'
      );
    },
  },
  components: {
    vueTopprogress,
  },
  mounted() {
    this.getBenefit();
  },
  methods: {
    async getBenefit() {
      this.$refs.topProgress.start();

      try {
        const { created_at, ...benefit } = await benefitsService.getBenefit(this.benefitId);
        
        const createdTimestamp = created_at.toDate();
        const formattedDate = moment(createdTimestamp).format('DD/MM/YYYY');7

        this.formattedDate = formattedDate
        this.benefit = benefit
        this.$refs.topProgress.done();
      } catch (error) {
        console.log(error)
        this.$notify({
          component: GetBenefitErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
    async addNewBenefit() {
      this.$refs.topProgress.start();

      try {
        // verify if need to check duplicates
        const logUser = {
          user: {
            email: this.currentPessoa.email.email
          }
        };
        logsService.createNewLog(logUser, '', 'Atualizar Benefício', '', this.benefit);

        await benefitsService.updateBenefit(this.benefitId, this.benefit);

        this.$refs.topProgress.done();

        this.$router.push('/benefits');

        this.$notify({
          component: UpdatedBenefitNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        console.log(error);
        this.$refs.topProgress.done();
        this.$notify({
          component: UpdatedBenefitErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.benefit = {};
    },
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today
    },
  },
  watch: {
    data() {
      this.isFormValid();
    }
  }
};
</script>

<template>
  <div class="add-user">
    <card>
      <form @submit.prevent="addNewBenefit()">
        <h5 slot="header" class="title">
          <router-link to="/benefits" class="fa fa-arrow-left"></router-link>
          Atualizar benefício
        </h5>
        <span><i><b>Atenção: preencha todos os campos para enviar para aprovação.</b></i></span>

        <div class="row mt-2">
          <div class="col-md-4 pr-md-1 mb-2">
            <base-input
              label="Nome*"
              placeholder="Nome"
              v-model="benefit.name"
            ></base-input>
            <div class="error" v-if="!$v.benefit.name.valid">
              Informe um nome válido.
            </div>
          </div>

          <div class="col-md-4 pl-md-1">
            <base-input
              label="Características*"
              placeholder="Características"
              v-model="benefit.characteristics"
            ></base-input>
            <div class="error" v-if="!$v.benefit.characteristics.valid">
              Informe uma Característica válida.
            </div>
          </div>

          <div class="col-md-4 pl-md-1">
            <base-input
              label="Valor do desconto*"
              v-model="benefit.discount_value"
              placeholder="Valor do desconto"
            ></base-input>
            <div class="error" v-if="!$v.benefit.discount_value.valid">
              Informe um desconto válido.
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 pr-md-1 mb-2">
            <label for="">Data de expiração</label>
            <date-picker
              v-model="benefit.expiration_date"
              valueType="format"
              format="DD/MM/YYYY"
              :disabled-date="disabledBeforeToday"
            ></date-picker>
            <div class="error mt-2" v-if="!$v.benefit.expiration_date.valid">
              Informe uma data de expiração.
            </div>
          </div>

          <div class="col-md-4 pl-md-1">
            <base-input
              label="URL do parceiro*"
              placeholder="URL do parceiro"
              v-model="benefit.url_partner"
            ></base-input>
            <div class="error" v-if="!$v.benefit.url_partner.valid">
              Informe uma URL válida.
            </div>
          </div>

          <div class="col-md-4 pl-md-1">
            <label for="">Selecione o nível da conquista*</label>
            <select class="form-control" v-model="benefit.achievement_level">
              <option
                v-for="(nivel, index) in [1, 2, 3, 4]"
                :value="nivel"
                :key="index"
              >
                {{ nivel }}
              </option>
            </select>
            <div class="error mt-2" v-if="!$v.benefit.achievement_level.valid">
              Informe um nível válido.
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-4 pr-md-1 mb-2">
            <base-input
              label="Status"
              :value="this.benefit.status"
              :disabled="true"
            ></base-input>
          </div>

          <div class="col-md-4 pl-md-1">
            <base-input
              label="Criado por"
              :value="this.benefit.created_by"
              :disabled="true"
            ></base-input>
          </div>

          <div class="col-md-4 pl-md-1">
            <base-input
              label="Aprovador por"
              :value="this.benefit.approved_by || 'Não aprovado'"
              :disabled="true"
            ></base-input>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-4 pr-md-1 mb-2">
            <base-input
              label="Data criação"
              :value="this.formattedDate"
              :disabled="true"
            ></base-input>
          </div>
        </div>

        <button type="submit" class="btn btn-sm mr-4">Salvar criação</button>
        <button type="submit" class="btn btn-sm" :disabled="isFormValid" @click="benefit.status=`Aguardando aprovação`">Enviar para aprovação</button>
      </form>
    </card>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
