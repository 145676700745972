<template>
  <div class="ao-vivo">
    <div class="row padding-bottom">
        <div class="col-md-12">
          <h3 class="noticias-big-title">Ao vivo agora</h3>
        </div>       
    </div>
    <div class="row">
      <div class="col-md-12">
        <label v-if="aoVivo.length == 0" class="no-padding more">Não existem lives no momento. Em breve novidades!</label>
        <div class="noticias-ao-vivo" v-for="item in aoVivo" v-bind:key="item">
          <label>video - 1</label>
        </div>
      </div>
    </div>
  </div>
</template>

  <script>
  export default {
    name: 'noticias-mercado-ao-vivo',
    props: {
    },
    data() {
      return {
        aoVivo: []
      }
    }
  };
  </script>

<style>

.ao-vivo .more {
  font-size: 0.9rem!important;
  color: darkblue;
}

.ao-vivo .noticias-big-title {
  font-weight: bold;
  color: darkblue;
  font-size: 1rem;
  margin-bottom: 0px!important;
}

.ao-vivo .noticias-ao-vivo {
  padding: 10px;
  width: 100%;
  min-height: 280px;
  background-color: cadetblue;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ao-vivo .noticias-ao-vivo label {
  font-size: 1rem;
}

.ao-vivo .padding-bottom {
  padding-bottom: 20px;
}
  
</style>
  