<script>
import { surveyArray } from '@/functions/survey.js';
import { vueTopprogress } from 'vue-top-progress';
import { DoubleBounce } from 'vue-loading-spinner';
import moment from 'moment-timezone';
import brokersService from '@/services/Brokers/brokersService';
import brokersHistoryService from '@/services/Brokers/brokersHistoryService';

export default {
  components: {
    vueTopprogress,
    DoubleBounce,
  },
  data() {
    return {
      started: false,
      questions: surveyArray,
      is_survey_open: false,
      survey: {
        date: "",
        answers: []
      },
      initialLoading: true,
      loading: false,
      readOnly: false,
      viewingUserAnswers: false,
    }
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
    customStyle() {
      if (!this.started) {
        return {
          justifyContent: 'center'
        };
      } else {
        return {
          justifyContent: 'space-between'
        }
      }
    },
    progress() {
      return Math.floor((this.questions.filter(i => i.selected !== null).length / this.questions.length) * 100);
    },
    unansweredQuestions() {
      return this.questions.some(question => question.selected === null);
    },
    userClaim() {
      const Claim = this.$store.getters.userClaim;

      return Claim.role;
    },
  },
  validations: {
    survey: {
      date: {
        valid: (date) => nonEmpty(date),
      },
    },
  },
  async mounted() {
    if (this.$route.params.id && this.userClaim === 'master' || this.userClaim === 'gestor') {
      const broker = await brokersService.getBroker(this.$route.params.id);
      if (broker.survey) {
        this.survey = broker.survey;
        this.questions = this.questions.map(question => {
          const questionRecord = broker.survey.answers.find(i => i.id === question.id);
          if (questionRecord) {
            question.selected = questionRecord.selected;
          }
          return question;
        });

        this.readOnly = true;
        this.viewingUserAnswers = true;
        this.started = true;
      } else {
        this.$router.push('brokers');
      }
    } else {
      const broker = await brokersService.getBrokerByEmail(this.currentPessoa.email.email);
      if (broker.admission_date) {
        const present = moment();
        if (broker.survey) {
          const surveyLastTime = moment(broker.survey.date, "DD/MM/YYYY");
          const dateDiff = present.diff(surveyLastTime, 'days');
          
          // 3 meses
          if (dateDiff >= 90) {
            this.is_survey_open = true;
          } else {
            this.readOnly = true;
            this.survey = broker.survey;

            this.questions = this.questions.map(question => {
              const questionRecord = broker.survey.answers.find(i => i.id === question.id);
              if (questionRecord) {
                question.selected = questionRecord.selected;
              }
              return question;
            });
          }
        } else {
          const admissionDate = moment(broker.admission_date, "DD/MM/YYYY");
          const dateDiff = present.diff(admissionDate, 'days');

          // 1 mes          
          if (dateDiff >= 30) {
            this.is_survey_open = true;
          }
        }
      }
    };

    this.initialLoading = false;
  },
  methods: {
    goBack() {
      this.started = false;

      if (this.viewingUserAnswers) {
        this.$router.back();
      }
    },
    async handleSurvey() {
      if (!this.started) {
        this.started = true;
        return;
      }

      if (!this.unansweredQuestions) {
        this.loading = true;

        const date = moment().tz('America/Sao_Paulo').format('DD-MM-YYYY HH:mm');
        const answers = this.questions.reduce(function (result, question) {
          if (question.selected !== null) {
            return result.concat({ id: question.id, selected: question.selected });
          }
          return result;
        }, []);

        this.survey = {
          answers,
          date
        };

        await brokersService.updateBrokerByEmail({ survey: this.survey }, this.currentPessoa.email.email);

        const brokerHistory = await brokersHistoryService.getBrokerHistory(this.currentPessoa.email.email);

        if (brokerHistory) {
          await brokersHistoryService.updateBrokerSurveyHistory(
            { survey: [...brokerHistory.survey, { answers, date }] },
            this.currentPessoa.email.email
          );
        } else {
          const history = {
            broker_id: this.currentPessoa.pessoa_id,
            email: this.currentPessoa.email.email,
            survey: [{ answers, date }]
          };
          await brokersHistoryService.createBrokerHistory(history);
        }

        this.$notify({
          title: 'Obrigado!',
          message: 'Pesquisa enviada com sucesso',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        this.started = false;
        this.readOnly = true;
        this.loading = false;
      }
    }
  }
}

</script>

<template>
  <div class="add-user">
    <card>
      <form @submit.prevent="handleSurvey()">
        <div class="col" v-if="!started && this.userClaim === 'assessor'">
          <h1>Olá, {{ currentPessoa.nome }}!</h1>
          <p>Seja bem-vindo à <strong>Pesquisa da AJX Capital.</strong></p>
          <p>Agradecemos por dedicar seu tempo para participar de nossa Pesquisa de Experiência do Usuário na plataforma.
          </p><br>
          <p>Seus insights são fundamentais para nossa constante busca por melhorias e aprimoramentos.<br></p>
          <p>Por favor, responda às seguintes perguntas com honestidade, com base em sua experiência ao utilizar nossa
            plataforma.<br></p>
          <p>Suas respostas são confidenciais e anônimas. Não há respostas certas ou erradas, apenas queremos entender
            melhor suas opiniões e percepções. A pesquisa consiste em sete breves perguntas e deve levar apenas alguns
            minutos para ser concluída.<br></p>
          <p>Agradecemos sua contribuição para tornar a plataforma da AJX ainda melhor. Vamos começar!</p><br>
          <p>Atenciosamente,</p>
          <p>Equipe da AJX Capital</p>
        </div>

        <div class="survey" v-if="started">
          <span v-if="readOnly && survey.date">Pesquisa realizada em {{ this.survey.date }}</span>
          <div v-bind:key="question.id" v-for="question in questions">
            <strong>{{ question.id }}. {{ question.question }}</strong>
            <div v-bind:key="idx" v-for="(option, idx) in question.options" class="survey-option">
              <input type="radio" v-model="question.selected" v-bind:value="option.value"
                v-bind:id="'surv_' + question.id + '_' + idx" :disabled="readOnly">
              <label v-bind:for="'surv_' + question.id + '_' + idx" class="radio-label">
                {{ option.label }}
              </label>
            </div>
          </div>

          <vue-ellipse-progress :progress="progress" :size="40" class="progress-indicator" v-if="!viewingUserAnswers">
            <span slot="legend-value">%</span>
          </vue-ellipse-progress>
        </div>

        <div class="buttons-container mt-3" :style="customStyle" v-if="!initialLoading">
          <button type="button" class="btn btn-sm ml-0" @click="goBack()" v-if="started" :disabled="loading">Voltar</button>
          <div v-if="!viewingUserAnswers">
            <button type="submit" class="btn btn-sm ml-0 " v-if="readOnly && !started">
              Verificar Ultimo Envio
            </button>
            <button type="submit" class="btn btn-sm ml-0" v-else-if="!started" :disabled="!is_survey_open">
              Iniciar
            </button>
            <button type="submit" class="btn btn-sm ml-0" v-else :disabled="unansweredQuestions || readOnly || loading">
              Enviar
            </button>
          </div>
        </div>
      </form>
    </card>
  </div>
</template>

<style>
form {
  position: relative;
}

.survey {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 1rem;
}

.survey-option {
  display: flex;
  column-gap: 0.5rem;
  margin-left: 1rem;
}

.radio-label {
  margin: 0 !important;
}

.progress-indicator {
  position: absolute;
  right: 0;
  top: 0;
}

.buttons-container {
  display: flex;
  width: 100%;
  align-items: flex-end;
  column-gap: 12px;
}
</style>

