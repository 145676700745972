<script>
import invoicesService from '@/services/Invoices/invoicesService';
import SuccessNotification from '@/components/Notifications/General/SuccessNotification';
import ErrorRegisterNotification from '@/components/Notifications/General/ErrorRegisterNotification';
import {vueTopprogress} from 'vue-top-progress';
import logsService from '@/services/Logs/logsService';
import { nonEmpty } from '@/validate/customValidators.js';
import { DoubleBounce } from 'vue-loading-spinner';

export default {
  data() {
    return {
      isLoading: false,
      invoice: {
        date: "",
      },
      invoice_file: {},
      invoice_url: '',
    };
  },
  mounted() {
    this.getInvoice();
  },
  validations: {
    invoice: {
      date: {
        valid: (date) => nonEmpty(date),
      },
    },
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
    isFormValid() {
      return (
        !this.$v.invoice.date.valid
      );
    },
    invoiceId() {
      return this.$route.params.id;
    },
  },
  components: {
    vueTopprogress,
    DoubleBounce,
  },
  methods: {
    async getInvoice() {
      const invoice = await invoicesService.getInvoice(this.invoiceId);
      const invoiceUrl = await invoicesService.getDownloadURL(invoice.filename);
      this.invoice = invoice;
      this.invoice_url = invoiceUrl;
    },
    async updateInvoice() {
      this.isLoading = true;
      this.$refs.topProgress.start();

      try {
        const logUser = {
          user: {
            email: this.currentPessoa.email.email
          }
        };
        logsService.createNewLog(logUser, '', 'Editar Nota fiscal', '', this.invoice);

        if (this.invoice_file) {
          await invoicesService.uploadInvoiceToStorage(this.invoice_file, this.invoice.filename);
          this.invoice.filename = this.invoice_file.name;
        }

        await invoicesService.updateInvoice(this.invoiceId, this.invoice);

        this.$refs.topProgress.done();

        this.$router.push('/invoices');

        this.$notify({
          component: SuccessNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        console.log(error);
        this.$refs.topProgress.done();
        this.$notify({
          component: ErrorRegisterNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      } finally {
        this.isLoading = false;
      }
    },
    handleFileUpload(event){
      const file = event.target.files[0]
      const dividedName = file.name.split(".");
      const extension = dividedName.pop();
      const newName = Date.now();
      const newFile = new File([file], `${newName}.${extension}`, { type: file.type });
      this.invoice_file = newFile;
    },
  },
  watch: {
    data() {
      this.isFormValid()
    }
  }
};
</script>

<template>
  <div class="add-user">
    <card>
      <form @submit.prevent="updateInvoice()">
        <h5 slot="header" class="title">
          <router-link to="/invoices" class="fa fa-arrow-left"></router-link>
          Atualizar Nota Fiscal
        </h5>

        <div class="row">
          <div class="col-md-4 pr-md-1 mb-2">
            <base-input label="Competência">
              <date-picker
                v-model="invoice.date"
                type="month"
                valueType="format"
                format="MM/YYYY"
              >
              </date-picker>
            </base-input>
          </div>

          <div class="col-md-12">
            <label>Nota fiscal (somente pdf)
              <input 
              type="file" 
              class="form-control" 
              accept=".pdf"
              @change="handleFileUpload( $event )"
              />
            </label>
          </div>
        </div>
        
        <button type="button" class="btn btn-sm mr-4"><a :href="this.invoice_url" target="_blank" class="link">Visualizar PDF: {{ this.invoice.filename || "" }}</a></button>
        <button type="submit" class="btn btn-sm" :disabled="isFormValid || isLoading">Atualizar</button>
      </form>
    </card>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>

<style>
  .link {
    color: #ffffff;
    padding: 20px 0;
    text-decoration: none;
  }

  .link:hover, a:focus {
    color: #ffffff;
  }
</style>