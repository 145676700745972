<script>
import Vue from 'vue';
import Cookies from 'js-cookie';
import Password from 'vue-password-strength-meter';
import { vueTopprogress } from 'vue-top-progress';

import { email } from 'vuelidate/lib/validators';
import ProcessError from '@/components/Notifications/Login/ProcessError';
import hubspotService from '@/services/HubSpot/hubspotService';
import { api } from '@/services/ServiceConfig/ServiceConfig.js';

import store from '@/store/index';
import HelperPlugin from '@/plugins/HelperPlugin';
import { CWLRedirectRef, CWLStatusInvestidor } from '@/store/cwl_enums';
import pessoasService from '@/services/Pessoas/pessoasService';

Vue.use(HelperPlugin);

export default {
  components: {
    vueTopprogress,
    Password,
  },
  data() {
    return {
      modalidade: process.env.VUE_APP_TYPE,
      submodalidade: process.env.VUE_APP_SUBTYPE,
      user: {},
      envPath: process.env.VUE_APP_ROOT_SITE,
    };
  },
  validations: {
    user: {
      email: {
        valid: email,
      },
    },
  },
  mounted() {
    try {
      const user = this.$store.getters.getPessoa;
      if (user && user.pessoa_id) {
        this.$router.push('/dashboard');
      }
    } catch (e) {}
  },
  computed: {
    appTitle() {
      return process.env.VUE_APP_TITLE;
    },
    registerUrl() {
      const redirect = this.$route.query.redirect
        ? 'redirect=' + this.$route.query.redirect
        : '';

      const value = '/register?' + redirect;
      return value;
    },
    loggedInUser() {
      return this.$store.getters.isLoggedIn;
    },
    investorLogged() {
      return this.$store.getters.isLoggedInvestor;
    },
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
    ref() {
      return this.$route.params.ref;
    },
  },
  methods: {
    async redirectTo() {
      const ref = this.$route.params.ref || this.$route.query.ref;
      const id =
        this.$route.query.id || this.$store.getters.paymentCheckout.financingId;
      if (ref === 'site' || id) {
        const siteUrl = process.env.VUE_APP_FINANCING_PAGE.replace('{id}', id);
        window.top.location.href = siteUrl;
      } else if (ref == CWLRedirectRef.siteCriarCampanha) {
        this.$router.push('/add-financing');
        await hubspotService.atualizarSessaoContatoAPI(
          this.currentPessoa.pessoa_id,
        );
      } else if (this.$route.query.redirect) {
        if (this.currentPessoa.caracteristicas.includes('assessor')) {
          this.$router.push('/market-news');
        } else {
          this.$router.push(this.$route.query.redirect);
        }
      } else {
        if (
          this.currentPessoa.dados_complementares.approvalStatus !=
            CWLStatusInvestidor.Aprovado &&
          this.currentPessoa.dados_complementares.approvalStatus !=
            CWLStatusInvestidor.EmAnalise
        ) {
          if (this.currentPessoa.caracteristicas.includes('assessor')) {
            this.$router.push('/market-news');
          } else {
            this.$router.push('/profile');
          }
          await hubspotService.atualizarSessaoContatoAPI(
            this.currentPessoa.pessoa_id,
          );
        } else {
          if (this.currentPessoa.caracteristicas.includes('assessor')) {
            this.$router.push('/market-news');
          } else {
            this.$router.push('/dashboard');
          }
          await hubspotService.atualizarSessaoContatoAPI(
            this.currentPessoa.pessoa_id,
          );
        }
      }
    },
    async loginWithCredentials() {
      this.$refs.topProgress.start();

      const emailToLoggin = this.user.email.toLowerCase();

      try {
        const { data } = await api.post('/signin', {
          email: emailToLoggin,
          password: this.user.password,
        });

        if (data.status) {
          this.$notify({
            message: data.message,
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 3000,
          });
          this.$refs.topProgress.done();
          return;
        }

        Cookies.set('accessToken', data.token, 3600);
        store.commit('SET_TOKEN', { token: data.token });

        const pessoa = await pessoasService.getPessoa();

        this.handleLogin(data.user, pessoa);
      } catch (error) {
        if (error.response && error.response.status === 429) {
          this.$notify({
            message: 'Muitas tentativas de login. Tente novamente mais tarde.',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 3000,
          });
          this.$refs.topProgress.done();
        }
      }
    },
    async handleLogin(authUser, pessoa) {
      try {
        this.$store.dispatch('login', { user: authUser });
        const objCooke = this.$CWLCreatePessoaForCookie(pessoa, pessoa.broker);
        this.$store.commit('SET_PESSOA', objCooke);
        this.$store.commit('SET_CLAIM', { role: pessoa.user.role });

        if (pessoa.user.role === 'investor') {
          store.commit(
            'SET_TERM_OF_USE_ACCEPTED',
            pessoa.dados_complementares.termsOfUseAccepted,
          );
        }

        const title = 'Olá ' + pessoa.nome + '!';
        this.$notify({
          title: title,
          message: 'Bem vindo de volta!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
        this.redirectTo();
      } catch (error) {
        this.$refs.topProgress.done();

        this.$notify({
          component: ProcessError,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
    navigateToSite() {
      window.top.location.href = this.envPath;
    },
  },
};
</script>

<template>
  <div class="sign-in">
    <div class="signin2"></div>
    <div class="card">
      <div class="back" style="margin-top: -30px !important">
        <i
          class="fa fa-arrow-left"
          style="color: #fff"
          @click="navigateToSite()"
        ></i>
      </div>

      <img
        src="@/assets/images/logo_ajx.png"
        alt="Customer Logo"
        class="brand"
        style="width: 200px !important"
      />

      <h1
        v-if="modalidade == 'doacao'"
        style="
          font-size: 28px;
          color: #000;
          font-family: Poppins, Arial, Helvetica, sans-serif !important;
          font-weight: bold;
        "
      >
        {{ this.appTitle }}
      </h1>

      <form @submit.prevent="loginWithCredentials" class="login-form">
        <div class="form-group">
          <label for="" class="control-label">Email</label>
          <input
            v-model="user.email"
            data-testId="email"
            type="email"
            class="Password__field"
            placeholder="email@example.com"
            required
          />
          <div class="error" v-if="user.email != '' && !$v.user.email.valid">
            Informe um e-mail válido.
          </div>
        </div>

        <div class="form-group">
          <label for="" class="control-label">Senha</label>
          <password
            v-model="user.password"
            :secureLength="6"
            :badge="false"
            :showStrengthMeter="false"
            :toggle="true"
            required
          />
        </div>

        <div class="form-group sign-in-row">
          <label for="" class="control-label">
            Não tem uma conta?
            <a :href="registerUrl" class="create-account">Criar</a>
          </label>

          <label for="" class="forgot-password-link">
            <router-link to="/forgot-password">
              Esqueci minha senha
            </router-link>
          </label>
        </div>

        <button
          type="submit"
          class="btn btn-primary"
          style="
            background-color: #79c5f2 !important;
            color: #12100b !important;
          "
        >
          Entrar
        </button>
      </form>
    </div>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>

<style scoped>
/*   AJX CSS  */

.sign-in {
  background-color: #12100b !important;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/ecwl-ajx-stage.appspot.com/o/sign-in-bg.png?alt=media&token=29e5e8a5-1b8d-4e5a-8725-bdce02a7e5f1);
  background-repeat: no-repeat;
  background-size: cover;
}

.signin2 {
  background-color: #000 !important;
  opacity: 0.2;
  width: 100%;
  position: absolute;
  height: 100vh;
}

.sign-in form > button.btn {
  background: #0006b4 !important;
  color: #e4e6ed !important;
}

.sign-in form > button.btn:hover {
  background: #00035a !important;
}

.sign-in > .card {
  background-color: #333;
  border-radius: 0px;
  border-color: #000 !important;
  border-width: 1px !important;
  border: outset;
  box-shadow: rgb(0 0 0 / 90%) 0px 0px 30px;
}

.sign-in h1#logo {
  /* padding: 40px 0 0 0; */
  overflow: hidden;
  /* background-image: url(https://stage.ajxcapital.com.br/wp-content/uploads/2022/02/ajx-capital-1.svg); */
  background-repeat: no-repeat;
  height: 0px !important;
  /* height: 70px; */
  background-position: center;
  background-color: #333;
}

.form-control {
  border-color: #fff !important;
}

.control-label {
  color: #fff;
}

div .error {
  color: #f00;
}

.forgot-password-link > a {
  color: #00f !important;
}

p {
  color: #fff;
}
</style>
