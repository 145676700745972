import firebase from 'firebase';
import { withLog } from '../../logger/with_log';

import { api } from '@/services/ServiceConfig/ServiceConfig';

export default {
  segmentRef() {
    return firebase.firestore().collection('segment-interest');
  },

  async getAllSegments() {
    const { data } = await api.get('/segments');

    return data.segments;
  },

  createNewSegment(segment) {
    withLog(() => this.segmentRef().add(segment));
  },

  deleteSegment(bank) {
    withLog(() => this.segmentRef().doc(bank).delete());
  },

  getSegment(id) {
    return withLog(() => this.segmentRef().doc(id).get());
  },

  updateSegment(id, segment) {
    return withLog(() => this.segmentRef().doc(id).update(segment));
  },
};
