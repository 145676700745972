<script>
import { vueTopprogress } from 'vue-top-progress';
import { DoubleBounce } from 'vue-loading-spinner';
import brokersService from '@/services/Brokers/brokersService';


export default {
  components: {
    vueTopprogress,
    DoubleBounce,
  },
  data() {
    return {
      isLoading: true,
      sheet: null,
    };
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
  },
  mounted() {
    this.getBrokerSheet();
  },
  methods: {
    async getBrokerSheet() {
      this.$refs.topProgress.start();
      try {
        const sheet = await brokersService.getBrokerSheet(this.currentPessoa.email.email);

        if (sheet) {
          this.sheet = sheet;
        }
      } catch(err) {
        console.log(err);
      } finally {
        this.isLoading = false;
        this.$refs.topProgress.done();
      }
    },
  },

};
</script>

<template>
  <div class="users-list">
    <div class="row">
      <div class="col-12">
        <card
          :title="`Google sheets`"
          :addItems="false"
          v-if="!this.isLoading"
        >
          <button
            type="button"
            class="btn btn-sm mr-4 py-2"
            v-if="this.sheet"
          >
            <a
              :href="this.sheet.worksheet.url"
              target="_blank"
              class="link"
            >
              Visualizar Google Sheet
            </a>
          </button>
          <!-- <button
            type="button"
            class="btn btn-sm mr-4 py-2"
            @click="migrateToProd"
            :disabled="isLoading"
          >
            Migrar banco
          </button> -->
          <h2 v-if="!this.sheet">Não há documentos vinculado a sua conta.</h2>
        </card>
      </div>
    </div>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>

<style>
  .link {
    color: #ffffff;
    padding: 20px 0;
    text-decoration: none;
  }

  .link:hover, a:focus {
    color: #ffffff;
  }
</style>
