<script>
import ConfirmationModal from '@/components/ConfirmationModal';

export default {
  name: 'brokers-table',
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: 'Table columns',
    },
    data: {
      type: Array,
      default: () => [],
      description: 'Table data',
    },
    type: {
      type: String,
      default: '',
      description: 'Whether table is striped or hover type',
    },
    theadClasses: {
      type: String,
      default: '',
      description: '<thead> css classes',
    },
    tbodyClasses: {
      type: String,
      default: '',
      description: '<tbody> css classes',
    },
    hasActions: {
      type: Boolean,
      default: false,
    },
    selectLeader: {
      type: Boolean,
      default: false,
    },
    isDownloadable: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ConfirmationModal,
  },
  data() {
    return {
      page: 1,
      perPage: 10,
      pages: [],
      showModal: false,
      preDeletedItem: {},
      searchBar: ''
    };
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
    filteredData() {
      this.setPages();
      return this.paginate(this.data.filter(obj => {
        return (
          (obj.name ? obj.name : '').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(this.searchBar.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) ||
          (obj.email ? obj.email : '').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(this.searchBar.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) ||
          (obj.cpf ? obj.cpf : '').replace('.', '').replace('-', '').includes(this.searchBar.replace('.', '').replace('-', ''))
        );
      }))
    }
  },
  methods: {
    hasValue(item, column) {
      return item[column.toLowerCase()] !== 'undefined';
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    setPages() {
      if (this.pages) {
        this.pages = [];
      }

      const numberOfPages = Math.ceil(this.data.length / this.perPage);

      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      const page = this.page;
      const perPage = this.perPage;

      const from = (page * perPage) - perPage;
      const to = (page * perPage);

      return data.slice(from, to);
    },
    preDeleteItem(item, index) {
      this.preDeletedItem = {
        item,
        index,
      };

      this.showModal = true;
    },
    deleteItem() {
      this.$emit('deleteItem', this.preDeletedItem);

      this.showModal = false;
    },
    downloadItem(user) {
      this.$emit('downloadItem', user);
    },
    addLeader(leader) {
      this.$emit('addLeader', leader);
    }
  },
  watch: {
    data() {
      this.setPages();
    },
  },
  directives: {
    tooltip: {
      bind: function (el) {
        el.addEventListener('mouseover', function (evt) {
          let targetEl = evt.target;
          if (targetEl.offsetWidth < targetEl.scrollWidth) {
            targetEl.setAttribute('title', evt.target.textContent);
          } else {
            targetEl.hasAttribute('title') && targetEl.removeAttribute('title');
          }
        });
      }
    }
  }
};
</script>

<template>
  <div>
    <div class="form-group">
      <label class="control-label"
        v-tooltip="'Você pode pesquisar digitando no campo de busca abaixo. A pesquisa pode ser feita pelo nome, email e cpf.'">
        Pesquisar
      </label>
      <input type="text" class="form-control" placeholder="Para pesquisar, digite sua busca aqui..."
        v-model="searchBar" />
    </div>
    <table class="table table-hover" :class="tableClass">
      <thead class="thead-light" :class="theadClasses">
        <tr>
          <th v-for="column in columns" :key="column">{{ column }}</th>
        </tr>
      </thead>
      <tbody :class="tbodyClasses">
        <tr v-for="(item, index) in filteredData" :key="index">
          <td class="min">
            <span class="ellipsis" v-tooltip>
              {{ item.name }}
            </span>
          </td>
          <td v-if="!selectLeader">
            <span class="ellipsis" v-tooltip>
              {{ item.email }}
            </span>
          </td>
          <td v-if="!selectLeader" class="min">{{ item.cpf }}</td>
          <td v-if="!selectLeader">
            <span class="ellipsis" v-tooltip>
              {{ item.chave_pix }}
            </span>
          </td>
          <td v-if="!selectLeader">
            <span class="ellipsis" v-tooltip>
              {{ item.hubspot_id }}
            </span>
          </td>
          <td v-if="!selectLeader" class="min">{{ item.status }}</td>
          <td v-if="hasActions" class="min">
            <div class="icons">
              <router-link :to="`edit-broker/${item.id}`" title="Ver Assessor" class="button-action">
                <i class="tim-icons icon-paper"></i>
              </router-link>
              <a @click="preDeleteItem(item, index)" href="#" style="color: red" title="Excluir Assessor"
                class="button-action">
                <i class="tim-icons icon-trash-simple"></i>
              </a>
            </div>
          </td>

          <td v-if="selectLeader" class="text-center" @click="() => addLeader(item)">
            <button type="button" class="btn btn-sm">Selecionar</button>
          </td>
        </tr>
      </tbody>
    </table>

    <nav class="table-pagination">
      <ul class="pagination">
        <li class="page-item">
          <button type="button" class="page-link" v-if="page != 1" @click="page--">
            <i class="fas fa-angle-left"></i>
          </button>
        </li>

        <li class="page-item">
          <button type="button" class="page-link" v-for="(pageNumber, index) in pages.slice(page - 1, page + 4)"
            :key="index" @click="page = pageNumber" :class="{ 'is-active': page === pageNumber }">
            {{ pageNumber }}
          </button>
        </li>

        <li class="page-item">
          <button type="button" class="page-link" v-if="page < pages.length" @click="page++">
            <i class="fas fa-angle-right"></i>
          </button>
        </li>
      </ul>
    </nav>

    <confirmation-modal :show="showModal" @close="showModal = false" @confirmDelete="deleteItem()"></confirmation-modal>
  </div>
</template>

<style>
td {
  width: auto;
}

td.min {
  width: 1%;
  white-space: nowrap;
}

tr {
  display: table-row !important;
}

tbody {
  display: table-row-group !important;
}

.ellipsis {
  max-width: 20vw;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
}

.control-label {
  cursor: default;
}

.icons {
  display: flex;
  justify-content: space-around;
}
</style>