export const surveyArray = [
  { 
    id: 1, 
    question: "Como você avalia a plataforma AJX Educacional?", 
    options: [
      { label: "Ruim", value: 0 }, 
      { label: "Bom", value: 1 }, 
      { label: "Excelente", value: 2 }
    ],
    selected: null
  },
  { 
    id: 2, 
    question: "Que nota você dá ao Processo de imersão à cultura do ecossistema AJX?", 
    options: [
      { label: "Muito Ruim", value: 0 }, 
      { label: "Ruim", value: 1 }, 
      { label: "Regular", value: 2 }, 
      { label: "Bom", value: 3 }, 
      { label: "Muito Bom", value: 4 }
    ],
    selected: null
  },
  { 
    id: 3, 
    question: "Como você avalia seu primeiro mês após recebidos os leads?", 
    options: [
      { label: "Muito Ruim", value: 0 }, 
      { label: "Ruim", value: 1 }, 
      { label: "Regular", value: 2 }, 
      { label: "Bom", value: 3 }, 
      { label: "Muito Bom", value: 4 }
    ],
    selected: null
  },
  { 
    id: 4, 
    question: "Qual sua nota em relação ao seu líder, nos temas de acolhimento, senso de pertencimento do líder sobre a equipe e nível de entrega pedagógica?", 
    options: [
      { label: "Muito Ruim", value: 0 }, 
      { label: "Ruim", value: 1 }, 
      { label: "Regular", value: 2 }, 
      { label: "Bom", value: 3 }, 
      { label: "Muito Bom", value: 4 }
    ],
    selected: null
  },
  { 
    id: 5, 
    question: "As pessoas que você ama tem real percepção em relação ao local do qual você trabalha? E sobre seus resultados obtidos?", 
    options: [
      { label: "Sim", value: 0 },
      { label: "Não", value: 1 }
    ],
    selected: null
  },
  { 
    id: 6, 
    question: "Seu sonho, antes de entrar na AJX... já foi realizado?", 
    options: [
      { label: "Sim", value: 0 },
      { label: "Não", value: 1 }
    ],
    selected: null
  },
  { 
    id: 7, 
    question: "E agora, você tem um novo sonho?", 
    options: [
      { label: "Sim", value: 0 },
      { label: "Não", value: 1 }
    ],
    selected: null
  }
];
