<script>

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import { quillEditor } from 'vue-quill-editor';

import VideoContentTable from '@/components/VIdeoContent/VideoContentTable.vue';
import videoService from '@/services/VideoContent/videoContentService';
import azureIntegrations from '@/services/VideoContent/azureIntegrations';

import {vueTopprogress} from 'vue-top-progress';
import {DoubleBounce} from 'vue-loading-spinner';
import playlistService from '@/services/VideoContent/playlistService';

const tableColumns = ['Nome'];

export default {
  components: {
    VideoContentTable,
    vueTopprogress,
    DoubleBounce,
    quillEditor,
  },
  data() {
    return {
      table: {
        title: 'Videos',
        columns: [...tableColumns],
      },
      isLoading: true,
      videos: []
    };
  },
  mounted() {
    this.getVideos();
    this.listAllVideos();

  },
  methods: {
    async listAllVideos() {
      const videos = await azureIntegrations.listAll();
    },
    async getVideos() {
      try {
        this.videos = await videoService.getAll();

        for(const video of this.videos) {
          if(video.publish_date) {
            const validateDate = this.$moment(video.publish_date, 'DD/MM/YYYY',true).isValid();
            video.publish_date = validateDate ? video.publish_date : this.$moment(String(video.publish_date)).format('DD/MM/YYYY')
          }
          if(video.live_date) {
            video.live_date = this.$moment(String(video.live_date)).format('DD/MM/YYYY')
          }

          if(video.thumbnail) {
            let thumnailDetails = JSON.parse(video.thumbnail);
            let url = await videoService.urlThumbnail(thumnailDetails.name);
            
            Object.assign(video, { publicThumb: url })
          }
        }
        
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async deleteVideo(preDeletedItem) {
      try {
        await videoService.delete(preDeletedItem.item.id);
        await playlistService.removeVideoFromPlaylist(preDeletedItem);

        this.getVideos();

        this.$notify({
          title: 'Ok',
          message: 'Video foi removido com sucesso!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        preDeletedItem = {};
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: error.message,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<template>
  <div class="users-list">
    <div class="row">
      <div class="col-12">
        <card
        :title="table.title"
        :addItems="true"
        :itemType="'Video'"
        :routerType="'video-content'">

          <div class="table-responsive">
            <video-content-table
              :data="videos"
              :columns="table.columns"
              :hasActions="false"
              thead-classes="text-primary"
              @deleteItem="deleteVideo"
            ></video-content-table>

            <double-bounce v-if="isLoading"></double-bounce>
          </div>

        </card>
      </div>
    </div>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
