import axios from 'axios';
import utils from './utils';
import { withLog } from '../../logger/with_log';
import firebase from 'firebase';
import financingService from '@/services/Financing/financingService';
import { api } from '@/services/ServiceConfig/ServiceConfig';

export default {
  orderRef() {
    return firebase.firestore().collection('orders');
  },

  async getOrdersByFieldEqualsTo(field, value) {
    try {
      const snapshot = await this.orderRef().where(field, '==', value).get();
      if (snapshot.empty) {
        return [];
      } else {
        return snapshot.docs.map((value) => ({
          id: value.id,
          ...value.data(),
        }));
      }
    } catch (error) {
      console.error(
        'get orders by ' + field + ' == ' + value + ' error',
        error,
      );
      return [];
    }
  },

  async getOrdersByUser() {
    const { data } = await api.get('/orders');

    return data.orders;
  },

  async getOrdersByFinancingId(id) {
    return this.getOrdersByFieldEqualsTo('financing_id', id);
  },

  async update(id, order) {
    return withLog(() => this.orderRef().doc(id).update(order));
  },

  async regenerateOrderContract(order) {
    return withLog(async () => {
      const endpoint = process.env.VUE_APP_ENDPOINT_WS + 'currentTerms';

      const response = await axios.post(endpoint, {
        orderId: order.id,
        financingId: order.financing_id,
        userId: order.pessoa.id,
      });
    });
  },

  async get(id) {
    return withLog(() => this.orderRef().doc(id).get());
  },

  getAllDocs(params) {
    return withLog(async () => {
      let response;
      // const snapshot = await this.orderRef().get();
      // return snapshot.docs.map(doc => doc.data());
      const endpoint = process.env.VUE_APP_ENDPOINT_WS + 'getAllOrders';
      // const response = await axios.post(endpoint);
      if (params.brokerName) {
        response = await axios.post(endpoint, {
          brokerName: params.brokerName,
          order_status: params.order_status,
          field_to_filter: params.field_to_filter,
          start_at: params.start_at,
          end_at: params.end_at,
        });
      } else {
        response = await axios.post(endpoint, {
          order_status: params.order_status,
          field_to_filter: params.field_to_filter,
          start_at: params.start_at,
          end_at: params.end_at,
        });
      }
      // console.log(data)
      // console.log('URL', response.data.result)
      const { data } = await axios.get(response.data.result);

      for (const order of data) {
        if (order.financing_details === undefined) {
          const findOneFinancing = await financingService.findById(
            order.financing_id,
          );
          order.financing_details = findOneFinancing;
        }
      }
      // console.log('Dados', data)
      return data;
    });
  },

  async getOrderByUserAndId(pessoaId, id) {
    const orders = await this.getOrdersByUser(pessoaId);
    return await utils.orderById(orders, id);
  },
};
