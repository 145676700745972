import firebase from 'firebase';
import { withLog } from '../../logger/with_log';
import settingsService from '@/services/Settings/settingsService';

export default {
  historyRef() {
    return firebase.firestore().collection('history');
  },

  async findAll() {
    try {
      const snapshot = await this.historyRef().get();
      if (snapshot.empty) {
        return [];
      }

      return snapshot.docs.map((value) => ({
        id: value.id,
        ...value.data(),
      }));
    } catch (error) {
      console.error('history find all error', error);
    }
  },

  getAll() {
    return withLog(() => this.historyRef().get());
  },

  getByContextAndId(context, id) {
    return withLog(() =>
      this.historyRef()
        .where('context', '==', context)
        .where('register_id', '==', id)
        .orderBy('transaction_date', 'asc')
        .get(),
    );
  },

  create(history) {
    try {
      const doc = this.historyRef().doc();
      const value = {
        ...history,
        id: doc.id,
      };
      doc.set(value);
      return value;
    } catch (error) {
      console.error('history service create error', error);
    }
  },

//   async findAccountsById(id) {
//     try {
//       return (await this.financingAccountsRef().doc(id).get()).data();
//     } catch (error) {
//       console.error('find accounts by id error', error);
//     }
//   },

//   async findAccountTokenByIdAndKind(id, kind) {
//     try {
//       if (['beneficiary', 'platform', 'agent'].indexOf(kind) !== -1) {
//         const financingAccounts = await this.findAccountsById(id);
//         return financingAccounts[kind].token;
//       } else if (kind === 'marketplace') {
//         return await settingsService.getMarketplaceToken();
//       }
//     } catch (error) {
//       throw new Error('Não foi possível consultar os dados da conta.');
//     }
//   },

  delete(history) {
    withLog(() => this.historyRef().doc(history).delete());
  },

  async findById(id) {
    try {
      return (await this.historyRef().doc(id).get()).data();
    } catch (error) {
      console.error('history service find by id error', error);
      throw Error('Não foi possível consultar os dados de histórico do registro campanha.');
    }
  },

  get(id) {
    return withLog(() => this.historyRef().doc(id).get());
  },

  update(id, history) {
    return withLog(() => this.historyRef().doc(id).update(history));
  },

};
