import axios from 'axios';
import moment from 'moment';

import attachmentsService from '@/services/Attachments/attachmentsService';

export default {
  async importarDocumentos(objeto) {
    const endpoint =
        process.env.VUE_APP_ENDPOINT_FIDUCIA + 'api/v1/bancarizacao/documentos';
    const financing = objeto.financing;
    const ccb = objeto.order.ccb;
    var operacao = 0;
    if ( ccb.importacao && ccb.importacao.operacao && ccb.importacao.operacao.operacao && ccb.importacao.operacao.operacao.operacao ) {
      operacao = ccb.importacao.operacao.operacao.operacao;
    }

    var payload = {
      tipo_documento: "PDF",
      categoria: "9",
      operacao: operacao,
      base64: "123"
    };

    const authPost = {
      username: process.env.VUE_APP_FIDUCIA_AUTH_USER,
      password: process.env.VUE_APP_FIDUCIA_AUTH_PWD
    }
    const token = `${authPost.username}:${authPost.password}`;
    const encodedToken = Buffer.from(token).toString('base64');
    const headers = {
      'user': process.env.VUE_APP_FIDUCIA_HEADER_USER,
      'Authorization': 'Basic '+ encodedToken,
      'Content-Type': 'application/pdf;base64'
    };
    console.log(payload);

    let filename = ccb.numero_ccb + "_ContratoCCB.pdf";
    return await attachmentsService
      .base64Attachment(filename, 'ContratosCCB', objeto)
      .then(function (base64) {
        payload.base64 = 'data:application/pdf;base64,' + base64;
        console.log(payload);
        try {
          return axios.post(endpoint, payload, { headers: headers }).then(r => r.data);
        } catch (error) {
          console.log(error);
          throw new Error(error.message);
        }
      });
  },

  criarOperacaoCredito(objeto) {
    const endpoint =
        process.env.VUE_APP_ENDPOINT_FIDUCIA + 'api/v1/bancarizacao/importar';
    const financing = objeto.financing;
    const ccb = objeto.order.ccb;
    const empresa = financing.dados_complementares.empresa;

    var simulacao = {};
    var primeiroVencimento = "";
    var numeroCCB = "";
    if ( ccb && ccb.simulacao ) {
      numeroCCB = ccb.numero_ccb;
      simulacao = ccb.simulacao;
      primeiroVencimento = moment(simulacao.payload.primeiro_vencimento, "YYYY-MM-DD").format('YYYY-MM-DD');
    }

    var receitas = "";
    if ( financing.fields["Receita Bruta Último Exercício"] ) {
      receitas = financing.fields["Receita Bruta Último Exercício"].value;
    }
    var socioCpf = "";
    if ( financing.fields["socio_cpf"] ) {
      socioCpf = financing.fields["socio_cpf"].value;
    }
    var socioNome = "";
    if ( financing.fields["socio_nome"] ) {
      socioNome = financing.fields["socio_nome"].value;
    }

    var dadosBancarios = {};
    if ( financing.dados_bancarios ) {
      dadosBancarios = financing.dados_bancarios;
    }

    const payload = {
      numero_ccb: numeroCCB,
      modalidade: "FI",
      valor_liberado: simulacao.payload.valor_liberado ? simulacao.payload.valor_liberado : "",
      primeiro_vencimento: primeiroVencimento,
      parcelas: simulacao.payload.parcelas ? simulacao.payload.parcelas : "",
      taxa_juros: simulacao.payload.taxa_juros ? simulacao.payload.taxa_juros : "",
      periodicidade: simulacao.payload.periodicidade ? simulacao.payload.periodicidade : "",
      cliente: {
        nome_razaosocial: empresa.nome ? empresa.nome : "",
        tipo_pessoa: "PJ",
        cpfcnpj: empresa.documento.numero ? empresa.documento.numero.replace('.', '').replace('.', '').replace('/', '').replace('-', '') : "",
        email: empresa.email.email ? empresa.email.email : "",
        celular: empresa.telefone.telefone ? empresa.telefone.telefone.replace('(', '').replace(')', '').replace(' ', '').replace('-', '').replace('.', '') : "",
        endereco: {
          logradouro: empresa.endereco.rua ? empresa.endereco.rua : "",
          numero: empresa.endereco.numero ? empresa.endereco.numero : "",
          complemento: empresa.endereco.complemento ? empresa.endereco.complemento : "",
          bairro: empresa.endereco.bairro ? empresa.endereco.bairro : "",
          cidade: empresa.endereco.cidade ? empresa.endereco.cidade : "",
          UF: empresa.endereco.estado ? empresa.endereco.estado : "",
          CEP: empresa.endereco.cep ? empresa.endereco.cep : "",
        },
        dados_bancarios: {
          agencia: dadosBancarios.agencia_numero ? dadosBancarios.agencia_numero : "",
          dig_agencia: dadosBancarios.agencia_digito ? dadosBancarios.agencia_digito : "",
          cod_banco: dadosBancarios.banco.code ? dadosBancarios.banco.code : "",
          conta: dadosBancarios.conta_numero ? dadosBancarios.conta_numero : "",
          dig_conta: dadosBancarios.conta_digito ? dadosBancarios.conta_digito : "",
          nome_razaosocial_titular: empresa.nome ? empresa.nome : "",
          cpfcnpj_titular: empresa.documento.numero ? empresa.documento.numero.replace('.', '').replace('.', '').replace('/', '').replace('-', '') : "",
          tipo: "CC",
        },
        receitas_pj: {
           "01": 1.00,
           "02": 1.00,
           "03": 1.00,
           "04": 1.00,
           "05": 1.00,
           "06": 1.00,
           "07": 1.00,
           "08": 1.00,
           "09": 1.00,
           "10": 1.00,
           "11": 1.00,
           "12": 1.00
        },
      },
      socios: [
        {
            cpf_socio: socioCpf.replace('.', '').replace('-', ''),
            nome_socio: socioNome
        }
      ]
    };
    
    const authPost = {
      username: process.env.VUE_APP_FIDUCIA_AUTH_USER,
      password: process.env.VUE_APP_FIDUCIA_AUTH_PWD
    }
    const token = `${authPost.username}:${authPost.password}`;
    const encodedToken = Buffer.from(token).toString('base64');
    const headers = {
      'user': process.env.VUE_APP_FIDUCIA_HEADER_USER,
      'Authorization': 'Basic '+ encodedToken
    };
    console.log(payload);
    try {
      return axios.post(endpoint, payload, { headers: headers }).then(r => r.data);
    } catch (error) {
      throw new Error(error.message);
    }
  },
};
