import firebase from 'firebase';
import { withLog } from '../../logger/with_log';

export default {
  invoicesRef() {
    return firebase.firestore().collection('invoices');
  },

  storageRef() {
    return firebase.storage().ref('nfs/');
  },

  async getAllInvoices() {
    return withLog(async () => {
      const snapshot = await this.invoicesRef().orderBy('date', 'desc').get();

      return snapshot.docs.map((doc) => doc.data());
    });
  },

  async getInvoice(id) {
    const snapshot = await withLog(() => this.invoicesRef().doc(id).get()); 
    return snapshot.data()
  },

  async createNewInvoice(invoice) {
    const docRef = this.invoicesRef().doc();
    const invoiceWithId = {
      ...invoice,
      id: docRef.id
    };
    await withLog(() => docRef.set(invoiceWithId));
  },
  
  async updateInvoice(invoiceId, invoice) {
    return withLog(() => this.invoicesRef().doc(invoiceId).update(invoice));
  },

  async deleteInvoice(invoiceId) {
    await withLog(() => this.invoicesRef().doc(invoiceId).delete());
  },

  async uploadInvoiceToStorage(file, oldFileName = null) {
    if (oldFileName) {
      const oldFileRef = this.storageRef().child(oldFileName);
      await oldFileRef.delete();
    }

    const fileRef = this.storageRef().child(file.name);
    const snapshot = await fileRef.put(file);

    return snapshot;
  },

  async deleteInvoiceInStorage(filename) {
    const oldFileRef = this.storageRef().child(filename);
    await oldFileRef.delete();
  },

  async getDownloadURL(fileName) {
    const fileRef = this.storageRef().child(fileName);
    try {
      const downloadURL = await fileRef.getDownloadURL();
      return downloadURL;
    } catch (error) {
      console.error("Error getting download URL:", error);
      return null;
    }
  }
}