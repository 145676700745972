<script>
import SuccessNotification from '@/components/Notifications/General/SuccessNotification';
import { vueTopprogress } from 'vue-top-progress';
import vSelect from 'vue-select';
import Vue from 'vue';
import newsService from '../../../services/News/newsService';

Vue.component('v-select', vSelect);

export default {
  data() {
    return {
      isLoading: false,
      ebook: {},
      target_audience: [],
      file: [null, null],
      Deselect: {
        render: (createElement) => createElement('span', '❌'),
      },
      isSending: false,
    };
  },
  computed: {
    newsId() {
      return this.$route.params.id;
    },
  },
  components: {
    vueTopprogress,
  },
  methods: {
    getTranslatedValue(option, isOriginalToTranslated = true) {
      if (isOriginalToTranslated) {
        return this.translationMap[option] || option;
      } else {
        const originalValue = Object.keys(this.translationMap).find(
          key => this.translationMap[key] === option
        );
        return originalValue || option;
      }
    },
    handleFileChange(fileNumber) {
      const inputRef = `file${fileNumber}`;
      this.file[fileNumber - 1] = this.$refs[inputRef].files[0];
    },
    async addNew() {
      this.$refs.topProgress.start();
      this.isSending = true;
      const formData = new FormData();

      try {
        if (!this.file[0] ||!this.file[1] || this.file.length < 2) {
          this.$notify({
            title: 'Ooopss',
            message: 'Escolha os arquivos para enviar',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 3000,
          });
          this.$refs.topProgress.done();
          return;
        }

        if (this.ebook.type === 'ebook' && (this.target_audience.length === 0 || !this.target_audience)) {
          this.$notify({
            title: 'Ooopss',
            message: 'Escolha o tipo para quem enviar',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 3000,
          });
          this.$refs.topProgress.done();
          return;
        }

        if (!this.ebook.type) {
          this.$notify({
            title: 'Ooopss',
            message: 'Escolha ao menos um tipo para o ebook',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 3000,
          });
          this.$refs.topProgress.done();
          return;
        }

        this.ebook.target_audience = this.target_audience || [];

        this.file.forEach((file, index) => {
        if (file !== null) {
            formData.append('file', file);
          }
        });

        formData.append('title', this.ebook.title);
        formData.append('description', this.ebook.description);
        formData.append('type', this.ebook.type);
        this.ebook.target_audience.forEach(audience => {
          formData.append('target_audience', audience);
        });

        if (this.newsId) {
          await newsService.updateNews(formData, this.newsId);

          this.$notify({
            title: 'Atualização',
            message: 'Atualização feita com sucesso',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 3000,
          });
        } else {
          await newsService.createNews(formData);

          this.$notify({
            title: 'Criação',
            message: 'Criação feita com sucesso',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 3000,
          });
        }

        this.$router.push('/ebooks');
      } catch(err) {
        console.log(err);
        this.$notify({
            title: 'Erro',
            message: 'Erro na criação',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 3000,
          });
      } finally {
        this.$refs.topProgress.done();
        this.isSending = false;
      }
    },
  },
  async mounted() {
    if(this.newsId) {
      const news = await newsService.getNews(this.newsId);
      this.target_audience = news.target_audience || [];

      const imgFile = await newsService.getFileObject(news.thumb, news.thumb_url);
      const pdfFile = await newsService.getFileObject(news.ebook_name, news.url_download);

      this.file[0] = imgFile;
      this.file[1] = pdfFile;

      this.ebook = news;
    }
  },
};
</script>

<template>
  <div class="add-user custom-row">
    <card>
      <form @submit.prevent="addNew()">
        <h5 slot="header" class="title">
          <router-link to="/ebooks" class="fa fa-arrow-left"></router-link>
          Salvar Ebook
        </h5>

        <div class="row">
          <div class="col-md-6">
            <base-input
              label="Título*"
              placeholder="Título do ebook"
              v-model="ebook.title"
              required
            >
            </base-input>
          </div>

          <div class="col-md-6">
            <base-input
              label="Descrição*"
              placeholder="Descrição"
              v-model="ebook.description"
              required
            >
            </base-input>
          </div>
        </div>

        <div class="row">
          

          <div class="col-md-6">
            <base-input
              label="Tipo de Conteúdo*"
            >
              <v-select
                label="uf"
                class="style-chooser"
                :options="['news', 'ebook']"
                v-model="ebook.type"
                :components="{ Deselect }"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    <em style="opacity: 0.9; color: #555"
                      ><b>{{ search }}</b> não encontrada</em
                    >
                  </template>
                </template>
              </v-select>
            </base-input>
          </div>

          <div 
            class="col-md-6 row mt-2" 
            style="display: flex; align-items: center;"
            v-if="ebook.type === 'ebook'"
          >
            <label class="mr-2">Para qual tipo:</label>

            <div v-for="(type, index) in ['customer', 'broker']" :key="index">
              <input :id="type" class="mr-2" type="checkbox" :value="type" v-model="target_audience"/>
              <label :for="type" class="mr-2">{{ type === 'customer' ? 'Clientes' : 'Assessores' }}</label>
            </div>

            <div class="row">
              <div class="ml-4" v-if="this.ebook.type === 'ebook' && (this.target_audience.length === 0 || !this.target_audience)">
                <label style="font-size: 12px;color: #f00">Escolha um tipo ao menos</label>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 pl-0">
          <label>Thumbnail
            <input 
              type="file" 
              class="form-control" 
              accept=".png,.jpg,.jpeg"
              id="file1" ref="file1"
              @change="handleFileChange(1)"
            />
          </label>
        </div>

        <div class="col-md-6 pl-0">
          <label>PDF
            <input 
              type="file" 
              class="form-control" 
              accept=".pdf"
              id="file2" ref="file2"
              @change="handleFileChange(2)"
            />
          </label>
          <div class="row">
            <div class="col-md-12 m-0">
              <label style="font-size: 12px;color: #f00">Limitação de 20mb para enviar um PDF</label>
            </div>
          </div>
        </div>

        <div class="row" v-if="this.ebook.thumb">
          <div class="col-md-12">
            <h4>Thumbnail atual</h4>
          </div>
        </div>
        <div class="row" v-if="this.ebook.thumb">
          <div class="col-md-12">
            <img :src=ebook.thumb_url style="width: 650px;height:350px;display:block">
          </div>
        </div>
        
        <button v-if="this.ebook.ebook_name" type="button" class="btn btn-sm mr-4"><a :href="this.ebook.url_download" target="_blank" class="link">Visualizar PDF: {{ this.ebook.ebook_name || "" }}</a></button>
        <button type="submit" class="btn btn-sm" :disabled="isSending">Salvar</button>
      </form>
    </card>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
<style>
  .link {
    color: #ffffff;
    padding: 20px 0;
    text-decoration: none;
  }

  .link:hover, a:focus {
    color: #ffffff;
  }
</style>