import axios from 'axios';

export default {
    async listAll() {
        
    },
    async uploadFile(video, provider = 'Vimeo') {
        const formData = new FormData()
        formData.append('video', video)
        const checkProvider = provider === 'Azure' ? 'https://devajxjonastest.azurewebsites.net/uploadVideoContentFiles' : 'https://devajxjonastest.azurewebsites.net/uploadVideoContentFilesv2'

        const response = await axios.post(
          checkProvider,  
          // 'http://localhost:8081/uploadVideoContentFilesv2',
          formData,
          {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            timeout: 60000000
          }
        );

        return response;
      }
}