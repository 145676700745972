<script>
import InvestorsTable from '@/components/Investors/InvestorsTable';
import { PessoaCaracteristica } from '@/store/cwl_enums';
import {vueTopprogress} from 'vue-top-progress';
import {DoubleBounce} from 'vue-loading-spinner';

import investorsService from '@/services/Investors/investorsService';

const tableColumns = ['Nome Completo', 'Email', 'CPF', 'Telefone', 'Status', 'Ações'];

export default {
  components: {
    InvestorsTable,
    vueTopprogress,
    DoubleBounce,
  },
  data() {
    return {
      table: {
        title: 'Lista de Investidores',
        columns: [...tableColumns],
      },
      investors: [],
      isLoading: true,
      closeModal: false,
    };
  },
  mounted() {
    this.getInvestors();
  },
  computed: {
    role() {
      const value = this.$store.getters.userClaim
        ? this.$store.getters.userClaim.role
        : '';
      return value.toLowerCase();
    },
    isGestor() {
      return this.role == PessoaCaracteristica.Gestor;
    },
    isAdminGestorCurador() {
      return (
        this.role == PessoaCaracteristica.Curador ||
        this.role == PessoaCaracteristica.Gestor ||
        this.role == PessoaCaracteristica.Master
      );
    },
  },
  methods: {
    async getInvestors() {
      try {

        if (this.isAdminGestorCurador){
          console.log('isAdminGestorCurador');
        } else {
          console.log('Denied Access'); return;
        }
        const data = await investorsService.getAllInvestorsAPI();

        console.log({data});

        data.forEach((investor) => {
          if (!investor.disabled) {
            this.investors.push(investor);
          }
        });

        this.isLoading = false;
      } catch (error) {}
    },
  },
};
</script>

<template>
  <div class="users-list">
    <div class="row">
      <div class="col-12">
        <card
          :title="table.title"
          :addItems="false"
          :itemType="'Investidor'"
          :routerType="'investor'"
        >
          <div class="table-responsive">
            <investors-table
              :data="investors"
              :columns="table.columns"
              :hasActions="true"
              thead-classes="text-primary"
            ></investors-table>

            <double-bounce v-if="isLoading"></double-bounce>
          </div>
        </card>
      </div>
    </div>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
