var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(this.brokerInfo.exam_release && !this.brokerInfo.exam)?_c('div',{staticClass:"alert alert-info",attrs:{"role":"alert"}},[_c('span',[_vm._v(" Sua prova já está liberada para realização. "),_c('router-link',{staticStyle:{"cursor":"pointer !important"},attrs:{"to":"/exam","tag":"span"}},[_c('b',[_vm._v("Clique aqui para ir para a prova")]),_vm._v(". ")])],1)]):_vm._e(),(
      this.brokerInfo.exam_release &&
      this.brokerInfo.exam &&
      this.brokerInfo.exam.exam_progress === 'Em andamento'
    )?_c('div',{staticClass:"alert alert-info",attrs:{"role":"alert"}},[_c('span',[_vm._v(" Você ainda não finalizou sua prova. "),_c('router-link',{staticStyle:{"cursor":"pointer !important"},attrs:{"to":"/exam","tag":"span"}},[_c('b',[_vm._v("Clique aqui para ir para a prova")]),_vm._v(". ")])],1)]):_vm._e(),(
      this.brokerInfo.exam_release &&
      this.brokerInfo.exam &&
      this.brokerInfo.exam.exam_approval === 'Pendente'
    )?_c('div',{staticClass:"alert alert-info",attrs:{"role":"alert"}},[_c('span',{staticStyle:{"color":"#f3f3f3ee"}},[_vm._v(" Sua prova ainda está em correção. ")])]):_vm._e(),_c('noticias-mercado-top-bar'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('noticias-mercado-meus-favoritos')],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('noticias-mercado-ao-vivo')],1),_c('div',{staticClass:"col-md-12"},[(this.brokerInfo.channel !== 'Monetize')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[(!_vm.isLoading)?_c('playlist-carousel',{attrs:{"hasLoadMore":true,"playlistName":`AJX Educacional - ${_vm.playlist.title}`,"playlist":_vm.playlist.linked}}):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('noticias-mercado-top-banner')],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[(this.userClaim !== 'assessor')?_c('noticias-mercado-novos-cursos'):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('noticias-mercado-mais-popular')],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ebooks-recent')],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }