<template>
  <div class="sugeridos">
    <div class="row" v-if="isLoading">
      <div class="col-md-12 loading-gif">
        <img src="@/assets/images/loading.gif" />
      </div>
    </div>
    <div class="row padding-bottom" v-if="!isLoading">
      <div class="col-md-11">
        <h3 class="sugeridos-big-title">Videos Sugeridos</h3>
      </div>
    </div>
    <div class="videos">
      <div class="content-video" v-for="item in videos" :key="item.id">
        <div class="noticias-novos-videos" v-on:click="openDetails(item.id)">
          <label class="assistido" v-if="item.viewed">Assistido</label>
          <img :src="item.thumbnail" alt="" class="img" />
          <img src="@/assets/images/start-128.png" class="start" />
          <div class="legend">
            <div class="row">
              <div class="col-md-12">
                <label class="title" :title="item.title">{{
                  item.title | truncate(30)
                }}</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label class="recorder">{{ item.recorder }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import videoService from '@/services/VideoContent/videoContentService';
import playlistService from '@/services/VideoContent/playlistService';
export default {
  name: 'noticias-mercado-videos-sugeridos',
  props: {},
  data() {
    return {
      assistido: false,
      isLoading: false,
      videos: [],
      startIcon: '',
    };
  },
  computed: {
    videoId() {
      return this.$route.params.id;
    },
    loggedInUser() {
      return this.$store.getters.isLoggedIn;
    },
    getRole() {
      const filteredRole =
        this.$store.getters.userClaim.role != 'assessor'
          ? 'clients'
          : 'brokers';

      if (
        this.$store.getters.userClaim.role === 'master' ||
        this.$store.getters.userClaim.role === 'gestor'
      ) {
        return this.$store.getters.userClaim.role;
      }

      return filteredRole;
    },
  },
  mounted() {
    console.log(this.$route.query);
    this.loadVideos();
  },
  methods: {
    openDetails(id) {
      this.$router.push(`../../market-news-video-details/${id}`);
    },
    async loadVideos() {
      let fullVideosObject = [];
      this.isLoading = true;

      // let videoIds = await playlistService.getPlaylistsLikedToVideo(
      //   this.videoId,
      //   10,
      // );

      // if (videoIds.length > 0) {
      //   //we need to get the details for each video
      //   for (let id = 0; id < videoIds.length; id++) {
      //     console.log(videoIds[id].id);
      //     let tempVideo = await videoService.get(videoIds[id].id);
      //     const showVideoToUser =
      //       tempVideo.target_audience.includes(this.getRole) || false;

      //     if (tempVideo && showVideoToUser) {
      //       fullVideosObject.push(tempVideo);
      //     }
      //   }
      // }

      // console.log(fullVideosObject);

      //if video has no playlist, get most popular ones
      if (fullVideosObject.length == 0) {
        fullVideosObject = await videoService.getMostPopularVideos(
          10,
          this.getRole,
        );
      }

      for (let i = 0; i < fullVideosObject.length; i++) {
        let v = fullVideosObject[i];
        let url = '@assets/images/no-thumbnail.jpg';
        if (v.thumbnail) {
          let thumb = JSON.parse(v.thumbnail);
          url = await videoService.urlThumbnail(thumb.name);
        }

        //console.log(fullVideosObject)
        let viewed = false;
        if (
          fullVideosObject[i].views &&
          fullVideosObject[i].views.includes(this.loggedInUser.email)
        ) {
          viewed = true;
        }

        this.videos.push({
          id: v.id,
          title: v.title,
          recorder: v.recorder,
          thumbnail: url,
          viewed: viewed,
        });
      }

      this.isLoading = false;
    },
  },
};
</script>

<style>
.videos {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.sugeridos .content-video {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.sugeridos .assistido {
  position: absolute;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 0.9rem;
  font-weight: bold;
  padding: 5px;
  border-top-right-radius: 8px;
}
.sugeridos .loading-gif {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sugeridos .noticias-novos-videos .legend .title {
  text-align: center;
  font-size: 0.8;
  color: #fff;
}

.sugeridos .noticias-novos-videos .legend .recorder {
  text-align: center;
  font-size: 0.8;
  color: #fff;
}

.sugeridos .noticias-novos-videos .start {
  position: absolute;
  width: 60px;
  height: 60px;
  opacity: 0.4;
}

.sugeridos .noticias-novos-videos:hover .start {
  opacity: 0.8;
}

.sugeridos .noticias-novos-videos .legend {
  padding-top: 10px;
  text-align: center;
  position: absolute;
  bottom: 0px;
  height: 70px;
  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.4);
}

.sugeridos .noticias-novos-videos {
  border-radius: 8px;
  width: fit-content;
  min-height: fit-content;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 10px 6px -6px #777;
}

.sugeridos .noticias-novos-videos:hover {
  cursor: pointer;
  -webkit-box-shadow: 0 10px 6px -6px #0d143c;
  -moz-box-shadow: 0 10px 6px -6px #0d143c;
  box-shadow: 0 10px 6px -6px #0d143c;
}

.sugeridos .noticias-novos-videos .img {
  border-radius: 8px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.sugeridos .noticias-top-banner label {
  font-size: 1rem;
}

.sugeridos .sugeridos-big-title {
  font-weight: bold;
  color: darkblue;
  font-size: 1rem;
  display: flex;
  align-items: center;
  margin: 0px;
}

.sugeridos .more-details {
  font-weight: normal;
  color: darkblue;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sugeridos .padding-bottom {
  padding-bottom: 20px;
}

.sugeridos .no-padding {
  padding: 0px;
}

.sugeridos .no-margin {
  margin: 0px;
}
</style>
