<script>
import ConfirmationModal from '@/components/ConfirmationModal';
import {
  CWLStatusInvestidor
} from '@/store/cwl_enums';

export default {
  name: 'investors-table',
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: 'Table columns',
    },
    data: {
      type: Array,
      default: () => [],
      description: 'Table data',
    },
    type: {
      type: String,
      default: '',
      description: 'Whether table is striped or hover type',
    },
    theadClasses: {
      type: String,
      default: '',
      description: '<thead> css classes',
    },
    tbodyClasses: {
      type: String,
      default: '',
      description: '<tbody> css classes',
    },
    hasActions: {
      type: Boolean,
      default: false,
    },
    isDownloadable: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ConfirmationModal,
  },
  data() {
    return {
      page: 1,
      perPage: 10,
      pages: [],
      showModal: false,
      preDeletedItem: {},
      searchBar: ''
    };
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
    displayedData() {
      return this.paginate(this.filteredData);
    },
    filteredData() {

      const toNormalForm = function(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
      }

      const multiSearchAnd = (text, searchWords) => (
        searchWords.every((el) => {
          return text.match(new RegExp(el,"i"))
        })
      )

      var arrSearchText = toNormalForm(this.searchBar).split(' ');

      const termSearch = '"'+ arrSearchText.join('" || "') + '"'

      var searchBarText = this.searchBar

      return this.data.filter(obj => {
        return(
             multiSearchAnd(obj.nome ? toNormalForm(obj.nome) : '', arrSearchText)
          || ((obj.documento ? obj.documento.numero.replace(/\D/g, '') : '') === this.searchBar.replace(/\D/g, ''))
          || (obj.email.email ? obj.email.email : '').toLowerCase().includes(this.searchBar.toLowerCase())
          || (obj.documento ? obj.documento.numero : '').toLowerCase().includes(this.searchBar.toLowerCase())
          || (obj.telefone ? obj.telefone.telefone : '').toLowerCase().includes(this.searchBar.toLowerCase())
          || (obj.dados_complementares && obj.dados_complementares.approvalStatus ? obj.dados_complementares.approvalStatus : '').toLowerCase().includes(this.searchBar.toLowerCase())
        )
      });
    },
    CWLStatusInvestidor() {
      return CWLStatusInvestidor;
    }
  },
  methods: {
    hasValue(item, column) {
      return item[column.toLowerCase()] !== 'undefined';
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    setPages() {
      if (this.pages) {
        this.pages = [];
      }

      const numberOfPages = Math.ceil(this.data.length / this.perPage);

      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      const page = this.page;
      const perPage = this.perPage;

      const from = (page * perPage) - perPage;
      const to = (page * perPage);

      return data.slice(from, to);
    },
    preDeleteItem(item, index) {
      this.preDeletedItem = {
        item,
        index,
      };

      this.showModal = true;
    },
    deleteItem() {
      this.$emit('deleteItem', this.preDeletedItem);

      this.showModal = false;
    },
    downloadItem(user) {
      this.$emit('downloadItem', user);
    },
  },
  watch: {
    data() {
      this.setPages();
    },
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Para pesquisar, digite sua busca aqui..." v-model="searchBar" />
        </div>
      </div>
    </div>
    <table class="table tablesorter" :class="tableClass">
      <thead :class="theadClasses">
      <tr>
        <slot name="columns">
          <th class="text-center" v-for="column in columns" :key="column">{{column}}</th>
        </slot>
      </tr>
      </thead>
      <tbody :class="tbodyClasses">
        <tr class="text-center"
          v-for="(item, index) in displayedData"
          :key="index"
        >
          <td>
            <router-link :to="`/profile/${item.id}`">
              {{ item.nome }}
            </router-link>
          </td>
          <td>{{ item.email.email }}</td>
          <td>{{ item.documento ? item.documento.numero : '' }}</td>
          <td>{{ item.telefone ? item.telefone.telefone : '' }}</td>
          <td
            :class="[item.dados_complementares && item.dados_complementares.approvalStatus === CWLStatusInvestidor.Reprovado ? 'text-warning' : 'text-info']"
          >
            {{ item.dados_complementares && item.dados_complementares.approvalStatus ? item.dados_complementares.approvalStatus : "" }}
          </td>
          <td style="text-align: center" v-if="hasActions" width="20%">
            <router-link
              :to="`orders/${item.id}`"
              class="nav-item dropdown-item"
              title="Visualizar investimentos do cliente"
            >
            <i class="tim-icons icon-single-copy-04"></i>
            </router-link>
            </td>
          </tr>
      </tbody>
    </table>

    <nav class="table-pagination">
      <ul class="pagination">
        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-if="page != 1"
            @click="page--"
          >
            <i class="fas fa-angle-left"></i>
          </button>
        </li>

        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
            :key="index"
            @click="page = pageNumber"
            :class="{ 'is-active': page === pageNumber }"
          >
            {{ pageNumber }}
          </button>
        </li>

        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-if="page < pages.length"
            @click="page++"
          >
            <i class="fas fa-angle-right"></i>
          </button>
        </li>
      </ul>
    </nav>

    <confirmation-modal
      :show="showModal"
      @close="showModal = false"
      @confirmDelete="deleteItem()"
    ></confirmation-modal>
  </div>
</template>
