<template>
    <div class="row">
        <div class="col-md-7">
          <input type="text" v-model="search" class="notifica-mercado-filter" placeholder="O que você está procurando?" v-on:keyup="onEnter"/>
        </div>
        <div class="col-md-1 top-bar-link">
          <label v-on:click="openFavs()" >Favoritos</label>
      </div>
    </div>
</template>

  <script>
  export default {
    name: 'noticias-mercado-top-bar',
    data() {
      return {
        search: ''
      }
    },
    props: {
    },
    methods: {
      onEnter(event) {
        if(event.keyCode === 13 && this.search.length > 0)
        {
          this.$router.push(`../market-news-videos?o=search&q=${this.search}`) 
        }
      },
      openFavs(id) {
        this.$router.push(`../market-news-videos?o=favs`) 
      },
    }
  };
  </script>

<style>
  .notifica-mercado-filter {
    display: block;
    width: 100%;
    margin: 20px auto;
    padding: 10px 45px;
    background: white url("../../assets/images/search-icon.svg") no-repeat 15px center;
    background-size: 15px 15px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }

  .top-bar-link {
    justify-content: center;
    align-items: center;
    display:flex;
    font-size: 1rem;
    
    @media (max-width : 520px) {
      justify-content: right;
      align-items: right;
    }   

  }

  .top-bar-link label {
    font-size: 0.9rem;
    margin-bottom: 0px;
    font-weight: bold;
    color: darkblue;
  }
  
  </style>
  