import Firebase from 'firebase/app';

import store from '@/store/index';
import { withLog } from '../../logger/with_log';
import firebase from 'firebase/app';

const _ref = () => {
  return firebase.firestore().collection('roles');
};

export default {
  claimsRef() {
    return firebase.firestore().collection('roles');
  },

  async setNewUserClaim(uid, email, claim) {
    withLog(async () => {
      const setClaimFunction =
        Firebase.functions().httpsCallable('AddUserRole');

      const data = { uid, claim, email };
      console.debug('requestr user claim', data);

      await setClaimFunction(data);
    });
  },

  async getUserClaim(user) {
    console.debug('claim users');
    return withLog(async () => {
      const userRole = (await _ref().doc(user.uid).get()).data();
      console.debug('user role', userRole);
      store.commit('SET_CLAIM', userRole);
      return userRole;
    });
  },

  async getUserClaimByEmail(email, returnJustOne = false) {
    console.debug('claim users');
    return withLog(async () => {
      const claims = await _ref().where('email', '==', email).get();
      if ( claims.size > 0 ) {
        const userRole = claims.docs[0].data();
        console.debug('user role', userRole);
        store.commit('SET_CLAIM', userRole);
        if ( returnJustOne ) {
          return userRole;
        }
      }
      else if ( returnJustOne ) {
        return undefined;
      }
      return claims;
    });
  },

  async deleteClaim(user) {
    withLog(async () => {
      const User = await _ref()
        .where('email', '==', user.item.email.email)
        .get();

      const collectionId = await User.docs[0].id;

      await _ref().doc(collectionId).delete();
    });
  },

  async updateClaimById(claim, id) {
    withLog(async () => {
      const snapshot = await this.claimsRef()
        .where('uid', '==', id)
        .get()
        .then((snapshot) => {
          return !snapshot.empty ? snapshot.docs[0] : null;
        });
      if (snapshot == null) throw 'broker not-found';
      await snapshot.ref.update(claim);
    });
  },

  async updateClaimByEmail(claim, email) {
    withLog(async () => {
      const snapshot = await this.claimsRef()
        .where('email', '==', email)
        .get()
        .then((snapshot) => {
          return !snapshot.empty ? snapshot.docs[0] : null;
        });
      if (snapshot == null) throw 'broker not-found';
      await snapshot.ref.update(claim);
    });
  },
};
