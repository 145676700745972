import axios from 'axios';
import settingsService from '@/services/Settings/settingsService';

export default {

    async criarContatoAPI(pessoaId) {
        let _features = await settingsService.getFeaturesToggle();
        if ( _features.integracao_crm_hubspot ) {
            const endpoint = process.env.VUE_APP_ENDPOINT_WS + "createContactCRM";
            await axios.post(endpoint, {
                pessoa_id: pessoaId
            });
        }
    },

    async atualizarContatoAPI(pessoaId) {
        let _features = await settingsService.getFeaturesToggle();
        if ( _features.integracao_crm_hubspot ) {
            const endpoint = process.env.VUE_APP_ENDPOINT_WS + "updateContactCRM";
            await axios.post(endpoint, {
                pessoa_id: pessoaId
            });
        }
    },

    async atualizarValorUltimoInvestimentoRealizadoContatoAPI(pessoaId, valor) {
        let _features = await settingsService.getFeaturesToggle();
        if ( _features.integracao_crm_hubspot ) {
            const endpoint = process.env.VUE_APP_ENDPOINT_WS + "updateContactCRM";
            await axios.post(endpoint, {
                pessoa_id: pessoaId,
                valor_ultimo_investimento_realizado: valor
            });
        }
    },

    async atualizarSessaoContatoAPI(pessoaId) {
        console.log('Atualizando API...')
        let _features = await settingsService.getFeaturesToggle();
        if ( _features.integracao_crm_hubspot ) {
            const endpoint = process.env.VUE_APP_ENDPOINT_WS + "updateContactCRM";
            await axios.post(endpoint, {
                pessoa_id: pessoaId,
                atualizar_sessao: true
            });
        }
    },
};