var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isLoading)?_c('div',{staticClass:"row"},[_vm._m(0)]):_vm._e(),(!_vm.isLoading)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"dash-card"},[_c('div',{staticClass:"row pl-3"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"col-md-5 middle right big"},[_c('label',[_vm._v(_vm._s(_vm._f("currency")(_vm.topNumbers.invested_capital)))])])])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"dash-card"},[_c('div',{staticClass:"row pl-3"},[_vm._m(3),_vm._m(4),_c('div',{staticClass:"col-md-5 right middle big"},[_c('label',[_vm._v(_vm._s(_vm._f("currency")(_vm.topNumbers.accumulated_income)))])])])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"dash-card"},[_c('div',{staticClass:"row pl-3"},[_vm._m(5),_vm._m(6),_c('div',{staticClass:"col-md-5 right middle big"},[_c('label',[_vm._v(_vm._s(_vm.topNumbers.average_monthly_income))])])])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"dash-card"},[_c('div',{staticClass:"row pl-3"},[_vm._m(7),_vm._m(8),_c('div',{staticClass:"col-md-5 right middle big"},[_c('label',[_vm._v(_vm._s(_vm.topNumbers.confirmed_investments))])])])])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12 loading-gif"},[_c('img',{attrs:{"src":require("@/assets/images/loading.gif")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-2 middle middle-icon"},[_c('i',{staticClass:"fa fa-solid fa-dollar-sign"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-5 middle"},[_c('label',[_vm._v("Aportes Realizados")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-2 middle middle-icon"},[_c('i',{staticClass:"fa fa-sharp fa-solid fa-money-bill-trend-up"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-5 middle"},[_c('label',[_vm._v("Remuneração Acumulada")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-2 middle middle-icon"},[_c('i',{staticClass:"fa fa-solid fa-percent"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-5 middle"},[_c('label',[_vm._v("Remuneração Média Mensal")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-2 middle middle-icon"},[_c('i',{staticClass:"fa fa-sharp fa-solid fa-stamp"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-5 middle"},[_c('label',[_vm._v("Aportes Efetivados")])])
}]

export { render, staticRenderFns }