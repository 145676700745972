<script>
import pessoasService from '@/services/Pessoas/pessoasService';
import authenticationService from '@/services/Authentication/authenticationService';

import Vue from 'vue';
import HelperPlugin from '@/plugins/HelperPlugin';
Vue.use(HelperPlugin);

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect);

import { PessoaCaracteristica, PessoaCaracteristicas, CWLFirebaseError } from '@/store/cwl_enums';

import {vueTopprogress} from 'vue-top-progress';

import birthDatepicker from 'vue-birth-datepicker';
import Password from 'vue-password-strength-meter';
import logsService from '@/services/Logs/logsService';
import Card from '@/components/Cards/Card.vue';
import settingsService from '@/services/Settings/settingsService';

export default {
  data() {
    return {
      pessoa: {
        telefone: {},
        documento: {},
        email: {},
        dados_complementares: {},
      },
      cachedPessoa: {
        telefone: {},
        documento: {},
        email: {},
        dados_complementares: {},
      },
      birthday: '',
      password: '',
      telefone: '',
      telefone_original: '',

      tipos_usuario: PessoaCaracteristicas,
      tipo_usuario: '',

      Deselect: {
        render: createElement => createElement('span', '❌'),
      },

      email_aprovacao_investidor: false,
      email_aporte_realizado: false,
      email_novo_investidor: false,
      email_oferta_aprovada: false,

      modalidade: process.env.VUE_APP_TYPE,
      submodalidade: process.env.VUE_APP_SUBTYPE,

      settings: {
        _features: {},
      },
    };
  },
  components: {
    vueTopprogress,
    birthDatepicker,
    Password,
    Card,
  },
  computed: {
    pessoaId() {
      return this.$route.params.id;
    },
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
  },
  async mounted() {
    await this.loadSettings();
    this.pessoa = this.$CWLNewPessoaPadrao();
    this.cachedPessoa = this.$CWLNewPessoaPadrao();
    this.getPessoa();
  },
  methods: {
    async loadSettings() {
      var settings = await settingsService.getPrivateSettings();
      this.settings = {
        ...settings,
        _features: settings._features || {},
      };
    },
    async getPessoa() {
      this.$refs.topProgress.start();


      try {
        const pessoaFirebase = await pessoasService.getPessoa(this.pessoaId);
        this.pessoa = pessoaFirebase.data();
        this.pessoa.id = pessoaFirebase.id;
        this.cachedPessoa = pessoaFirebase.data();
        this.cachedPessoa.id = pessoaFirebase.id;

        this.birthday = this.pessoa.dados_complementares.birthday;
        this.telefone = this.pessoa.telefone.telefone;
        this.telefone_original = this.pessoa.telefone.telefone;

        /**
         * Configurações de e-mail
         */
        if ( this.pessoa.config_emails ) {
          this.email_aprovacao_investidor = this.pessoa.config_emails.email_aprovacao_investidor;
          this.email_aporte_realizado = this.pessoa.config_emails.email_aporte_realizado;
          this.email_novo_investidor = this.pessoa.config_emails.email_novo_investidor;
          if ( this.settings._features.criar_oferta_pelo_usuario && this.pessoa.config_emails.email_oferta_aprovada === undefined ) {
            this.email_oferta_aprovada = true;
          }
          else {
            this.email_oferta_aprovada = this.pessoa.config_emails.email_oferta_aprovada;
          }
        }

        this.tipo_usuario = this.$CWLDescricaoCaracteristica(this.pessoa.caracteristicas);
        this.exam = pessoaFirebase.data().exam;

        this.$refs.topProgress.done();
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },

    async updateUser() {
      this.$refs.topProgress.start();

      try {
        
        if ( this.pessoa.telefone.telefone.length > 0 && this.telefone != this.telefone_original ) {
          this.pessoa.telefone.telefone = this.telefone;
          this.pessoa.telefones = [];
          this.pessoa.telefones.push(this.pessoa.telefone);
        }

        this.pessoa.dados_complementares.birthday = this.birthday;

        const logUser = {
            user: {
                email: this.currentPessoa.email.email
            }
        };
        const logContent = this.pessoa;
        logsService.createNewLog(logUser, '', 'Atualizar Usuário', '', logContent);

        /**
         * Configurações de e-mail
         */
        if ( !this.pessoa.config_emails ) {
          this.pessoa.config_emails = {};
        }
        this.pessoa.config_emails.email_aprovacao_investidor = this.email_aprovacao_investidor;
        this.pessoa.config_emails.email_aporte_realizado = this.email_aporte_realizado;
        this.pessoa.config_emails.email_novo_investidor = this.email_novo_investidor;
        this.pessoa.config_emails.email_oferta_aprovada = this.email_oferta_aprovada;

        await pessoasService.updatePessoa(this.pessoa.id, this.pessoa);

        this.$notify({
          title: 'Usuário atualizado com sucesso!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        this.$router.push('/users');
        this.$refs.topProgress.done();
      } catch (error) {
        console.log(error);
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },

    async redefinirSenha() {
      this.$refs.topProgress.start();
      try {
        console.log(this.pessoa.email.email);
        await authenticationService.updatePassword(this.pessoa.email.email);

        this.$notify({
          title: 'Alteração de senha solicitada!',
          message: 'Enviamos um e-mail para o usuário redefinir sua senha.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
        this.$refs.topProgress.done();
      }
      catch (error) {
        console.log(error);
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },

    async trocarEmail() {
      
      authenticationService
        .updateUserOnFirebase(this.pessoa.email.email, {
          email: 'teste@teste.com'
        })
        .then((userRecord) => {
          // See the UserRecord reference doc for the contents of userRecord.
          console.log('Successfully updated user', userRecord);
        })
        .catch((error) => {
          console.log('Error updating user:', error);
        });
    },

    settingsCheck(element) {
      let elementType = element.srcElement.getAttribute('data-type');
      if ( elementType == "receber-email" ) {
        let ref = element.srcElement.getAttribute('for');
        this.settingsCheckReceberEmail(this.$refs[ref]);
      }
    },
    settingsCheckReceberEmail(element) {
      element.click();
    },
  },
};
</script>

<template>
  <div class="add-user">
    <form @submit.prevent="updateUser()">
      <card>
        <h5 slot="header" class="title">
          <router-link to="/users" class="fa fa-arrow-left"></router-link>
          Ver Usuário
        </h5>

        <div class="row">
          <div class="col-md-4 pr-md-1">
            <base-input required label="Tipo de usuário*" v-model="tipo_usuario" readonly>
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 pr-md-1">
            <base-input
              label="Nome*"
              placeholder="Informe o nome"
              v-model="pessoa.nome"
              required
            ></base-input>
          </div>

          <div class="col-md-4 pr-md-1">
            <base-input
              label="Celular*"
              v-model="telefone"
              placeholder="Informe o melhor celular"
              v-mask="['(##) ####-####', '(##) #####-####']"
              required
            ></base-input>
          </div>

          <div class="col-md-4 pl-md-1">
            <label for="" class="control-label">Data de Nascimento</label><br />
            <birth-datepicker
              v-model="birthday"
              :valueIsString="true"
              :high="true"
              :hideHeader="true"
              :attachment="'bottom'"
              :delimiter="'/'"
              :placeholder="'Informe a data de nascimento. Ex: 27/12/1993'"
              :locale="['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov']"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 pr-md-3">
            <base-input
              label="CPF*"
              v-model="pessoa.documento.numero"
              placeholder="Informe o seu cpf"
              type="cpf"
              v-mask="['###.###.###-##']"
              required
              disabled
            ></base-input>
          </div>

          <div class="col-md-4 pr-md-3">
            <base-input
              label="E-mail*"
              v-model="pessoa.email.email"
              placeholder="Informe o melhor e-mail"
              type="email"
              disabled
            ></base-input>
          </div>
        </div>
      </card>

      <card v-if="modalidade == 'equity' && submodalidade == 'bacen' && ( tipo_usuario == 'gestor' || tipo_usuario == 'curador' )">
        <h5 slot="header" class="title">
          Configurações de usuário
        </h5>

        <div class="row">
          <div class="col-md-12">

            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>E-mail</th>
                  <th style="text-align: center">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="tipo_usuario == 'gestor' || tipo_usuario == 'curador'">
                  <td>
                    Informativos para novos cadastros de investidores
                  </td>
                  <td style="text-align: center;">
                    <div class="row">
                      <div style="padding-top: 2px;">
                        <input
                          name="receber_email_novos_cadastros"
                          ref="receber_email_novos_cadastros"
                          type="checkbox"
                          v-model="email_novo_investidor"
                          style="cursor: pointer;"
                        />
                      </div>
                      <div style="margin-left: 5px;">
                        <label
                          for="receber_email_novos_cadastros"
                          data-type="receber-email"
                          @click="settingsCheck"
                        >
                          Receber e-mail
                        </label>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-if="tipo_usuario == 'gestor' || tipo_usuario == 'curador'">
                  <td>
                    Informativos para aprovação de cadastro de investidores
                  </td>
                  <td style="text-align: center;">
                    <div class="row">
                      <div style="padding-top: 2px;">
                        <input
                          name="receber_email_aprovacao_cadastro"
                          ref="receber_email_aprovacao_cadastro"
                          type="checkbox"
                          v-model="email_aprovacao_investidor"
                          style="cursor: pointer;"
                        />
                      </div>
                      <div style="margin-left: 5px;">
                        <label
                          for="receber_email_aprovacao_cadastro"
                          data-type="receber-email"
                          @click="settingsCheck"
                        >
                          Receber e-mail
                        </label>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-if="tipo_usuario == 'gestor'">
                  <td>
                    Informativos de aportes realizados pelos investidores
                  </td>
                  <td style="text-align: center;">
                    <div class="row">
                      <div style="padding-top: 2px;">
                        <input
                          name="receber_email_aportes"
                          ref="receber_email_aportes"
                          type="checkbox"
                          v-model="email_aporte_realizado"
                          style="cursor: pointer;"
                        />
                      </div>
                      <div style="margin-left: 5px;">
                        <label
                          for="receber_email_aportes"
                          data-type="receber-email"
                          @click="settingsCheck"
                        >
                          Receber e-mail
                        </label>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-if="tipo_usuario == 'gestor'">
                  <td>
                    Informativos de ofertas aprovadas para publicar
                  </td>
                  <td style="text-align: center;">
                    <div class="row">
                      <div style="padding-top: 2px;">
                        <input
                          name="receber_email_ofertas_aprovadas"
                          ref="receber_email_ofertas_aprovadas"
                          type="checkbox"
                          v-model="email_oferta_aprovada"
                          style="cursor: pointer;"
                        />
                      </div>
                      <div style="margin-left: 5px;">
                        <label
                          for="receber_email_ofertas_aprovadas"
                          data-type="receber-email"
                          @click="settingsCheck"
                        >
                          Receber e-mail
                        </label>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </card>

      <card>
        <!-- <button
          type="submit"
          class="btn btn-sm"
        >
          Salvar
        </button>

        <button
          type="button"
          class="btn btn-sm btn-warning"
          @click="redefinirSenha"
        >
          Redefinir senha
        </button> -->
      </card>
    </form>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>

