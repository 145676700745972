import firebase from 'firebase';
import { withLog } from '../../logger/with_log';

import { api } from '@/services/ServiceConfig/ServiceConfig';

export default {
  banksRef() {
    return firebase.firestore().collection('banks');
  },

  async getAllBanks() {
    const { data } = await api.get('/banks');

    return data.banks;
  },

  getByCode(code) {
    return withLog(() => this.banksRef().where('code', '==', code).get());
  },

  createNewBank(bank) {
    withLog(() => this.banksRef().add(bank));
  },

  deleteBank(bank) {
    withLog(() => this.banksRef().doc(bank).delete());
  },

  getBank(id) {
    return withLog(() => this.banksRef().doc(id).get());
  },

  updateBank(id, bank) {
    return withLog(() => this.banksRef().doc(id).update(bank));
  },
};
