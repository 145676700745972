<script>
import BrokersTable from '@/components/Brokers/BrokersTable';
import brokersService from '@/services/Brokers/brokersService';

import EmptyBrokersNotification from '@/components/Notifications/Brokers/EmptyBrokersNotification';
import DeleteBrokerErrorNotification from '@/components/Notifications/Brokers/DeleteBrokerErrorNotification';
import DeleteBrokerSuccessNotification from '@/components/Notifications/Brokers/DeleteBrokerSuccessNotification';

import { vueTopprogress } from 'vue-top-progress';
import { DoubleBounce } from 'vue-loading-spinner';
import logsService from '@/services/Logs/logsService';

const tableColumns = ['Nome', 'E-mail', 'CPF', 'Chave pix', 'Hubspot ID', 'Status', 'Ações'];

export default {
  components: {
    BrokersTable,
    vueTopprogress,
    DoubleBounce,
  },
  data() {
    return {
      table: {
        title: 'Lista de Assessores',
        columns: [...tableColumns],
      },
      brokers: [],
      isLoading: true,
      closeModal: false,
    };
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
  },
  mounted() {
    this.getBrokers();
  },
  methods: {
    async getBrokers() {
      this.$refs.topProgress.start();
      this.brokers = await brokersService.getAllBrokers() || [];
      if (this.brokers.lenght === 0) {
        this.$notify({
          component: EmptyBrokersNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.isLoading = false;
      this.$refs.topProgress.done();
    },
    deleteBroker(preDeletedItem) {
      try {
        const logUser = {
          user: {
            email: this.currentPessoa.email.email,
          },
        };
        const logContent = preDeletedItem.item;
        logsService.createNewLog(logUser, '', 'Excluir Assessor', '', logContent);

        brokersService.deleteBroker(preDeletedItem.item.id);

        this.getBrokers();

        this.$notify({
          component: DeleteBrokerSuccessNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        preDeletedItem = {};
      } catch (error) {
        this.$notify({
          component: DeleteBrokerErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<template>
  <div class="users-list">
    <div class="row">
      <div class="col-12">
        <card
          :title="`${table.title} (${this.brokers.length})`"
          :addItems="true"
          :itemType="'Assessor'"
          :routerType="'broker'"
        >
          <div class="table-responsive">
            <brokers-table
              :data="brokers"
              :columns="table.columns"
              :hasActions="true"
              thead-classes="text-primary"
              @deleteItem="deleteBroker"
            ></brokers-table>

            <double-bounce v-if="isLoading"></double-bounce>
          </div>
        </card>
      </div>
    </div>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
