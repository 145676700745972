import firebase from 'firebase';
import { withLog } from '../../logger/with_log';

export default {
  certificationsRef() {
    return firebase.firestore().collection('certifications');
  },

  storageRef() {
    return firebase.storage().ref('certifications/');
  },

  async getAllCertifications() {
    return withLog(async () => {
      const snapshot = await this.certificationsRef().orderBy('name', 'asc').get();

      return snapshot.docs.map((doc) => doc.data());
    });
  },

  async getCertification(id) {
    const snapshot = await withLog(() => this.certificationsRef().doc(id).get()); 
    return snapshot.data()
  },

  async createNewCertification(certification) {
    const docRef = this.certificationsRef().doc();
    const certificationWithId = {
      ...certification,
      id: docRef.id
    };
    await withLog(() => docRef.set(certificationWithId));
  },
  
  async updateCertification(certificationId, certification) {
    return withLog(() => this.certificationsRef().doc(certificationId).update(certification));
  },

  async deleteCertification(certificationId) {
    await withLog(() => this.certificationsRef().doc(certificationId).delete());
  },

  async uploadCertificationToStorage(file, oldFileName = null) {
    if (oldFileName) {
      const oldFileRef = this.storageRef().child(oldFileName);
      await oldFileRef.delete();
    }

    const fileRef = this.storageRef().child(file.name);
    const snapshot = await fileRef.put(file);

    return snapshot;
  },

  async deleteCertificationInStorage(filename) {
    const oldFileRef = this.storageRef().child(filename);
    await oldFileRef.delete();
  },

  async getDownloadURL(fileName) {
    const fileRef = this.storageRef().child(fileName);
    try {
      const downloadURL = await fileRef.getDownloadURL();
      return downloadURL;
    } catch (error) {
      console.error("Error getting download URL:", error);
      return null;
    }
  }
}