import firebase from 'firebase';
import { withLog } from '../../logger/with_log';

export default {
  examRef() {
    return firebase.firestore().collection('exam-temporary');
  },

  async createExamTemporary(exam) {
    const docRef = this.examRef().doc();
    const examWithId = {
      ...exam,
      id: docRef.id
    };
    await withLog(() => docRef.set(examWithId));
  },

  async updateExamTemporary(examId, exam) {
    return withLog(() => this.examRef().doc(examId).update(exam));
  },

  async updateExamByEmail(exam, email) {
    withLog(async () => {
      const snapshot = await this.examRef()
        .where('email', '==', email)
        .get()
        .then((snapshot) => {
          return !snapshot.empty ? snapshot.docs[0] : null;
        });
      if (snapshot == null) {
        await this.createExamTemporary({ ...exam, email })
      } else {
        await snapshot.ref.update(exam);
      };
    });
  },

  async getExamByEmail(email) {
    const snapshot = await withLog(() => this.examRef().where('email', '==', email).get());
    if (!snapshot || snapshot.docs.length === 0) return;
    return snapshot.docs[0].data();
  },

  async deleteExam(examId) {
    await withLog(() => this.examRef().doc(examId).delete());
  },
  async deleteExamByEmail(email) {
    const querySnapshot = await this.examRef().where('email', '==', email).get();
  
    if (!querySnapshot.empty) {
      querySnapshot.forEach(async (doc) => {
        await withLog(() => doc.ref.delete());
      });
    }
  }
}