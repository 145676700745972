<script>
import EditProfileForm from '../Profile/EditProfileForm';
import BrokerProfile from '../Profile/BrokerProfile';

import statesService from '@/services/States/statesService';
import banksService from '@/services/Banks/banksService';
import segmentsService from '@/services/Segments/segmentsService';
import valuesService from '@/services/Values/valuesService';
import investmentsProfile from '@/services/InvestmentsProfile/investmentsProfileService';
import investorsService from '@/services/Investors/investorsService';
import settingsService from '@/services/Settings/settingsService';
import attachmentsService from '@/services/Attachments/attachmentsService';
import claimsService from '@/services/Authentication/claimsService';
import ordersService from '@/services/Orders/ordersService';
import moment from 'moment';
import logsService from '@/services/Logs/logsService';
import hubspotService from '@/services/HubSpot/hubspotService';

import GetInvestorErrorNotification from '@/components/Notifications/Investors/GetInvestorErrorNotification';
import UpdateInvestorErrorNotification from '@/components/Notifications/Investors/UpdateInvestorErrorNotification';
import UpdateInvestorSuccessNotification from '@/components/Notifications/Investors/UpdateInvestorSuccessNotification';
import EmptyProfileNamePersonType from '@/components/Notifications/Investors/EmptyProfileNamePersonType';
import InvalidCPFNotification from '@/components/Notifications/Investors/InvalidCPFNotification';

import Vue from 'vue';
import HelperPlugin from '@/plugins/HelperPlugin';
Vue.use(HelperPlugin);

import pessoasService from '@/services/Pessoas/pessoasService';
import authenticationService from '@/services/Authentication/authenticationService';

import { vueTopprogress } from 'vue-top-progress';

import birthDatepicker from 'vue-birth-datepicker';

import { email, required } from 'vuelidate/lib/validators';
import { phone, cpf } from '@/validate/customValidators.js';
import operationalsService from '@/services/Operationals/operationalsService';

import { PessoaCaracteristica, CWLStatusInvestidor } from '@/store/cwl_enums';

export default {
  components: {
    EditProfileForm,
    vueTopprogress,
    birthDatepicker,
    BrokerProfile,
  },
  data() {
    return {
      model: {
        states: [],
        banks: [],
        segments: [],
        values: [],
        profiles: [],
      },
      pessoa: this.$CWLNewPessoaPadrao(),
      operationalInformations: [],
      settings: {},
      getUploadedDocuments: {},
      nome: '',
      telefone: '',
      birthday: '',
      modalidade: process.env.VUE_APP_TYPE,
      statusInvestidores: [
        CWLStatusInvestidor.Incompleto,
        CWLStatusInvestidor.EmAnalise,
        CWLStatusInvestidor.Aprovado,
        CWLStatusInvestidor.Reprovado,
      ],
      CWLStatusInvestidor: CWLStatusInvestidor,
      statusSelected: '',
      novoEmailTitleButton: 'Alterar e-mail',
      showNovoEmailField: false,
      showNovoEmailError: false,
      novoEmail: '',
      simularUsuarioErro: '',
      updateEmailLoading: false,
    };
  },
  validations: {
    telefone: {
      valid: (telefone) => required(telefone) && phone(telefone),
    },
  },
  async mounted() {
    this.$refs.topProgress.start();
    try {
      await this.getInvestorInformations();
      this.getStates();
      this.getBanks();
      this.getSegments();
      this.getValues();
      this.getProfiles();

      this.getSettings();
      this.getUserDocuments();
    } catch (error) {
      console.error(error);
    }
    this.$refs.topProgress.done();
  },
  computed: {
    isValidEmail() {
      var value = {};
      if (!this.pessoaId) {
        value = this.currentPessoa.email.email;
      } else {
        value = this.pessoa.email.email;
      }

      return value != '' && email(value);
    },
    isValidNovoEmail() {
      return this.novoEmail == '' || email(this.novoEmail);
    },
    novoEmailError() {
      return this.novoEmail != '' && this.showNovoEmailError;
    },
    isValidCpf() {
      var value = {};
      if (!this.pessoaId) {
        value = this.currentPessoa.documento.numero;
      } else {
        value = this.pessoa.documento.numero;
      }
      return value != '' && cpf(value);
    },
    userRole() {
      const Claim = this.$store.getters.userClaim;

      return Claim.role;
    },
    loggedUser() {
      return this.$store.getters.isLoggedIn;
    },
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
    pessoaId() {
      return this.$route.params.id;
    },
    role() {
      const value =
        this.$store.getters.userClaim && this.$store.getters.userClaim.role
          ? this.$store.getters.userClaim.role
          : 'investor';
      return value.toLowerCase();
    },
    isGestor() {
      return this.role == PessoaCaracteristica.Gestor;
    },
    isCurador() {
      return this.role == PessoaCaracteristica.Curador;
    },
    isGestorOrCurador() {
      return this.isCurador || this.isGestor;
    },
    isMaster() {
      return this.role == PessoaCaracteristica.Master;
    },
    isUsuario() {
      return this.role == PessoaCaracteristica.InvestorRole;
    },
    isBroker() {
      return this.role == PessoaCaracteristica.Broker;
    },
    isExamInitialized() {
      const user = this.currentPessoa;
      if (!user.exam) return false;
      return true;
    },
    isExamPending() {
      const user = this.currentPessoa;
      return user.exam.exam_approval === 'Pendente';
    },
  },
  methods: {
    async getStates() {
      this.model.states = await statesService.getAllStates();
    },
    async getBanks() {
      this.model.banks = await banksService.getAllBanks();
    },
    async getValues() {
      const values = await valuesService.getAllValues();

      values.forEach((value) => {
        const newValue = value.data();
        newValue.id = value.id;

        this.model.values.push(newValue);
      });
    },
    async getSegments() {
      this.model.segments = await segmentsService.getAllSegments();
    },
    async getProfiles() {
      const profiles = await investmentsProfile.getAllInvestments();

      profiles.forEach((profile) => {
        const newProfile = profile.data();
        newProfile.id = profile.id;

        this.model.profiles.push(newProfile);
      });
    },
    async getInvestorInformations() {
      try {
        var pessoa = this.$CWLNewPessoaPadrao();
        if (this.pessoaId) {
          pessoa = await pessoasService.findPessoaById(this.pessoaId);
        } else {
          pessoa = await pessoasService.getPessoaBy({
            id: this.currentPessoa.pessoa_id,
          });
        }

        this.statusSelected = pessoa.dados_complementares.approvalStatus;

        this.nome = pessoa.nome;
        this.telefone = pessoa.telefone ? pessoa.telefone.telefone : '';
        this.birthday = pessoa.dados_complementares
          ? pessoa.dados_complementares.birthday
          : '';

        if (!pessoa) throw 'pessoa not-found';

        pessoa.dados_bancarios = pessoa.dados_bancarios || {};
        pessoa.dados_complementares = pessoa.dados_complementares || {};
        pessoa.dados_complementares.perfil =
          pessoa.dados_complementares.perfil || {};
        pessoa.dados_complementares.dados_bancarios =
          pessoa.dados_complementares.dados_bancarios || {};
        pessoa.endereco = pessoa.endereco || {};
        pessoa.telefone = pessoa.telefone || {};
        pessoa.telefones = pessoa.telefones || [];
        pessoa.exam = pessoa.exam || {};

        this.pessoa = pessoa;

        // const userInformations = await investorsService.getInvestor(this.loggedUser.email);
      } catch (e) {
        console.error('get investor information error', e);
        this.$notify({
          component: GetInvestorErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
    async saveProfileInformations() {
      if (!this.validarCPF(this.pessoa.dados_complementares.user.cpf)) {
        this.$notify({
          component: InvalidCPFNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
        return;
      }

      try {
        if (
          this.pessoa.dados_complementares.fullName &&
          this.pessoa.dados_complementares.perfil.personType
        ) {
          let allDocumentUploaded = null;

          if (
            this.getUploadedDocuments.AddressDocument &&
            this.getUploadedDocuments.IdentificationDocument &&
            this.getUploadedDocuments.IncomeDocument
          ) {
            allDocumentUploaded = true;
          }

          if (
            (this.settings.isMandatoryDocument && allDocumentUploaded) ||
            !this.settings.isMandatoryDocument
          ) {
            this.pessoa.dados_complementares.approvalStatus =
              CWLStatusInvestidor.EmAnalise;
          }

          if (this.isGestor) {
            this.pessoa.dados_complementares.approvalStatus = this.statusSelected;
            await investorsService.updateInvestor(
              this.pessoa.id,
              this.pessoa,
              false,
            );
          } else {
            await investorsService.updateInvestor(
              this.pessoa.id,
              this.pessoa,
              allDocumentUploaded,
            );
          }



          this.getInvestorInformations();

          this.$notify({
            component: UpdateInvestorSuccessNotification,
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 3000,
          });
        } else {
          this.$notify({
            component: EmptyProfileNamePersonType,
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 3000,
          });
        }
      } catch (e) {
        console.log(e);
        this.$notify({
          component: UpdateInvestorErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
    async getSettings() {
      this.settings = await settingsService.getPublicSettings();
    },
    async getUserDocuments() {
      const result = await attachmentsService.getUserDocuments(
        'InvestorDocuments',
        this.loggedUser.email,
      );

      result.items.map((item) => {
        this.getUploadedDocuments[
          this.reverseTranslatedDocuments(item.name)
        ] = true;
      });

      this.$forceUpdate();
    },
    reverseTranslatedDocuments(document) {
      return {
        'Documento de Identificação': 'IdentificationDocument',
        'Documento de Renda': 'IncomeDocument',
        'Documento de Endereço': 'AddressDocument',
        'Outro Documento': 'OtherDocument',
      }[document ? document : 'Outro Documento'];
    },
    validarCPF(c) {
      const cpf = c.replace(/[^\d]+/g, '');
      if (cpf == '') return false;
      // Elimina CPFs invalidos conhecidos
      if (
        cpf.length != 11 ||
        cpf == '00000000000' ||
        cpf == '11111111111' ||
        cpf == '22222222222' ||
        cpf == '33333333333' ||
        cpf == '44444444444' ||
        cpf == '55555555555' ||
        cpf == '66666666666' ||
        cpf == '77777777777' ||
        cpf == '88888888888' ||
        cpf == '99999999999'
      ) {
        return false;
      }
      // Valida 1o digito
      let add = 0;
      for (let i = 0; i < 9; i++) {
        add += parseInt(cpf.charAt(i)) * (10 - i);
      }
      let rev = 11 - (add % 11);
      if (rev == 10 || rev == 11) {
        rev = 0;
      }
      if (rev != parseInt(cpf.charAt(9))) {
        return false;
      }
      // Valida 2o digito
      add = 0;
      for (let i = 0; i < 10; i++) {
        add += parseInt(cpf.charAt(i)) * (11 - i);
      }
      rev = 11 - (add % 11);
      if (rev == 10 || rev == 11) {
        rev = 0;
      }
      if (rev != parseInt(cpf.charAt(10))) {
        return false;
      }
      return true;
    },

    async saveProfileInformations(params) {
      const pessoa =
        params && params.pessoa
          ? { ...this.pessoa, ...params.pessoa }
          : this.pessoa;
      const goToDocuments =
        params && params.goToDocuments ? params.goToDocuments : false;
      const documentFilled =
        params && params.documentFilled ? params.documentFilled : false;
      this.updateUser(pessoa, goToDocuments, documentFilled);
    },

    async updateUser(
      pessoaP = null,
      goToDocuments = false,
      documentFilled = false,
    ) {
      if (this.$v.$invalid) {
        return this.$notify({
          title: 'Informe os dados corretamente!',
          message:
            'Um ou mais dados informados não foram preenchidos corretamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 7000,
        });
      }

      this.$refs.topProgress.start();

      try {
        const pessoa = pessoaP || this.pessoa;
        if (this.telefone.length > 0) {
          pessoa.telefone.telefone = this.telefone;
          pessoa.telefones = [pessoa.telefone];
          // pessoa.telefones.push(pessoa.telefone);
        }

        if (!this.pessoaId) {
          if (this.currentPessoa.documento) {
            pessoa.documento.numero = this.currentPessoa.documento.numero;
            pessoa.documentos = [pessoa.documento];
            // pessoa.documentos.push(pessoa.documento);
          }
        }

        pessoa.nome = this.nome;
        pessoa.dados_complementares.birthday = this.birthday;

        let allDocumentUploaded = null;

        if (
          // this.getUploadedDocuments.AddressDocument &&
          // this.getUploadedDocuments.IncomeDocument &&
          documentFilled
        ) {
          allDocumentUploaded = true;
        }

        var informationsFilled = await this.isInformationsFilled(pessoa);

        if (
          (this.settings.isMandatoryDocument &&
            allDocumentUploaded &&
            informationsFilled) ||
          (!this.settings.isMandatoryDocument && informationsFilled)
        ) {
          this.pessoa.dados_complementares.approvalStatus =
            CWLStatusInvestidor.EmAnalise;
        }

        if (this.isGestor) {
          pessoa.dados_complementares.approvalStatus = this.statusSelected;
        }
        await pessoasService.updatePessoaBy(pessoa, {
          email: pessoa.email.email,
        });

        if (!this.pessoaId) {
          if (this.isUsuario) {
            if (
              !pessoa.dados_complementares.approvalEmailSent &&
              this.pessoa.dados_complementares.approvalStatus ==
                CWLStatusInvestidor.EmAnalise
            ) {
              pessoa.dados_complementares.approvalEmailSent = true;
              await investorsService.enviarEmailAtualizacaoCadastroAnalise(
                pessoa,
              );
            }
          }
          await pessoasService.updatePessoaBy(pessoa, {
            email: pessoa.email.email,
          });
          const objCooke = this.$CWLCreatePessoaForCookie(pessoa);
          this.$store.commit('SET_PESSOA', objCooke);
        }

        try {
          hubspotService.atualizarContatoAPI(pessoa.id);
        } catch (error) {
          console.log(error);
        }

        this.$notify({
          title: 'Dados atualizados com sucesso!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        this.$refs.topProgress.done();

        if (goToDocuments) {
          var routeDocuments = 'documents-list';
          if (this.pessoaId) {
            routeDocuments = '/documents-list/' + this.pessoaId;
          }
          this.$router.push(routeDocuments);
        }
      } catch (error) {
        console.log(error);
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },

    cadastroReprovado() {
      this.statusSelected = CWLStatusInvestidor.Reprovado;
    },
    cadastroAprovado() {
      this.statusSelected = CWLStatusInvestidor.Aprovado;
    },

    async isInformationsFilled(pessoaP) {
      let pessoa = pessoaP;
      return (
        pessoa.nome != '' &&
        pessoa.telefone.telefone &&
        pessoa.telefone.telefone != '' &&
        pessoa.dados_complementares.birthday &&
        pessoa.dados_complementares.birthday != '' &&
        pessoa.dados_complementares.rg &&
        pessoa.dados_complementares.rg != '' &&
        pessoa.dados_complementares.estado_civil &&
        pessoa.dados_complementares.estado_civil != '' &&
        pessoa.dados_complementares.genero &&
        pessoa.dados_complementares.genero != '' &&
        pessoa.endereco.cep &&
        pessoa.endereco.cep != '' &&
        pessoa.endereco.numero &&
        pessoa.endereco.numero != '' &&
        pessoa.endereco.bairro &&
        pessoa.endereco.bairro != '' &&
        pessoa.endereco.rua &&
        pessoa.endereco.rua != '' &&
        pessoa.endereco.cidade &&
        pessoa.endereco.cidade != '' &&
        pessoa.endereco.estado &&
        pessoa.endereco.estado.name &&
        pessoa.endereco.estado.name != '' &&
        pessoa.dados_complementares.ocupacao &&
        pessoa.dados_complementares.ocupacao != '' &&
        pessoa.dados_complementares.dados_bancarios.agencia &&
        pessoa.dados_complementares.dados_bancarios.agencia != '' &&
        pessoa.dados_complementares.dados_bancarios.conta &&
        pessoa.dados_complementares.dados_bancarios.conta != '' &&
        pessoa.dados_complementares.dados_bancarios.banco &&
        pessoa.dados_complementares.dados_bancarios.banco != '' &&
        pessoa.dados_complementares.perfil.renda_bruta_anual &&
        pessoa.dados_complementares.perfil.renda_bruta_anual != '' &&
        pessoa.dados_complementares.perfil.investimentos_financeiros &&
        pessoa.dados_complementares.perfil.investimentos_financeiros != ''
      );
    },

    async redefinirSenha() {
      this.$refs.topProgress.start();
      try {
        await authenticationService.updatePassword(this.pessoa.email.email);

        this.$notify({
          title: 'Alteração de senha solicitada!',
          message: 'Enviamos um e-mail para redefinir a senha.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
        this.$refs.topProgress.done();
      } catch (error) {
        console.log(error);
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },

    alterarEmailClick() {
      if (this.showNovoEmailField) {
        this.showNovoEmailField = false;
        this.novoEmailTitleButton = 'Alterar e-mail';
      } else {
        this.showNovoEmailField = true;
        this.novoEmailTitleButton = 'Cancelar';
      }
    },

    async duplicarAuth(password) {
      try {
        return await authenticationService.createNewUser(
          this.novoEmail,
          password,
        );
      } catch (error) {
        console.log(error);
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
        return false;
      }
    },

    async duplicarRole(uid) {
      try {
        await claimsService.setNewUserClaim(uid, this.novoEmail, 'investor');
        return true;
      } catch (error) {
        console.log(error);
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
        return false;
      }
    },

    async duplicarPessoa(pessoaId) {
      try {
        let pessoaP = await pessoasService.getPessoaById(pessoaId);
        console.log(pessoaP);
        var pessoaParaDuplicar = pessoaP;
        pessoaParaDuplicar.email.email = this.novoEmail;
        pessoaParaDuplicar.emails.push(pessoaParaDuplicar.email);
        if (pessoaParaDuplicar.dados_complementares.user) {
          pessoaParaDuplicar.dados_complementares.user.email = this.novoEmail;
        }

        return await pessoasService.criarPessoaSemValidacao(pessoaParaDuplicar);
      } catch (error) {
        console.log(error);
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
        return false;
      }
    },

    async atualizarEntidades(novaPessoa) {
      let orders = await ordersService.getOrdersByUser(this.pessoa.id);
      try {
        orders;
        orders.forEach(async (order) => {
          order.pessoa = novaPessoa;
          await ordersService.update(order.id, order);
        });
        return true;
      } catch (error) {
        console.log(error);
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
        return false;
      }
    },

    async concatenarAlteradoPessoaAntiga() {
      let data = moment().format('DD/MM/YYYY HH:mm');
      this.pessoa.nome = this.pessoa.nome + ' - ALTERADO [' + data + ']';
      this.pessoa.disabled = true;

      try {
        await pessoasService.updatePessoa(this.pessoa.id, this.pessoa);
        return true;
      } catch (error) {
        console.log(error);
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
        return false;
      }
    },

    async enviarEmailResetSenha(email) {
      try {
        await authenticationService.updatePassword(email);
        return true;
      } catch (error) {
        console.log(error);
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
        return false;
      }
    },

    async simularUsuario() {
      try {
        let email = this.pessoa.email.email;
        const roles = await claimsService.getUserClaimByEmail(email);
        if (roles.size > 0) {
          const role = roles.docs[0].data();
          const user = this.pessoa;

          if (user && !role) {
            this.simularUsuarioErro =
              'Usuário encontrado, mas role não. Tente novamente.';
          } else if (!user) {
            this.simularUsuarioErro =
              'Este usuário não possui conta. Tente novamente.';
          } else {
            const fakeUser = {
              email: email,
              uid: user.id,
            };
            this.$store.commit('SET_USER', fakeUser);
            const objCooke = this.$CWLCreatePessoaForCookie(user);
            this.$store.commit('SET_PESSOA', objCooke);
            this.$store.commit('SET_CLAIM', role);
            if (role.role === 'investor') {
              this.$store.commit(
                'SET_TERM_OF_USE_ACCEPTED',
                user.dados_complementares.termsOfUseAccepted,
              );
            }
            // SUCCESS
            this.$notify({
              title: 'Sucesso',
              message:
                'Agora você está logado como ' + user.nome + ' (' + email + ')',
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'success',
              timeout: 3000,
            });
            this.$router.push('/dashboard');
          }
        }
      } catch (error) {
        console.log(error);
        this.simularUsuarioErro = 'Erro genérico. Tente novamente';
      }
    },

    async alterarEmail() {
      this.$refs.topProgress.start();
      this.updateEmailLoading = true;
      try {
        await operationalsService.updateEmail(
          this.novoEmail,
          this.pessoa.email.email,
        );



      this.$notify({
        title: 'E-mail atualizado com sucesso!',
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: 'success',
        timeout: 3000,
      });

      this.$refs.topProgress.done();
      this.pessoa.email.email = this.novoEmail;
    } catch (err) {
        console.log(err);
        this.$notify({
          title: 'Erro ao atualizar e-mail. Entre em contato com o suporte',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'error',
          timeout: 3000,
        });
      } finally {
        this.updateEmailLoading = false;
      }
    }
  },
};
</script>
<template>
  <div class="add-user">
    <div
      v-if="
        isUsuario &&
        currentPessoa.dados_complementares.approvalStatus ===
          CWLStatusInvestidor.Incompleto
      "
      role="alert"
      class="alert alert-warning"
    >
      <span>
        Seu cadastro não está completo. Por favor preencha todos os dados abaixo
        e envie todos os documentos obrigatórios:
      </span>
    </div>

    <div
      v-if="
        isUsuario &&
        currentPessoa.dados_complementares.approvalStatus ===
          CWLStatusInvestidor.EmAnalise
      "
      role="alert"
      class="alert alert-info"
      style="background-color: #00035a !important"
    >
      <span>
        Seu cadastro já foi preenchido, e está sob analíse. Você será informado
        por e-mail de qualquer atualização.
      </span>
    </div>

    <div
      v-if="
        isUsuario &&
        currentPessoa.dados_complementares.approvalStatus ===
          CWLStatusInvestidor.Reprovado
      "
      role="alert"
      class="alert alert-info"
      style="background-color: #00035a !important"
    >
      <span>
        Seu cadastro foi reprovado. Por favor verifique o motivo no final da
        página e faça as correções.
      </span>
    </div>

    <card v-if="userRole !== 'assessor'">
      <form @submit.prevent="updateUser()">
        <h5 slot="header" class="title" v-if="!pessoaId">Meus Dados</h5>
        <h5 slot="header" class="title" v-if="pessoaId">Dados Básicos</h5>

        <div class="row">
          <div class="col-md-4 pr-md-1">
            <base-input
              label="Nome*"
              :boldLabel="true"
              placeholder="Informe o nome"
              v-model="nome"
              required
            ></base-input>
          </div>

          <div class="col-md-4 pr-md-1">
            <div class="form-group">
              <base-input
                label="Celular*"
                :boldLabel="true"
                v-model="telefone"
                placeholder="Informe o melhor celular"
                v-mask="['(##) ####-####', '(##) #####-####']"
                required
              ></base-input>
              <div class="error" v-if="!$v.telefone.valid">
                Informe um telefone com DDD válido.
              </div>
            </div>
          </div>

          <div class="col-md-4 pl-md-1">
            <label for="" class="control-label" style="font-weight: bold"
              >Data de Nascimento</label
            ><br />
            <birth-datepicker
              v-model="birthday"
              v-mask="'##/##/####'"
              :valueIsString="true"
              :high="true"
              :hideHeader="true"
              :attachment="'bottom'"
              :delimiter="'/'"
              :placeholder="'Informe a data de nascimento. Ex: 27/12/1993'"
              :locale="[
                'Jan',
                'Fev',
                'Mar',
                'Abr',
                'Mai',
                'Jun',
                'Jul',
                'Ago',
                'Set',
                'Out',
                'Nov',
              ]"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 pr-md-3">
            <div class="form-group">
              <base-input
                label="E-mail*"
                :boldLabel="true"
                v-model="pessoa.email.email"
                placeholder="Informe o melhor e-mail"
                type="email"
                disabled
              ></base-input>
              <div class="error" v-if="!isValidEmail">
                Informe um e-mail válido.
              </div>
            </div>
          </div>
          <div class="col-md-4 pr-md-3">
            <div class="form-group">
              <base-input
                label="CPF*"
                :boldLabel="true"
                v-model="pessoa.documento.numero"
                placeholder="Informe o seu cpf"
                type="cpf"
                v-if="userRole != 'master'"
                v-mask="['###.###.###-##']"
                disabled
              ></base-input>
              <base-input
                label="CPF*"
                :boldLabel="true"
                v-model="pessoa.documento.numero"
                placeholder="Informe o seu cpf"
                type="cpf"
                v-if="userRole == 'master'"
                v-mask="['###.###.###-##']"
              ></base-input>
              <div class="error" v-if="!isValidCpf">Informe um CPF válido.</div>
            </div>
          </div>
          <div
            class="col-md-4 pr-md-3"
            v-if="
              (!isMaster && isGestorOrCurador && pessoaId) ||
              (!isGestor && !pessoaId)
            "
          >
            <div class="form-group">
              <base-input
                v-if="!isGestorOrCurador"
                label="Status do Cadastro"
                :boldLabel="true"
                v-model="pessoa.dados_complementares.approvalStatus"
                placeholder="Status do cadastro"
                disabled
              ></base-input>

              <label v-if="isGestorOrCurador">Status do Cadastro</label>
              <select
                class="form-control"
                v-model="statusSelected"
                required
                v-if="isGestorOrCurador"
              >
                <option
                  :value="status"
                  v-for="(status, index) in statusInvestidores"
                  :key="index"
                >
                  {{ status }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="row" v-if="isBroker">
          <div class="col-md-4 pr-md-3">
            <div class="form-group">
              <base-input
                label="Progresso da prova"
                :boldLabel="true"
                :value="
                  this.pessoa.exam
                    ? this.pessoa.exam.exam_progress
                    : 'Prova não inicializada'
                "
                placeholder="Progresso da prova"
                disabled
              ></base-input>
            </div>
          </div>
          <div class="col-md-4 pr-md-3">
            <div class="form-group">
              <base-input
                label="Resultado da prova"
                :boldLabel="true"
                :value="
                  this.pessoa.exam
                    ? this.pessoa.exam.exam_approval
                    : 'Prova não inicializada'
                "
                placeholder="Resultado da prova"
                disabled
              ></base-input>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <button type="submit" class="btn btn-sm">Salvar</button>

            <button
              type="button"
              class="btn btn-sm btn-warning"
              @click="redefinirSenha"
            >
              Redefinir senha
            </button>

            <!-- <button
              type="button"
              class="btn btn-sm btn-info"
              @click="alterarEmailClick"
              v-if="isMaster || isGestor"
            >
              {{ novoEmailTitleButton }}
            </button> -->

            <div class="form-group" v-if="isMaster">
              <button
                type="button"
                class="btn btn-sm btn-info"
                @click="simularUsuario"
                v-if="isMaster"
              >
                Simular Usuário
              </button>
              <div
                class="error"
                v-if="simularUsuarioErro != ''"
                style="color: red !important"
              >
                {{ simularUsuarioErro }}
              </div>
            </div>

            <div
              class="form-group"
              v-if="(isMaster || isGestor) && showNovoEmailField"
            >
              <base-input
                label="Novo e-mail"
                :boldLabel="true"
                v-model="novoEmail"
                placeholder="Informe o novo e-mail"
                type="email"
              ></base-input>
              <div class="error" v-if="!isValidNovoEmail">
                Informe um e-mail válido.
              </div>
            </div>

            <div
              class="form-group"
              v-if="(isMaster || isGestor) && showNovoEmailField"
            >
              <button
                type="button"
                class="btn btn-sm btn-info"
                @click="alterarEmail"
                v-if="(isMaster || isGestor) && showNovoEmailField"
                :disabled="updateEmailLoading"
              >
                Salvar
              </button>
              <div
                class="error"
                v-if="novoEmailError"
                style="color: red !important"
              >
                Houve um erro ao alterar o e-mail.
              </div>
            </div>
          </div>
        </div>
      </form>
    </card>

    <broker-profile v-if="userRole === 'assessor'"> </broker-profile>

    <div
      class="row profile"
      v-if="
        modalidade == 'equity' &&
        userRole !== 'assessor' &&
        userRole !== 'master' &&
        pessoa.email.email &&
        ((isGestorOrCurador && pessoaId) || !isGestorOrCurador)
      "
    >
      <div class="col-md-12">
        <edit-profile-form
          :pessoa="pessoa"
          :model="model"
          @saveProfileInformations="saveProfileInformations"
          @cadastroReprovado="cadastroReprovado"
          @cadastroAprovado="cadastroAprovado"
        />
      </div>
    </div>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
