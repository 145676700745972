export const questionsArray = [
  { id: 1, question: "Qual a diferença de uma FINTECH para uma EDTECH?" },
  { id: 2, question: "Qual a missão da AJX o quanto FINTECH e EDTECH?" },
  { id: 3, question: "Quais são os pilares que estabelecem a cultura da AJX Capital?" },
  { id: 4, question: "Quais são as 4 moedas que regem a cultura da AJX Capital?" },
  { id: 5, question: "Dentro da cultura da AJX o que é motivação?" },
  { id: 6, question: "Dentro da cultura da AJX o que é inconformidade?" },
  { id: 7, question: "Qual a diferença entre Comprometimento X Disciplina?" },
  { id: 8, question: "Quais são os pilares financeiros da AJX Capital?" },
  { id: 9, question: "Dentro da cultura da AJX o que é o estado de pureza?" },
  { id: 10, question: "Qual a diferença entre Motivação e Ativação?" },
  { id: 11, question: "Como ter uma entrega de Excelência?" },
  { id: 12, question: "Qual a diferença entre Sofisticação X Excelência?" },
  { id: 13, question: "Como fazer uma gestão sofisticada?" },
  { id: 14, question: "Qual a diferença entre produto e solução?" },
  { id: 15, question: "Quais são os perfis que um investidor pode ter, quem pode analisá-lo?" },
  { id: 16, question: "Quem é responsável pela regulamentação de uma CCB?" },
  { id: 17, question: "CCB é uma solução? Se sim, por que?" },
  { id: 18, question: "Quando nasceu a lei das FINTECH?" },
  { id: 19, question: "Quando nasceu a lei das White Labels?" },
  { id: 20, question: "Quais as características de uma CCB? Explique abaixo:", options: ["Produto Financeiro:", "Prazo:", "Taxa Remuneratória e ganho sobre o capital aportado:", "Regulador:", "Solução"] },
  { id: 21, question: "Qual o lastro que a AJX utiliza na intermediação da CCB?" },
  { id: 22, question: "Qual a diferença entre Lastro e Garantia?" },
  { id: 23, question: "Qual a diferença entre Contrato e cédula?" },
  { id: 24, question: "A AJX é regulada pelo Banco Central?" },
  { id: 25, question: "O que são erros honestos e por que eles devem ser evitados?" },
  { id: 26, question: "Qual a diferença, explique:", options: ["Interação:", "Conexão:", "Relacionamento:", "Intimidade"] },
  { id: 27, question: "A CCB é uma renda fixa de crédito público ou com crédito privado? E por quê?" },
  { id: 28, question: "Qual a diferença entre", options: ["Mercado Financeiro e qual seu regulador:", "Mercado de Capitais e qual seu regulador:"] },
  { id: 29, question: "Para quem você indicaria uma CCB e por quê?" },
  { id: 30, question: "Qual a diferença de um fundo de investimento e uma CCB?" },
  { id: 31, question: "Qual o regulador de um fundo de investimento e de uma CCB?" },
  { id: 32, question: "Qual a diferença entre os modelos: remuneração mensal e Bullet?" },
  { id: 33, question: "Como entregar uma solução?" },
  { id: 34, question: "Dentro da cultura da AJX o que a intimidade nos revela?" },
  { id: 35, question: "Qual o valor da influência de um líder sobre seus liderados, e como fazer liderança através da condução na jornada do nosso cliente?" },
  { id: 36, question: "Qual o valor da aplicação mínima em uma CCB?" },
  { id: 37, question: "Qual a diferença entre Aplicação e Aporte?" },
  { id: 38, question: "Qual a diferença entre Remuneração e Rentabilidade?" },
  { id: 39, question: "O imposto de renda na CCB é sobre a rentabilidade ou sobre o ganho de capital?" },
  { id: 40, question: "Qual a diferença entre rentabilidade e ganho de capital?" },
  { id: 41, question: "Dentro da cultura da AJX o que é ousadia?" },
  { id: 42, question: "Qual a diferença entre fundo de renda fixa referenciado DI e título de renda fixa privado?" },
  { id: 43, question: "Qual a diferença entre taxa SELIC e IPCA?" },
  { id: 44, question: "A cada quantos dias acontecem as reuniões do COPOM?" },
  { id: 45, question: "Qual a diferença entre regulador e autorregulador?" },
  { id: 46, question: "Qual o papel da CVM no Sistema Financeiro Nacional?" },
  { id: 47, question: "Qual o papel do Banco Central no Sistema Financeiro Nacional?" },
  { id: 48, question: "O que é taxa SELIC e qual o seu papel?" },
  { id: 49, question: "Quem anuncia a taxa SELIC?" },
  { id: 50, question: "Quem mede o IPCA?" },
  { id: 51, question: "Qual a diferença entre entregar e receber, na interação e conexão com um cliente?" },
  { id: 52, question: "Quais são os valores da AJX Capital que corroboram para nossa entrega de solução como EDTECH?" },
  { id: 53, question: "Como gerar no atendimento (Interação/ Conexão e Relacionamento)?" },
  { id: 54, question: "Como despertar na entrega da solução? Curiosidade e Interesse." },
  { id: 55, question: "Como despertar a Necessidade na decisão de uma compra, ajustada e consciente, comprometida com a verdade?" },
  { id: 56, question: "O que é uma agenda positiva e fértil?" },
  { id: 57, question: "Quais as três visões do empreendedorismo da AJX?" },
  { id: 58, question: "Como atualizar seu modelo de negócios?" },
  { id: 59, question: "Dentro da cultura AJX o que é ousadia?" },
  { id: 60, question: "Dentro da cultura AJX o que é Dispersão e Frustração?" },
  { id: 61, question: "CCB é um investimento?" },
  { id: 62, question: "O que é uma Rotina Sofisticada e como atualizá-la?" },
  { id: 63, question: "Dentro da cultura AJX, descreva sobre:", options: ["Geração de valor:", "Geração de Riqueza:", "Faturamento:", "Receita:", "Lucro:"] },
  { id: 64, question: "Qual a diferença:", options: ["Intermediação:", "Emissão:"] },
  { id: 65, question: "Redação de 250 palavras e no máximo 300 palavras.", options: ["Tema: Qual a missão da AJX Capital a cerca do meio onde os nossos clientes estão inseridos. exemplo: comunidade, redes sociais, média renda e alta renda."] }
];
