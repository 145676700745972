<script>
import { vueTopprogress } from 'vue-top-progress';
import NoticiasMercadoTopBar from '@/components/NoticiasMercado/NoticiasMercadoTopBar.vue';
import videoService from '@/services/VideoContent/videoContentService';
import HLSCore from '@core-player/playcore-hls';
import Vue from 'vue';
import VueCoreVideoPlayer from 'vue-core-video-player';
import moment from 'moment-timezone';
import NoticiasMercadoVideosSugeridos from '@/components/NoticiasMercado/NoticiasMercadoVideosSugeridos.vue';

Vue.use(VueCoreVideoPlayer, {
  lang: 'pt-BR',
});

export default {
  components: {
    vueTopprogress,
    NoticiasMercadoTopBar,
    HLSCore,
    NoticiasMercadoVideosSugeridos,
  },
  data() {
    return {
      height: 800,
      width: 1200,
      options: {},
      playerReady: false,
      isLoading: false,
      fav: false,
      liked: false,
      favorite: false,
      message: '',
      HLSCore,
      isLoading: false,
      vimeo_player: false,
      video: {},
      isLoadingFav: false,
      isLoadingLike: false,
    };
  },
  computed: {
    videoId() {
      return this.$route.params.id;
    },
    loggedInUser() {
      return this.$store.getters.isLoggedIn;
    },
    investorLogged() {
      return this.$store.getters.isLoggedInvestor;
    },
  },
  mounted() {
    this.loadVideoDetails();
  },
  methods: {
    onReady() {
      this.playerReady = true;
    },
    play() {
      this.$refs.player.play();
    },
    stop() {
      this.$refs.player.stop();
    },
    async addNote() {
      let result = await videoService.addNote(
        this.videoId,
        this.message,
        this.loggedInUser.email,
      );

      this.$notify({
        title: 'Anotação',
        message: 'Anotação salva com sucesso',
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: 'success',
        timeout: 3000,
      });
    },
    async likeVideo() {
      if (this.isLoadingLike === true) return;

      this.isLoadingLike = true;
      this.liked = true;
      let result = await videoService.addLike(this.videoId);
      this.isLoadingLike = false;
    },
    async removeLikeVideo() {
      if (this.isLoadingLike === true) return;

      this.isLoadingLike = true;
      this.liked = false;
      let result = await videoService.removeLike(this.videoId);
      this.isLoadingLike = false;
    },
    async favVideo() {
      if (this.isLoadingFav === true) return;

      this.isLoadingFav = true;
      this.fav = true;
      await videoService.addFav(this.videoId);
      this.isLoadingFav = false;
    },
    async removeFavVideo() {
      if (this.isLoadingFav === true) return;

      this.isLoadingFav = true;
      this.fav = false;
      await videoService.removeFav(this.videoId);
      this.isLoadingFav = false;
    },
    handleGoBack() {
      this.$router.go(-1);
    },
    async loadVideoDetails() {
      this.isLoading = true;

      let result = await videoService.addView(this.videoId);

      this.videos = [];
      let fullVideoObject = await videoService.get(this.videoId);

      if (fullVideoObject.azure) {
        this.vimeo_player =
          fullVideoObject.azure.streamingUri.includes('vimeo');

        if (this.vimeo_player) {
          this.video.source = fullVideoObject.azure.streamingUri;
        } else {
          this.video.source =
            fullVideoObject.azure.streamingUri + '(format=m3u8-cmaf)';
        }
      }

      //console.log(this.video.source)
      this.video.title = fullVideoObject.title;
      this.video.totalViews = fullVideoObject.total_views || 0;
      this.video.description = fullVideoObject.description;
      this.video.publishDate = moment(
        fullVideoObject.publish_date || new Date(),
      ).format('DD/MM/YYYY');
      this.video.recorder = fullVideoObject.recorder;
      this.video.participants = fullVideoObject.participants;
      this.video.totalLikes = 0;

      if (fullVideoObject.likes) {
        this.video.totalLikes = fullVideoObject.likes.length;
      }

      if (
        fullVideoObject.likes &&
        fullVideoObject.likes.includes(this.loggedInUser.email)
      ) {
        this.liked = true;
      }

      if (
        fullVideoObject.favs &&
        fullVideoObject.favs.includes(this.loggedInUser.email)
      ) {
        this.fav = true;
      }

      if (fullVideoObject.notes) {
        for (let i = 0; i < fullVideoObject.notes.length; i++) {
          if (fullVideoObject.notes[i].email == this.loggedInUser.email) {
            this.message += fullVideoObject.notes[i].note;
          }
        }
      }

      this.isLoading = false;
    },
  },
};
</script>

<template>
  <div>
    <noticias-mercado-top-bar></noticias-mercado-top-bar>
    <div class="row" v-if="isLoading">
      <div class="col-md-12 loading-gif">
        <img src="@/assets/images/loading.gif" />
      </div>
    </div>
    <div class="row" v-if="!isLoading">
      <div class="col-lg-9 col-md-12 col-sm-12">
        <div class="col-md-12 details-goback">
          <i
            class="fa fa-arrow-left mr-3"
            @click="handleGoBack"
            style="color: darkblue; cursor: pointer"
          ></i>
          <h3 class="details-goback-title">Voltar</h3>
        </div>

        <div class="row">
          <!-- Video Player -->
          <div class="col-md-12">
            <div class="video-player-content">
              <vue-core-video-player
                v-if="!this.vimeo_player"
                :core="HLSCore"
                :src="video.source"
              ></vue-core-video-player>
              <vimeo-player
                v-if="this.vimeo_player"
                class="vimeo"
                ref="player"
                :video-id="video.source"
                @ready="onReady"
                :options="{ responsive: true }"
              >
              </vimeo-player>
            </div>
            <div class="video-player-actions">
              <div class="row">
                <div class="col-md-2 col-sm-6">
                  <label
                    title="Gostei"
                    class="no-margin"
                    v-on:click="likeVideo()"
                    v-if="!liked"
                    ><i
                      class="fa fa-regular fa-heart"
                      style="font-size: 18pt"
                    ></i
                  ></label>
                  <label
                    title="Remover Gostei"
                    class="no-margin"
                    v-on:click="removeLikeVideo()"
                    v-if="liked"
                    ><i
                      class="fa fa-light fa-heart"
                      style="font-size: 18pt; color: #f00"
                    ></i
                  ></label>
                  <label
                    title="Favoritar"
                    class="no-margin"
                    v-on:click="favVideo()"
                    v-if="!fav"
                    ><i
                      class="fa fa-regular fa-star"
                      style="font-size: 18pt; margin-left: 15px"
                    ></i
                  ></label>
                  <label
                    title="Remover Favoritar"
                    class="no-margin"
                    v-on:click="removeFavVideo()"
                    v-if="fav"
                    ><i
                      class="fa fa-light fa-star"
                      style="font-size: 18pt; color: #ff0; margin-left: 15px"
                    ></i
                  ></label>
                </div>
                <div class="col-md-2 col-sm-6"></div>
              </div>
            </div>
          </div>

          <!-- Video Details -->
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <h3 class="video-details-title">{{ video.title }}</h3>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label class="video-details-publish-date"
                  >Publicado em
                  <span class="badge badge-primary">{{
                    video.publishDate
                  }}</span></label
                >&nbsp;&nbsp;&nbsp;
                <label class="video-details-publish-date"
                  ><span class="badge badge-info"
                    >{{ video.totalViews }} Visualizações</span
                  ></label
                >
                <label
                  class="video-details-publish-date"
                  style="margin-left: 20px"
                  ><span class="badge badge-info"
                    >{{ video.totalLikes }} Gostei
                  </span></label
                >
                <br />
                <label
                  class="video-details-publish-date"
                  v-if="video.recorder != ''"
                  >Apresentado por:
                  <span class="badge badge-primary">{{
                    video.recorder
                  }}</span></label
                >
                <br />
                <label
                  class="video-details-publish-date"
                  v-if="video.participants != ''"
                  >Participantes:
                  <span class="badge badge-primary">{{
                    video.participants
                  }}</span></label
                >
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <h3 class="video-details-title-description">Descrição:</h3>
              </div>
              <div class="col-md-12">
                <div class="video-details-description">
                  <p v-html="video.description"></p>
                </div>
              </div>
            </div>
          </div>
          <!-- Video Comments -->
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <h3 class="comments-title">Anotações Pessoais</h3>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <textarea
                  v-model="message"
                  rows="10"
                  class="form-control commentario-video"
                  placeholder="Deixe sua anotação, somente você pode visualiar!"
                ></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <button type="submit" class="btn btn-sm" v-on:click="addNote()">
                  Salvar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- suggested videos -->
      <div class="col-lg-3">
        <noticias-mercado-videos-sugeridos></noticias-mercado-videos-sugeridos>
      </div>
    </div>
  </div>
</template>
<style>
.player {
  max-width: 800px;
  height: auto;
}

.commentario-video {
  padding: 10px !important;
  background-color: #fff !important;
  min-height: 100px !important;
}

.comments-title {
  padding-top: 40px;
  font-size: 1rem;
  color: #333;
  padding-bottom: 0px;
  font-weight: bold;
  margin-bottom: 0px;
}

.no-margin {
  margin: 0px;
}

.loading-gif {
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-player-content {
  width: 100%;
  max-height: auto;
  display: block;
}

.video-player-actions {
  width: 100%;
  padding: 15px;
  color: #333;
  font-size: 0.8rem;
  background-color: rgba(0, 0, 0, 0.2);
}

.video-details-title {
  padding: 10px 0px 0px 0px;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0px;
  color: #333;
}

.video-details-title-description {
  padding: 10px 0px 0px 0px;
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 0px;
  color: #333;
}

.video-details-publish-date {
  padding: 5px 0px 0px 0px;
  font-size: 0.8rem;
  font-weight: 500;
  color: #333;
}

.video-details-description {
  padding: 10px 0px 0px 0px;
  border-radius: 5px;
  color: #333;
  font-size: 0.8rem;
}

.video-details-description p {
  color: #333;
}

.player {
  width: 100% !important;
  height: 100% !important;
}

#player {
  width: 100% !important;
  height: 100% !important;
}

.details-goback-title {
  font-weight: bold;
  color: darkblue;
  font-size: 1rem;
  margin: 0px;
}

.details-goback {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
</style>
