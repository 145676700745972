<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';


import { quillEditor } from 'vue-quill-editor';
import playlistService from '@/services/VideoContent/playlistService';
import videoContentService from '@/services/VideoContent/videoContentService';
import SuccessNotification from '@/components/Notifications/General/SuccessNotification';
import { vueTopprogress } from 'vue-top-progress';
import draggable from 'vuedraggable'
import vSelect from 'vue-select';
import Vue from 'vue';
Vue.component('v-select', vSelect);

export default {
  data() {
    return {
      available: [
      ],
      linked: [
      ],
      playlist: {},
      broker_type: [],
      tiposDeAtivo : ["Sim", "Nao"],
      Deselect: {
        render: (createElement) => createElement('span', '❌'),
      },
      searchBar: ''
    };
  },
  computed: {
    playlistId() {
      return this.$route.params.id;
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    filteredData() {
      return this.available.filter(obj => {
        return (obj.name ? obj.name : '').toLowerCase().includes(this.searchBar.toLowerCase())
      });
    },
  },
  components: {
    vueTopprogress,
    quillEditor,
    draggable
  },
  methods: {
    log: function(evt) {
      //window.console.log(evt);
      this.playlist.linked = this.linked;
      console.log(this.playlist)
    },
    async addNew() {
      this.$refs.topProgress.start();

      try {
        if (this.broker_type.length === 0 || !this.broker_type) {
          this.$notify({
            title: 'Ooopss',
            message: 'Escolha ao menos um público',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 3000,
          });
          this.$refs.topProgress.done();
          return;
        } 
  
        this.playlist.broker_type = this.broker_type;

        if(this.playlistId) {
          this.playlist.changedTime = new Date();
          let added = await playlistService.update(this.playlistId, this.playlist);
          this.$router.push('/admin-playlists');
          } else {
          this.playlist.createdTime = new Date();
          this.playlist.changedTime = new Date();
          var added = await playlistService.add(this.playlist);

          this.$router.push('admin-playlists');
        }

        this.$refs.topProgress.done();

        this.$notify({
          component: SuccessNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        console.log(error);
        this.$refs.topProgress.done();

        this.$notify({
          title: 'Ooopss',
          message: error.message,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

    },
    async getVideosAvailable() {
      let vi = await videoContentService.getAll();
      for(let i = 0; i < vi.length; i++) {
        let v = vi[i]; 
        let found = false;
        if(this.playlist.linked) {
          found = this.playlist.linked.some(el => el.id === v.id);
        }
        
        console.log(found)
        if(!found) {
          this.available.push({ id : v.id, name : v.title })
        }
      }      
    },
    async getPlaylistDetails() {
      this.$refs.topProgress.start();
      try {
        if(this.playlistId) {
          const playlistDetails = await playlistService.get(this.playlistId);
          if(playlistDetails) {
            this.playlist = playlistDetails;

            if (!this.playlist.broker_type || this.playlist.broker_type.length === 0) {
              this.playlist.broker_type = ['Cliente']
            } else {
              this.broker_type = this.playlist.broker_type;
            }

            if(this.playlist.linked)  {
              this.linked = this.playlist.linked;
            }
          }
        }
      }catch(error) {
        console.log(error);
      }
      this.$refs.topProgress.done();
    }
  },
  
  async mounted() {
    if(this.playlistId) {
      this.getPlaylistDetails();
    }
    this.getVideosAvailable();
  },
  beforeDestroy() {
    
  }
};
</script>

<template>
  <div class="add-user">
    <card>
      <form @submit.prevent="addNew()">
        <h5 slot="header" class="title">
          <router-link to="/admin-playlists" class="fa fa-arrow-left"></router-link>
          Salvar Playlist
        </h5>


        <div class="row">
          <div class="col-md-12">
            <base-input
              label="Titulo*"
              placeholder="Titulo da playlist"
              v-model="playlist.title"
              required
            >
            </base-input>
          </div>
        </div>

        
        <div class="row">
          <div class="col-md-2">
            <base-input
                required
                label="Ativo*"
              >
                <v-select
                  label="uf"
                  class="style-chooser"
                  :options="tiposDeAtivo"
                  v-model="playlist.active"
                  :components="{ Deselect }"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      <em style="opacity: 0.9; color: #555"
                        ><b>{{ search }}</b> não encontrada</em
                      >
                    </template>
                  </template>
                </v-select>
              </base-input>
          </div>
          <div class="col-md-4">
            <base-input
                required
              label="Escolha a tag*"
            >
              <v-select
                label="uf"
                class="style-chooser"
                :options="['Universidade do saber', 'crm']"
                v-model="playlist.tag"
                :components="{ Deselect }"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    <em style="opacity: 0.9; color: #555"
                      ><b>{{ search }}</b> não encontrada</em
                    >
                  </template>
                </template>
              </v-select>
            </base-input>
          </div>
          <div 
            class="col-md-6 row mt-2" 
            style="display: flex; align-items: center; justify-content: center;"
          >
            <label class="mr-2">Para qual público:</label>

            <div v-for="(type, index) in ['Monetize', 'Life Office', 'Híbrido', 'Cliente']" :key="index">
              <input :id="type" class="mr-2" type="checkbox" :value="type" v-model="broker_type"/>
              <label :for="type" class="mr-2">{{ type }}</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <input type="text" class="form-control" placeholder="Para pesquisar, digite sua busca aqui..." v-model="searchBar" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <h3>Videos Disponiveis</h3>
            <draggable class="list-group" :list="filteredData" group="people" @change="log">
              <div
                class="list-group-item"
                v-for="(element, index) in filteredData"
                :key="index"
              >
                {{ element.name }}
              </div>
            </draggable>
          </div>
      
          <div class="col-6">
            <h3>Videos da Playlist</h3>
            <draggable class="list-group" :list="linked" group="people" @change="log">
              <div
                class="list-group-item"
                v-for="(element, index) in linked"
                :key="index"
              >
                {{ element.name }}
              </div>
            </draggable>
          </div>
        </div>

        <button type="submit" class="btn btn-sm">Salvar</button>
      </form>
    </card>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
