<script>
import { vueTopprogress } from 'vue-top-progress';
import { DoubleBounce } from 'vue-loading-spinner';
import { nonEmpty } from '@/validate/customValidators.js';
import { questionsArray } from '@/functions/questions.js';
import expiringStorage from '@/components/Tabs/expiringStorage';
import moment from 'moment-timezone';
import brokersService from '@/services/Brokers/brokersService';
import examService from '@/services/Exam/examService';

export default {
  components: {
    vueTopprogress,
    DoubleBounce,
  },
  data() {
    return {
      question_control: 0,
      question_answer: "",
      word_count: 0,
      exam: {
        name: "",
        date: "",
        answers: [],
        timeline: [],
      },
      exam_release: false,
      questions: questionsArray,
      progress: 0,
      letter_count: 0,
      disclaimer: false,
    };
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
    customStyle() {
      if (this.question_control === 0) {
        return {
          justifyContent: 'center'
        };
      } else {
        return {
          justifyContent: 'space-between'
        }
      }
    },
    borderStyle() {
      if (this.letter_count > 400 && this.question_control > 0 && this.question_control <= 64) {
        return { border: '1px solid #f06160', borderRadius: '10px', padding: '10px' };
      } else {
        return { border: '1px solid #ccc', borderRadius: '10px', padding: '10px' };;
      }
    },
  },
  async mounted() {
    await this.getBrokerExam();
    const storagedAnswers = expiringStorage.get("@AJX:exam");
    
    if (storagedAnswers && storagedAnswers.length > 0) {
      this.question_control = storagedAnswers[storagedAnswers.length - 1].id + 1;
      this.exam.answers = storagedAnswers;

      this.progress = Math.floor((this.exam.answers.length / 65) * 100);
    } else {
      const findExamFirebase = await examService.getExamByEmail(this.currentPessoa.email.email);
      if (findExamFirebase) {
        this.question_control = findExamFirebase.answers[findExamFirebase.answers.length - 1].id + 1;
        this.exam.answers = findExamFirebase.answers;
      }
    }
  },
  validations: {
    exam: {
      name: {
        valid: (name) => nonEmpty(name),
      },
      date: {
        valid: (date) => nonEmpty(date),
      },
    },
  },
  methods: {
    async handleExam() {
      const pessoa = this.currentPessoa;
      const findQuestion = this.exam.answers.findIndex(answer => answer.id === this.question_control);
      const findNextQuestion = this.exam.answers.find(answer => answer.id === this.question_control + 1);
      const answer = { id: this.question_control, answer: this.question_answer };

      if (this.question_control === 0) {
        const date = moment().tz('America/Sao_Paulo').format('DD-MM-YYYY HH:mm');
        const examStarted = `exam-started: ${date}`;
        Object.assign(this.exam, { name: pessoa.nome, date, exam_progress: "Em andamento", exam_approval: "Prova não finalizada", timeline: [examStarted] });
        
        await examService.createExamTemporary({ ...this.exam, email: this.currentPessoa.email.email });

        await brokersService.updateBrokerByEmail({ exam: this.exam }, this.currentPessoa.email.email);

        this.question_control = this.question_control + 1;

        return;
      }

      if (findQuestion !== -1) {
        this.exam.answers[findQuestion] = answer;
        expiringStorage.set("@AJX:exam", this.exam.answers, 4320);
        await examService.updateExamByEmail(this.exam, this.currentPessoa.email.email);

      } else {
        this.exam.answers.push(answer);
        expiringStorage.set("@AJX:exam", this.exam.answers, 4320);
        await examService.updateExamByEmail(this.exam, this.currentPessoa.email.email);

        this.progress = Math.floor((this.exam.answers.length / 65) * 100) > 100 ? 100 : Math.floor((this.exam.answers.length / 65) * 100);
      }

      if (this.question_control === 65) {
        const date = moment().tz('America/Sao_Paulo').format('DD-MM-YYYY HH:mm');
        const examEnded = `exam-ended: ${date}`;
        Object.assign(this.exam, { exam_progress: "Finalizado", exam_approval: "Pendente", disclaimer: this.disclaimer, timeline: [...this.exam.timeline, examEnded] });

        await brokersService.updateBrokerByEmail({ exam: this.exam }, this.currentPessoa.email.email);
        localStorage.removeItem('@AJX:exam');
      }

      this.question_answer = findNextQuestion ? findNextQuestion.answer : "";
      this.word_count = findNextQuestion ? this.word_count : 0;
      this.question_control = this.question_control + 1;
      if (this.question_control === 66) {
        localStorage.removeItem('@AJX:exam');
      }
    },
    async getBrokerExam() {
      const broker = await brokersService.getBrokerByEmail(this.currentPessoa.email.email);
      this.exam = { name: "",
        date: "",
        answers: [], 
        timeline: [],
      };

      this.exam_release = broker.exam_release;

      if (broker.exam && broker.exam.exam_progress === 'Em andamento') {
        this.question_control = 1;
      }
    },
    countWords() {
      const text = this.question_answer.trim();

      const words = text.match(/\S+/g);

      this.word_count = words ? words.length : 0;
    },
    countLetters() {
      this.letter_count = this.question_answer.length;
    },
    renderButtonText(question_control, exam_release) {
      if (exam_release === false) {
        return 'Prova não liberada, consulte um administrador';
      }

      switch(question_control) {
        case 0:
          return "Iniciar prova";
        case 65:
          return "Finalizar prova";
        case 66: 
          return "Aguardando avaliação ou aprovado/reprovado";
        default:
          return "Avançar"; 
      }
    },
    goBackQuestion() {
      this.question_control = this.question_control - 1;

      const findQuestion = this.exam.answers.filter(answer => answer.id === this.question_control);

      this.question_answer = findQuestion && findQuestion.length > 0 ? findQuestion[0].answer : "";
    }
  },
};
</script>

<template>
  <div class="add-user">
    <card>
      <form @submit.prevent="handleExam()">
        <div class="col" v-if="question_control === 0 || question_control > 65">
          <h1>Olá, {{currentPessoa.nome}}!</h1>
          <p>Seja bem-vindo à <strong>Avaliação para Especialista em Mentalidade Financeira da AJX Capital.</strong><br>Esta prova tem como objetivo testar seus conhecimentos e habilidades sobre o mundo financeiro e a cultura da nossa empresa.</p><br>
          <p>A prova é composta por 65 questões, cada uma relacionada a aspectos importantes da área financeira e da atuação da AJX no mercado. Você encontrará questões sobre fintechs, edtechs, cultura organizacional, produtos financeiros, regulamentações, liderança e muito mais.</p><br>
          <p>É importante responder com atenção e sinceridade, pois o resultado desta prova será utilizado para avaliar sua adequação ao cargo de Especialista em Mentalidade Financeira na AJX Capital.</p><br>
          <p>A seguir, você encontrará todas as questões numeradas e detalhadamente elaboradas. Você terá a oportunidade de responder cada uma delas e demonstrar seu conhecimento e experiência.</p><br>
          <p>Lembramos que suas respostas são salvas automaticamente ao avançar em cada questão e ficam salvas por 3 dias. Caso deseje revisar alguma questão, você pode utilizar o botão de voltar para retornar a questões anteriores.</p><br>
          <p>Após revisar todas as questões e se sentir confiante em suas respostas, clique no botão de finalizar para concluir a prova e enviá-la para avaliação.</p><br>
          <p>Desejamos uma excelente prova e muito sucesso nesta avaliação!</p><br>
          <p>Atenciosamente,</p>
          <p><strong>Equipe da AJX Capital</strong></p><br>
        </div>

        <div style="display: flex; justify-content: space-between; align-items: center;" v-if="question_control > 0 && question_control <= 65">
          <div>
            <strong>{{ questions[question_control - 1].id }}. {{ questions[question_control - 1].question }}</strong>
            <template v-if="questions[question_control - 1].options" v-for="(option, index) in questions[question_control - 1].options">
              <br>{{ option }}
            </template>
          </div>
          <vue-ellipse-progress 
            :progress="progress" 
            :size="40"
          >
            <span slot="legend-value">%</span>
          </vue-ellipse-progress>
        </div>

        <textarea
          type="text"
          rows="3"
          class="form-control mt-2"
          placeholder="Resposta"
          :style="borderStyle"
          @input="countWords(); countLetters()"
          v-model="question_answer"
          v-if="question_control > 0 && question_control <= 65"
        >
        </textarea>

        <div style="display: flex; align-items: center;" v-if="question_control > 0 && question_control <= 65">
          <p class="mr-4">Contagem de palavras: {{ word_count }}</p>
          <p class="mr-4" v-if="question_control > 0 && question_control <= 64">Contagem de caracteres: {{ letter_count }}/400</p>
          <p style="color: red" v-if="letter_count > 400 && question_control > 0 && question_control <= 64">Resposta excedeu o limite de caracteres</p>
        </div>

        <div class="mb-1 text-end" v-if="question_control === 65">
          <base-checkbox
            v-model="disclaimer"
          >
            Deseja assinar eletronicamente e enviar a avaliação para revisão do Administrador? (essa operação não poderá ser revertida)
          </base-checkbox>
        </div>

        <div style="display: flex; align-items: center; width: 100%;" :style="customStyle">
          <button type="button" class="btn btn-sm ml-0" v-if="question_control > 0 && question_control <= 65" :disabled="question_control === 1" @click="goBackQuestion()">Voltar</button>
          <button type="submit" class="btn btn-sm ml-0" :disabled="question_control > 65 || (letter_count > 400 && question_control < 65) || exam_release === false">{{ renderButtonText(this.question_control, this.exam_release) }}</button>
        </div>
      </form>
    </card>
  </div>
</template>

<style>
.red-border {
  border: 1px solid #f06160;
}
</style>
