import firebase from 'firebase';
import { withLog } from '../../logger/with_log';

import { api } from '@/services/ServiceConfig/ServiceConfig';

export default {
  valuesRef() {
    return firebase.firestore().collection('values');
  },

  async getAllValues() {
    const { data } = await api.get('/values');

    return data.values;
  },

  createNewValue(value) {
    withLog(() => this.valuesRef().add(value));
  },

  deleteValue(value) {
    withLog(() => this.valuesRef().doc(value).delete());
  },

  getValue(id) {
    return withLog(() => this.valuesRef().doc(id).get());
  },

  updateValue(id, value) {
    return withLog(() => this.valuesRef().doc(id).update(value));
  },
};
