<script>
import invoicesService from '@/services/Invoices/invoicesService';
import SuccessNotification from '@/components/Notifications/General/SuccessNotification';
import ErrorRegisterNotification from '@/components/Notifications/General/ErrorRegisterNotification';
import {vueTopprogress} from 'vue-top-progress';
import logsService from '@/services/Logs/logsService';
import { nonEmpty } from '@/validate/customValidators.js';
import { DoubleBounce } from 'vue-loading-spinner';

export default {
  data() {
    return {
      invoice: {
        date: "",
      },
      invoice_file: {},
    };
  },
  mounted() {
  },
  validations: {
    invoice: {
      date: {
        valid: (date) => nonEmpty(date),
      },
    },
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
    isFormValid() {
      return (
        !this.$v.invoice.date.valid
      );
    },
  },
  components: {
    vueTopprogress,
    DoubleBounce,
  },
  methods: {
    async addNewInvoice() {
      this.$refs.topProgress.start();

      try {
        const logUser = {
          user: {
            email: this.currentPessoa.email.email
          }
        };
        logsService.createNewLog(logUser, '', 'Criar Nota fiscal', '', this.invoice);

        if (this.invoice_file) {
          await invoicesService.uploadInvoiceToStorage(this.invoice_file);
          this.invoice.filename = this.invoice_file.name;
        }

        await invoicesService.createNewInvoice(this.invoice);

        this.$refs.topProgress.done();

        this.$router.push('invoices');

        this.$notify({
          component: SuccessNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        console.log(error);
        this.$refs.topProgress.done();
        this.$notify({
          component: ErrorRegisterNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
    handleFileUpload(event){
      const file = event.target.files[0]
      const dividedName = file.name.split(".");
      const extension = dividedName.pop();
      const newName = Date.now();
      const newFile = new File([file], `${newName}.${extension}`, { type: file.type });
      this.invoice_file = newFile;
    },
  },
  watch: {
    data() {
      this.isFormValid()
    }
  }
};
</script>

<template>
  <div class="add-user">
    <card>
      <form @submit.prevent="addNewInvoice()">
        <h5 slot="header" class="title">
          <router-link to="/invoices" class="fa fa-arrow-left"></router-link>
          Adicionar Nota Fiscal
        </h5>

        <div class="row">
          <div class="col-md-4 pr-md-1 mb-2">
            <base-input label="Competência">
              <date-picker
                v-model="invoice.date"
                type="month"
                valueType="format"
                format="MM/YYYY"
              >
              </date-picker>
            </base-input>
          </div>

          <div class="col-md-12">
            <label>Nota fiscal (somente pdf)
              <input 
                type="file" 
                class="form-control" 
                accept=".pdf"
                @change="handleFileUpload( $event )"
              />
            </label>
          </div>
        </div>

        <button type="submit" class="btn btn-sm" :disabled="isFormValid">Adicionar</button>
      </form>
    </card>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
