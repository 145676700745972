import firebase from 'firebase';
import { withLog } from '../../logger/with_log';

import { api } from '@/services/ServiceConfig/ServiceConfig';

export default {
  investmentProfileRef() {
    return firebase.firestore().collection('investments-profile');
  },

  async getAllInvestments() {
    const { data } = await api.get('/profiles');

    return data.profiles;
  },

  createNewInvestment(investment) {
    withLog(() => this.investmentProfileRef().add(investment));
  },

  deleteInvestment(investment) {
    withLog(() => this.investmentProfileRef().doc(investment).delete());
  },

  getInvestment(id) {
    return withLog(() => this.investmentProfileRef().doc(id).get());
  },

  updateInvestment(id, investment) {
    return withLog(() =>
      this.investmentProfileRef().doc(id).update(investment),
    );
  },
};
