<template>
  <div>
    <a 
      href="/exam" 
      class="nav-link" 
      v-if="userClaim === 'master' || userClaim === 'gestor' || (userClaim === 'assessor' && this.broker_exam_release)"
    >
      {{ $t('sidebar.exam') }}
    </a>
    <a 
      href="/survey" 
      class="nav-link"
      v-if="userClaim === 'assessor'"
    >
      {{ $t('sidebar.survey')}}
    </a>
  </div>
</template>

<script>
import brokersService from '@/services/Brokers/brokersService';

export default {
  name: 'sidebar-submenu-feedback-exams',
  data() {
    return {
      broker_exam_release: false
    };
  },
  computed: {
    userClaim() {
      const Claim = this.$store.getters.userClaim;

      return Claim.role;
    },
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
  },
  methods: {
    async getBroker() {
      const broker = await brokersService.getBrokerByEmail(this.currentPessoa.email.email);
      this.broker_exam_release = broker.exam_release;
    }
  },
  mounted() {
    this.getBroker();
  },
};
</script>
