<script>
import ValuesTable from '@/components/Values/ValuesTable';
import valuesService from '@/services/Values/valuesService';

import DeleteValueErrorNotification from '@/components/Notifications/Values/DeleteValueErrorNotification';
import DeleteValueSuccessNotification from '@/components/Notifications/Values/DeleteValueSuccessNotification';
import EmptyValuesNotification from '@/components/Notifications/Values/EmptyValuesNotification';

import {vueTopprogress} from 'vue-top-progress';
import {DoubleBounce} from 'vue-loading-spinner';

const tableColumns = ['Valores'];

export default {
  components: {
    ValuesTable,
    vueTopprogress,
    DoubleBounce,
  },
  data() {
    return {
      table: {
        title: 'Lista de Valores para Investir',
        columns: [...tableColumns],
      },
      values: [],
      isLoading: true,
      closeModal: false,
    };
  },
  mounted() {
    this.getValues();
  },
  methods: {
    getValues() {
      this.values = [];
      this.$refs.topProgress.start();

      valuesService.getAllValues()
          .then(function(values) {
            if (values.empty) {
              this.$notify({
                component: EmptyValuesNotification,
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'warning',
                timeout: 3000,
              });
            }

            this.values = values
            this.isLoading = false;
            this.$refs.topProgress.done();
          }.bind(this));
    },
    deleteValue(preDeletedItem) {
      try {
        valuesService.deleteValue(preDeletedItem.item.id);

        this.getValues();

        this.$notify({
          component: DeleteValueSuccessNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        preDeletedItem = {};
      } catch (error) {
        this.$notify({
          component: DeleteValueErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<template>
  <div class="users-list">
    <div class="row">
      <div class="col-12">
        <card
          :title="table.title"
          :addItems="true"
          :itemType="'Valores'"
          :routerType="'values'"
        >
          <div class="table-responsive">
            <values-table
              :data="values"
              :columns="table.columns"
              :hasActions="true"
              thead-classes="text-primary"
              @deleteItem="deleteValue"
            ></values-table>

            <double-bounce v-if="isLoading"></double-bounce>
          </div>
        </card>
      </div>
    </div>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
