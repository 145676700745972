<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import settingsService from '@/services/Settings/settingsService';

import { vueTopprogress } from 'vue-top-progress';
import { quillEditor } from 'vue-quill-editor';
import { cnpj } from '@/validate/customValidators.js';
import { withLog } from '@/logger/with_log';
import investorsService from '@/services/Investors/investorsService';
import hubspotService from '@/services/HubSpot/hubspotService';
import Tabs from '@/components/Tabs/Tabs';
import Tab from '@/components/Tabs/Tab';
import Modal from '@/components/Modal';

import {
  CWLTipoTaxaPagamento, CWLInvestimentoDashboard
} from '@/store/cwl_enums';

// import pdfvuer from 'pdfvuer';
// import {DoubleBounce} from 'vue-loading-spinner';

export default {
  components: {
    vueTopprogress,
    quillEditor,
    'zz-tabs': Tabs,
    'zz-tab': Tab,
    Modal,
    // DoubleBounce,
    // pdf: pdfvuer,
  },
  data() {
    return {
      contentTermosUso: '',
      contentTaxasPrazo: '',
      contentTermos4A: '',
      contentTermos4B: '',
      contentTermos4C: '',
      contentSocialAgent: '',
      editorCondicoesTermosUsoOption: {
        placeholder:
          'Descreva aqui as condições e termos de uso da plataforma.',
      },
      editorTaxasPrazosOption: {
        placeholder: 'Descreva aqui as taxas e prazos da plataforma.',
      },
      editorTermosAgenteOption: {
        placeholder: 'Descreva aqui os termos de serviço para Agente (API3).',
      },
      editorTermoUso4A: {
        placeholder: 'Descreva aqui os termos.',
      },
      editorTermoUso4B: {
        placeholder: 'Descreva aqui os termos.',
      },
      editorTermoUso4C: {
        placeholder: 'Descreva aqui os termos.',
      },
      settingsPublic: {
        overwriteMinGlobalInvestment: undefined,
      },
      settings: {
        integracao: {
          safe2pay: {
            safe2pay_api_keys: {},
            credit_card: {},
            doc: {},
            ted: {},
            pix: {}
          }
        },
        terms: {},
        emails: {},
        _features: {},
      },
      modalidade: process.env.VUE_APP_TYPE,
      submodalidade: process.env.VUE_APP_SUBTYPE,
      showSafe2PayModal: false,
      settingsLoaded: false,

      CWLTipoTaxaPagamento,
      CWLInvestimentoDashboard
    };
  },
  validations: {
    settings: {
      cnpj: {
        valid: cnpj,
      },
    },
  },
  methods: {
    onEditorBlur() {},
    onEditorFocus() {},
    onEditorReady() {},
    onEditorChange({ html }) {
      this.contentTermosUso = html;
      this.settings.terms.condicoes_termos_uso = html;
    },
    onTaxasPrazoEditorChange({ html }) {
      this.contentTaxasPrazo = html;
      this.settings.terms.taxas_prazos = html;
    },
    onEditorAgentChange({ html }) {
      this.contentSocialAgent = html;
      this.settings.terms.agent_term = html;
    },
    onTermos4AEditorChange({ html }) {
      this.contentTermos4A = html;
      this.settings.termos_4a = html;
    },

    onTermos4BEditorChange({ html }) {
      this.contentTermos4B = html;
      this.settings.termos_4b = html;
    },

    onTermos4CEditorChange({ html }) {
      this.contentTermos4C = html;
      this.settings.termos_4c = html;
    },

    async loadSettings() {
      this.settingsPublic = await settingsService.getPublicSettings();
      var settings = await settingsService.getPrivateSettings();
      this.settings = {
        ...settings,
        emails: settings.emails || {},
        terms: settings.terms || {},
        _features: settings._features || {},
      };
      this.settingsLoaded = true;
    },
    checkSandboxAtivo() {
      this.$refs.marketplace_token_sandbox_active.click();
    },
    checkEmailSecure() {
      this.$refs.email_secure.click();
    },
    checkOverwriteGlobalInvestment() {
      this.$refs.overwriteMinGlobalInvestment.click();
    },
    checkValidarLimiteInvestimento() {
      this.$refs.validarLimiteInvestimento.click();
    },
    checkIsBancarizadorManual() {
      this.$refs.isBancarizadorManual.click();
    },
    async integrarContatosCRM() {
      let investors = await investorsService.getAllInvestorsUsuario();
      if ( !investors.empty ) {
        for ( const investor of investors.docs ) {
          const pessoa = investor.data();

          try {
            if ( pessoa.id ) {
              await hubspotService.cirarContatoAPI(pessoa.id);
            }
          }
          catch (error) {
            console.log(error);
          }
        }
      }
    },
    async save() {
      if (!this.$v.settings.cnpj.valid) {
        return this.$notify({
          title: 'Informe um CNPJ válido!',
          message: 'Informe um número de CNPJ válido.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 7000,
        });
      }

      this.$refs.topProgress.start();
      try {
        await settingsService.updatePublicSettings(this.settingsPublic);
        await settingsService.updatePrivateSettings(this.settings);

        this.$refs.topProgress.done();
        this.$notify({
          title: 'Configurações atualizadas!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (e) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },

    funcionalidadesCheck(element) {
      let elementType = element.srcElement.getAttribute('data-type');
      if ( elementType == "permitir" ) {
        let ref = element.srcElement.getAttribute('for');
        this.funcionalidadesCheckPermitir(this.$refs[ref]);
      }
    },
    funcionalidadesCheckPermitir(element) {
      element.click();
    },

    integracaoSafe2PayCheck(element) {
      let elementType = element.srcElement.getAttribute('data-type');
      if ( elementType == "habilitar" ) {
        let ref = element.srcElement.getAttribute('for');
        this.integracaoSafe2PayCheckHabilitar(this.$refs[ref]);
      }
    },
    integracaoSafe2PayCheckHabilitar(element) {
      element.click();
    },
  },
  computed: {
    editor() {
      return this.$refs.quillEditor.quill;
    },
  },
  mounted() {
    this.loadSettings();
  },
};
</script>

<template>
  <div class="add-user">
    <form @submit.prevent="save()">
      <div style="text-align: right;">
        <button type="submit" class="btn btn-sm mt-3">
          Salvar configurações
        </button>
      </div>

      <div>
        <zz-tabs>
          <zz-tab id="configuracoes-gerais" name="Configurações Gerais">
              
                <div class="row">
                  <div class="col-md-3 mt-3">
                    <div class="form-group">
                      <base-input
                        label="CNPJ da Plataforma"
                        placeholder="Informe o CNPJ"
                        v-model="settings.cnpj"
                      >
                      </base-input>
                      <div
                        class="error"
                        v-if="settings.cnpj != '' && $v.settings.cnpj.$invalid"
                      >
                        Informe um CNPJ válido.
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 mt-3">
                    <base-input
                      label="Nome da empresa da Plataforma"
                      placeholder="Informe o nome da empresa"
                      v-model="settings.companyName"
                    >
                    </base-input>
                  </div>
                  <div class="col-md-3 mt-3">
                    <base-input
                      label="Nome da Plataforma"
                      placeholder="Informe o nome da plataforma"
                      v-model="settings.platformName"
                    >
                    </base-input>
                  </div>
                  <div class="col-md-3 mt-3">
                    <base-input
                      label="Identificação da Plataforma"
                      placeholder="Informe a identificação"
                      v-model="settings.platformIdentifier"
                    >
                    </base-input>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-3 mt-3">
                    <base-input
                      label="Site da Plataforma"
                      placeholder="Informe o site"
                      v-model="settings.site"
                    >
                    </base-input>
                  </div>

                  <div class="col-md-3 mt-3">
                    <label> Investimento Mínimo Permitido </label>
                    <currency-input
                      placeholder="Informe o valor mínimo"
                      v-model="settingsPublic.minGlobalInvestment"
                      currency="BRL"
                      locale="pt-br"
                      :auto-decimal-mode="true"
                      class="form-control"
                    />
                  </div>

                  <div class="col-md-3 mt-3">
                    <base-input
                      label="Material Didático"
                      placeholder="Informe o link"
                      v-model="settings.link_material_didatico"
                    >
                    </base-input>
                  </div>

                  <div class="col-md-3 mt-3">
                    <base-input
                      label="Investimentos Dashboard"
                    >
                      <select
                        class="form-control"
                        v-model="settings.investimento_dashboard"
                        required
                      >
                        <option :value="CWLInvestimentoDashboard.Pagos">Apresentar somente investimentos pagos</option>
                        <option :value="CWLInvestimentoDashboard.PagosPendentes">Apresentar investimentos pagos e pendentes</option>
                      </select>
                    </base-input>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12 mt-12">
                    <input
                      name="overwriteMinGlobalInvestment"
                      ref="overwriteMinGlobalInvestment"
                      type="checkbox"
                      v-model="settingsPublic.overwriteMinGlobalInvestment"
                    />
                    <label
                      for="overwriteMinGlobalInvestment"
                      style="margin-left: 5px"
                      @click="checkOverwriteGlobalInvestment"
                    >
                      Permitir Sobrescrever Investimento Mínimo Permitido</label
                    >
                  </div>
                  <div class="col-md-12 mt-12">
                    <input
                      name="validarLimiteInvestimento"
                      ref="validarLimiteInvestimento"
                      type="checkbox"
                      v-model="settingsPublic.validar_limite_investimento"
                    />
                    <label
                      for="validarLimiteInvestimento"
                      style="margin-left: 5px"
                      @click="checkValidarLimiteInvestimento"
                    >
                      Travar investimentos maiores que o limite</label
                    >
                  </div>
                  <div class="col-md-12 mt-12">
                    <input
                      name="isBancarizadorManual"
                      ref="isBancarizadorManual"
                      type="checkbox"
                      v-model="settings.is_bancarizador_manual"
                    />
                    <label
                      for="isBancarizadorManual"
                      style="margin-left: 5px"
                      @click="checkIsBancarizadorManual"
                    >
                      Integração com Bancarizador é manual</label
                    >
                  </div>
                </div>

                <div class="row" v-if="settings.is_bancarizador_manual">
                  <div class="col-md-3 mt-3">
                    <base-input
                      label="Razão Social (Credor)"
                      placeholder="Informe a razão social"
                      v-model="settings.bancarizador_razao_social"
                    >
                    </base-input>
                  </div>
                  <div class="col-md-3 mt-3">
                    <base-input
                      label="CNPJ (Credor)"
                      placeholder="Informe o CNPJ"
                      v-model="settings.bancarizador_cnpj"
                    >
                    </base-input>
                  </div>
                  <div class="col-md-3 mt-3">
                    <base-input
                      label="Endereço (Credor)"
                      placeholder="Informe o endereço"
                      v-model="settings.bancarizador_endereco"
                    >
                    </base-input>
                  </div>
                  <div class="col-md-3 mt-3">
                    <base-input
                      label="CEP (Credor)"
                      placeholder="Informe o CEP"
                      v-model="settings.bancarizador_cep"
                    >
                    </base-input>
                  </div>
                  <div class="col-md-3 mt-3">
                    <base-input
                      label="Cidade (Credor)"
                      placeholder="Informe a cidade"
                      v-model="settings.bancarizador_cidade"
                    >
                    </base-input>
                  </div>
                  <div class="col-md-3 mt-3">
                    <base-input
                      label="UF (Credor)"
                      placeholder="Informe o UF"
                      v-model="settings.bancarizador_uf"
                    >
                    </base-input>
                  </div>
                  <div class="col-md-3 mt-3">
                    <base-input
                      label="Natureza (Credor)"
                      placeholder="Informe a natureza"
                      v-model="settings.bancarizador_natureza"
                    >
                    </base-input>
                  </div>
                  <div class="col-md-3 mt-3">
                    <base-input
                      label="Praça Pagamento (Credor)"
                      placeholder="Informe a praça de pagamento"
                      v-model="settings.bancarizador_praca_pagamento"
                    >
                    </base-input>
                  </div>
                  <div class="col-md-3 mt-3">
                    <base-input
                      label="E-mail (Credor)"
                      placeholder="Informe e-mail"
                      v-model="settings.bancarizador_email"
                    >
                    </base-input>
                  </div>
                </div>
               
          </zz-tab>
          <zz-tab id="temos" name="Termos">
              
                <div class="row">
                  <div class="col-md-12 mt-3">
                    <label for="quillEditor">Condições e Termos de Uso</label>
                    <quill-editor
                      ref="quillEditor"
                      v-model="settings.terms.condicoes_termos_uso"
                      :options="editorCondicoesTermosUsoOption"
                      @blur="onEditorBlur($event)"
                      @focus="onEditorFocus($event)"
                      @ready="onEditorReady($event)"
                      @change="onEditorChange($event)"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12 mt-3">
                    <label for="quillEditor">Taxas e Prazos</label>
                    <quill-editor
                      ref="quillEditor"
                      v-model="settings.terms.taxas_prazos"
                      :options="editorTaxasPrazosOption"
                      @blur="onEditorBlur($event)"
                      @focus="onEditorFocus($event)"
                      @ready="onEditorReady($event)"
                      @change="onTaxasPrazoEditorChange($event)"
                    />
                  </div>
                </div>

                <h5 slot="header" class="subtitle">
                <b>Variáveis:</b> #data, #local, #nomeInvestidor, #cpfInvestidor,
                #razaoSocialEmissor, #montante
              </h5>

              <div class="row">
                <div class="col-md-12 mt-3">
                  <label for="quillEditor">Autodeclaração - Anexo 4-A</label>
                  <quill-editor
                    ref="quillEditor"
                    v-model="settings.termos_4a"
                    :options="editorTermoUso4A"
                    @blur="onEditorBlur($event)"
                    @focus="onEditorFocus($event)"
                    @ready="onEditorReady($event)"
                    @change="onTermos4AEditorChange($event)"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 mt-3">
                  <label for="quillEditor">Autodeclaração - Anexo 4-B</label>
                  <quill-editor
                    ref="quillEditor"
                    v-model="settings.termos_4b"
                    :options="editorTermoUso4B"
                    @blur="onEditorBlur($event)"
                    @focus="onEditorFocus($event)"
                    @ready="onEditorReady($event)"
                    @change="onTermos4BEditorChange($event)"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 mt-3">
                  <label for="quillEditor">Autodeclaração - Anexo 4-C</label>
                  <quill-editor
                    ref="quillEditor"
                    v-model="settings.termos_4c"
                    :options="editorTermoUso4C"
                    @blur="onEditorBlur($event)"
                    @focus="onEditorFocus($event)"
                    @ready="onEditorReady($event)"
                    @change="onTermos4CEditorChange($event)"
                  />
                </div>
              </div>
              
          </zz-tab>
          <zz-tab id="agente-api3" name="Agente (API3)" v-if="modalidade == 'doacao'">

                <div class="row">
                  <div class="col-md-12 mt-3">
                    <label for="quillEditor">Termos de Parceria de Serviço</label>
                    <quill-editor
                      ref="quillEditor"
                      v-model="settings.terms.agent_term"
                      :options="editorTermosAgenteOption"
                      @blur="onEditorBlur($event)"
                      @focus="onEditorFocus($event)"
                      @ready="onEditorReady($event)"
                      @change="onEditorAgentChange($event)"
                    />
                  </div>
                </div>
          </zz-tab>
          <zz-tab id="integracao" name="Integração">

              <div class="row">
                <div class="col-lg-2 col-md-3">
                  <div class="row align-items-center integracao-botao-abrir-modal" @click="showSafe2PayModal = true">
                    <div class="col-md-9">
                      <img src="@/assets/images/integracao_logo_safe2pay.png" class="integracao-botao-abrir-modal-logo" />
                    </div>
                    <div class="col-md-3 text-right" style="margin-top: 0 !important;">
                      <img src="@/assets/images/check.png" class="integracao-botao-abrir-modal-img-checked"
                        v-if="
                          settingsLoaded &&
                          settings.integracao.safe2pay.safe2pay_api_keys.marketplace_token_prd && settings.integracao.safe2pay.safe2pay_api_keys.marketplace_token_prd.length > 0 &&
                          settings.integracao.safe2pay.safe2pay_api_keys.marketplace_token_sandbox && settings.integracao.safe2pay.safe2pay_api_keys.marketplace_token_sandbox.length > 0
                        "/>
                      <img src="@/assets/images/arrow-right.png" class="integracao-botao-abrir-modal-img-not-checked"
                        v-if="
                          settingsLoaded && (
                            !settings.integracao.safe2pay.safe2pay_api_keys.marketplace_token_prd || settings.integracao.safe2pay.safe2pay_api_keys.marketplace_token_prd.length == 0 ||
                            !settings.integracao.safe2pay.safe2pay_api_keys.marketplace_token_sandbox || settings.integracao.safe2pay.safe2pay_api_keys.marketplace_token_sandbox.length == 0
                          )
                        "/>
                    </div>
                  </div>
                </div>
              </div>
          </zz-tab>
          <zz-tab id="email" name="E-mail">
            
              <div class="row">
                <div class="col-md-3 mt-3">
                  <base-input
                    label="Host"
                    placeholder="Informe o host"
                    v-model="settings.emails.host"
                  >
                  </base-input>
                </div>
                <div class="col-md-2 mt-3">
                  <base-input
                    label="Porta"
                    placeholder="Informe a porta"
                    v-model="settings.emails.porta"
                    v-mask="'####'"
                  >
                  </base-input>
                </div>
                <div class="col-md-3 mt-3">
                  <base-input
                    label="Usuário"
                    placeholder="Informe o usuário"
                    v-model="settings.emails.usuario"
                  >
                  </base-input>
                </div>
                <div class="col-md-3 mt-3">
                  <base-input
                    label="Senha"
                    placeholder="Informe a senha"
                    v-model="settings.emails.senha"
                  >
                  </base-input>
                </div>
                <div class="col-md-1 mt-3">
                  <div class="form-group">
                    <label class="control-label"> Secure </label>
                    <input
                      class="form-control"
                      style="width: 20px; height: 20px; margin-left: 5px; margin-top: 10px;"
                      ref="email_secure"
                      type="checkbox"
                      v-model="settings.emails.secure"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4 mt-3">
                  <base-input
                    label="'From' dos e-mails"
                    placeholder="Informe aqui"
                    v-model="settings.emails.from"
                  >
                  </base-input>
                </div>
                <div class="col-md-4 mt-3">
                  <base-input
                    label="Título dos e-mails"
                    placeholder="Informe o título"
                    v-model="settings.emails.titulo"
                  >
                  </base-input>
                </div>
                <div class="col-md-4 mt-3">
                  <base-input
                    label="Rodapé dos e-mails"
                    placeholder="Informe o rodapé"
                    v-model="settings.emails.rodape"
                  >
                  </base-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-8 mt-3">
                  <div class="form-group">
                    <label class="control-label"> Aviso acima do Rodapé </label>
                    <textarea
                      type="text"
                      cols="100"
                      rows="5"
                      class="form-control"
                      placeholder="Informe o aviso"
                      style="border: 1px solid #ccc; border-radius: 10px"
                      v-model="settings.emails.aviso_acima_rodape"
                    >
                    </textarea>
                  </div>
                </div>
                <div class="col-md-2 mt-3">
                  <base-input
                    label="Cor do e-mail (hex)"
                    placeholder="Ex: #288cf7"
                    v-model="settings.emails.cor"
                  >
                  </base-input>
                </div>
                <div class="col-md-2 mt-3">
                  <base-input
                    label="Cor da fonte (hex)"
                    placeholder="Ex: #ffffff"
                    v-model="settings.emails.cor_fonte"
                  >
                  </base-input>
                </div>
              </div>
            
          </zz-tab>
          <zz-tab id="utilitarios" name="Utilitários">

              <button type="button" class="btn btn-sm mt-3" @click="integrarContatosCRM">
                Integrar Contatos com CRM
              </button>
            
          </zz-tab>
          <zz-tab id="funcionalidades" name="Funcionalidades">

              <div class="row">
                <div class="col-md-12">

                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr style="background-color: rgba(34, 42, 66, 0.05);">
                        <th>Funcionalidade</th>
                        <th style="text-align: center">Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colspan="2" style="text-align: center; font-size: 12px;"><b>OFERTAS</b></td>
                      </tr>
                      <tr>
                        <td>
                          Criar pelo Usuário
                        </td>
                        <td style="text-align: center;">
                          <div class="row">
                            <div style="padding-top: 2px;">
                              <input
                                name="criar_oferta_pelo_usuario"
                                ref="criar_oferta_pelo_usuario"
                                type="checkbox"
                                v-model="settings._features.criar_oferta_pelo_usuario"
                                style="cursor: pointer;"
                              />
                            </div>
                            <div style="margin-left: 5px;">
                              <label
                                for="criar_oferta_pelo_usuario"
                                data-type="permitir"
                                @click="funcionalidadesCheck"
                              >
                                Permitir
                              </label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2" style="text-align: center; font-size: 12px;"><b>RELATÓRIOS</b></td>
                      </tr>
                      <tr>
                        <td>
                          Relatório Anual CVM
                        </td>
                        <td style="text-align: center;">
                          <div class="row">
                            <div style="padding-top: 2px;">
                              <input
                                name="visualizar_relatorios_cvm"
                                ref="visualizar_relatorios_cvm"
                                type="checkbox"
                                v-model="settings._features.visualizar_relatorios_cvm"
                                style="cursor: pointer;"
                              />
                            </div>
                            <div style="margin-left: 5px;">
                              <label
                                for="visualizar_relatorios_cvm"
                                data-type="permitir"
                                @click="funcionalidadesCheck"
                              >
                                Visualizar
                              </label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Relatório de Vencimentos CCBs
                        </td>
                        <td style="text-align: center;">
                          <div class="row">
                            <div style="padding-top: 2px;">
                              <input
                                name="visualizar_relatorios_vencimento_ccbs"
                                ref="visualizar_relatorios_vencimento_ccbs"
                                type="checkbox"
                                v-model="settings._features.visualizar_relatorios_vencimento_ccbs"
                                style="cursor: pointer;"
                              />
                            </div>
                            <div style="margin-left: 5px;">
                              <label
                                for="visualizar_relatorios_vencimento_ccbs"
                                data-type="permitir"
                                @click="funcionalidadesCheck"
                              >
                                Visualizar
                              </label>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
            
          </zz-tab>
        </zz-tabs>
      </div>
      
      <modal :show="showSafe2PayModal" @close="showSafe2PayModal = false">
        <div>
          <div>
            <b>Configurações de integração Safe2Pay</b>
          </div>
          <button class="modal-close-button" @click="showSafe2PayModal = false" type="button">
            <i class="tim-icons icon-simple-remove"></i>
          </button>
          <br />
          <br />
          <div class="card custom-modal-card">
            <div class="login-form">
              <div class="row">
                <div class="col-md-5 mt-3">
                  <base-input
                    label="Token Marketplace Produtivo"
                    placeholder="Informe o token produtivo do Marketplace"
                    v-model="settings.integracao.safe2pay.safe2pay_api_keys.marketplace_token_prd"
                  >
                  </base-input>
                </div>
                <div class="col-md-5 mt-3">
                  <base-input
                    label="Token Marketplace Sandox"
                    placeholder="Informe o token sandbox do Marketplace"
                    v-model="settings.integracao.safe2pay.safe2pay_api_keys.marketplace_token_sandbox"
                  >
                  </base-input>
                </div>
                <div class="col-md-2 mt-3">
                  <input
                    name="marketplace_token_sandbox_active"
                    ref="marketplace_token_sandbox_active"
                    type="checkbox"
                    v-model="
                      settings.integracao.safe2pay.safe2pay_api_keys.marketplace_token_sandbox_active
                    "
                  />
                  <label
                    for="marketplace_token_sandbox_active"
                    style="margin-left: 5px; margin-top: 36px"
                    @click="checkSandboxAtivo"
                  >
                    Sandbox Ativo</label
                  >
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-md-12 mt-12">
                  <table class="table table-striped table-bordered">
                    <!-- <thead>
                      <tr>
                        <th colspan="2">Forma de Pagamento</th>
                      </tr>
                    </thead> -->
                    <tbody>
                      <!-- CARTÃO DE CRÉDITO -->
                      <tr class="custom-cursor-pointer">
                        <td style="width: 80%;">
                          <b>Cartão de Crédito</b>
                        </td>
                        <td style="text-align: center; cursor: pointer;">
                          <div class="row">
                            <div class="custom-table-checkbox-enabled">
                              <input
                                name="integracao_safe2pay_cartao_credito"
                                ref="integracao_safe2pay_cartao_credito"
                                type="checkbox"
                                v-model="settings.integracao.safe2pay.credit_card.is_enabled"
                                class="custom-cursor-pointer"
                              />
                            </div>
                            <div class="custom-table-label-enabled">
                              <label
                                for="integracao_safe2pay_cartao_credito"
                                data-type="habilitar"
                                @click="integracaoSafe2PayCheck"
                              >
                                Habilitar
                              </label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr class="custom-cursor-pointer" v-if="settings.integracao.safe2pay.credit_card.is_enabled">
                        <td colspan="2" class="forma-pagamento-configuracoes">
                          <div class="row">
                            <div class="col-md-3 mt-3 remove-margin-top-col-3">
                              <base-input label="Repassar Taxa p/ Subconta">
                                <select
                                  class="form-control"
                                  v-model="settings.integracao.safe2pay.credit_card.isSubaccountTaxPayer"
                                  required
                                >
                                  <option value="1">SIM</option>
                                  <option value="0">NÃO</option>
                                </select>
                              </base-input>
                            </div>
                            <div class="col-md-2 mt-2">
                              <base-input
                                label="Tipo de Taxa"
                              >
                                <select
                                  class="form-control"
                                  v-model="settings.integracao.safe2pay.credit_card.taxTypeName"
                                  required
                                >
                                  <option :value="CWLTipoTaxaPagamento.Fixo">Fixo</option>
                                  <option :value="CWLTipoTaxaPagamento.Percentual">Percentual</option>
                                </select>
                              </base-input>
                            </div>
                            <div class="col-md-2 mt-2">
                              <label>Valor da Taxa</label>
                              <base-input
                                v-model="settings.integracao.safe2pay.credit_card.tax"
                                placeholder="Valor da Taxa"
                                required
                                v-mask="['##.##']"
                              ></base-input>
                            </div>
                            <div class="col-md-2 mt-2">
                              <label>Valor Mínimo</label>
                              <currency-input
                                class="form-control"
                                type="currency"
                                v-model="settings.integracao.safe2pay.credit_card.min_value"
                                placeholder="Valor Mínimo"
                                required
                                currency="BRL"
                                locale="pt-br"
                                :auto-decimal-mode="true"
                              />
                            </div>
                            <div class="col-md-2 mt-2">
                              <label>Valor Máximo</label>
                              <currency-input
                                class="form-control"
                                type="currency"
                                v-model="settings.integracao.safe2pay.credit_card.max_value"
                                placeholder="Valor Máximo"
                                required
                                currency="BRL"
                                locale="pt-br"
                                :auto-decimal-mode="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>

                      <!-- BOLETO -->
                      <tr class="custom-cursor-pointer">
                        <td>
                          <b>Boleto</b>
                        </td>
                        <td style="text-align: center; cursor: pointer;">
                          <div class="row">
                            <div class="custom-table-checkbox-enabled">
                              <input
                                name="integracao_safe2pay_boleto"
                                ref="integracao_safe2pay_boleto"
                                type="checkbox"
                                v-model="settings.integracao.safe2pay.doc.is_enabled"
                                class="custom-cursor-pointer"
                              />
                            </div>
                            <div class="custom-table-label-enabled">
                              <label
                                for="integracao_safe2pay_boleto"
                                data-type="habilitar"
                                @click="integracaoSafe2PayCheck">
                                Habilitar
                              </label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr class="custom-cursor-pointer" v-if="settings.integracao.safe2pay.doc.is_enabled">
                        <td colspan="2" class="forma-pagamento-configuracoes">
                          <div class="row">
                            <div class="col-md-3 mt-3 remove-margin-top-col-3">
                              <base-input label="Repassar Taxa p/ Subconta">
                                <select
                                  class="form-control"
                                  v-model="settings.integracao.safe2pay.doc.isSubaccountTaxPayer"
                                  required
                                >
                                  <option value="1">SIM</option>
                                  <option value="0">NÃO</option>
                                </select>
                              </base-input>
                            </div>
                            <div class="col-md-2 mt-2">
                              <base-input
                                label="Tipo de Taxa"
                              >
                                <select
                                  class="form-control"
                                  v-model="settings.integracao.safe2pay.doc.taxTypeName"
                                  required
                                >
                                  <option :value="CWLTipoTaxaPagamento.Fixo">Fixo</option>
                                  <option :value="CWLTipoTaxaPagamento.Percentual">Percentual</option>
                                </select>
                              </base-input>
                            </div>
                            <div class="col-md-2 mt-2">
                              <label>Valor da Taxa</label>
                              <base-input
                                v-model="settings.integracao.safe2pay.doc.tax"
                                placeholder="Valor da Taxa"
                                required
                                v-mask="['##.##']"
                              ></base-input>
                            </div>
                            <div class="col-md-2 mt-2">
                              <label>Valor Mínimo</label>
                              <currency-input
                                class="form-control"
                                type="currency"
                                v-model="settings.integracao.safe2pay.doc.min_value"
                                placeholder="Valor Mínimo"
                                required
                                currency="BRL"
                                locale="pt-br"
                                :auto-decimal-mode="true"
                              />
                            </div>
                            <div class="col-md-2 mt-2">
                              <label>Valor Máximo</label>
                              <currency-input
                                class="form-control"
                                type="currency"
                                v-model="settings.integracao.safe2pay.doc.max_value"
                                placeholder="Valor Máximo"
                                required
                                currency="BRL"
                                locale="pt-br"
                                :auto-decimal-mode="true"
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-3 mt-3">
                              <label>Multa após o Vencimento</label>
                              <base-input
                                v-model="settings.integracao.safe2pay.doc.multa_vencimento"
                                placeholder="Multa após o Vencimento"
                                required
                                v-mask="['#.##']"
                              ></base-input>
                            </div>
                            <div class="col-md-3 mt-3">
                              <label>Juros após o Vencimento</label>
                              <base-input
                                v-model="settings.integracao.safe2pay.doc.juros_vencimento"
                                placeholder="Juros após o Vencimento"
                                required
                                v-mask="['#.##']"
                              ></base-input>
                            </div>
                          </div>
                        </td>
                      </tr>

                      <!-- TED -->
                      <tr class="custom-cursor-pointer">
                        <td>
                          <b>TED</b>
                        </td>
                        <td style="text-align: center; cursor: pointer;">
                          <div class="row">
                            <div class="custom-table-checkbox-enabled">
                              <input
                                name="integracao_safe2pay_ted"
                                ref="integracao_safe2pay_ted"
                                type="checkbox"
                                v-model="settings.integracao.safe2pay.ted.is_enabled"
                                class="custom-cursor-pointer"
                              />
                            </div>
                            <div class="custom-table-label-enabled">
                              <label
                                for="integracao_safe2pay_ted"
                                data-type="habilitar"
                                @click="integracaoSafe2PayCheck"
                              >
                                Habilitar
                              </label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr class="custom-cursor-pointer" v-if="settings.integracao.safe2pay.ted.is_enabled">
                        <td colspan="2" class="forma-pagamento-configuracoes">
                          <div class="row">
                            <div class="col-md-2 mt-2">
                              <label>Valor Mínimo</label>
                              <currency-input
                                class="form-control"
                                type="currency"
                                v-model="settings.integracao.safe2pay.ted.min_value"
                                placeholder="Valor Mínimo"
                                required
                                currency="BRL"
                                locale="pt-br"
                                :auto-decimal-mode="true"
                              />
                            </div>
                            <div class="col-md-2 mt-2">
                              <label>Valor Máximo</label>
                              <currency-input
                                class="form-control"
                                type="currency"
                                v-model="settings.integracao.safe2pay.ted.max_value"
                                placeholder="Valor Máximo"
                                required
                                currency="BRL"
                                locale="pt-br"
                                :auto-decimal-mode="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>

                      <!-- PIX -->
                      <tr class="custom-cursor-pointer">
                        <td>
                          <b>PIX</b>
                        </td>
                        <td style="text-align: center; cursor: pointer;">
                          <div class="row">
                            <div class="custom-table-checkbox-enabled">
                              <input
                                name="integracao_safe2pay_pix"
                                ref="integracao_safe2pay_pix"
                                type="checkbox"
                                v-model="settings.integracao.safe2pay.pix.is_enabled"
                                class="custom-cursor-pointer"
                              />
                            </div>
                            <div class="custom-table-label-enabled">
                              <label
                                for="integracao_safe2pay_pix"
                                data-type="habilitar"
                                @click="integracaoSafe2PayCheck"
                              >
                                Habilitar
                              </label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr class="custom-cursor-pointer" v-if="settings.integracao.safe2pay.pix.is_enabled">
                        <td colspan="2" class="forma-pagamento-configuracoes">
                          <div class="row">
                            <div class="col-md-2 mt-2">
                              <label>Valor Mínimo</label>
                              <currency-input
                                class="form-control"
                                type="currency"
                                v-model="settings.integracao.safe2pay.pix.min_value"
                                placeholder="Valor Mínimo"
                                required
                                currency="BRL"
                                locale="pt-br"
                                :auto-decimal-mode="true"
                              />
                            </div>
                            <div class="col-md-2 mt-2">
                              <label>Valor Máximo</label>
                              <currency-input
                                class="form-control"
                                type="currency"
                                v-model="settings.integracao.safe2pay.pix.max_value"
                                placeholder="Valor Máximo"
                                required
                                currency="BRL"
                                locale="pt-br"
                                :auto-decimal-mode="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </modal>
      
    </form>
    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>

<style>
.modal-close-button {
  background-color: rgb(255, 255, 255);
  border: 1px solid #aaa;
  border-radius: 20px;
  height: 40px;
  width: 40px;
  position: absolute;
  right: 0;
  top: 10px;
  cursor: pointer;
  color: #888;
}
.custom-modal-card {
  background-color: #fff !important;
  max-width: 1024px !important;
}
.custom-modal-login-form {
  max-width: 1024px;
}
.forma-pagamento-configuracoes {
  padding-top: 0 !important;
}
.integracao-botao-abrir-modal {
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 20px rgb(0 0 0 / 25%);
  height: 80px;
  cursor: pointer;
}
@media(max-width: 768px) {
  .integracao-botao-abrir-modal {
    margin: 30px !important;
  }
}
@media(max-width: 768px) {
  .integracao-botao-abrir-modal-logo {
    width: 120px;
  }
}
.integracao-botao-abrir-modal-img-checked {
  width: 20px;
  height: 16px;
  margin-top: -40px;
}
.integracao-botao-abrir-modal-img-not-checked {
  width: 20px;
  height: 20px;
}
.remove-margin-top-col-3 {
  margin-top: 0.5rem !important;
}
.custom-cursor-pointer {
  cursor: default !important;
}
.custom-table-checkbox-enabled {
  padding-top: 2px;
}
.custom-table-label-enabled {
  margin-left: 5px;
}
</style>