<script>
import brokersService from '@/services/Brokers/brokersService';
import UpdatedBrokerNotification from '@/components/Notifications/Brokers/UpdatedBrokerNotification';
import UpdatedBrokerErrorNotification from '@/components/Notifications/Brokers/UpdatedBrokerErrorNotification';
import GetBrokerErrorNotification from '@/components/Notifications/Brokers/GetBrokerErrorNotification';
import {vueTopprogress} from 'vue-top-progress';
import BrokersTable from '@/components/Brokers/BrokersTable';
import { DoubleBounce } from 'vue-loading-spinner';
import logsService from '@/services/Logs/logsService';
import { nonEmpty } from '@/validate/customValidators.js';
import authenticationService from '@/services/Authentication/authenticationService';
import store from '@/store/index';
import firebase from 'firebase';
import claimsService from '@/services/Authentication/claimsService';
import pessoasService from '@/services/Pessoas/pessoasService';

export default {
  components: {
    vueTopprogress,
    DoubleBounce,
    BrokersTable,
  },
  data() {
    return {
      broker: {
        name: "",
        email: "",
        cpf: "",
        chave_pix: "",
        hubspot_id: "",
        channel: "",
        exam_release: true,
        exam: {},
        company: {},
        avatar: "teste",
      },
      broker_leader_name: "",
      isLoading: false,
      isErrorClass: true,
    };
  },
  mounted() {
    this.getBroker();
  },
  validations: {
    broker: {
      email: {
        valid: (email) => nonEmpty(email),
      },
      chave_pix: {
        valid: (chave_pix) => nonEmpty(chave_pix),
      },
    },
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
    brokerId() {
      return this.$route.params.id;
    },
    isFormValid() {
      return (
        !this.$v.broker.email.valid ||
        !this.$v.broker.chave_pix.valid
      );
    },
  },
  
  methods: {
    async getBroker() {
      this.$refs.topProgress.start();

      try {
        const broker = await brokersService.getBrokerByEmail(this.currentPessoa.email.email);
        const brokersLeaders = await brokersService.getAllBrokerLeaders();
        const findBrokerLeader = brokersLeaders.find(leader => leader.id === broker.leader_id);
        this.broker_leader_name = findBrokerLeader.name;

        if(!broker.company) {
          broker.company = {
            cnpj: "",
            name: "",
            bank: "",
            agency: "",
            account: "",
          };
        }

        this.broker = {...broker}

        if (broker.avatar) {
          const url = await brokersService.getDownloadURL(broker.avatar);
          store.commit('SET_PROFILE_IMAGE', url);
        }

        this.$refs.topProgress.done();
      } catch (error) {
        console.log(error)
        this.$notify({
          component: GetBrokerErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
    async updateBroker() {
      this.$refs.topProgress.start();
      this.isLoading = true;

      try {
        const logUser = {
          user: {
            email: this.currentPessoa.email.email
          }
        };
        logsService.createNewLog(logUser, '', 'Atualizar Assessor', '', this.broker);

        const user = firebase.auth().currentUser;

        await brokersService.updateBroker(this.broker.id, this.broker);
        await user.updateEmail(this.broker.email);
        await pessoasService.updatePessoa(this.currentPessoa.pessoa_id, { email: { email: this.broker.email }, dados_complementares: { user: { email: this.broker.email } } });
        await claimsService.updateClaimById({ email: this.broker.email }, this.currentPessoa.pessoa_id);

        this.$refs.topProgress.done();

        this.$notify({
          component: UpdatedBrokerNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        console.log(error);
        this.$refs.topProgress.done();
        this.$notify({
          component: UpdatedBrokerErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      } finally {
        this.isLoading = false;
      }
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    async handleFileUpload(event){
      this.isLoading = true;
      const file = event.target.files[0]
      const dividedName = file.name.split(".");
      const extension = dividedName.pop();
      const newName = Date.now();
      const newFile = new File([file], `${newName}.${extension}`, { type: file.type });

      try {
        await brokersService.uploadImageToStorage(newFile, this.broker.avatar);
        await brokersService.updateBroker(this.broker.id, { avatar: newFile.name });

        this.$notify({
          title: "Imagem salva com sucesso!",
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
        this.broker.avatar = newFile.name;
        
        const url = await brokersService.getDownloadURL(newFile.name);
        store.commit('SET_PROFILE_IMAGE', url);
      } catch(err) {
        console.log(err);
        this.$notify({
          title: "Erro ao salvar imagem",
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async redefinePassword() {
      this.$refs.topProgress.start();
      try {
        await authenticationService.updatePassword(this.broker.email);

        this.$notify({
          title: 'Alteração de senha solicitada!',
          message: 'Enviamos um e-mail para o usuário redefinir sua senha.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
        this.$refs.topProgress.done();
      }
      catch (error) {
        console.log(error);
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },
  },
  watch: {
    data() {
      this.isFormValid()
    }
  }
};
</script>

<template>
  <div class="add-user">
    <card>
      <form @submit.prevent="updateBroker()">
        <h5 slot="header" class="title">
          <router-link to="/brokers" class="fa fa-arrow-left"></router-link>
          Meus dados
        </h5>

        <div class="row">
          <div class="col-md-6 mb-2">
            <base-input
              label="Nome*"
              placeholder="Nome"
              v-model="broker.name"
              disabled
            ></base-input>
          </div>

          <div class="col-md-6 pl-md-1">
            <label for="" class="control-label">Email*</label>
            <input
              v-model="broker.email"
              data-testId="email"
              type="email"
              class="form-control"
              placeholder="Digite seu e-mail"
              required
            />
            <div class="error" v-if="!$v.broker.email.valid">
              Informe um e-mail válido.
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <base-input
              label="CPF"
              v-model="broker.cpf"
              v-mask="['###.###.###-##']"
              disabled
            ></base-input>
          </div>

          <div class="col-md-6">
            <base-input
              label="Hubspot ID"
              disabled
              v-model="broker.hubspot_id"
            ></base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <base-input
              label="Canal"
              disabled
              v-model="broker.channel"
            ></base-input>
          </div>

          <div class="col-md-4">
            <base-input
              label="Nível"
              disabled
              v-model="broker.level"
            ></base-input>
          </div>

          <div class="col-md-4">
            <base-input
              label="Líder"
              :value="broker_leader_name"
              disabled
            ></base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <base-input
              label="Progresso da prova"
              :value="this.broker.exam ? this.broker.exam.exam_progress : 'Não inicializado'"
              disabled
            ></base-input>
          </div>

          <div class="col-md-6">
            <base-input
              label="Resultado da prova"
              :value="this.broker.exam ? this.broker.exam.exam_approval : 'Não inicializado'"
              disabled
            ></base-input>
          </div>

          <div class="col-md-6">
            <base-input
              label="CNPJ"
              v-model="broker.company.cnpj"
              v-mask="['##.###.###/####.##']"
            ></base-input>
          </div>

          <div class="col-md-6">
            <base-input
              label="Razão social"
              v-model="broker.company.name"
            ></base-input>
          </div>

          <div class="col-md-3">
            <base-input
              label="Chave pix*"
              placeholder="Chave pix"
              v-model="broker.chave_pix"
              required
            ></base-input>
            <div class="error" v-if="!$v.broker.chave_pix.valid">
              Informe uma chave pix válida.
            </div>
          </div>

          <div class="col-md-3">
            <base-input
              label="Banco"
              v-model="broker.company.bank"
            ></base-input>
          </div>

          <div class="col-md-3">
            <base-input
              label="Agência (sem dígito)"
              :boldLabel="true"
              placeholder="Digite sua Agência"
              v-model="broker.company.agency"
            ></base-input>
          </div>
          <div class="col-md-3">
            <base-input
              label="Conta (com dígito)"
              :boldLabel="true"
              placeholder="Digite sua Conta"
              v-model="broker.company.account"
            ></base-input>
          </div>

          <input 
            type="file" 
            ref="fileInput"
            accept=".png,.jpeg"
            @change="handleFileUpload($event)"
            hidden
          />

          <div class="col-md-6" style="margin-top: 21.5px;">
            <button
              type="button"
              class="btn btn-sm mr-4"
              @click="openFileInput"
              :disabled="this.isLoading"
            >
              Atualizar foto
            </button>
            <button type="submit" class="btn btn-sm mr-4" :disabled="isFormValid || this.isLoading">Atualizar</button>
            <button
              type="button"
              class="btn btn-sm btn-warning"
              @click="redefinePassword"
            >
              Redefinir senha
            </button>
          </div>
        </div>
      </form>
    </card>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
