import Firebase from 'firebase/app';

import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/storage';
import firebase from 'firebase/app';

export const initializeFirebaseApp = (buildApp) => {
  let firebaseConfig = {};
  if (process.env.VUE_APP_ENVIRONMENT == 'ajx-stage') {
    firebaseConfig = {};
  } else if (process.env.VUE_APP_ENVIRONMENT == 'ajx-prd') {
    firebaseConfig = {};
  }

  Firebase.initializeApp(firebaseConfig);
  Firebase.auth().onAuthStateChanged(() => {
    buildApp();
  });
};

export const database = () => Firebase.firestore();

export const collectionRef = (name) => {
  return firebase.firestore().collection(name);
};

export const currentUser = () => {
  return firebase.auth().currentUser;
};

export const currentUserId = () => {
  const user = firebase.auth().currentUser;
  return user ? user.uid : undefined;
};
