<script>
import brokersService from '@/services/Brokers/brokersService';
import BrokersTable from '@/components/Brokers/BrokersTable';
import SuccessNotification from '@/components/Notifications/General/SuccessNotification';
import ErrorRegisterNotification from '@/components/Notifications/General/ErrorRegisterNotification';
import { vueTopprogress } from 'vue-top-progress';
import logsService from '@/services/Logs/logsService';
import { cpf, nonEmpty } from '@/validate/customValidators.js';
import { DoubleBounce } from 'vue-loading-spinner';
import authenticationService from '@/services/Authentication/authenticationService';
import claimsService from '@/services/Authentication/claimsService';
import { getInitialPessoaValue } from '@/functions/getInitialPessoaValue.js';
import pessoasService from '@/services/Pessoas/pessoasService.js';
import birthDatepicker from 'vue-birth-datepicker';

const tableColumns = ["Nome", "Ações"];

export default {
  data() {
    return {
      broker: {
        name: "",
        email: "",
        cpf: "",
        chave_pix: "",
        hubspot_id: "",
        status: false,
        channel: "",
        leader_id: "",
        color: "",
        exam_release: false,
        admission_date: ""
      },
      broker_leader_name: "",
      brokerLeaders: [],
      table: {
        title: 'Lista de Assessores',
        columns: tableColumns,
      },
      pessoa: {},
      isLoading: true,
      isFormLoading: false,
      temp_password: "",
    };
  },
  mounted() {
    this.getBrokerLeaders();
  },
  validations: {
    broker: {
      name: {
        valid: (name) => nonEmpty(name),
      },
      email: {
        valid: (email) => nonEmpty(email),
      },
      cpf: {
        valid: (_cpf) => cpf(_cpf),
      },
      chave_pix: {
        valid: (chave_pix) => nonEmpty(chave_pix),
      },
      hubspot_id: {
        valid: (hubspot_id) => nonEmpty(hubspot_id),
      },
      channel: {
        valid: (channel) => nonEmpty(channel),
      },
      level: {
        valid: (level) => nonEmpty(level),
      },
      leader_id: {
        valid: (leader_id) => nonEmpty(leader_id),
      },
      admission_date: {
        valid: (admission_date) => nonEmpty(admission_date) && admission_date !== null,
      }
    },
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
    isFormValid() {
      return (
        !this.$v.broker.name.valid ||
        !this.$v.broker.email.valid ||
        !this.$v.broker.cpf.valid ||
        !this.$v.broker.chave_pix.valid ||
        !this.$v.broker.channel.valid ||
        !this.$v.broker.level.valid ||
        !this.$v.broker.leader_id.valid ||
        !this.$v.broker.hubspot_id.valid ||
        !this.$v.broker.admission_date.valid
      );
    },
  },
  components: {
    vueTopprogress,
    DoubleBounce,
    BrokersTable,
    birthDatepicker
  },
  methods: {
    async addNewBroker() {
      this.$refs.topProgress.start();
      this.isFormLoading = true;
      this.broker.email = this.broker.email.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");;

      try {
        // verify if need to check duplicates
        const logUser = {
          user: {
            email: this.currentPessoa.email.email
          }
        };
        logsService.createNewLog(logUser, '', 'Criar Assessor', '', this.broker);
        const lastPosition = await brokersService.getLastBrokerPosition();

        if (lastPosition >= 35) {
          this.broker.color = 'darkgray';
        }

        await brokersService.createNewBroker(Object.assign(this.broker, {
          position: lastPosition + 1,
        }));

        const pessoa = getInitialPessoaValue(this.broker.name, this.broker.email);

        this.pessoa = pessoa;

        const tempPassword = await authenticationService.generateTempPassword();

        const firebaseResponse = await authenticationService.createNewUser(
          this.broker.email,
          tempPassword,
        );

        await pessoasService.createPerson(
          this.pessoa
        );

        await claimsService.setNewUserClaim(firebaseResponse.user.uid, firebaseResponse.user.email, 'assessor');

        await brokersService.sendMailBroker(this.broker.name, this.broker.email, tempPassword);
        this.$refs.topProgress.done();

        this.$router.push('brokers');

        this.$notify({
          component: SuccessNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
        this.$notify({
          title: 'Senha temporária, envie para o assessor.',
          message: `A senha temporária do broker é: ${tempPassword}`,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 1000000,
          closeOnClick: false,
        });
      } catch (error) {
        console.log(error);
        this.$refs.topProgress.done();
        this.$notify({
          component: ErrorRegisterNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      } finally {
        this.isFormLoading = false;
      }

      this.broker = {};
    },
    async getBrokerLeaders() {
      this.isLoading = true
      const brokerLeaders = await brokersService.getAllBrokerLeaders() || [];
      
      const formattedBrokers = brokerLeaders.map(broker => ({ id: broker.id, name: broker.name, color: broker.team_color }));

      this.brokerLeaders = formattedBrokers;

      if (this.brokerLeaders.lenght === 0) {
        this.$notify({
          component: EmptyBrokersNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.isLoading = false;
    },
    addLeader(leader) {
      const { color, id, name } = leader;
      this.broker.leader_id = id;
      this.broker.color = color;
      this.broker_leader_name = name;
    },
    toggleCheckbox(whichCheck) {
      if (whichCheck === 'status') {
        this.broker.status = !this.broker.status;
      } else {
        this.broker.exam_release = !this.broker.exam_release;
      }
    },
    // async handleCreatePerson() {
    //   const tempPassword = await authenticationService.generateTempPassword();
    //   const broker = await brokersService.getBrokerByEmail('anderson.soaresj@icloud.com');
    //   console.log(broker);
    //   try {
    //     const pessoa = getInitialPessoaValue(broker.name, broker.email);
    //     this.pessoa = pessoa;
    //     console.log('entrei');

    //     const firebaseResponse = await authenticationService.createNewUser(
    //       'anderson.soaresj@icloud.com',
    //       tempPassword,
    //     );

    //     await pessoasService.createPerson(
    //       this.pessoa
    //     );

    //     await claimsService.setNewUserClaim(firebaseResponse.user.uid, 'anderson.soaresj@icloud.com', 'assessor');
    //     await brokersService.sendMailBroker(broker.name, broker.email, tempPassword);
    //     console.log('sai');

    //   } catch(err) {
    //     console.log(err);
    //   }
    // }
  },
  watch: {
    data() {
      this.isFormValid()
    }
  }
};
</script>

<template>
  <div class="add-user">
    <card>
      <form @submit.prevent="addNewBroker()">
        <h5 slot="header" class="title">
          <router-link to="/brokers" class="fa fa-arrow-left"></router-link>
          Adicionar Assessor
        </h5>

        <div class="row">
          <div class="col-md-4 pr-md-1 mb-2">
            <base-input
              label="Nome*"
              placeholder="Nome"
              v-model="broker.name"
              required
            ></base-input>
            <div class="error" v-if="!$v.broker.name.valid">
              Informe um nome válido.
            </div>
          </div>

          <div class="col-md-4 pl-md-1">
            <label for="" class="control-label">Email*</label>
            <input
              v-model="broker.email"
              data-testId="email"
              type="email"
              class="form-control"
              placeholder="Digite seu e-mail"
              required
            />
            <div class="error" v-if="!$v.broker.email.valid">
              Informe um e-mail válido.
            </div>
          </div>

          <div class="col-md-4 pl-md-1">
            <base-input
              label="CPF*"
              v-model="broker.cpf"
              placeholder="Digite seu CPF"
              v-mask="['###.###.###-##']"
              required
            ></base-input>
            <div class="error" v-if="!$v.broker.cpf.valid">
              Informe um CPF válido.
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 pr-md-1 mb-2">
            <base-input
              label="Chave pix*"
              placeholder="Chave pix"
              v-model="broker.chave_pix"
              required
            ></base-input>
            <div class="error" v-if="!$v.broker.chave_pix.valid">
              Informe uma chave pix válida.
            </div>
          </div>

          <div class="col-md-4 pl-md-1">
            <base-input
              label="Hubspot ID*"
              placeholder="Hubspot ID"
              v-model="broker.hubspot_id"
              required
            ></base-input>
            <div class="error" v-if="!$v.broker.hubspot_id.valid">
              Informe um hubspot ID válido.
            </div>
          </div>

          <div class="col-md-4 pl-md-1">
            <base-input label="Data de Admissão*" placeholder="Data de Admissão" >
              <date-picker 
                v-model="broker.admission_date"
                valueType="format" 
                format="DD/MM/YYYY"
              ></date-picker>
            </base-input>
            <div class="error" v-if="!$v.broker.admission_date.valid">
              Informe a data de admissão do assessor.
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 pr-md-1">
            <label for="">Selecione o canal*</label>
            <select class="form-control" v-model="broker.channel">
              <option
                v-for="(channel, index) in ['Life Office', 'Híbrido', 'Monetize']"
                :value="channel"
                :key="index"
              >
                {{ channel }}
              </option>
            </select>
            <div class="error mt-2" v-if="!$v.broker.channel.valid">
              Informe um canal válido.
            </div>
          </div>

          <div class="col-md-4 pl-md-1">
            <label for="">Selecione o nível*</label>
            <select class="form-control" v-model="broker.level">
              <option v-for="(level, index) in ['Júnior', 'Pleno', 'Sênior', 'Master']" :value="level" :key="index">
                {{ level }}
              </option>
            </select>
            <div class="error mt-2" v-if="!$v.broker.level.valid">
              Informe um nível válido.
            </div>
          </div>

          <div class="col-md-4 pr-md-1">
            <base-input
              label="Escolha um líder*"
              :value="this.broker_leader_name"
              :disabled="true"
            ></base-input>
            <div class="error mt-2" v-if="!this.broker_leader_name">
              Informe um líder válido.
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 pr-md-1 mb-2 mt-2 align-self-center">
            <input name="status" ref="status" type="checkbox" v-model="broker.status" />
            <label for="status" style="margin-left: 5px;" @click="toggleCheckbox('status')">Ativo</label>
            <input name="exam_release" ref="exam_release" type="checkbox" class="ml-4" v-model="broker.exam_release" />
            <label for="exam_release" style="margin-left: 5px;" @click="toggleCheckbox('exam_release')">Liberar
              prova</label>
          </div>
        </div>

        <div>
          <h2 class="my-4">Selecione um líder</h2>
          <div class="table-responsive">
            <brokers-table
              :data="brokerLeaders"
              :columns="table.columns"
              :selectLeader="true"
              thead-classes="text-primary"
              @addLeader="addLeader"
            ></brokers-table>

            <double-bounce v-if="isLoading"></double-bounce>
          </div>
        </div>

        <button type="submit" class="btn btn-sm" :disabled="isFormValid || isFormLoading">Adicionar</button>
        <!-- <button type="button" class="btn btn-sm" @click="handleCreatePerson">Criar Pessoa</button> -->
      </form>
    </card>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
