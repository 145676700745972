<template>
  <nav
    class="navbar navbar-expand-lg new-nav-bar navbar-fixed-top"
  >
    <div class="container-fluid">
      <div class="navbar-wrapper">
        <div
          class="navbar-toggle d-inline"
          :class="{ toggled: $sidebar.showSidebar }"
        >
          <button
            type="button"
            class="navbar-toggler"
            aria-label="Navbar toggle button"
            @click="toggleSidebar"
          >
            <span class="navbar-toggler-bar bar1"></span>
            <span class="navbar-toggler-bar bar2"></span>
            <span class="navbar-toggler-bar bar3"></span>
          </button>
        </div>
        <a class="navbar-brand" href="#"><img src="@/assets/images/logo_ajx.png" alt="Customer Logo" style="width: 120px !important;" /></a>
      </div>
      <button
        class="navbar-toggler profile"
        type="button"
        @click="toggleMenu"
        data-toggle="collapse"
        data-target="#navigation"
        aria-controls="navigation-index"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
      </button>

      <collapse-transition>
        <div class="collapse navbar-collapse show" v-show="showMenu">
          <ul class="navbar-nav" :class="isRTL ? 'mr-auto' : 'ml-auto'">
            <div
              class="search-bar input-group"
              @click="searchModalVisible = true"
            >
              <p style="color: #fff; margin-top: 12px">
                <b>{{ currentPessoa.nome }}</b>
              </p>
            </div>
            <base-dropdown
              tag="li"
              :menu-on-right="!isRTL"
              title-tag="a"
              class="nav-item"
              menu-classes="dropdown-navbar"
            >
              <a
                slot="title"
                href="#"
                class="dropdown-toggle nav-link"
                data-toggle="dropdown"
                aria-expanded="true"
              >
                <div class="photo">
                  <img :src="role === 'assessor' && urlImage ? urlImage : this.placeholderImage" />
                </div>
                <b class="caret d-none d-lg-block d-xl-block"></b>
              </a>
              <li class="nav-link" v-if="role !== 'assessor'">
                <router-link
                  tag="a"
                  to="/profile"
                  class="nav-item dropdown-item"
                  >Meus Dados</router-link
                >
              </li>
              <!-- <li
                class="nav-link"
                v-if="!isAdminOrGestorOrCurador && modalidade == 'doacao'"
              >
                <router-link
                  tag="a"
                  to="/add-financing"
                  class="nav-item dropdown-item"
                  >Criar Campanha</router-link
                >
              </li> -->
              <!-- <li
                class="nav-link"
                v-if="(isAdminOrGestorOrCurador || features.criar_oferta_pelo_usuario) && modalidade == 'equity'"
              >
                <router-link
                  tag="a"
                  to="/add-financing"
                  class="nav-item dropdown-item"
                  >Criar Oferta</router-link
                >
              </li> -->
              <!-- <li
                class="nav-link"
                v-if="!isAdminOrGestorOrCurador && modalidade == 'doacao'"
              >
                <router-link
                  tag="a"
                  to="/financing"
                  class="nav-item dropdown-item"
                  >Minhas Campanhas</router-link
                >
              </li> -->
              <!-- <li
                class="nav-link"
                v-if="(isAdminOrGestorOrCurador || features.criar_oferta_pelo_usuario) && modalidade == 'equity'"
              >
                <router-link
                  tag="a"
                  to="/financing"
                  class="nav-item dropdown-item"
                  >Minhas Ofertas</router-link
                >
              </li> -->
              <!-- <li class="nav-link" v-if="!isAdminOrGestorOrCurador && modalidade == 'doacao'">
                <router-link
                  tag="a"
                  to="/social-transformation/register"
                  class="nav-item dropdown-item"
                >
                  <span
                    v-if="
                      (currentPessoa.caracteristicas || []).indexOf(
                        'social-transformation-agent',
                      ) === -1
                    "
                    >Torne-se um Agente (API3)</span
                  >
                  <span
                    v-if="
                      (currentPessoa.caracteristicas || []).indexOf(
                        'social-transformation-agent',
                      ) !== -1
                    "
                    >{{ $t('social_transformation.title') }}</span
                  >
                </router-link>
              </li> -->
              <div class="dropdown-divider"></div>
              <li class="nav-link" v-if="role !== 'assessor'">
                <a class="nav-item dropdown-item" @click="navigateToCCBs()"
                  >{{ $t('sidebar.financing') }} Disponíveis</a
                >
              </li>
              <li class="nav-link" v-if="role !== 'assessor'">
                <a class="nav-item dropdown-item" @click="navigateToSite()"
                  >Voltar para o Site</a
                >
              </li>
              <li class="nav-link">
                <a class="nav-item dropdown-item" @click="logoutUser()">Sair</a>
              </li>
            </base-dropdown>
          </ul>
        </div>
      </collapse-transition>
    </div>
  </nav>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions';
import Modal from '@/components/Modal';
import { PessoaCaracteristica } from '@/store/cwl_enums';
import settingsService from '@/services/Settings/settingsService';
import placeholderImage from '@/../public/img/anime3.png';

export default {
  components: {
    CollapseTransition,
    Modal,
  },
  async mounted() {
    this.features = await settingsService.getFeaturesToggle();
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      // return this.$rtl.isRTL;
      return false;
    },
    role() {
      const value = this.$store.getters.userClaim.role || '';
      return value.toLowerCase();
    },
    isAdminOrGestorOrCurador() {
      return (
        this.role == PessoaCaracteristica.Master ||
        this.role == PessoaCaracteristica.Gestor ||
        this.role == PessoaCaracteristica.Curador
      );
    },
    urlImage() {
      return this.$store.getters.profileImage;
    },
    currentPessoa() {
      const pessoa = this.$store.getters.getPessoa;
      console.debug('current pessoa', pessoa);
      return pessoa;
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
      envPath: process.env.VUE_APP_ROOT_SITE,
      modalidade: process.env.VUE_APP_TYPE,
      features: {},
      placeholderImage: placeholderImage,
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    logoutUser() {
      this.$store.commit('SET_OFERTA_ID_TEMP', '');
      this.$store.dispatch('logout');
      this.$router.push('/login');
    },
    navigateToSite() {
      window.top.location.href = this.envPath;
    },
    navigateToCCBs() {
      window.top.location.href = this.envPath + "/investimentos";
    }
  },
};
</script>
<style></style>
