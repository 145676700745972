<script>
import EmptyTermsOfUse from '@/components/Notifications/TermsOfUse/EmptyTermsOfUse';
import GetTermsOfUseError from '@/components/Notifications/TermsOfUse/GetTermsOfUseError';

import attachmentsService from '@/services/Attachments/attachmentsService';
import investorsService from '@/services/Investors/investorsService';

import { vueTopprogress } from 'vue-top-progress';
import { DoubleBounce } from 'vue-loading-spinner';

import store from '@/store/index';

import pdfvuer from 'pdfvuer';

const tableColumns = ['Nome'];

export default {
  components: {
    vueTopprogress,
    DoubleBounce,
    pdf: pdfvuer,
  },
  data() {
    return {
      table: {
        title: 'Lista de Termos de Uso',
        columns: [...tableColumns],
      },
      page: 1,
      numPages: 0,
      pdfdata: undefined,
      scale: 'page-width',
      lastAttachment: {},
      isLoading: true,
      pdfUrl:
        'https://firebasestorage.googleapis.com/v0/b/crowdfunding-wl-dev.appspot.com/o/TermsOfUse%2FTermo%20de%20Uso%2024_04_2020?alt=media&token=f65b5d81-a601-48d1-a133-5f59b809931d',
    };
  },
  mounted() {
    this.getAttachments();
  },
  methods: {
    getPdf(url) {
      const self = this;
      self.pdfdata = pdfvuer.createLoadingTask(url);

      self.pdfdata.then((pdf) => {
        self.numPages = pdf.numPages;

        window.onscroll = function () {
          changePage();
        };

        // eslint-disable-next-line require-jsdoc
        function changePage() {
          let i = 1;
          const count = Number(pdf.numPages);

          do {
            if (
              window.pageYOffset >= self.findPos(document.getElementById(i)) &&
              window.pageYOffset <= self.findPos(document.getElementById(i + 1))
            ) {
              self.page = i;
            }
            i++;
          } while (i < count);
          if (window.pageYOffset >= self.findPos(document.getElementById(i))) {
            self.page = i;
          }
        }
      });
    },

    findPos(obj) {
      return 0;
    },

    async getAttachments() {
      this.$refs.topProgress.start();

      try {
        const attachments = await attachmentsService.getAttachments(
          'InvestmentTerms',
        );
        const attachmentsItems = await attachments.items;
        if (attachmentsItems.length == 0) {
          this.$notify({
            component: EmptyTermsOfUse,
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 3000,
          });
        } else {
          this.lastAttachment = attachmentsItems[0];
          const url = await attachmentsService.urlAttachment(
            this.lastAttachment.name,
            'InvestmentTerms',
          );
          this.getPdf(url);
        }
      } catch (e) {
        this.$notify({
          component: GetTermsOfUseError,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.$refs.topProgress.done();
      this.isLoading = false;
    },
    logoutUser() {
      this.$router.push('login');
      this.$store.dispatch('logout');
    },
    async accept() {
      this.$refs.topProgress.start();

      await investorsService
        .getInvestor(store.getters.isLoggedIn.email)
        .then((response) => {
          response.forEach((doc) => {
            const investor = doc.data();
            investor.dados_complementares.termsOfUseAccepted = true;
            store.commit('SET_TERM_OF_USE_ACCEPTED', true);
            investorsService.updateInvestor(doc.id, investor);

            this.$refs.topProgress.done();
          });
        });

      this.$router.push('dashboard');
    },
  },
};
</script>

<template>
  <div class="term-of-use">
    <card
      :title="'Termo de Uso'"
      :titleBold="true"
      :subTitle="'Para continuar, é preciso que você leia e aceite os termos de uso.'"
      :subtitleBold="true"
    >
      <div class="card">
        <div class="login-form">
          <pdf
            :src="pdfdata"
            v-for="i in numPages"
            :key="i"
            :id="i"
            :page="i"
            :scale.sync="scale"
            style="width: 100%"
          />

          <double-bounce v-if="isLoading"></double-bounce>
        </div>

        <div class="terms-buttons">
          <button class="btn btn-simple" @click="logoutUser()">Sair</button>
          <button class="btn btn-primary" @click="accept()">Aceitar</button>
        </div>
      </div>
    </card>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
