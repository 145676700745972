<script>
import GetCampaignErrorNotification from '@/components/Notifications/Campaign/GetCampaignErrorNotification';
import GetFinancialErrorNotification from '@/components/Notifications/Campaign/GetFinancialErrorNotification';
import FinancialTable from '@/components/Campaign/FinancialTable';

import financingService from '@/services/Financing/financingService';
import ordersService from '@/services/Orders/ordersService';

import settingsService from '@/services/Settings/settingsService';
import { CWLInvestimentoDashboard } from '@/store/cwl_enums';

import moment from 'moment';

const tableColumns = ['Valor do Investimento', 'Forma de Pagamento', 'Data do Aporte', 'Status de Pagamento', 'Remuneração Acumulada', 'Contrato'];

export default {
  name: 'campaign-details',
  components: {
    FinancialTable,
  },
  data() {
    return {
      campaign: {},
      financials: [],
      isLoading: true,
      table: {
        columns: [...tableColumns],
      },
      modalidade: process.env.VUE_APP_TYPE,
      modalidadeOferta: '',
      prazo: '',
      nomeOferta: ''
    };
  },
  computed: {
    campaignId() {
      return this.$route.params.id;
    },
    generalReport() {
      return this.$route.params.report;
    },
    envPath() {
      return process.env.VUE_APP_ROOT_SITE;
    },
    loggedPessoa() {
      return this.$store.getters.getPessoa;
    },
  },
  mounted() {
    this.getCampaignDetails();
  },
  methods: {
    async getCampaignDetails() {
      try {
        const campaign = await financingService.get(this.campaignId);
        this.campaign = campaign.data();
        this.nomeOferta = this.campaign.name;

        if ( this.campaign.fields['modalidade_remuneracao'] ) {
          this.modalidadeOferta = this.campaign.fields['modalidade_remuneracao'].value;
        }

        if ( !this.generalReport ) {
          document.getElementById(
            'parsedContent',
          ).innerHTML = await campaign.data().content;

          this.table.columns = ['Valor do Investimento', 'Forma de Pagamento', 'Data do Aporte', 'Status de Pagamento', 'Remuneração Acumulada', 'Contrato'];
        }
        else {
          this.table.columns = ['Investidor', 'Código da Operação', 'Número da CCB', 'Valor do Investimento', 'Remuneração Acumulada', 'Valor da Remuneração', 'Carência Total do Lote', 'Contrato'];
        }

        this.getFinancialDetails();
      } catch (e) {
        this.$notify({
          component: GetCampaignErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
    async getFinancialDetails() {
      try {
        function compare(a, b) {
          let dateA = moment(b.order_date);
          let dateB = moment(a.order_date);
          if (dateA < dateB) {
            return -1;
          }
          else if (dateA > dateB) {
            return 1;
          }
          return 0;
        }

        let preOrdersToSort;
        if ( !this.generalReport ) {
          preOrdersToSort = await ordersService.getOrderByUserAndId(this.loggedPessoa.pessoa_id, this.campaignId);
        }
        else {
          preOrdersToSort = await ordersService.getOrdersByFinancingId(this.campaignId);
          if ( this.campaign.fields['prazo_oferta'] && this.campaign.fields['prazo_oferta'].value ) {
            this.prazo = this.campaign.fields['prazo_oferta'].value;
          }
        }
        
        const privateSettings = await settingsService.getPrivateSettings();
        const investimentoDashboard = privateSettings.investimento_dashboard ? privateSettings.investimento_dashboard : CWLInvestimentoDashboard.PagosPendentes;
        var ordersToSort = preOrdersToSort.filter((order) => {
          if ( investimentoDashboard == CWLInvestimentoDashboard.PagosPendentes ) {
            return true;
          }
          else if ( investimentoDashboard == CWLInvestimentoDashboard.Pagos ) {
            return order.status == "Pago";
          }
          else {
            return false;
          }
        });

        this.financials = ordersToSort.sort(compare);
        this.isLoading = false;
      } catch (e) {
        console.log(e);
        this.$notify({
          component: GetFinancialErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
    redirectToSite() {
      location.replace(`${this.envPath}project-detail/${this.campaignId}`);
    },
  },
};
</script>

<template>
  <div class="row campaign-details">
    <div class="col-md-12">
      <card v-if="!generalReport">
        <div class="card-header">
          <h3 class="card-title" v-if="modalidade == 'doacao'">
            <router-link to="/dashboard" class="fas fa-arrow-left" tag="i" />
            Detalhes da Campanha
          </h3>
          <h3 class="card-title" v-if="modalidade == 'equity'">
            <router-link to="/dashboard" class="fas fa-arrow-left" tag="i" />
            Detalhes da Oferta
          </h3>

          <!-- <button class="btn" @click="redirectToSite()">
            Visualizar no Site
          </button> -->
        </div>

        <div class="campaign-informations">
          <div id="parsedContent"></div>
          
          <div class="card-body">

            <div class="row">

              <div class="col-md-3">
                <base-input
                  label="Modalidade"
                  placeholder="Modalidade"
                  v-model="modalidadeOferta"
                  :disabled="true"
                ></base-input>
              </div>

            </div>

          </div>

        </div>
      </card>

      <card v-if="modalidade == 'equity' && !generalReport">
        <div class="card-header">
          <h3 class="card-title">{{ $t('financing.relevantInformantionsTitle') }}</h3>
        </div>
        <div class="card-body">
          {{ $t('financing.relevantInformantionsBody') }} <a target="_blank" :href="this.campaign.informacoes_relevantes ? this.campaign.informacoes_relevantes : ''">{{ $t('financing.relevantInformantionsClickHere') }}</a>.
        </div>
      </card>

      <card>
        <div class="card-header" v-if="!generalReport">
          <h3 class="card-title" v-if="modalidade == 'doacao'">Investimentos nesta Campanha</h3>
          <h3 class="card-title" v-if="modalidade == 'equity'">Investimentos nesta Oferta</h3>
        </div>

        <div class="card-header" v-if="generalReport">
          <h3 class="card-title">Investimentos na Oferta {{ nomeOferta }}</h3>
        </div>

        <div class="financial-details" v-if="!isLoading">
          <financial-table
            :data="financials"
            :campaign="campaign"
            :columns="table.columns"
            :prazo="prazo"
            thead-classes="text-primary" />
        </div>
      </card>
    </div>
  </div>
</template>
