import firebase from 'firebase';
import { withLog } from '../../logger/with_log';

export default {
  benefitsRef() {
    return firebase.firestore().collection('benefits');
  },

  async getAllBenefits() {
    return withLog(async () => {
      const snapshot = await this.benefitsRef().orderBy('status', 'desc').get();

      return snapshot.docs.map((doc) => doc.data());
    });
  },

  async getBenefit(id) {
    const snapshot = await withLog(() => this.benefitsRef().doc(id).get()); 
    return snapshot.data()
  },


  async createNewBenefit(benefit, username) {
    const docRef = this.benefitsRef().doc();
    const benefitWithId = {
      ...benefit,
      id: docRef.id,
      created_by: username,
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
    };
    await withLog(() => docRef.set(benefitWithId));
  },
  
  async updateBenefit(benefitId, benefit) {
    return withLog(() => this.benefitsRef().doc(benefitId).update(benefit));
  },

  async deleteBenefit(benefitId) {
    await withLog(() => this.benefitsRef().doc(benefitId).delete());
  },
}