<template>
  <div>
    <div class="row" v-if="isLoading">
      <div class="col-md-12 loading-gif">
        <img src="@/assets/images/loading.gif" />
      </div>
    </div>
    <div class="row" v-if="!isLoading">
      <div class="col-md-3">
        <div class="dash-card">
          <div class="row pl-3">
            <div class="col-md-2 middle middle-icon">
              <i class="fa fa-solid fa-dollar-sign"></i>
            </div>
            <div class="col-md-5 middle">
              <label>Aportes Realizados</label>
            </div>
            <div class="col-md-5 middle right big">
              <label>{{ topNumbers.invested_capital | currency }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="dash-card">
          <div class="row pl-3">
            <div class="col-md-2 middle middle-icon">
              <i class="fa fa-sharp fa-solid fa-money-bill-trend-up"></i>
            </div>
            <div class="col-md-5 middle">
              <label>Remuneração Acumulada</label>
            </div>
            <div class="col-md-5 right middle big">
              <label>{{ topNumbers.accumulated_income | currency }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="dash-card">
          <div class="row pl-3">
            <div class="col-md-2 middle middle-icon">
              <i class="fa fa-solid fa-percent"></i>
            </div>
            <div class="col-md-5 middle">
              <label>Remuneração Média Mensal</label>
            </div>
            <div class="col-md-5 right middle big">
              <label>{{ topNumbers.average_monthly_income }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="dash-card">
          <div class="row pl-3">
            <div class="col-md-2 middle middle-icon">
              <i class="fa fa-sharp fa-solid fa-stamp"></i>
            </div>
            <div class="col-md-5 middle">
              <label>Aportes Efetivados</label>
            </div>
            <div class="col-md-5 right middle big">
              <label>{{ topNumbers.confirmed_investments }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'top-numbers-dashboard',
  props: {},
  components: {},
  data() {
    return {
      isLoading: false,
      topNumbers: {},
    };
  },
  mounted() {
    this.loadTopNumbers();
  },
  methods: {
    async loadTopNumbers() {
      this.isLoading = true;
      const pessoa = this.$store.getters.getPessoa;

      this.topNumbers.invested_capital = 0;
      this.topNumbers.accumulated_income = 0;
      this.topNumbers.average_monthly_income = 0;
      this.topNumbers.confirmed_investments = 0;

      if (pessoa.dashboard_metrics) {
        this.topNumbers.invested_capital =
          pessoa.dashboard_metrics.invested_capital || 0;
        this.topNumbers.accumulated_income =
          pessoa.dashboard_metrics.accumulated_income || 0;
        this.topNumbers.average_monthly_income =
          pessoa.dashboard_metrics.average_monthly_income || '';
        this.topNumbers.confirmed_investments =
          pessoa.dashboard_metrics.confirmed_investments || 0;
      }

      this.topNumbers.invested_capital = this.topNumbers.invested_capital;
      this.topNumbers.accumulated_income = this.topNumbers.accumulated_income;
      this.topNumbers.average_monthly_income =
        this.topNumbers.average_monthly_income;
      this.topNumbers.confirmed_investments =
        this.topNumbers.confirmed_investments;

      this.isLoading = false;
    },
  },
};
</script>

<style>
.right {
  text-align: right;
}

@media (max-width: 520px) {
  .middle {
    display: flex;
    width: 100% !important;
    height: 50px !important;
    margin: auto;
    align-items: center;
    justify-content: center;
  }

  .middle label {
    font-size: 1.2rem !important;
    text-align: center;
    padding-left: 0px !important;
    padding-top: 20px;
  }

  .dash-card i {
    font-size: 3.5rem !important;
    color: #fff;
    padding-top: 20px !important;
  }

  .dash-card label {
    padding-right: 0px;
  }
  .middle-icon {
    margin-left: -5px !important;
  }
}

.middle {
  display: flex;
  width: 50%;
  height: 90px;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.middle label {
  padding-left: 30px;
}

.middle-icon {
  margin-top: -5px;
}

.dash-card {
  background-color: #0d143d;
  color: #fff;
  width: 100%;
  margin-bottom: 40px;
  border-radius: 10px;
  padding: 20px;
  min-height: 110px;
}

.dash-card i {
  font-size: 24pt;
  color: #fff;
}

.dash-card label {
  font-size: 0.9rem;
  color: #fff;
  padding-right: 20px;
  font-weight: bold;
}

.dash-card .row {
  height: 100%;
}
</style>
