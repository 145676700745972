import {
  PessoaCaracteristica,
  CWLStatusInvestidor
} from '@/store/cwl_enums';

export function getInitialPessoaValue(name, email) {
  const pessoa = {
    nome: name,
    email: { email },
    caracteristicas: [PessoaCaracteristica.Broker],
    dados_complementares: {
      user: {
        maritalStatus: '',
        email,
      },
      address: {
        state: '',
      },
      bank: {
        bankName: '',
      },
      profile: {
        personType: '',
        segment: '',
        value: '',
        profile: '',
      },
      fullName: name,
      approvalStatus: CWLStatusInvestidor.Aprovado,
      termsOfUseAccepted: true,
      termsOfUseDateAccepted: '',
    }
  }

  return pessoa;
};