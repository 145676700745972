<template>
  <div class="playlist">
    <div class="row padding-bottom">
      <div class="col-md-10">
        <h3 class="noticias-big-title">{{ playlistName }}</h3>
      </div>
      <div class="col-md-2 more-details" v-if="hasLoadMore">
        <label v-on:click="openMore()" class="no-margin more">Ver Mais</label>
      </div>
    </div> 
    <div class="row" v-if="isLoading">
      <div class="col-md-12 loading-gif">
        <img src="@/assets/images/loading.gif">
      </div>
    </div>
    <div v-if="!isLoading">
        <div>
          <ssr-carousel ref="carousel" :show-dots="true" :show-arrows="true" :slides-per-page="3">
            <div class="slide" v-for="item in videos" :key="item.id" >
              <div class="videos-carousel">
                <div class="content-video">
                  <div class="noticias-novos-videos" v-on:click="openDetails(item.id)">
                    <label class="assistido" v-if="item.viewed">Assistido</label>
                    <img :src="item.thumbnail" alt="" class="img">
                    <img src="@/assets/images/start-128.png" class="start"/>
                    <div class="legend">
                        <div class="row">
                          <div class="col-md-12">
                            <label class="title" :title="item.title">{{item.title | truncate(30)}}</label>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <label class="recorder">{{item.recorder}}</label>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ssr-carousel>            
        </div>
    </div>
  </div>
</template>

<script>
import videoService from '@/services/VideoContent/videoContentService';
export default {
  name: 'playlist-carousel',
  props: {
    playlistName: {
      type: String,
      default: '',
    },
    playlist: {
      type: Array,
      default: () => [],
    },
    hasLoadMore: {
      type: Boolean,
      default: false,
    }
  },
  components: {
  },
  data() {
    return {
      isLoading: false,
      videos : [],
    }
  },
  computed: {
    loggedInUser() {
      return this.$store.getters.isLoggedIn;
    },
  },
  async mounted() {
    await this.loadBanners();
  },
  methods: {
    async loadBanners() {
      this.isLoading = true;
      const videos = [];

      for(let i = 0; i < this.playlist.length; i++) {
        const videoId = this.playlist[i].id;
        const video = await videoService.findById(videoId);
        let thumb = JSON.parse(video.thumbnail);
        const thumbUrl = await videoService.urlThumbnail(thumb.name);

        let viewed = false;
        if(video.views && video.views.includes(this.loggedInUser.email)) {
          viewed = true;
        }

        videos.push({
          id: videoId,
          title: video.title,
          recorder: video.recorder,
          thumbnail: thumbUrl,
          viewed,
        })
      }

      this.videos = videos;

      this.isLoading = false;
    },
    openDetails(id) {
      this.$router.push(`market-news-video-details/${id}`) 
    },
    openMore() {
      this.$router.push(`academy`) 
    }
  }
  
};
</script>

<style>

.playlist .slide {
overflow: hidden;
position: relative;
}

.ssr-carousel-slides .ssr-carousel-mask .ssr-carousel-track {
  justify-content: flex-start;
}

.playlist .VueCarousel-inner {
visibility: visible!important;;
}

.playlist .loading-gif {
display: flex;
align-items: center;
justify-content: center;
}

.playlist .slide .videos-carousel {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.playlist .slide .videos-carousel .content-video {
  display: flex;
  width: 100%;
  height: 105%;
}

.playlist .slide .videos-carousel .assistido {
  position: absolute;
  right: 0;
  top: 0;
  background: rgba(0,0,0,.5);
  color:#fff;
  font-size: 0.9rem;
  font-weight: bold;
  padding: 5px;
  border-top-right-radius: 8px;
}

.playlist .more {
  font-size: 0.9rem!important;
  color: darkblue;
}

.playlist .loading-gif {
  display: flex;
  align-items: center;
  justify-content: center;
} 

.playlist .slide .videos-carousel .noticias-novos-videos .legend .title { 
  text-align: center;
  font-size: 0.8rem;
  color: #fff;
}

.playlist .slide .videos-carousel .noticias-novos-videos .legend .recorder { 
  text-align: center;
  font-size: 0.8rem;
  color: #fff;
}

.playlist .slide .videos-carousel .noticias-novos-videos .start {
  position: absolute;
  width: 100px;
  height: 100px;
  opacity: 0.6;
}

.playlist .slide .videos-carousel .noticias-novos-videos:hover .start {
  opacity: 0.8;
}

.playlist .slide .videos-carousel .noticias-novos-videos .legend {
  padding-top: 10px;
  text-align: center;
  position: absolute;
  bottom: 0px;
  height: 70px;
  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  left: 0px;
  background-color: rgba(0,0,0,0.4);
}

.playlist .slide .videos-carousel .noticias-novos-videos {
  border-radius: 8px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  width: 100%;
  -webkit-box-shadow: 0 10px 6px -6px #777;
     -moz-box-shadow: 0 10px 6px -6px #777;
          box-shadow: 0 10px 6px -6px #777;
}

.playlist .slide .videos-carousel .noticias-novos-videos:hover {
  cursor: pointer;
  -webkit-box-shadow: 0 10px 6px -6px #0d143c;
     -moz-box-shadow: 0 10px 6px -6px #0d143c;
          box-shadow: 0 10px 6px -6px #0d143c;
}

.playlist .slide .videos-carousel  .noticias-novos-videos .img {
  border-radius: 8px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.playlist .noticias-big-title {
  font-weight: bold;
  color: darkblue;
  font-size: 1rem;
  display: flex;
  align-items: center;
  margin: 0px;
}

.playlist .more-details {
  font-weight: normal;
  color: darkblue;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: right;
}

.playlist .padding-bottom {
  padding-bottom: 20px;
}

.playlist .no-padding {
  padding: 0px;
}

.playlist .no-margin {
  margin: 0px;
}

</style>
