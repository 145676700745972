<script>
import ConfirmationModal from '@/components/ConfirmationModal';

import Vue from 'vue';

import HelperPlugin from '@/plugins/HelperPlugin';
Vue.use(HelperPlugin);

export default {
  name: 'users-table',
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: 'Table columns',
    },
    data: {
      type: Array,
      default: () => [],
      description: 'Table data',
    },
    type: {
      type: String,
      default: '',
      description: 'Whether table is striped or hover type',
    },
    theadClasses: {
      type: String,
      default: '',
      description: '<thead> css classes',
    },
    tbodyClasses: {
      type: String,
      default: '',
      description: '<tbody> css classes',
    },
    hasActions: {
      type: Boolean,
      default: false,
    },
    isDownloadable: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ConfirmationModal,
  },
  data() {
    return {
      page: 1,
      perPage: 10,
      pages: [],
      showModal: false,
      preDeletedItem: {},

      searchBar: ''
    };
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
    displayedData() {
      return this.paginate(this.filteredData);
    },
    filteredData() {
      return this.data.filter(obj => {
        return (
          (obj.nome ? obj.nome : '').toLowerCase().includes(this.searchBar.toLowerCase())
          || (obj.email ? obj.email.email : '').toLowerCase().includes(this.searchBar.toLowerCase())
          || (obj.telefone ? obj.telefone.telefone : '').toLowerCase().includes(this.searchBar.toLowerCase())
          || (obj.caracteristicas ? this.descricaoCaracteristicas(obj.caracteristicas) : '').toLowerCase().includes(this.searchBar.toLowerCase())
        )
      });
    },
  },
  methods: {
    hasValue(item, column) {
      return item[column.toLowerCase()] !== 'undefined';
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    setPages() {
      if (this.pages) {
        this.pages = [];
      }

      const numberOfPages = Math.ceil(this.data.length / this.perPage);

      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      const page = this.page;
      const perPage = this.perPage;

      const from = (page * perPage) - perPage;
      const to = (page * perPage);

      return data.slice(from, to);
    },
    preDeleteItem(item, index) {
      this.preDeletedItem = {
        item,
        index,
      };

      this.showModal = true;
    },
    deleteItem() {
      this.$emit('deleteItem', this.preDeletedItem);

      this.showModal = false;
    },

    descricaoCaracteristicas(caracteristicas) {
      return this.$CWLDescricaoCaracteristica(caracteristicas);
    },
  },
  watch: {
    data() {
      this.setPages();
    },
  },
};
</script>

<template>
  <div>
    <div class="form-group">
      <input type="text" class="form-control" placeholder="Para pesquisar, digite sua busca aqui..."
        v-model="searchBar" />
    </div>
    <table class="table table-hover" :class="tableClass">
      <thead class="thead-light" :class="theadClasses">
        <tr>
          <slot name="columns">
            <th>Tipo de Usuário</th>
            <th>Nome</th>
            <th>E-mail</th>
            <th style="text-align: center;">Telefone</th>
            <th style="text-align: center;">Ações</th>
          </slot>
        </tr>
      </thead>
      <tbody :class="tbodyClasses">
        <tr v-for="(item, index) in displayedData" :key="index" style="cursor: default !important; height: 60px;">
          <td>
            {{ descricaoCaracteristicas(item.caracteristicas) }}
          </td>
          <td>
            <router-link :to="`edit-user/${item.id}`" title="Ver usuário">
              {{ item.nome }}
            </router-link>
          </td>
          <td>{{ (item.email ? item.email.email : ' -- ') }}</td>
          <td style="text-align: center;">{{ (item.telefone ? item.telefone.telefone : ' -- ') }}</td>

          <td style="text-align: center;" v-if="hasActions" width="12%">
            <router-link :to="`edit-user/${item.id}`" title="Ver usuário" class="button-action">
              <i class="tim-icons icon-paper"></i>
            </router-link>
            <!-- <a @click="preDeleteItem(item, index)" href="#" style="color: red" title="Excluir usuário"
              class="button-action">
              <i class="tim-icons icon-trash-simple"></i>
            </a> -->
          </td>
        </tr>
      </tbody>
    </table>

    <nav class="table-pagination">
      <ul class="pagination">
        <li class="page-item">
          <button type="button" class="page-link" v-if="page != 1" @click="page--">
            <i class="fas fa-angle-left"></i>
          </button>
        </li>

        <li class="page-item">
          <button type="button" class="page-link" v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
            :key="index" @click="page = pageNumber" :class="{ 'is-active': page === pageNumber }">
            {{ pageNumber }}
          </button>
        </li>

        <li class="page-item">
          <button type="button" class="page-link" v-if="page < pages.length" @click="page++">
            <i class="fas fa-angle-right"></i>
          </button>
        </li>
      </ul>
    </nav>

    <confirmation-modal :show="showModal" @close="showModal = false" @confirmDelete="deleteItem()"></confirmation-modal>
  </div>
</template>

<style>
.button-action {
  margin-left: 10px;
  margin-right: 10px;
}

td.min {
  width: 1%;
  white-space: nowrap;
}
</style>