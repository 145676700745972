<script>
import {vueTopprogress} from 'vue-top-progress';
import PlaylistCarousel from '@/components/NoticiasMercado/PlaylistCarousel.vue'; 
import playlistService from '@/services/VideoContent/playlistService';

const tableColumns = ['Nome'];

export default {
  components: {
    vueTopprogress,
    PlaylistCarousel,
},
  data() {
    return {
      table: {
        title: 'Educação',
        columns: [...tableColumns],
      },
      isLoading: true,
      playlists: [],
    };
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
    getRole() {
      return this.$store.getters.userClaim.role;
    },
  },
  mounted() {
    this.getPlaylists();
  },
  methods: {
    async getPlaylists() {
      try {
        if (this.getRole === 'master' || 'gestor') {
          const adminType = 'Admin';
          const playlists = await playlistService.findPlaylistsByTag('Universidade do saber', adminType);
          this.playlists = playlists;
        } else {
          const playlists = await playlistService.findPlaylistsByTag('Universidade do saber', this.currentPessoa.channel);
  
          this.playlists = playlists;
        }
      } catch(err) {
        console.log(err);
      } finally {
        this.isLoading = false; 
      }
    },
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<template>
  <div class="academy">
    <div class="row padding-bottom">
      <div class="col-md-12 academy-header">
        <i class="fa fa-arrow-left mr-3" @click="goBack" style="color: darkblue; cursor: pointer;"></i>
        <h3 class="academy-title">Trilha do Conhecimento</h3>
      </div>
    </div> 
    <div class="row">
      <div class="col-md-12">
        <div class="row" v-if="!isLoading" v-for="item in playlists" :key="item.id">
          <div class="col-md-12">
            <playlist-carousel :playlistName="item.title" :playlist="item.linked"></playlist-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.academy-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.academy-title {
  font-weight: bold;
  color: darkblue;
  font-size: 1rem;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}
</style>