import moment from 'moment-timezone';

export default (order) => {
  //Calculo de remuneracao deve considerar D+30 a partir do pagamento do aporte
  if (!order) return 0;
  if (order.status === 'Pago' || order.status === 'Liquidado') {
    var today;

    const data_pagamento = moment.unix(order.data_pagamento);
    const data_vencimento = moment.unix(order.data_vencimento);
    const data_liquidacao = moment.unix(order.data_liquidacao);

    //Se já foi liquidado congela o cálculo de remuneracao
    if (order.data_liquidacao) {
      today = data_liquidacao;
    } else if (order.data_vencimento) {
      if (data_vencimento > moment()) today = moment();
      else today = data_vencimento;
    } else {
      today = moment();
    }

    const months = today.diff(data_pagamento, 'month');

    var prazoAtual = 0;
    if (months > parseInt(order.financing_details.fields['prazo_oferta'].value))
      prazoAtual = parseInt(
        order.financing_details.fields['prazo_oferta'].value,
      );
    else prazoAtual = months;

    const juros = calcularJurosCompostos(
      order.item.value,
      order.financing_details.fields['taxa_juros'].value
        .toString()
        .replace(',', '.'),
      prazoAtual,
    );

    return juros;
  } else {
    return 0;
  }
};

export const calcularJurosCompostos = (valorAporte, taxaJuros, prazo) => {
  let investimento_inicial = parseFloat(valorAporte);
  var meses = parseInt(prazo);
  let taxa_de_juros = parseFloat(taxaJuros.toString().replace(',', '.'));

  let investimento_acumulado = investimento_inicial;

  var total_investimento_acumulado = investimento_inicial;

  let juros_compostos = 0;
  let juros_compostos_total = 0;

  for (let i = 0; i < meses; i++) {
    juros_compostos = (investimento_acumulado * taxa_de_juros) / 100;
    juros_compostos_total = juros_compostos_total + juros_compostos;
    investimento_acumulado = investimento_acumulado + juros_compostos;
  }

  return juros_compostos_total;
};
