import VueRouter from 'vue-router';
import routes from './routes';
import store from '@/store/index';

import Vue from 'vue';
import NotificationPlugin from '@/components/NotificationPlugin';
import { api } from '../services/ServiceConfig/ServiceConfig';
Vue.use(NotificationPlugin);

export const router = new VueRouter({
  routes, // short for routes: routes
  mode: 'history',
  linkExactActiveClass: 'active',
  scrollBehavior: (to) => {
    if (to.hash) {
      // return { selector: to.hash };
      return { x: 0, y: 0 };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

const checkToken = async () => {
  try {
    await api.get('/valid-token');
  } catch (error) {
    Vue.prototype.$notify({
      title: 'Ooopss',
      message: 'Sua sessão expirou. Por favor, logue novamente.',
      icon: 'tim-icons icon-bell-55',
      horizontalAlign: 'center',
      verticalAlign: 'top',
      type: 'warning',
      timeout: 3000,
    });

    store.dispatch('logout');

    return router.push('/login');
  }
};

router.afterEach((to) => {
  if (
    to.name !== 'login' &&
    to.name !== 'register' &&
    to.name !== 'forgot-password'
  ) {
    checkToken();
  }
});

router.beforeEach((to, from, next) => {
  if (
    (!Object.keys(store.getters.isLoggedIn).length > 0 ||
      !Object.keys(store.getters.getPessoa).length > 0) &&
    to.name !== 'login' &&
    to.name !== 'register' &&
    to.name !== 'forgot-password' &&
    to.name !== 'register-ref' &&
    to.name !== 'login-ref'
  ) {
    if (from.name) {
      Vue.prototype.$notify({
        title: 'Ooopss',
        message: 'Sua sessão expirou. Por favor, logue novamente.',
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: 'warning',
        timeout: 3000,
      });
    }

    store.commit('RESET', '');
    return router.push('/login');
  }

  next();
});

export default router;
