import axios from 'axios';
import firebase from 'firebase';

import {
  TipoDocumento,
  TipoEndereco,
  TipoTelefone,
  TipoEmail,
  EnderecoPadrao,
  PessoaCaracteristicas,
} from '@/store/cwl_enums';
import store from '@/store/index';
import { withLog } from '../../logger/with_log';
import { currentUser } from '../../firebase';
import {
  DocumentoPadrao,
  EmailPadrao,
  PessoaCaracteristica,
  PessoaPadraoPJ,
  TelefonePadrao,
} from '../../store/cwl_enums';
import _ from 'lodash';

import { api } from '@/services/ServiceConfig/ServiceConfig';

export default {
  pessoaRef() {
    return firebase.firestore().collection('pessoas');
  },

  async createPerson(person) {
    const docRef = this.pessoaRef().doc();
    const personWithId = {
      ...person,
      id: docRef.id,
    };
    await withLog(() => docRef.set(personWithId));
  },

  async criarPessoaSemValidacao(pessoa) {
    const pessoaAdicionada = await this.pessoaRef().add(pessoa);
    const pessoaFromFirebase = await this.pessoaRef()
      .doc(pessoaAdicionada.id)
      .get();
    const novaPessoa = pessoaFromFirebase.data();
    novaPessoa.id = pessoaFromFirebase.id;

    await this.pessoaRef().doc(novaPessoa.id).update(novaPessoa);

    return novaPessoa;
  },

  async addPersona(userId, persona) {
    this.pessoaRef()
      .doc(userId)
      .update({
        caracteristicas: firebase.firestore.FieldValue.arrayUnion(persona),
      });
  },

  async removePersona(userId, persona) {
    this.pessoaRef()
      .doc(userId)
      .update({
        caracteristicas: firebase.firestore.FieldValue.arrayRemove(persona),
      });
  },

  async hasPersona(userId, persona) {
    let _personasToCheck = typeof persona === 'string' ? [persona] : persona;
    _personasToCheck = _personasToCheck.map((e) => e.toLowerCase());

    const data = (await this.pessoaRef().doc(userId).get()).data();
    const personas = (data.caracteristicas || []).map((e) => e.toLowerCase());

    const value = _personasToCheck.filter((e) => personas.indexOf(e) !== -1);
    return value.length > 0;
  },

  async getUsers() {
    return withLog(() =>
      this.pessoaRef()
        .where('caracteristicas', 'array-contains-any', PessoaCaracteristicas)
        .get(),
    );
  },

  async findCurrentUser() {
    try {
      const user = currentUser();
      if (!user) {
        throw 'user not authenticated';
      }

      return this.findPessoaById(user.uid);
    } catch (error) {
      console.error('find current user error', error);
    }
  },

  async findPessoaById(id) {
    return withLog(async () => {
      const doc = await this.pessoaRef().doc(id).get();
      if (!doc.data()) {
        return this.getPessoaByPropId(id);
      }
      return doc.data();
    });
  },

  async findCompanyByIdentificationNumber(identificationNumber) {
    return withLog(async () => {
      const id = identificationNumber.match(/\d+/g).join('');
      const doc = await this.pessoaRef().doc(id).get();
      return doc.data();
    });
  },

  async getPessoa() {
    const { data } = await api.get('/pessoas');

    return data;
  },

  async getPessoaById(id) {
    return this.getPessoaBy({ id });
  },

  async getPessoaByPropId(id) {
    const snapshot = await withLog(() =>
      this.pessoaRef().where('id', '==', id).get(),
    );
    if (!snapshot) return;
    return snapshot.docs[0].data();
  },

  async getPessoaBy({ id, email }) {
    return withLog(async () => {
      if (id) {
        return this.pessoaRef()
          .doc(id)
          .get()
          .then((snapshot) => (snapshot ? snapshot.data() : null));
      } else if (email) {
        return this.pessoaRef()
          .where('email.email', '==', email)
          .get()
          .then((snapshot) => {
            return !snapshot.empty ? snapshot.docs[0].data() : null;
          });
      }
      return null;
    });
  },

  async updatePessoa(id, pessoa) {
    await withLog(() => this.pessoaRef().doc(id).update(pessoa));
  },

  async updatePessoaBy(pessoa) {
    const parsedPessoa = _.omit(pessoa, [
      'id',
      'documentos',
      'documento',
      'email',
      'emails',
    ]);
    await api.post('/pessoas', parsedPessoa);
  },

  async createNewPessoa(pessoa) {
    await withLog(async () => {
      const ref = this.pessoaRef();
      const uid = currentUser().uid;
      if (!uid) {
        throw 'usuário não foi criado';
      }

      var doc = await ref.doc(uid).get();
      if (!doc.exists) {
        ref.doc(uid).set({ ...pessoa, id: uid });
      }
    });
  },

  async deletePessoa(id) {
    await withLog(() => this.pessoaRef().doc(id).delete());
  },

  async searchByPrincipalDocument(doc) {
    return withLog(() =>
      this.pessoaRef().where('documento.numero', '==', doc).get(),
    );
  },

  async searchPJByCNPJ(cnpj) {
    return withLog(() =>
      this.pessoaRef().where('documento.numero', '==', cnpj).get(),
    );
  },

  async updateCompanyByUserId(company, uid) {
    try {
      var pessoaRef = this.pessoaRef().doc(uid || currentUserId());
      const current = (await pessoaRef.get()).data();
      await pessoaRef.update({
        empresa: {
          ...current.empresa,
          ...company,
        },
      });
    } catch (error) {
      console.error('save address social transformation error', error);
    }
  },

  async deleteCompanyByUserId(userId) {
    const pessoaRef = this.pessoaRef().doc(userId);
    await pessoaRef.update({
      empresa: {},
    });
  },

  async updateCompany(value, { responsavel } = {}) {
    try {
      const id = value.id || value.documento.numero.match(/\d+/g).join('');

      const doc = this.pessoaRef().doc(id);
      const snapshot = await doc.get();

      let company = snapshot.exists
        ? {
            ...snapshot.data(),
            ...value,
            id,
          }
        : { ...value, id };

      if (!company.documento.numero) {
        throw 'cnpj da empresa é obrigatório';
      }

      company.telefones = company.telefone
        ? [...(company.telefones || []), company.telefone]
        : company.telefones || [];

      company.enderecos =
        company.endereco && company.endereco.cep
          ? [...(company.enderecos || []), company.endereco]
          : company.endereco || [];

      company.emails =
        company.email && company.email.email
          ? [...(company.emails || []), company.email]
          : company.emails || [];

      company.documentos =
        company.documento && company.documento.numero
          ? [...(company.documentos || []), company.documento]
          : company.documentos || [];

      if (responsavel) {
        company.dados_complementares = {
          ...company.dados_complementares,
          responsavel: responsavel,
        };
      }

      if (snapshot.exists) {
        doc.update(company);
      } else {
        doc.set(company);
      }

      return company;
    } catch (error) {
      console.error('create company error', error);
    }
  },

  async criarPessoaPJ(pessoa) {
    console.warn('DEPRECATED: criarPessoaPJ');
    await withLog(async () => {
      const empresasEncontradas = await this.searchPJByCNPJ(
        pessoa.documento.numero,
      );
      if (empresasEncontradas.size > 0) {
        await this.pessoaRef().doc(pessoa.id).update(pessoa);
        return pessoa;
      } else {
        if (!pessoa.dados_complementares.responsavel) {
          pessoa.dados_complementares.responsavel = store.getters.getPessoa;
        }
        const pessoaAdicionada = await this.pessoaRef().add(pessoa);
        const pessoaFromFirebase = await this.pessoaRef()
          .doc(pessoaAdicionada.id)
          .get();
        const novaPessoa = pessoaFromFirebase.data();
        novaPessoa.id = pessoaFromFirebase.id;
        return novaPessoa;
      }
    });
  },

  async searchExternalPJByCNPJ(cnpj) {
    const cnpjWithoutMask = cnpj
      .replace('.', '')
      .replace('.', '')
      .replace('/', '')
      .replace('-', '');

    const newPessoa = defaultPersonPJ();

    let objReturn = null;
    await axios
      .get(
        'https://api.cnpja.com.br/companies/{cnpj}?company_max_age=30'.replace(
          '{cnpj}',
          cnpjWithoutMask,
        ),
        {
          headers: {
            authorization: process.env.VUE_APP_CNPJA_API_KEY,
          },
        },
      )
      .then((response) => {
        const dadosEmpresa = response.data;

        const pessoa = newPessoa;

        pessoa.nome = dadosEmpresa.name;
        pessoa.dados_complementares.nome_fantasia = dadosEmpresa.alias
          ? dadosEmpresa.alias
          : '';

        /**
         * Documento
         */
        pessoa.documento.tipo = TipoDocumento.CNPJ;
        pessoa.documento.numero = cnpj;
        /**
         * Endereço
         */
        if (dadosEmpresa.address) {
          pessoa.endereco.tipo = TipoEndereco.Comercial;
          pessoa.endereco.rua = dadosEmpresa.address.street;
          pessoa.endereco.numero = dadosEmpresa.address.number;
          pessoa.endereco.complemento = dadosEmpresa.address.details;
          pessoa.endereco.bairro = dadosEmpresa.address.neighborhood;
          pessoa.endereco.cep = dadosEmpresa.address.zip;
          pessoa.endereco.cidade = dadosEmpresa.address.city;
          pessoa.endereco.estado = dadosEmpresa.address.state;
          pessoa.endereco.pais = 'BR';
        }
        /**
         * Telefone
         */
        if (dadosEmpresa.phone && dadosEmpresa.phone.length > 0) {
          pessoa.telefone.tipo = TipoTelefone.Comercial;
          pessoa.telefone.telefone = dadosEmpresa.phone;
        }
        /**
         * E-mail
         */
        if (dadosEmpresa.email && dadosEmpresa.email.length > 0) {
          pessoa.email.tipo = TipoEmail.Comercial;
          pessoa.email.email = dadosEmpresa.email;
        }

        objReturn = pessoa;
      })
      .catch((error) => {
        console.log(error);
      });

    return objReturn;
  },

  async searchAddressByZipcode(cep) {
    const cepWithoutMask = cep
      .replace('.', '')
      .replace('.', '')
      .replace('/', '')
      .replace('-', '');

    const newEndereco = Object.assign({}, EnderecoPadrao);

    let objReturn = null;
    await axios
      .get(
        'https://viacep.com.br/ws/{cep}/json/'.replace('{cep}', cepWithoutMask),
      )
      .then((response) => {
        const dadosEndereco = response.data;
        if (!dadosEndereco.erro) {
          const endereco = newEndereco;
          endereco.tipo = TipoEndereco.Comercial;
          endereco.rua = dadosEndereco.logradouro;
          endereco.numero = '';
          endereco.complemento = '';
          endereco.bairro = dadosEndereco.bairro;
          endereco.cep = dadosEndereco.cep;
          endereco.cidade = dadosEndereco.localidade;
          endereco.estado = dadosEndereco.uf;
          endereco.pais = 'BR';

          objReturn = endereco;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return objReturn;
  },
};

export const defaultPersonPJ = function () {
  const newPessoaPJ = Object.assign({}, PessoaPadraoPJ);
  newPessoaPJ.caracteristicas = [];
  newPessoaPJ.caracteristicas.push(PessoaCaracteristica.PessoaJuridica);

  newPessoaPJ.endereco = Object.assign({}, EnderecoPadrao);
  newPessoaPJ.endereco.tipo = TipoEndereco.Comercial;
  newPessoaPJ.enderecos = [];

  newPessoaPJ.telefone = Object.assign({}, TelefonePadrao);
  newPessoaPJ.telefone.tipo = TipoTelefone.Comercial;
  newPessoaPJ.telefones = [];

  newPessoaPJ.email = Object.assign({}, EmailPadrao);
  newPessoaPJ.email.tipo = TipoEmail.Comercial;
  newPessoaPJ.emails = [];

  newPessoaPJ.documento = Object.assign({}, DocumentoPadrao);
  newPessoaPJ.documento.tipo = TipoDocumento.CNPJ;
  newPessoaPJ.documentos = [];

  newPessoaPJ.dados_complementares = {};
  newPessoaPJ.dados_complementares.nome_fantasia = '';

  return newPessoaPJ;
};
