import firebase from 'firebase';
const pdf2base64 = require('pdf-to-base64');
import { withLog } from '../../logger/with_log';

import { api } from '@/services/ServiceConfig/ServiceConfig';

export default {
  storageRef(path) {
    return firebase.storage().ref(path);
  },

  tipoDocumentoRef() {
    return firebase.firestore().collection('perfil_tipo_documento');
  },

  async getTipoDocumentos() {
    const { data } = await api.get('/perfil-tipo-documento');

    return data.perfilTipoDocumento;
  },

  async addNewAttachment(attachment, fileName, storage = 'Standard') {
    const operationalAttachment = this.storageRef(storage);

    const fileNameAdjusted = fileName.replace(/\//g, '_');
    const response = await operationalAttachment
      .child(fileNameAdjusted)
      .put(attachment);

    return response;
  },

  async getAttachments(storage = 'Standard') {
    const operationalAttachments = this.storageRef(storage);

    const response = await operationalAttachments.listAll();

    return response;
  },

  async getUserDocuments() {
    const { data } = await api.get('/documents');

    return data.attachments;
  },

  async deleteAttachment(file, storage = 'Standard', user = undefined) {
    const operationalAttachments = this.storageRef(storage);

    if (user) {
      await operationalAttachments.child(user).child(file).delete();
    } else {
      await operationalAttachments.child(file).delete();
    }
  },

  async downloadAttachment(file, storage = 'Standard', type = undefined) {
    const { data } = await api.post(
      '/download',
      {
        filePath: `${storage}/${file}`,
      },
      {
        responseType: 'blob',
      },
    );

    const url = window.URL.createObjectURL(data);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = file;

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  },

  async base64Attachment(file, storage) {
    const operationalAttachments = this.storageRef(storage);

    return await operationalAttachments
      .child(file)
      .getDownloadURL()
      .then(async function (url) {
        return await pdf2base64(url)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            console.log(error);
          });
      });
  },

  async urlAttachment(file, storage) {
    let urlAtt = '';
    const operationalAttachments = this.storageRef(storage);
    await operationalAttachments
      .child(file)
      .getDownloadURL()
      .then(function (url) {
        urlAtt = url;
      });

    return urlAtt;
  },

  async getFirstInvestmentTerm() {
    const { data } = await api.get('/investment-term');

    return data.investmentTerm;
  },

  async addNewDocumentAttachment(attachment, fileName, storage, user) {
    const formData = new FormData();
    const fileNameAdjusted = fileName.replace(/\//g, '_');
    formData.append('file', attachment, fileNameAdjusted);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    api.post(`/documents/${storage}`, formData, config);
  },

  async addNewFinancingImageAttachment(
    attachment,
    fileName,
    storage,
    financingId,
  ) {
    const attach = this.storageRef(storage);

    const fileNameAdjusted = fileName.replace(/\//g, '_');

    const response = await attach
      .child(financingId)
      .child(fileNameAdjusted)
      .put(attachment);

    return response;
  },
};
