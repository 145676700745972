import axios from 'axios';
import Cookies from 'js-cookie';

export const ServiceConfig = axios.create({
  baseURL: process.env.VUE_APP_ENDPOINT_WS,
});

export const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    const accessToken = Cookies.get('accessToken');
    if (accessToken) {
      config.headers.authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
