<script>
import { vueTopprogress } from 'vue-top-progress';
import { DoubleBounce } from 'vue-loading-spinner';

export default {
  components: {
    vueTopprogress,
    DoubleBounce,
  },
  data() {
    const month = new Date().getMonth();
    const year = new Date().getFullYear();
    return {
      masks: {
        weekdays: 'WWW',
      },
      attributes: [
          // {
          //   key: 1,
          //   customData: {
          //     title: 'Lunch with mom',
          //     class: 'alert-warning p-2',
          //   },
          //   dates: new Date(year, month, 1),
          // },
          // {
          //   key: 2,
          //   customData: {
          //     title: 'Take Noah to basketball practice',
          //     class: 'alert-warning p-2',
          //   },
          //   dates: new Date(year, month, 2),
          // },
          // {
          //   key: 3,
          //   customData: {
          //     title: "Noah's basketball game.",
          //     class: 'alert-warning p-2',
          //   },
          //   dates: new Date(year, month, 5),
          // },
          // {
          //   key: 4,
          //   customData: {
          //     title: 'Take car to the shop',
          //     class: 'alert-warning p-2',
          //   },
          //   dates: new Date(year, month, 5),
          // },
          // {
          //   key: 4,
          //   customData: {
          //     title: 'Meeting with new client.',
          //     class: 'alert-info p-2',
          //   },
          //   dates: new Date(year, month, 7),
          // },
          // {
          //   key: 5,
          //   customData: {
          //     title: "Mia's gymnastics practice.",
          //     class: 'alert-info p-2',
          //   },
          //   dates: new Date(year, month, 11),
          // },
          // {
          //   key: 6,
          //   customData: {
          //     title: 'Cookout with friends.',
          //     class: 'alert-success p-2',
          //   },
          //   dates: { months: 5, ordinalWeekdays: { 2: 1 } },
          // },
          // {
          //   key: 7,
          //   customData: {
          //     title: "Mia's gymnastics recital.",
          //     class: 'alert-success p-2',
          //   },
          //   dates: new Date(year, month, 22),
          // },
          // {
          //   key: 8,
          //   customData: {
          //     title: 'Visit great grandma.',
          //     class: 'alert-success p-2',
          //   },
          //   dates: new Date(year, month, 25),
          // },
      ],
    };
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<template>
  <div class="users-list">
    <div class="row">
      <div class="col-12">
        <card
          :title="`Reuniões`"
          :addItems="false"
        >
          <vc-calendar
            class="custom-calendar max-w-full"
            locale="pt-BR"
            :masks="masks"
            :attributes="attributes"
            disable-page-swipe
            is-expanded
          >
            <template v-slot:day-content="{ day, attributes }">
              <div class="custom-container">
                <span class="custom-subtitle">{{ day.day }}</span>
                  <div class="custom-flex-grow">
                  <p v-for="attr in attributes" :key="attr.key" :class="attr.customData.class" style="border-radius: 4px;">
                    <span class="custom-tag-content">{{ attr.customData.title }}</span>
                  </p>
                </div>
              </div>
            </template>
          </vc-calendar>
        </card>
      </div>
    </div>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>

<style lang="scss">
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  display: none;
}

.custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;

  border-radius: 0;
  width: 100%;

  & .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
  }
  & .vc-weeks {
    padding: 0;
  }
  & .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;
  }
  & .vc-day {
    padding: 0 5px 3px 5px;
    text-align: left;
    height: var(--day-height);
    min-width: var(--day-width);
    background-color: white;
    &.weekday-1,
    &.weekday-7 {
      background-color: #eff8ff;
    }
    &:not(.on-bottom) {
      border-bottom: var(--day-border);
      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }
    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }
  & .vc-day-dots {
    margin-bottom: 5px;
  }
}

.custom-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.custom-subtitle {
  font-size: 1rem;
}

.custom-flex-grow {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: auto;
}

.custom-tag {
  border-radius: 4px;
  margin-top: 0;
  margin-bottom: 0;
}

.custom-tag-content {
  display: inline-block;
  padding: 0rem 0.2rem;
  font-size: 0.75rem;
  color: black;
}
</style>
