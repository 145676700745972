import firebase from 'firebase';
import { withLog } from '../../logger/with_log';

export default {
  brokersHistoryRef() {
    return firebase.firestore().collection('brokers-history');
  },

  async getBrokerHistory(email) {
    const snapshot = await withLog(() => this.brokersHistoryRef().where('email', '==', email).get());
    if (!snapshot || snapshot.empty) return;
    return snapshot.docs[0].data();
  },

  async createBrokerHistory(broker) {
    const docRef = this.brokersHistoryRef().doc();
    const brokerHistory = {
      ...broker,
      id: docRef.id
    };
    await withLog(() => docRef.set(brokerHistory));
  },

  async updateBrokerSurveyHistory(brokerHistory, email) {
    withLog(async () => {
      const snapshot = await this.brokersHistoryRef()
        .where('email', '==', email)
        .get()
        .then((snapshot) => {
          return !snapshot.empty ? snapshot.docs[0] : null;
        });
      if (snapshot == null) throw 'broker-history not-found';
      await snapshot.ref.update(brokerHistory)
    })
  }
}