<script>
import InvoicesTable from '@/components/BrokerArea/Invoice/InvoiceTable';
import invoicesService from '@/services/Invoices/invoicesService';

import { vueTopprogress } from 'vue-top-progress';
import { DoubleBounce } from 'vue-loading-spinner';
import logsService from '@/services/Logs/logsService';

const tableColumns = ['Competência', 'Nome arquivo', 'Ações'];

export default {
  components: {
    InvoicesTable,
    vueTopprogress,
    DoubleBounce,
  },
  data() {
    return {
      table: {
        title: 'Lista de Nota Fiscais',
        columns: [...tableColumns],
      },
      invoices: [],
      isLoading: true,
      closeModal: false,
    };
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
  },
  mounted() {
    this.getInvoices();
  },
  methods: {
    async getInvoices() {
      this.$refs.topProgress.start();
      this.invoices = await invoicesService.getAllInvoices() || [];
      if (this.invoices.lenght === 0) {
        this.$notify({
          title: 'Sem notas fiscais para mostrar',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.isLoading = false;
      this.$refs.topProgress.done();
    },
    async deleteInvoice(preDeletedItem) {
      try {
        const logUser = {
          user: {
            email: this.currentPessoa.email.email,
          },
        };
        const logContent = preDeletedItem.item;
        logsService.createNewLog(logUser, '', 'Excluir Invoice', '', logContent);

        await invoicesService.deleteInvoice(preDeletedItem.item.id);
        await invoicesService.deleteInvoiceInStorage(preDeletedItem.item.filename);

        this.getInvoices();

        this.$notify({
          title: 'Nota fiscal Deletada com sucesso',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        preDeletedItem = {};
      } catch (error) {
        console.log(error);
        this.$notify({
          title: 'Houve um erro ao deletar a nota fiscal',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<template>
  <div class="users-list">
    <div class="row">
      <div class="col-12">
        <card
          :title="`${table.title} (${this.invoices.length})`"
          :addItems="true"
          :itemType="'Notas Fiscais'"
          :routerType="'invoice'"
        >
          <div class="table-responsive">
            <invoices-table
              :data="invoices"
              :columns="table.columns"
              thead-classes="text-primary"
              @deleteItem="deleteInvoice"
            ></invoices-table>

            <double-bounce v-if="isLoading"></double-bounce>
          </div>
        </card>
      </div>
    </div>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
