/* eslint-disable require-jsdoc */
import firebase from 'firebase';
import { withLog } from '@/logger/with_log';
import PlaceholderImg from '@/assets/images/placeholder.jpeg';
import { api } from '@/services/ServiceConfig/ServiceConfig.js';

export default {
  storageRef(path) {
    return firebase.storage().ref(path);
  },

  collection() {
    return firebase.firestore().collection('videos');
  },

  async getAll() {
    const { data } = await api.get('/videos/all');

    return data.videos;
  },

  async update(id, data) {
    const c = data.title.toLowerCase();

    var array = [];
    for (let i = 1; i < c.length + 1; i++) {
      array.push(c.substring(0, i));
    }

    data.title_search = array;

    await this.collection().doc(id).update(data);
  },

  async get(id) {
    const { data } = await api.get(`/video/${id}`);

    return data.video || null;
  },

  async findById(id) {
    const { data } = await api.get(`/video/${id}`);

    return data.video;
  },

  async add(data) {
    return await withLog(async () => {
      const docRef = this.collection().doc();
      const c = data.title.toLowerCase();

      var array = [];
      for (let i = 1; i < c.length + 1; i++) {
        array.push(c.substring(0, i));
      }

      data.title_search = array;

      const video = {
        ...data,
        id: docRef.id,
      };

      await docRef.set(video);

      return video;
    });
  },

  async delete(id) {
    await withLog(() => this.collection().doc(id).delete());
  },

  async uploadFile(attachment, fileName, storage = 'video-content') {
    const operationalAttachment = this.storageRef(storage);

    const fileNameAdjusted = fileName.replace(/\//g, '_');
    const response = await operationalAttachment
      .child(fileNameAdjusted)
      .put(attachment);

    //console.log(response);

    return response;
  },

  async urlThumbnail(file, storage = 'video-content') {
    const { data } = await api.get('/download/image', {
      params: {
        filePath: `video-content/${file}`,
      },
    });

    return data.url;
  },

  //dashboard services
  async getMostRecentVideos(limit = 3) {
    const { data } = await api.get('/videos/recent', {
      params: {
        limit,
      },
    });

    return data.videos;
  },

  async getSearchVideos(search, limit = 100) {
    const { data } = await api.get('/videos/search', {
      params: {
        search,
        limit,
      },
    });

    return data.videos;
  },

  async getFavVideos(limit = 250) {
    const { data } = await api.get('/videos/favorites', {
      params: {
        limit,
      },
    });

    return data.videos;
  },

  async getMostPopularVideos(limit = 3) {
    const { data } = await api.get('/videos/popular', {
      params: {
        limit,
      },
    });

    return data.videos;
  },

  async getBannerVideos(limit = 5) {
    const { data } = await api.get('/videos/banner', {
      params: {
        limit,
      },
    });

    return data.videos;
  },

  async addView(id) {
    await api.patch('/videos/add-view', {
      videoId: id,
    });
  },

  async addFav(id) {
    await api.patch('/videos/add-favorite', {
      videoId: id,
    });
  },

  async removeFav(id) {
    await api.patch('/videos/remove-favorite', {
      videoId: id,
    });
  },

  async addLike(id, email) {
    await api.patch('/videos/add-like', {
      videoId: id,
    });
  },

  async removeLike(id, email) {
    await api.patch('/videos/remove-like', {
      videoId: id,
    });
  },

  async addNote(id, note, email) {
    await api.patch('/videos/add-note', {
      videoId: id,
      note,
    });
  },

  async getFileObjectForPlaceholder() {
    const response = await fetch(PlaceholderImg);
    const blob = await response.blob();

    const file = new File([blob], 'placeholder.jpeg', { type: blob.type });

    return file;
  },
};
