<script>
import brokersService from '@/services/Brokers/brokersService';
import UpdatedBrokerNotification from '@/components/Notifications/Brokers/UpdatedBrokerNotification';
import UpdatedBrokerErrorNotification from '@/components/Notifications/Brokers/UpdatedBrokerErrorNotification';
import SuccessNotification from '@/components/Notifications/General/SuccessNotification';
import ErrorRegisterNotification from '@/components/Notifications/General/ErrorRegisterNotification';
import GetBrokerErrorNotification from '@/components/Notifications/Brokers/GetBrokerErrorNotification';
import {vueTopprogress} from 'vue-top-progress';
import BrokersTable from '@/components/Brokers/BrokersTable';
import { DoubleBounce } from 'vue-loading-spinner';
import logsService from '@/services/Logs/logsService';
import { cpf, nonEmpty } from '@/validate/customValidators.js';
import { questionsArray } from '@/functions/questions.js';
import { getInitialPessoaValue } from '@/functions/getInitialPessoaValue.js';
import VueHtml2pdf from 'vue-html2pdf';
import authenticationService from '@/services/Authentication/authenticationService';
import claimsService from '@/services/Authentication/claimsService';

const tableColumns = ["Nome", "Ações"];

export default {
  components: {
    vueTopprogress,
    DoubleBounce,
    BrokersTable,
    VueHtml2pdf,
  },
  data() {
    return {
      broker: {
        name: "",
        email: "",
        cpf: "",
        chave_pix: "",
        hubspot_id: "",
        status: false,
        channel: "",
        leader_id: "",
        color: "",
        exam_release: true,
        admission_date: "",
        exam: {},
      },
      broker_leader_name: "",
      brokerLeaders: [],
      table: {
        title: 'Lista de Assessores',
        columns: tableColumns,
      },
      isLoading: true,
      isFormLoading: false,
      base64Image: null,
      questions: questionsArray,
      isErrorClass: true,
      isGenerating: false,
      isCreatingAccount: false,
      isPageLoading: true,
      brokerHasAccount: false,
      pessoa: {},
      old_email: '',
    };
  },
  async mounted() {
    await this.getBrokerLeaders();
    await this.getBroker();
    await this.checkUserBroker();
    this.isPageLoading = false;
  },
  validations: {
    broker: {
      name: {
        valid: (name) => nonEmpty(name),
      },
      email: {
        valid: (email) => nonEmpty(email),
      },
      cpf: {
        valid: (_cpf) => cpf(_cpf),
      },
      chave_pix: {
        valid: (chave_pix) => nonEmpty(chave_pix),
      },
      hubspot_id: {
        valid: (hubspot_id) => nonEmpty(hubspot_id),
      },
      channel: {
        valid: (channel) => nonEmpty(channel),
      },
      level: {
        valid: (level) => nonEmpty(level),
      },
      admission_date: {
        valid: (admission_date) => nonEmpty(admission_date) && admission_date !== null,
      }
    },
    broker_leader_name: {
      valid: (broker_leader_name) => nonEmpty(broker_leader_name),
    },
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
    brokerId() {
      return this.$route.params.id;
    },
    isFormValid() {
      return (
        !this.$v.broker.name.valid ||
        !this.$v.broker.email.valid ||
        !this.$v.broker.cpf.valid ||
        !this.$v.broker.chave_pix.valid ||
        !this.$v.broker.channel.valid ||
        !this.$v.broker.level.valid ||
        !this.$v.broker_leader_name.valid ||
        !this.$v.broker.hubspot_id.valid ||
        !this.$v.broker.admission_date.valid
    );
    },
  },
  
  methods: {
    async getBroker() {
      this.$refs.topProgress.start();

      try {
        const broker = await brokersService.getBroker(this.brokerId);
        let findBrokerLeader = this.brokerLeaders.find(leader => leader.id === broker.leader_id);
        if (!findBrokerLeader) findBrokerLeader = "";
        this.broker_leader_name = findBrokerLeader.name;
        this.broker = { 
          ...broker, 
          status: broker.status === 'INATIVO' ? false : true, 
          exam_release: broker.exam_release || false,
          exam: broker.exam || {}
        };
        this.old_email = broker.email;
        this.$refs.topProgress.done();
      } catch (error) {
        console.log(error)
        this.$notify({
          component: GetBrokerErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
    async checkUserBroker() {
      const signInMethods = await authenticationService.getUserByEmail(this.broker.email);

      if (signInMethods.length > 0) {
        this.brokerHasAccount = true;
      }
    },
    async updateBroker() {
      this.$refs.topProgress.start();
      this.isFormLoading = true;

      try {
        const logUser = {
          user: {
            email: this.currentPessoa.email.email
          }
        };
        logsService.createNewLog(logUser, '', 'Atualizar Assessor', '', this.broker);

        const formattedBroker = {
          ...this.broker,
          status: this.broker.status === true ? 'ATIVO' : 'INATIVO',
          exam_release: this.broker.exam_release ? this.broker.exam_release : false,
        }

        if (this.old_email !== formattedBroker.email) {
          await brokersService.updateBrokerEmail(formattedBroker.email, this.brokerId, this.old_email);
          await brokersService.updateBroker(this.brokerId, formattedBroker);
        } else {
          await brokersService.updateBroker(this.brokerId, formattedBroker);
        }

        this.$refs.topProgress.done();

        this.$router.push('/brokers');

        this.$notify({
          component: UpdatedBrokerNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        console.log(error);
        this.$refs.topProgress.done();
        this.$notify({
          component: UpdatedBrokerErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      } finally {
        this.isFormLoading = false;
      }
    },
    async createBrokerAccount() {
      try {
        this.isCreatingAccount = true;
        const pessoa = getInitialPessoaValue(this.broker.name, this.broker.email);

        this.pessoa = pessoa;

        const tempPassword = await authenticationService.generateTempPassword();

        const firebaseResponse = await authenticationService.createNewUser(
          this.broker.email,
          tempPassword,
        );

        await investorsService.createNewInvestor(
          this.pessoa,
        );

        await claimsService.setNewUserClaim(firebaseResponse.user.uid, firebaseResponse.user.email, 'assessor');

        this.brokerHasAccount = true;
      
        this.$notify({
          component: SuccessNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
        this.$notify({
          title: 'Senha temporária, envie para o assessor.',
          message: `A senha temporária do broker é: ${tempPassword}`,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 1000000,
          closeOnClick: false,
        });
      } catch(err) {
        console.log(err);
        this.$notify({
          component: ErrorRegisterNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      } finally {
        this.isCreatingAccount = false;
      }
    },
    async getBrokerLeaders() {
      this.isLoading = true
      const brokerLeaders = await brokersService.getAllBrokerLeaders() || [];
      
      const formattedBrokers = brokerLeaders.map(broker => ({ id: broker.id, name: broker.name, color: broker.team_color }));

      this.brokerLeaders = formattedBrokers;

      if (this.brokerLeaders.lenght === 0) {
        this.$notify({
          component: EmptyBrokersNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.isLoading = false;
    },
    addLeader(leader) {
      const { color, id, name } = leader;
      this.broker.leader_id = id;
      this.broker.color = color;
      this.broker_leader_name = name;
    },
    toggleCheckbox(whichCheck) {
      if (whichCheck === 'status') {
        this.broker.status = !this.broker.status;
      } else {
        this.broker.exam_release = !this.broker.exam_release;
      }
    },
    goToEvalExam() {
      this.$router.push(`/exam/eval/${this.$route.params.id}`);
    },
    reviewBrokerSurvey() {
      this.$router.push(`/survey/${this.$route.params.id}`);
    },
    async imageUrlToBase64(imageUrl) {
      try {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      } catch (error) {
        console.error("Error converting image to base64:", error);
        return null;
      }
    },
    async convertImageUrlToBase64() {
      const imageUrl = "@/assets/images/logo_ajx.png"; // Replace this with your image URL
      this.base64Image = await this.imageUrlToBase64(imageUrl);
    },
    onProgress(event) {
      if (event === 100) {
        this.isGenerating = false;
      }
    },
    generateReport() {
      this.isGenerating = true;
      this.$refs.html2Pdf.generatePdf();
    },
    async redefinePassword() {
      this.$refs.topProgress.start();
      try {
        await authenticationService.updatePassword(this.broker.email);

        this.$notify({
          title: 'Alteração de senha solicitada!',
          message: 'Enviamos um e-mail para o usuário redefinir sua senha.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
        this.$refs.topProgress.done();
      }
      catch (error) {
        console.log(error);
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },
  },
  watch: {
    data() {
      this.isFormValid()
    }
  }
};
</script>

<template>
  <div class="add-user">
    <card v-if="!isPageLoading">
      <form @submit.prevent="updateBroker()">
        <h5 slot="header" class="title">
          <router-link to="/brokers" class="fa fa-arrow-left"></router-link>
          Atualizar Assessor
        </h5>

        <div class="row">
          <div class="col-md-4 pr-md-1 mb-2">
            <base-input
              label="Nome*"
              placeholder="Nome"
              v-model="broker.name"
              required
            ></base-input>
            <div class="error" v-if="!$v.broker.name.valid">
              Informe um nome válido.
            </div>
          </div>

          <div class="col-md-4 pl-md-1">
            <label for="" class="control-label">Email*</label>
            <input
              v-model="broker.email"
              data-testId="email"
              type="email"
              class="form-control"
              placeholder="Digite seu e-mail"
              required
            />
            <div class="error" v-if="!$v.broker.email.valid">
              Informe um e-mail válido.
            </div>
          </div>

          <div class="col-md-4 pl-md-1">
            <base-input
              label="CPF*"
              v-model="broker.cpf"
              placeholder="Digite seu CPF"
              v-mask="['###.###.###-##']"
              required
            ></base-input>
            <div class="error" v-if="!$v.broker.cpf.valid">
              Informe um CPF válido.
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 pr-md-1 mb-2">
            <base-input
              label="Chave pix*"
              placeholder="Chave pix"
              v-model="broker.chave_pix"
              required
            ></base-input>
            <div class="error" v-if="!$v.broker.chave_pix.valid">
              Informe uma chave pix válida.
            </div>
          </div>

          <div class="col-md-4 pl-md-1">
            <base-input
              label="Hubspot ID*"
              placeholder="Hubspot ID"
              v-model="broker.hubspot_id"
              required
            ></base-input>
            <div class="error" v-if="!$v.broker.hubspot_id.valid">
              Informe um hubspot ID válido.
            </div>
          </div>

          <div class="col-md-4 pl-md-1">
            <base-input label="Data de Admissão*" placeholder="Data de Admissão" >
              <date-picker 
                v-model="broker.admission_date"
                v-bind:value="broker.admission_date"
                valueType="format" 
                format="DD/MM/YYYY"
              ></date-picker>
            </base-input>
            <div class="error" v-if="!$v.broker.admission_date.valid">
              Informe a data de admissão do assessor.
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 pr-md-1">
            <label for="">Selecione o canal*</label>
            <select class="form-control" v-model="broker.channel">
              <option
                v-for="(channel, index) in ['Life Office', 'Híbrido', 'Monetize']"
                :value="channel"
                :key="index"
              >
                {{ channel }}
              </option>
            </select>
            <div class="error mt-2" v-if="!$v.broker.channel.valid">
              Informe um canal válido.
            </div>
          </div>

          <div class="col-md-4 pr-md-1">
            <label for="">Selecione o nível*</label>
            <select class="form-control" v-model="broker.level">
              <option
                v-for="(level, index) in ['Júnior', 'Pleno', 'Sênior', 'Master']"
                :value="level"
                :key="index"
              >
                {{ level }}
              </option>
            </select>
            <div class="error mt-2" v-if="!$v.broker.level.valid">
              Informe um nível válido.
            </div>
          </div>

          <div class="col-md-4 pr-md-1">
            <base-input
              label="Escolha um líder*"
              :value="this.broker_leader_name"
              :disabled="true"
            ></base-input>
            <div class="error mt-2" v-if="!this.broker_leader_name">
              Informe um líder válido.
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 pr-md-1 mt-2">
            <base-input
              label="Cor do time"
              :value="this.broker.color"
              :disabled="true"
            ></base-input>
          </div>

          <div class="col-md-4 pr-md-1 mt-2">
            <base-input
              label="Posição"
              :value="this.broker.position"
              :disabled="true"
            ></base-input>
          </div>

          <div class="col-md-4 pr-md-1 align-self-center">
            <input
              name="status"
              ref="status"
              type="checkbox"
              v-model="broker.status"
            />  
            <label
              for="status"
              style="margin-left: 5px;"
              @click="toggleCheckbox('status')"
            >Ativo</label>
            <input
              name="exam_release"
              ref="exam_release"
              type="checkbox"
              class="ml-4"
              v-model="broker.exam_release"
            />  
            <label
              for="exam_release"
              style="margin-left: 5px;"
              @click="toggleCheckbox('exam_release')"
            >Liberar prova</label>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 pr-md-1">
            <base-input
              label="Progresso da prova"
              :value="Object.keys(this.broker.exam).length > 0 ? this.broker.exam.exam_progress : 'Não inicializado'"
              disabled
            ></base-input>
          </div>

          <div class="col-md-4 pr-md-1">
            <base-input
              label="Resultado da prova"
              :value="Object.keys(this.broker.exam).length > 0 ? this.broker.exam.exam_approval : 'Não inicializado'"
              disabled
            ></base-input>
          </div>

          <div class="col-md-12 pr-md-1" style="margin-top: 21.5px;">
            <button
              type="button"
              class="btn btn-sm"
              :disabled="this.broker.exam && this.broker.exam.exam_progress !== 'Finalizado'"
              @click="goToEvalExam()"
            >
              Corrigir prova
            </button>

            <button
              type="button"
              class="btn btn-sm ml-4"
              @click="generateReport()"
              :disabled="this.isGenerating || !(this.broker.exam.exam_approval === 'Reprovado' || this.broker.exam.exam_approval === 'Aprovado')"
            >
              Gerar PDF da prova
            </button>

            <button
              type="button"
              class="btn btn-sm ml-4"
              :disabled="!this.broker.survey"
              @click="reviewBrokerSurvey()"
            >
              Verificar respostas da pesquisa
            </button>
          </div>

          <div class="col-md-6 pr-md-1" style="margin-top: 21.5px;">
            <button
              type="button"
              class="btn btn-sm btn-warning"
              @click="redefinePassword"
            >
              Redefinir senha
            </button>

            <button
              v-if="!this.brokerHasAccount"
              type="button"
              class="btn btn-sm ml-4"
              :disabled="this.isCreatingAccount"
              @click="createBrokerAccount()"
            >
              Criar conta para o assessor
            </button>
          </div>
        </div>

        <div>
          <h2 class="my-4">Selecione um líder</h2>
          <div class="table-responsive">
            <brokers-table
              :data="brokerLeaders"
              :columns="table.columns"
              :selectLeader="true"
              thead-classes="text-primary"
              @addLeader="addLeader"
            ></brokers-table>

            <double-bounce v-if="isLoading"></double-bounce>
          </div>
        </div>
        
        <button type="submit" class="btn btn-sm" :disabled="isFormValid || isFormLoading">Atualizar</button>
      </form>
    </card>

    <vue-topprogress ref="topProgress"></vue-topprogress>
    <vue-html2pdf
      :show-layout="false"
      :enable-download="true"
      :preview-modal="true"
      :manual-pagination="true"
      filename="prova_corrigida"
      :pdf-quality="2"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      ref="html2Pdf"
      @progress="onProgress($event)"
    >
      <section slot="pdf-content" class="pdf-content">
        <div class="header-pdf">
          <img src="@/assets/images/logo_ajx.png" alt="AJX Logo" style="width: 250px; height: auto;">
        </div>
        <section class="pdf-item">
          <h1 class="pdf-title">Avaliação para Especialista em Mentalidade Financeira da AJX Capital (Corrigida)</h1>
        </section>
        <section class="pdf-item">
          <div class="content">
            <h2 class="broker-name">Nome: {{ this.broker.exam.name }}</h2>
            <!-- <p>CPF: {{ this.currentPessoa. }}</p> -->
            <p class="overall-score">Acertos: {{ this.broker.exam.percent }}%</p>
          </div>
        </section>
        <section class="pdf-item">
          <div class="content">
            <ul>
              <h3 class="pdf-subtitle">Questões e Avaliação</h3>
              <li class="item-pdf" v-for="question in broker.exam.answers" :key="question.id">
                <strong class="question">{{ question.id }}. {{ questions[question.id - 1].question }}</strong>
                <strong class="question" v-if="questions[question.id - 1].options" v-for="option in questions[question.id - 1].options"><br />{{ option }}</strong>
                <p class="answer">Resposta: {{ question.answer }}</p>
                <p :class="{ 'error': question.evaluate === 'Incorreto' && isErrorClass, 'correction': question.evaluate !== 'Incorreto' && true }">Avaliação: {{ question.evaluate }}</p>
                <div 
                  v-if="
                    question.id === 4 || 
                    question.id === 10 || 
                    question.id === 15 ||
                    question.id === 20 ||
                    question.id === 25 ||
                    question.id === 30 ||
                    question.id === 35 ||
                    question.id === 40 ||
                    question.id === 45 ||
                    question.id === 50 ||
                    question.id === 55 ||
                    question.id === 60 ||
                    question.id === 64
                  " class="html2pdf__page-break"></div>
              </li>
            </ul>
          </div>
        </section>
      </section>
    </vue-html2pdf>
  </div>
</template>


<style scoped>
  .pdf-content {
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.6;
  }

  .header-pdf {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    background-color: #1a1e34;
  }

  .pdf-title {
    color: black;
    text-align: center;
    margin: 40px 0;
  }

  .content {
    padding: 0 30px;
  }

  .broker-name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
    color: black;
  }

  .cpf {
    font-size: 14px;
    margin-bottom: 5px;
    color: black;
  }

  .overall-score {
    font-size: 16px;
    font-weight: bold;
    color: black;
  }

  .pdf-subtitle {
    font-size: 20px;
    font-weight: bold;
    margin: 20px 0;
    color: black;
    text-align: center;
  }

  .question {
    font-size: 16px;
    font-weight: bold;
    color: black;
  }

  .answer {
    font-size: 14px;
    margin-left: 20px;
    color: black;
  }

  .correction {
    font-size: 14px;
    margin-left: 20px;
    color: #008000; /* Green color for correct answers */
  }

  .error {
    font-size: 14px;
    margin-left: 20px;
    color: #f06160;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  .item-pdf {
    margin-bottom: 15px;
  }

</style>
