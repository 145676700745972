<template>
  <div>
    <a href="/invoices" class="nav-link">NFs</a>
    <a href="/certifications" class="nav-link">Certificações</a>
    <a href="#" class="nav-link">RPA (Em breve)</a>
    <a href="/sheets" class="nav-link">Google Sheets</a>
  </div>
</template>
<script>
export default {
  name: 'sidebar-submenu-broker-area',
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  },
};
</script>
