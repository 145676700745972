<script>
import { vueTopprogress } from 'vue-top-progress';
import brokersService from '@/services/Brokers/brokersService';
import NoticiasMercadoTopBar from '@/components/NoticiasMercado/NoticiasMercadoTopBar.vue';
import NoticiasMercadoTopBanner from '@/components/NoticiasMercado/NoticiasMercadoTopBanner.vue';
import NoticiasMercadoAoVivo from '@/components/NoticiasMercado/NoticiasMercadoAoVivo.vue';
import NoticiasMercadoNovosCursos from '@/components/NoticiasMercado/NoticiasMercadoNovosCursos.vue';
import NoticiasMercadoMaisPopular from '@/components/NoticiasMercado/NoticiasMercadoMaisPopular.vue';
import NoticiasMercadoMeusFavoritos from '@/components/NoticiasMercado/NoticiasMercadoMeusFavoritos.vue';
import PlaylistCarousel from '@/components/NoticiasMercado/PlaylistCarousel.vue';
import EbooksRecent from '@/components/News/EbooksRecent.vue';
import playlistService from '@/services/VideoContent/playlistService';
import store from '@/store/index';

const tableColumns = ['Nome'];

export default {
  components: {
    vueTopprogress,
    NoticiasMercadoTopBar,
    NoticiasMercadoTopBanner,
    NoticiasMercadoAoVivo,
    NoticiasMercadoNovosCursos,
    NoticiasMercadoMaisPopular,
    NoticiasMercadoMaisPopular,
    NoticiasMercadoMeusFavoritos,
    PlaylistCarousel,
    EbooksRecent,
  },
  data() {
    return {
      table: {
        title: 'Educação',
        columns: [...tableColumns],
      },
      isLoading: true,
      playlist: {},
      brokerInfo: {},
    };
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
    userClaim() {
      const Claim = this.$store.getters.userClaim;

      return Claim.role;
    },
  },
  mounted() {
    this.getBroker();
    if (this.userClaim === 'assessor') {
      this.getFirstSeason();
    }
  },
  methods: {
    async getBroker() {
      const broker = await brokersService.getBrokerByEmail();

      if (broker.image) {
        const url = await brokersService.getDownloadURL(broker.image);
        store.commit('SET_PROFILE_IMAGE', url);
      }

      this.brokerInfo = broker;
    },
    async getFirstSeason() {
      try {
        const playlist = await playlistService.findPlaylistByTitle(
          '1 Temporada',
        );

        this.playlist = playlist;
      } catch (err) {
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<template>
  <div>
    <div
      v-if="this.brokerInfo.exam_release && !this.brokerInfo.exam"
      role="alert"
      class="alert alert-info"
    >
      <span>
        Sua prova já está liberada para realização.
        <router-link to="/exam" tag="span" style="cursor: pointer !important">
          <b>Clique aqui para ir para a prova</b>.
        </router-link>
      </span>
    </div>

    <div
      v-if="
        this.brokerInfo.exam_release &&
        this.brokerInfo.exam &&
        this.brokerInfo.exam.exam_progress === 'Em andamento'
      "
      role="alert"
      class="alert alert-info"
    >
      <span>
        Você ainda não finalizou sua prova.
        <router-link to="/exam" tag="span" style="cursor: pointer !important">
          <b>Clique aqui para ir para a prova</b>.
        </router-link>
      </span>
    </div>

    <div
      v-if="
        this.brokerInfo.exam_release &&
        this.brokerInfo.exam &&
        this.brokerInfo.exam.exam_approval === 'Pendente'
      "
      role="alert"
      class="alert alert-info"
    >
      <span style="color: #f3f3f3ee"> Sua prova ainda está em correção. </span>
    </div>

    <noticias-mercado-top-bar></noticias-mercado-top-bar>

    <div class="row">
      <div class="col-md-12">
        <noticias-mercado-meus-favoritos></noticias-mercado-meus-favoritos>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <noticias-mercado-ao-vivo></noticias-mercado-ao-vivo>
      </div>
      <div class="col-md-12">
        <div class="row" v-if="this.brokerInfo.channel !== 'Monetize'">
          <div class="col-md-12">
            <playlist-carousel
              v-if="!isLoading"
              :hasLoadMore="true"
              :playlistName="`AJX Educacional - ${playlist.title}`"
              :playlist="playlist.linked"
            ></playlist-carousel>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <noticias-mercado-top-banner></noticias-mercado-top-banner>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <noticias-mercado-novos-cursos
              v-if="this.userClaim !== 'assessor'"
            ></noticias-mercado-novos-cursos>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <noticias-mercado-mais-popular></noticias-mercado-mais-popular>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <ebooks-recent></ebooks-recent>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
