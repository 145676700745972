<script>
import NewsTable from '@/components/News/NewsTable.vue';
import newsService from '@/services/News/newsService';

import {vueTopprogress} from 'vue-top-progress';
import {DoubleBounce} from 'vue-loading-spinner';

const tableColumns = ['Título', 'Tipo', 'Thumb', 'Data de cadastro', 'Ações'];

export default {
  components: {
    NewsTable,
    vueTopprogress,
    DoubleBounce,
  },
  data() {
    return {
      table: {
        title: 'Ebooks',
        columns: [...tableColumns],
      },
      isLoading: true,
      ebooks: []
    };
  },
  mounted() {
    this.getEbooks();
  },
  methods: {
    async getEbooks() {
      try {
        const ebooks = await newsService.getNewsAdmin();

        this.ebooks = ebooks;
      } catch (error) {
        console.log(error);
        this.$notify({
          title: 'Ops',
          message: 'Houve algum erro ao buscar os e-books',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async deleteEbook(preDeletedItem) {
      try {
        await newsService.deleteNews(preDeletedItem.item.id);

        await this.getEbooks();

        this.$notify({
          title: 'Ok',
          message: 'E-book foi removido com sucesso!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        preDeletedItem = {};
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: error.message,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<template>
  <div class="users-list">
    <div class="row">
      <div class="col-12">
        <card
        :title="table.title"
        :addItems="true"
        :itemType="'Ebook'"
        :routerType="'ebooks-content'">

          <div class="table-responsive">
            <news-table
              :data="ebooks"
              :columns="table.columns"
              :hasActions="false"
              thead-classes="text-primary"
              @deleteItem="deleteEbook"
            ></news-table>

            <double-bounce v-if="isLoading"></double-bounce>
          </div>

        </card>
      </div>
    </div>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
