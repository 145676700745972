<script>
import TopNavbar from './TopNavbar.vue';
import DashboardContent from './Content.vue';
import settingsService from '@/services/Settings/settingsService';
import brokersService from '@/services/Brokers/brokersService';

export default {
  components: {
    TopNavbar,
    DashboardContent,
  },
  async mounted() {
    console.debug('mounted dashboard layout');
    this.socialTransformationEnabled =
      await settingsService.socialTransformationEnabled();
      this.features = await settingsService.getFeaturesToggle();

    this.getBroker();
  },
  computed: {
    userClaim() {
      const Claim = this.$store.getters.userClaim;

      return Claim.role;
    },
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
  },
  data() {
    return {
      layout: this.$route.meta.layout,
      modalidade: process.env.VUE_APP_TYPE,
      socialTransformationEnabled: undefined,
      features: {},
      broker_exam_release: false,
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    async getBroker() {
      if (this.userClaim !== 'assessor') {
        return;
      }

      const broker = await brokersService.getBrokerByEmail(this.currentPessoa.email.email);
      if (broker) {
        this.broker_exam_release = broker.exam_release;
      }
    }
  },
};
</script>

<template>
  <div class="wrapper">
    <side-bar v-if="!layout">
      <template slot="links">

        <sidebar-link
          v-if="userClaim !== 'assessor'"
          to="/dashboard"
          :name="$t('sidebar.dashboard')"
          icon="tim-icons icon-chart-pie-36"
        />

        <template>
          <sidebar-link
            v-if="userClaim === 'master' || userClaim === 'gestor'"
            to="/users"
            :name="$t('sidebar.users')"
            icon="tim-icons icon-single-02"
          />
          <!-- <sidebar-link
            v-if="userClaim === 'master' || userClaim === 'gestor' || userClaim === 'curador'"
            to="/investors"
            :name="$t('sidebar.investors')"
            icon="tim-icons icon-wallet-43"
          ></sidebar-link> -->

          <sidebar-link
            v-if="userClaim === 'master'"
            to="/investments-profile"
            :name="$t('sidebar.investments-profile')"
            icon="tim-icons icon-badge"
          />
          <!-- <sidebar-link
            to="/financing"
            :name="$t('sidebar.financing')"
            icon="tim-icons icon-money-coins"
            v-if="modalidade == 'doacao'"
          ></sidebar-link>

          <sidebar-link
            to="/financing"
            :name="$t('sidebar.financing')"
            icon="tim-icons icon-money-coins"
            v-if="modalidade == 'equity' && (userClaim === 'master' || userClaim === 'gestor' || userClaim === 'curador' || features.criar_oferta_pelo_usuario)"
          ></sidebar-link> -->

          <!-- <sidebar-link
            to="/market-news"
            :name="$t('sidebar.market-news')"
            icon="tim-icons icon-tv-2"
          ></sidebar-link> -->

          <h5
            v-if="userClaim === 'master' || userClaim === 'gestor'"
            class="card-title mt-3 ml-4"
            style="color: #344563">
              Relatórios
          </h5>

          <sidebar-link
            v-if="userClaim === 'master' || userClaim === 'gestor'"
            to="/logs"
            :name="$t('sidebar.logs')"
            icon="tim-icons icon-notes"
          ></sidebar-link>

          <sidebar-link
            v-if="userClaim === 'master' || userClaim === 'gestor'"
            to="/reports"
            :name="$t('sidebar.reports')"
            icon="tim-icons icon-upload"
          ></sidebar-link>

          <sidebar-link
            v-if="userClaim === 'master' || userClaim === 'gestor'"
            to="/orders"
            :name="$t('sidebar.orders')"
            icon="tim-icons icon-single-copy-04"
          ></sidebar-link>

          <h5
            v-if="userClaim === 'master' || userClaim === 'gestor'"
            class="card-title mt-3 ml-4"
            style="color: #344563">
              Parâmetros
          </h5>

          <sidebar-link
            v-if="userClaim === 'master' || userClaim === 'gestor'"
            to="/brokers"
            :name="$t('sidebar.brokers')"
            icon="tim-icons icon-single-02"
          />

          <sidebar-link
            to="/market-news"
            :name="userClaim === 'assessor' ? 'Página inicial' : $t('sidebar.education')"
            icon="tim-icons icon-tv-2"
          ></sidebar-link>

          <sidebar-link
            v-if="userClaim === 'master' || userClaim === 'gestor' || userClaim === 'assessor'"
            to=""
            :name="$t('sidebar.academy')"
            icon="tim-icons icon-single-copy-04"
          />

          <sidebar-link
            v-if="userClaim === 'master' || userClaim === 'gestor' || userClaim === 'assessor'"
            to=""
            :name="$t('sidebar.brokerArea')"
            icon="tim-icons icon-single-02"
          />

          <sidebar-link
            v-if="userClaim === 'master' || userClaim === 'gestor'"
            to="/benefits"
            :name="$t('sidebar.benefits')"
            icon="tim-icons icon-single-02"
          />

          <sidebar-link
            v-if="userClaim === 'master' || userClaim === 'gestor'"
            to="/ebooks"
            :name="$t('sidebar.ebooks')"
            icon="tim-icons icon-single-copy-04"
          ></sidebar-link>

          <sidebar-link
            v-if="userClaim === 'master' || userClaim === 'gestor'"
            to="/video-content"
            :name="$t('sidebar.video-content')"
            icon="tim-icons icon-video-66"
          ></sidebar-link>

          <sidebar-link
            v-if="userClaim === 'master' || userClaim === 'gestor'"
            to="/admin-playlists"
            :name="$t('sidebar.playlist-content')"
            icon="tim-icons icon-triangle-right-17"
          ></sidebar-link>

          <!-- <sidebar-link
            v-if="userClaim === 'master'"
            to="/attachments"
            :name="$t('sidebar.attachments')"
            icon="tim-icons icon-attach-87"
          ></sidebar-link> -->

          <!-- <sidebar-link
            v-if="userClaim === 'master' || userClaim === 'gestor'"
            to="/terms-of-use"
            :name="$t('sidebar.terms-of-use')"
            icon="tim-icons icon-single-copy-04"
          ></sidebar-link> -->

        </template>

        <!-- <sidebar-link
          v-if="userClaim !== 'master'"
          to="/profile"
          :name="$t('sidebar.profile')"
          icon="tim-icons icon-single-02"
        ></sidebar-link> -->
        <sidebar-link
          v-if="userClaim == 'master'"
          to="/dynamic-fields"
          :name="$t('sidebar.dynamicFields')"
          icon="tim-icons icon-html5"
        ></sidebar-link>

        <sidebar-link
          v-if="userClaim == 'master'"
          to="/settings"
          :name="$t('sidebar.settings')"
          icon="tim-icons icon-html5"
        ></sidebar-link>

        <sidebar-link
          v-if="userClaim == 'investor'"
          to="/profile"
          :name="'Meus Dados'"
          icon="tim-icons icon-single-02"
        ></sidebar-link>

        <sidebar-link
          v-if="userClaim == 'investor'"
          to="/my-orders"
          :name="'Meus Aportes'"
          icon="tim-icons icon-wallet-43"
        ></sidebar-link>

        <sidebar-link
          v-if="userClaim == 'investor'"
          to="#"
          :name="$t('sidebar.financing') + ' Disponíveis'"
          fnc_name="fnc-ccbs-disponveis"
          :fnc="true"
          icon="tim-icons icon-money-coins"
        ></sidebar-link>
        <sidebar-link
            v-if="userClaim === 'master' || userClaim === 'gestor' || userClaim === 'assessor'"
            to=""
            :name="$t('sidebar.examsFeedback')"
            icon="tim-icons icon-single-copy-04"
        ></sidebar-link>
        <sidebar-link
          v-if="userClaim === 'master' || userClaim === 'gestor' || (userClaim === 'assessor' && this.broker_exam_release)"
          to="/meetings"
          :name="$t('sidebar.meets')"
          icon="tim-icons icon-single-02"
        ></sidebar-link>
        <sidebar-link
          v-if="(userClaim === 'assessor')"
          to="/profile"
          :name="'Meus dados'"
          icon="tim-icons icon-single-02"
        ></sidebar-link>
        <sidebar-link
          v-if="userClaim == 'investor'"
          to="#"
          :name="'Voltar para o Site'"
          fnc_name="fnc-voltar-site"
          :fnc="true"
          icon="tim-icons icon-link-72"
        ></sidebar-link>

        <sidebar-link
          v-if="userClaim == 'investor'"
          to="#"
          :name="'Sair'"
          fnc_name="fnc-sair"
          :fnc="true"
          icon="tim-icons icon-button-power"
        ></sidebar-link>

        <sidebar-link
            v-if="
              (userClaim === 'master' ||
              userClaim === 'gestor' ||
              userClaim === 'curador')
              && modalidade == 'doacao'
            "
            to="/social-transformation/agents"
            :name="$t('sidebar.social-agent')"
            icon="tim-icons icon-single-02"
          />
          <sidebar-link
            v-if="userClaim === 'master' || userClaim === 'gestor'"
            to="/categorias"
            :name="$t('sidebar.categorias')"
            icon="tim-icons icon-tag"
          />
          <sidebar-link
            v-if="userClaim === 'master' || userClaim === 'gestor'"
            to="/banks"
            :name="$t('sidebar.banks')"
            icon="tim-icons icon-bank"
          />
          <sidebar-link
            v-if="userClaim === 'master' || userClaim === 'gestor'"
            to="/segment-interest"
            :name="$t('sidebar.segment-intereset')"
            icon="tim-icons icon-chart-bar-32"
          />
          <sidebar-link
            v-if="userClaim === 'master' || userClaim === 'gestor'"
            to="/states"
            :name="$t('sidebar.states')"
            icon="tim-icons icon-square-pin"
          />
          <sidebar-link
            v-if="userClaim === 'master' || userClaim === 'gestor'"
            to="/values"
            :name="$t('sidebar.values')"
            icon="tim-icons icon-coins"
          />
          <div class="text-center">
            <img src="@/assets/images/avod.png" alt="Developer Logo" style="width: 150px !important;" class="p-4" />
          </div>
      </template>
    </side-bar>

    <div class="main-panel" v-if="!layout">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>
    </div>

    <dashboard-content
      @click.native="toggleSidebar"
      v-if="layout"
      :layout="layout"
    >
    </dashboard-content>
  </div>
</template>
