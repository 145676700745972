<script>
import { vueTopprogress } from 'vue-top-progress';
import { DoubleBounce } from 'vue-loading-spinner';
import OrdersDinamicTable from '@/components/Orders/OrdersDinamicTable';
import getRemuneracaoAcumulada from '../../../functions/getRemuneracaoAcumulada';
import ordersService from '@/services/Orders/ordersService';
import financingService from '@/services/Financing/financingService';
import moment from 'moment';


export default {
  components: {
    OrdersDinamicTable, 
    vueTopprogress,
    DoubleBounce,
  },
  data() {
    return {
      tableConfirmados: {
        title: 'Aportes Realizados por Situação',
        columns: ['Oferta', 'Valor do Investimento', 'Vencimento CCB', 'Andamento', 'Remuneração Acumulada', 'Valor Atualizado', 'Situação', 'Assessor', 'Gateway', 'Ações'],
        type: 'confirmed',
        data: [],
      },
      tablePendentes: {
        title: 'Aportes Pendentes',
        columns: ['Oferta', 'Nº CCB', 'Situação', 'Ações'],
        type: 'pending',
        data: [],
      },
      confirmedOrderOptions: [
        // { value: 'status', label: 'Status' },
        // { value: 'liquidada', label: 'Liquidada' },
        // { value: 'broker', label: 'Assessor' },
        // { value: 'tipo_pagamento', label: 'Tipo de pagamento' },
        { value: 'data_contratacao', label: 'Data de contratação' },
        { value: 'data_pagamento', label: 'Data de pagamento' },
        { value: 'data_vencimento', label: 'Data de vencimento' },
        { value: 'data_liquidacao', label: 'Data de liquidação' },
      ],
      confirmedStatusOptions: [
        { value: 'Pendente', label: 'Pendente' },
        { value: 'Vencido', label: 'Vencido' },
        { value: 'Pago', label: 'Pago' },
        { value: 'Liquidado', label: 'Liquidado' },
      ],
      page: 1,
      perPage: 10,
      pages: [],
      person: {},
      isLoading: false,
      closeModal: false,
    };
  },
  computed: {
    investorId() {
      return this.$route.params.id;
    },
    loggedPessoa() {
      return this.$store.getters.getPessoa;
    }
  }
};
</script>
    <!-- invested_capital: number;
    accumulated_income: number;
    average_monthly_income: number;
    confirmed_investments: number; -->
<template>
  <div class="orders-list">
    <div class="row">
      <div class="col-lg-12">
        <div class="card"><!---->
          <div class="card-header">
            <h5 class="card-title">
              <router-link
                  to="/dashboard"
                  class="fa fa-arrow-left"
                  title="Voltar"
                ></router-link>
              Aportes
            </h5>
            <p>
              Relação de Cédulas de Crédito Bancário Emitidas
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <orders-dinamic-table
          v-if="!isLoading"
          :title="tableConfirmados.title"
          :type="tableConfirmados.type"
          :columns="tableConfirmados.columns"
          :isAllOrdersPage="true"
          :confirmedOrderOptions="confirmedOrderOptions"
          :confirmedStatusOptions="confirmedStatusOptions"
        />
      </div>
    </div> 
    <!-- <div class="row">
      <div class="col-md-12 text-center">
        <double-bounce v-if="isLoading"></double-bounce>
      </div>
    </div> -->
    <!-- <div class="row">
      <div class="col-lg-12">
        <orders-table
          v-if="!isLoading"
          :title="tablePendentes.title"
          :type="tablePendentes.type"
          :columns="tablePendentes.columns"
          :data="tablePendentes.data"
          :isAllOrdersPage="true"
          @reloadData="getOrders"
        />
      </div>
    </div>  -->
  <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
